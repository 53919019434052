import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 103 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_269_16)">
      <path
        d="M48.4824 0C36.8506 0.668976 25.9411 5.19665 17.2344 12.9685L30.7008 26.4568C35.7683 22.2314 41.9222 19.6784 48.4824 19.0779V0Z"
        fill="currentColor"
      />
      <path
        d="M26.4319 30.7285L12.9675 17.2422C5.20023 25.961 0.673867 36.8863 0.00195312 48.541H19.0489C19.6524 41.9681 22.2073 35.8043 26.4319 30.7285Z"
        fill="currentColor"
      />
      <path
        d="M83.9513 48.541H102.998C102.326 36.8863 97.8 25.961 90.0347 17.2422L76.5703 30.7285C80.7949 35.8043 83.3498 41.9681 83.9533 48.541H83.9513Z"
        fill="currentColor"
      />
      <path
        d="M83.9472 54.5859C83.5247 59.2406 82.197 63.1234 80.6299 66.2386C82.1608 68.6022 83.4362 70.599 84.2791 71.9269C86.5745 75.5398 86.0655 80.1783 83.0439 83.2068C82.3096 83.9423 81.4708 84.5347 80.5635 84.972L83.6997 88.1133C84.2892 88.7037 85.0637 88.9999 85.8362 88.9999C86.6087 88.9999 87.3832 88.7037 87.9726 88.1133C97.0093 79.062 102.28 67.2522 103 54.5859H83.9472Z"
        fill="currentColor"
      />
      <path
        d="M19.053 54.586H0C0.722207 67.2522 5.9909 79.0621 15.0275 88.1134C15.617 88.7038 16.3915 89 17.164 89C17.9365 89 18.711 88.7038 19.3004 88.1134L28.4558 78.9412C29.6366 77.7584 29.6366 75.8421 28.4558 74.6593C26.0658 72.2655 20.0488 65.5657 19.051 54.584L19.053 54.586Z"
        fill="currentColor"
      />
      <path
        d="M85.7656 12.9685C77.0589 5.19665 66.1493 0.668976 54.5176 0V19.0759C61.0778 19.6764 67.2316 22.2293 72.2992 26.4548L85.7656 12.9664V12.9685Z"
        fill="currentColor"
      />
      <path
        d="M58.2048 44.8978C54.5072 41.1942 48.4922 41.1942 44.7946 44.8978C41.0971 48.6013 41.0971 54.6261 44.7946 58.3297C49.4658 63.0085 72.4176 77.6877 75.0187 79.346C76.2117 80.1057 77.7727 79.9344 78.7726 78.9329C79.7724 77.9315 79.9434 76.3679 79.185 75.173C77.5293 72.5676 62.874 49.5766 58.2027 44.8998L58.2048 44.8978Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_269_16">
        <rect width="103" height="89" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;

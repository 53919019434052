import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewPivotTable } from "./ViewPivotTable";
import { EditPivotTableMeta } from "./EditPivotTableMeta";

export class PivotTableMetaData {
  type = "pivot_table";
  table: string = "";
  rowLabel: string = "";
  columnLabel: string = "";
  aggregationColumn: string = "";
  aggregator: string = "";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
}

export type Value = {
  value: number;
};

export type PivotTableData = {
  data: Value[];
};

export const PivotTablePanelDef: PanelDef<PivotTableMetaData, PivotTableData> =
  {
    ViewComponent: ViewPivotTable,
    EditComponent: EditPivotTableMeta,
    formatData: (data) => data,
    metaConstructor: PivotTableMetaData,
    previewAspectRatio: { width: 500, height: 300 },
  };

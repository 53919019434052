import React from "react";
import ReactDOM from "react-dom";
import "./ui/index.css";
import * as serviceWorker from "./ui/serviceWorker";
import "semantic-ui-css/semantic.css";
import { UserContextProvider } from "./ui/context/User.context";
import AppWithThemeProvider from "./ui/AppWithThemeProvider";

const AppWithProviders = () => {
  return (
    <UserContextProvider>
      <AppWithThemeProvider />
    </UserContextProvider>
  );
};

ReactDOM.render(<AppWithProviders />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

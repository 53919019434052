import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

type BaseProps = {
  onClick: () => void;
  arrowDir: "left" | "right";
};

type CarouselArrowProps = BaseProps &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const CarouselArrowContainer = styled.div<CarouselArrowProps>`
  position: absolute;
  left: ${(props) => (props.arrowDir === "left" ? "-30px" : "")};
  right: ${(props) => (props.arrowDir === "right" ? "-30px" : "")};
  cursor: pointer;
  transition: 150ms all;
  z-index: 11;
  &:hover {
    transform: scale(1.1);
  }
`;

export default function CarouselArrow({
  onClick,
  arrowDir,
  ...props
}: CarouselArrowProps) {
  return (
    <CarouselArrowContainer onClick={onClick} arrowDir={arrowDir} {...props}>
      <Icon name={`chevron ${arrowDir}`} size={"big"} />
      <div />
    </CarouselArrowContainer>
  );
}

import moment from "moment";

import {
  DevicePulseData,
  DevicePulseMetaData,
  DevicePulseDataItem,
} from "./PanelDef";
import React, { ReactNode } from "react";
import { PanelViewComponent } from "../PanelDef";
import styled from "styled-components";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Modal,
  Pagination,
  Popup,
  Table,
  TableHeaderCell,
} from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../../util";
import { getTenantFromURL } from "../../../../../BytebeamClient";
import { TableContainer } from "../util";
import _ from "lodash";
import csvjson from "csvjson";
import { saveAs } from "file-saver";
import { breakpoints } from "../../../../common/breakpoints";
import { beamtoast } from "../../../../common/CustomToast";

const DevicePulseContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-top: 60px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: scroll;
`;

const DevicePulseSummaryList = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  flex-basis: auto;
`;

const DevicePulseSummaryText = styled.div`
  color: #738596;
`;

const DevicePulseSummaryNumber = styled.div`
  font-size: 40px;
  line-height: 45px;
`;

const DevicePulseSummaryCount = styled.span`
  &:hover {
    cursor: pointer;
    filter: invert(1) saturate(3);
  }
`;

const DevicePulseList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 10px;
  width: calc(100% - 250px);
  align-content: flex-start;
`;

const DevicePulse = styled.div`
  width: 10px;
  height: 10px;
  margin: 1px;
  &:hover {
    cursor: pointer;
    border: 1px solid #3b3b3b;
    transform: scale(1.7);
  }
`;

const DeviceMetadataPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  padding-top: 5px;
`;

const DeviceMetadataItem = styled.div`
  margin-bottom: 10px;
`;

const DeviceMetadataKey = styled.div`
  color: #738596;
`;

const DeviceMetadataValue = styled.div`
  color: white;
`;

const DeviceMetadataPulse = styled.div`
  color: white;
  padding-bottom: 10px;
  font-size: 18px;
`;

const LinkTableCell = styled(Table.Cell)`
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.colors["link-text-color"]};
`;

export const DownloadButton = styled.button`
  background: ${(props) =>
    props?.style?.background
      ? props?.style?.background
      : props.theme.colors["action_button-background"]};
  padding: ${(props) =>
    props?.style?.padding ? props?.style?.padding : "10px 15px"};
  border-radius: ${(props) =>
    props?.style?.borderRadius ? props?.style?.borderRadius : "2px"};
  font-size: 14px;
  color: ${(props) =>
    props?.style?.color
      ? props?.style?.color
      : props.theme.colors["action_button-text"]};
  opacity: 1;
  border: ${(props) =>
    props?.style?.border
      ? props?.style?.border
      : props.theme.colors["pulse-download-border"]};
  cursor: pointer;
`;

//hardcoded for Micelio as they want to see these fields in the metadata
const MICELIO_METADATA = ["registration_number", "city", "model", "client"];

function ShowDeviceListModal(props: {
  count: number;
  data: any;
  threshold: any;
  allThresholds: any;
  currentTenant: any;
  dashboardId: any;
}) {
  //DevicePulseDataItem

  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const devicesPerPage = 10; // Number of devices to display per page

  // Update current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentIndex = props.allThresholds.findIndex((threshold) => {
    return threshold.threshold === props.threshold.threshold;
  });
  const devicesDataInit = props.data.filter((device) => {
    if (currentIndex === 0) {
      return device.pulse < props.threshold.threshold;
    }
    return (
      device.pulse < props.threshold.threshold &&
      device.pulse >= props.allThresholds[currentIndex - 1].threshold
    );
  });

  const devicesData: DevicePulseDataItem[] = _.sortBy(devicesDataInit, ["id"]);

  // Calculate total number of pages
  const totalPages = Math.ceil(devicesData.length / devicesPerPage);

  // Calculate index range for the devices to be displayed on the current page
  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;

  const devicesDataSliced = devicesData.slice(
    indexOfFirstDevice,
    indexOfLastDevice
  );

  const downloadMetadata = (data) => {
    try {
      // Flatten the data while ensuring -serial_metadata keys come first, when it exists
      const flattenedData = data.map(
        ({ "-serial_metadata": serialMetadata, metadata, ...rest }) => {
          // Filter out the serialMetadata key from the metadata object, if it exists and serialMetadata is present
          const filteredMetadata =
            serialMetadata && typeof serialMetadata === "object"
              ? Object.keys(metadata).reduce((filtered, key) => {
                  if (!(key in serialMetadata)) {
                    filtered[key] = metadata[key];
                  }
                  return filtered;
                }, {})
              : { ...metadata };

          return {
            ...(serialMetadata ? { serialMetadata } : {}),
            ...filteredMetadata,
            ...rest,
          };
        }
      );

      // Order the data by serialMetadata and other fields when serialMetadata is present
      const orderedData = flattenedData.map((item) => {
        const { serialMetadata, id, pulse, ...rest } = item; // Destructure serialMetadata and other fields

        return {
          ...(serialMetadata
            ? {
                [`#${Object.keys(serialMetadata)[0]}`]:
                  Object.values(serialMetadata)[0],
              }
            : {}), // Conditionally include serialized keys
          id,
          pulse,
          ...rest,
        };
      });

      let csvData = csvjson.toCSV(orderedData, {
        headers: "key",
        delimiter: ",",
      });
      csvData = csvData.replace("[].", "");
      csvData = csvData.replaceAll("[].Metadata.", "");
      return csvData;
    } catch (error) {
      console.error("Error While Fetching all devices data: ", error);
      throw error;
    }
  };

  const downloadAllDevicesMetadata = async () => {
    try {
      const csvData: BlobPart = await downloadMetadata(devicesData);
      const file = new File([csvData], "metadata.csv", {
        type: "text/csv",
      });
      saveAs(file);
    } catch (error) {
      console.log("Error in downloading metadata: ", error);
    }
  };

  const navigatetoDashboard = (currentTenant, dashboardId, deviceId) => {
    if (currentTenant && dashboardId && deviceId) {
      window.open(
        `/projects/${currentTenant}/dashboards/${dashboardId}?id=${deviceId}`,
        "_blank"
      );
    } else {
      beamtoast.error("Please select the Device Dashboard in Panel settings");
    }
  };

  return (
    <Modal
      className="dark"
      open={open}
      trigger={
        <DevicePulseSummaryCount id={props.threshold.color}>
          {props.count}
        </DevicePulseSummaryCount>
      }
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      inverted
    >
      <Header>
        <Grid>
          <Grid.Column floated="left" width={11}>
            {currentIndex === props.allThresholds.length - 1
              ? `Devices with threshold more than ${moment
                  .duration(
                    props.allThresholds[currentIndex - 2].threshold,
                    "seconds"
                  )
                  .humanize({ ss: -1 })}`
              : `Devices with threshold less than ${moment
                  .duration(props.threshold.threshold, "seconds")
                  .humanize({ ss: -1 })} ${
                  currentIndex > 0
                    ? `and greater than ${moment
                        .duration(
                          props.allThresholds[currentIndex - 1].threshold,
                          "seconds"
                        )
                        .humanize({ ss: -1 })}`
                    : ""
                }`}
          </Grid.Column>
          <Grid.Column floated="right" width={5}>
            <DownloadButton type="submit" onClick={downloadAllDevicesMetadata}>
              <Icon name="download" link />
              Download Metadata
            </DownloadButton>
          </Grid.Column>
        </Grid>
      </Header>
      <Modal.Content>
        {devicesData.length === 0 && <span>No devices found</span>}
        {devicesData.length > 0 && (
          <TableContainer>
            <div className="tableContentContainer">
              <Table unstackable compact selectable size="small">
                <Table.Header>
                  <Table.Row>
                    <TableHeaderCell width={5}>
                      {devicesDataSliced?.[0]?.["-serial_metadata"]
                        ? `#${capitalizeFirstLetter(
                            Object.keys(
                              devicesDataSliced?.[0]?.["-serial_metadata"]
                            ).toString()
                          )}`
                        : "Id"}
                    </TableHeaderCell>
                    <TableHeaderCell width={7}>Pulse</TableHeaderCell>
                    {MICELIO_METADATA.filter((key) =>
                      Object.keys(devicesData[0].metadata).includes(key)
                    ).map((key) => (
                      <TableHeaderCell key={key}>
                        {capitalizeFirstLetter(key)}
                      </TableHeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {devicesDataSliced.map((device) => {
                    return (
                      <Popup
                        key={device.id}
                        inverted
                        position="top right"
                        content={
                          <>
                            {device?.["-serial_metadata"] && (
                              <p>Device Id: {device.id}</p>
                            )}
                            {device?.metadata &&
                            Object.entries(device.metadata).length > 0
                              ? Object.entries(device.metadata).map(
                                  ([key, value]) => {
                                    return (
                                      <p key={key}>
                                        {value ? (
                                          `${key} : ${value as ReactNode}`
                                        ) : (
                                          <></>
                                        )}
                                      </p>
                                    );
                                  }
                                )
                              : "No metadata"}
                          </>
                        }
                        style={{ marginBottom: "6px" }}
                        trigger={
                          <Table.Row key={device.id}>
                            <LinkTableCell
                              onClick={() =>
                                navigatetoDashboard(
                                  props.currentTenant,
                                  props.dashboardId,
                                  device.id
                                )
                              }
                            >
                              {device?.["-serial_metadata"]
                                ? Object.values(device?.["-serial_metadata"])[0]
                                : device.id}
                            </LinkTableCell>
                            <Table.Cell>
                              {`${moment
                                .duration(device.pulse, "seconds")
                                .humanize({ ss: -1 })} ago`}
                            </Table.Cell>
                            {MICELIO_METADATA.filter((key) =>
                              Object.keys(devicesData[0].metadata).includes(key)
                            ).map((key) => (
                              <Table.Cell key={key}>
                                {device.metadata[key]}
                              </Table.Cell>
                            ))}
                          </Table.Row>
                        }
                      />
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          </TableContainer>
        )}
        {devicesData.length > 0 && (
          <Pagination
            activePage={currentPage}
            totalPages={totalPages}
            siblingRange={1}
            boundaryRange={0}
            ellipsisItem={null}
            onPageChange={(event, { activePage }) =>
              handlePageChange(activePage)
            }
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => setOpen(false)}>
          {" "}
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

const DeviceMetadataPopup = (props: DevicePulseDataItem) => {
  return (
    <DeviceMetadataPopupContainer>
      <DeviceMetadataPulse>
        {moment.duration(props.pulse, "seconds").humanize({ ss: -1 })}
      </DeviceMetadataPulse>
      <DeviceMetadataItem>
        {/* Show the serial-key when it is present in the panel data */}
        <DeviceMetadataKey>
          {props?.["-serial_metadata"]
            ? `#${capitalizeFirstLetter(Object?.keys(props?.["-serial_metadata"]).toString())}`
            : "Id"}
        </DeviceMetadataKey>
        <DeviceMetadataValue>
          {props?.["-serial_metadata"]
            ? `${Object.values(props?.["-serial_metadata"])[0]}`
            : props.id}
        </DeviceMetadataValue>
      </DeviceMetadataItem>

      {Object.keys(props.metadata || {})
        .filter(
          (key) =>
            key !== Object.keys(props?.["-serial_metadata"] ?? {}).toString()
        )
        .map((key) => (
          <DeviceMetadataItem key={key}>
            <DeviceMetadataKey>{capitalizeFirstLetter(key)}</DeviceMetadataKey>
            <DeviceMetadataValue>{props.metadata[key]}</DeviceMetadataValue>
          </DeviceMetadataItem>
        ))}
    </DeviceMetadataPopupContainer>
  );
};

export class ViewDevicePulse extends PanelViewComponent<
  DevicePulseMetaData,
  DevicePulseData
> {
  navigatetoDashboard(currentTenant, dashboardId, deviceId) {
    if (currentTenant && dashboardId && deviceId) {
      window.open(
        `/projects/${currentTenant}/dashboards/${dashboardId}?id=${deviceId}`,
        "_blank"
      );
    } else {
      beamtoast.error("Please select the Device Dashboard in Panel settings");
    }
  }

  state = {
    screenWidth: window.innerWidth,
  };

  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    let thresholds = JSON.parse(
      JSON.stringify(this.props.panelMeta.thresholds)
    );
    let timeRangeStartThreshold =
      (Math.round(this.props.timeRange.getEndTime().toDate().valueOf()) -
        Math.round(this.props.timeRange.getStartTime().toDate().valueOf())) /
      1000;
    if (timeRangeStartThreshold > thresholds[thresholds.length - 1].threshold) {
      thresholds.push({
        threshold: timeRangeStartThreshold,
        color: "#898989",
      });
    }
    // Pushing all the remaining pulses to the last threshold
    thresholds.push({
      threshold: 9999999999999,
      color: "#4D4D4D",
    });

    const numThresholds = thresholds.length;
    const currentTenant = getTenantFromURL();
    let data = this.props.data.data;
    let oldCount = 0;

    return (
      <DevicePulseContainer>
        <DevicePulseList>
          {data.map((item) => {
            const { pulse } = item;
            let color = thresholds[numThresholds - 1].color;

            for (let i = 0; i < numThresholds; i++) {
              if (pulse < thresholds[i].threshold) {
                color = thresholds[i].color;
                break;
              }
            }

            return (
              <Popup
                hoverable
                disabled={this.state.screenWidth < breakpoints.sm}
                key={item.id}
                inverted
                position="bottom center"
                content={<DeviceMetadataPopup {...item} />}
                trigger={
                  <DevicePulse
                    id={`${item.id}_${color}`}
                    style={{ backgroundColor: color, cursor: "pointer" }}
                    onClick={() =>
                      this.navigatetoDashboard(
                        currentTenant,
                        this.props.panelMeta.device_dashboard_id,
                        item.id
                      )
                    }
                  />
                }
              />
            );
          })}
        </DevicePulseList>
        <DevicePulseSummaryList>
          {thresholds.map((threshold, i) => {
            const totalCount = data.filter(
              (d) => d.pulse < threshold.threshold
            ).length;
            const count = totalCount - oldCount;
            oldCount = totalCount;

            return (
              <React.Fragment key={threshold.threshold}>
                <DevicePulseSummaryText>
                  {i !== numThresholds - 1 ? (
                    <span>
                      Pulse less than{" "}
                      {moment
                        .duration(threshold.threshold, "seconds")
                        .humanize({ ss: -1 })}
                    </span>
                  ) : (
                    <span>
                      Pulse more than{" "}
                      {moment
                        .duration(
                          thresholds[thresholds.length - 2].threshold,
                          "seconds"
                        )
                        .humanize({ ss: -1 })}
                    </span>
                  )}
                </DevicePulseSummaryText>
                <DevicePulseSummaryNumber style={{ color: threshold.color }}>
                  <ShowDeviceListModal
                    count={count}
                    data={data}
                    threshold={threshold}
                    allThresholds={thresholds}
                    currentTenant={currentTenant}
                    dashboardId={this.props.panelMeta.device_dashboard_id}
                  />
                </DevicePulseSummaryNumber>

                {i !== numThresholds - 1 ? <Divider /> : <></>}
              </React.Fragment>
            );
          })}
          <Divider />
          <DevicePulseSummaryText>Total</DevicePulseSummaryText>
          <DevicePulseSummaryNumber>{data.length}</DevicePulseSummaryNumber>
        </DevicePulseSummaryList>
      </DevicePulseContainer>
    );
  }
}

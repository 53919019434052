import * as React from "react";
import { SVGProps } from "react";

const HeatMapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="14.5 18.5 121 112"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.5 18.5h4v108h117v4h-121v-112Z"
      style={{
        opacity: 0.826,
      }}
    />
    <path
      fill="currentColor"
      d="M44.5 29.5c7.148.997 9.648 4.996 7.5 12-2.513 3.712-6.013 4.878-10.5 3.5-1.833-.5-3-1.667-3.5-3.5-2.184-6.598-.018-10.598 6.5-12Z"
      style={{
        opacity: 0.882,
      }}
    />
    <path
      fill="currentColor"
      d="M79.5 29.5c6.787 1.23 9.287 5.23 7.5 12-.5 1.833-1.667 3-3.5 3.5-9.492 1.51-12.992-2.323-10.5-11.5 1.792-2.128 3.958-3.461 6.5-4Z"
      style={{
        opacity: 0.873,
      }}
    />
    <path
      fill="currentColor"
      d="M112.5 31.5c6.52.217 8.187 3.05 5 8.5-7.263.82-8.929-2.012-5-8.5Z"
      style={{
        opacity: 0.766,
      }}
    />
    <path
      fill="currentColor"
      d="M111.5 56.5c9.489-.679 13.656 3.654 12.5 13-2.919 5.525-7.419 7.358-13.5 5.5-4.655-2.897-6.155-7.063-4.5-12.5 1.36-2.523 3.193-4.523 5.5-6Z"
      style={{
        opacity: 0.882,
      }}
    />
    <path
      fill="currentColor"
      d="M40.5 58.5c9.94-1.226 14.44 3.107 13.5 13-2.633 5.231-6.8 7.065-12.5 5.5-5.231-2.633-7.065-6.8-5.5-12.5a40.775 40.775 0 0 1 4.5-6Z"
      style={{
        opacity: 0.884,
      }}
    />
    <path
      fill="currentColor"
      d="M77.5 61.5c7.148-.014 9.648 3.319 7.5 10-5.365 4.245-9.198 3.078-11.5-3.5.222-2.962 1.556-5.129 4-6.5Z"
      style={{
        opacity: 0.818,
      }}
    />
    <path
      fill="currentColor"
      d="M75.5 88.5c9.94-1.226 14.44 3.107 13.5 13-2.741 4.919-6.908 6.752-12.5 5.5-5.525-2.919-7.358-7.419-5.5-13.5a32.832 32.832 0 0 1 4.5-5ZM110.5 88.5c9.498-1.327 13.998 2.673 13.5 12-2.182 5.741-6.349 7.908-12.5 6.5-5.525-2.919-7.358-7.419-5.5-13.5 1.025-2.187 2.525-3.854 4.5-5Z"
      style={{
        opacity: 0.882,
      }}
    />
    <path
      fill="currentColor"
      d="M41.5 93.5c5.528-1.43 8.862.57 10 6-1.15 5.591-4.484 7.425-10 5.5-3.92-3.827-3.92-7.66 0-11.5Z"
      style={{
        opacity: 0.818,
      }}
    />
  </svg>
);

export default HeatMapIcon;

import uuid from "uuid";
import { TimeRange } from "../../Datetime/TimeRange";
import { ReplayState } from "../../DashboardHeader";
import { PanelDef } from "../PanelDef";
import { ViewStateTimeline } from "./ViewStateTimeline";
import { EditStateTimelineMeta } from "./EditStateTimelineMeta";

export type StateTimelineColumn = {
  name: string;
};

export type StateColor = {
  state: string;
  color: string;
  pickerOpen: boolean;
};

export class StateTimelineMetaData {
  type = "state_timeline";
  table: string = "";
  columns: Array<StateTimelineColumn> = [];
  stateColumn: string = "";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  toggleBarClick: boolean = false;
  colorMapping: StateColor[] = [
    {
      state: "on",
      color: "#7def85",
      pickerOpen: false,
    },
    {
      state: "off",
      color: "#f93839",
      pickerOpen: false,
    },
  ];
}

export type TimestampedState = {
  timestamp: number;
  state: string;
};

export type StateTimelineData = {
  id: string | number;
  data: TimestampedState[];
}[];

function formatData(
  panelData: StateTimelineData,
  timeRange: TimeRange,
  replayState: ReplayState,
  replayStep: number
): StateTimelineData {
  // if(replayState === ReplayState.ReplayStopped) {
  return panelData;
  // }

  // const data = panelData.data;
  // const newData: TimestampedValue[] = []
  // let index = 0;
  // const startTimestamp = timeRange.getStartTime().toDate().valueOf();
  // const endTimestamp = timeRange.getEndTime().toDate().valueOf()

  // for(let i = startTimestamp; i <= endTimestamp; i += replayStep) {
  //   while(index < data.length) {
  //     if(data[index].timestamp > i) {
  //       break;
  //     }

  //     index++;
  //   }

  //   index = index === 0 ? 0 : index - 1;

  //   newData.push(data[index])
  // }

  // return {
  //   data: newData
  // }
}

export const StateTimelinePanelDef: PanelDef<
  StateTimelineMetaData,
  StateTimelineData
> = {
  ViewComponent: ViewStateTimeline,
  EditComponent: EditStateTimelineMeta,
  formatData: formatData,
  metaConstructor: StateTimelineMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

import React, { SyntheticEvent } from "react";
import { Dropdown, DropdownProps, Popup } from "semantic-ui-react";
import { isNumericType } from "../../util";

const unitOptions: Array<{ text: string; value: string }> = [
  // ==================== Custom Options ====================
  // '%'
  {
    text: "%",
    value: "%",
  },
  {
    text: "ohm",
    value: "ohm",
  },

  // ==================== Length ====================
  // 'nm' | 'μm' | 'mm' | 'cm' | 'm' | 'km' | 'mil'| 'in'| 'yd'| 'ft-us'| 'ft'| 'fathom'| 'mi'| 'nMi'
  {
    text: "nm",
    value: "nm",
  },
  {
    text: "μm",
    value: "μm",
  },
  {
    text: "mm",
    value: "mm",
  },
  {
    text: "cm",
    value: "cm",
  },
  {
    text: "m",
    value: "m",
  },
  {
    text: "km",
    value: "km",
  },
  {
    text: "mil",
    value: "mil",
  },
  {
    text: "in",
    value: "in",
  },
  {
    text: "yd",
    value: "yd",
  },
  {
    text: "ft-us",
    value: "ft-us",
  },
  {
    text: "ft",
    value: "ft",
  },
  {
    text: "fathom",
    value: "fathom",
  },
  {
    text: "mi",
    value: "mi",
  },
  {
    text: "nMi",
    value: "nMi",
  },

  // ==================== Acceleration ====================
  // 'm/s^2' | 'g-force'
  {
    text: "m/s^2",
    value: "m/s2",
  },
  {
    text: "g-force",
    value: "g-force",
  },

  // ==================== Force ====================
  // 'N' | 'kN' | 'lbf' | 'kgf'
  {
    text: "N",
    value: "N",
  },
  {
    text: "kN",
    value: "kN",
  },
  {
    text: "lbf",
    value: "lbf",
  },
  {
    text: "kgf",
    value: "kgf",
  },

  // ==================== Area ====================
  // 'nm^2' | 'µm^2' | 'mm^2' | 'cm^2' | 'm^2' | 'ha' | 'km^2' | 'in^2' | 'yd^2' | 'ft^2' | 'ac' | 'mi^2'
  {
    text: "nm^2",
    value: "nm2",
  },
  {
    text: "µm^2",
    value: "µm2",
  },
  {
    text: "mm^2",
    value: "mm2",
  },
  {
    text: "cm^2",
    value: "cm2",
  },
  {
    text: "m^2",
    value: "m2",
  },
  {
    text: "ha",
    value: "ha",
  },
  {
    text: "km^2",
    value: "km2",
  },
  {
    text: "in^2",
    value: "in2",
  },
  {
    text: "yd^2",
    value: "yd2",
  },
  {
    text: "ft^2",
    value: "ft2",
  },
  {
    text: "ac",
    value: "ac",
  },
  {
    text: "mi^2",
    value: "mi2",
  },

  // ==================== Charge ====================
  // 'c' | 'mC' | 'μC' | 'nC' | 'pC'
  // {
  //   text: "c",
  //   value: "c",
  // },
  {
    text: "mC",
    value: "mC",
  },
  {
    text: "μC",
    value: "μC",
  },
  {
    text: "nC",
    value: "nC",
  },
  {
    text: "pC",
    value: "pC",
  },

  // ==================== Mass ====================
  // 'mcg' | 'mg' | 'g' | 'kg' | 'mt' | 'oz' | 'lb' | 'st' | 't'
  {
    text: "mcg",
    value: "mcg",
  },
  {
    text: "mg",
    value: "mg",
  },
  {
    text: "g",
    value: "g",
  },
  {
    text: "kg",
    value: "kg",
  },
  {
    text: "mt",
    value: "mt",
  },
  {
    text: "oz",
    value: "oz",
  },
  {
    text: "lb",
    value: "lb",
  },
  {
    text: "st",
    value: "st",
  },
  {
    text: "t",
    value: "t",
  },

  // ==================== Mass Flow Rate ====================
  // 'kg/s' | 'kg/h' | 'mt/h' | 'lb/s' | 'lb/h'
  {
    text: "kg/s",
    value: "kg/s",
  },
  {
    text: "kg/h",
    value: "kg/h",
  },
  {
    text: "mt/h",
    value: "mt/h",
  },
  {
    text: "lb/s",
    value: "lb/s",
  },
  {
    text: "lb/h",
    value: "lb/h",
  },

  // ==================== Volume ====================
  // 'mm3' | 'cm3' | 'ml' | 'l' | 'kl' | 'Ml' | 'Gl' | 'm3' | 'km3' | 'cl' | 'dl' | 'krm' | 'tsk' | 'msk' | 'kkp' | 'glas' | 'kanna'
  // 'tsp' | 'Tbs' | 'in3' | 'fl-oz' | 'cup' | 'pnt' | 'qt' | 'gal' | 'ft3' | 'yd3'
  {
    text: "mm^3",
    value: "mm3",
  },
  {
    text: "cm^3",
    value: "cm3",
  },
  {
    text: "ml",
    value: "ml",
  },
  {
    text: "l",
    value: "l",
  },
  {
    text: "kl",
    value: "kl",
  },
  {
    text: "Ml",
    value: "Ml",
  },
  {
    text: "Gl",
    value: "Gl",
  },
  {
    text: "m^3",
    value: "m3",
  },
  {
    text: "km^3",
    value: "km3",
  },
  {
    text: "cl",
    value: "cl",
  },
  {
    text: "dl",
    value: "dl",
  },
  {
    text: "krm",
    value: "krm",
  },
  {
    text: "tsk",
    value: "tsk",
  },
  {
    text: "msk",
    value: "msk",
  },
  {
    text: "kkp",
    value: "kkp",
  },
  {
    text: "glas",
    value: "glas",
  },
  {
    text: "kanna",
    value: "kanna",
  },
  {
    text: "tsp",
    value: "tsp",
  },
  {
    text: "Tbs",
    value: "Tbs",
  },
  {
    text: "in^3",
    value: "in3",
  },
  {
    text: "fl-oz",
    value: "fl-oz",
  },
  {
    text: "cup",
    value: "cup",
  },
  {
    text: "pnt",
    value: "pnt",
  },
  {
    text: "qt",
    value: "qt",
  },
  {
    text: "gal",
    value: "gal",
  },
  {
    text: "ft^3",
    value: "ft3",
  },
  {
    text: "yd^3",
    value: "yd3",
  },

  // ==================== Volume Flow Rate ====================
  // 'mm3/s' | 'cm3/s' | 'ml/s' | 'cl/s' | 'dl/s' | 'l/s' | 'l/min' | 'l/h' | 'kl/s' | 'kl/min' | 'kl/h' | 'm3/s' | 'm3/min' | 'm3/h' | 'km3/s'
  // 'tsp/s' | 'Tbs/s' | 'in3/s' | 'in3/min' | 'in3/h' | 'fl-oz/s' | 'fl-oz/min' | 'fl-oz/h' | 'cup/s' | 'pnt/s' | 'pnt/min' | 'pnt/h' | 'qt/s' | 'gal/s' | 'gal/min' | 'gal/h' | 'ft3/s' | 'ft3/min' | 'ft3/h' | 'yd3/s' | 'yd3/min' | 'yd3/h'
  {
    text: "mm^3/s",
    value: "mm3/s",
  },
  {
    text: "cm^3/s",
    value: "cm3/s",
  },
  {
    text: "ml/s",
    value: "ml/s",
  },
  {
    text: "cl/s",
    value: "cl/s",
  },
  {
    text: "dl/s",
    value: "dl/s",
  },
  {
    text: "l/s",
    value: "l/s",
  },
  {
    text: "l/min",
    value: "l/min",
  },
  {
    text: "l/h",
    value: "l/h",
  },
  {
    text: "kl/s",
    value: "kl/s",
  },
  {
    text: "kl/min",
    value: "kl/min",
  },
  {
    text: "kl/h",
    value: "kl/h",
  },
  {
    text: "m^3/s",
    value: "m3/s",
  },
  {
    text: "m^3/min",
    value: "m3/min",
  },
  {
    text: "m^3/h",
    value: "m3/h",
  },
  {
    text: "km^3/s",
    value: "km3/s",
  },
  {
    text: "tsp/s",
    value: "tsp/s",
  },
  {
    text: "Tbs/s",
    value: "Tbs/s",
  },
  {
    text: "in^3/s",
    value: "in3/s",
  },
  {
    text: "in^3/min",
    value: "in3/min",
  },
  {
    text: "in^3/h",
    value: "in3/h",
  },
  {
    text: "fl-oz/s",
    value: "fl-oz/s",
  },
  {
    text: "fl-oz/min",
    value: "fl-oz/min",
  },
  {
    text: "fl-oz/h",
    value: "fl-oz/h",
  },
  {
    text: "cup/s",
    value: "cup/s",
  },
  {
    text: "pnt/s",
    value: "pnt/s",
  },
  {
    text: "pnt/min",
    value: "pnt/min",
  },
  {
    text: "pnt/h",
    value: "pnt/h",
  },
  {
    text: "qt/s",
    value: "qt/s",
  },
  {
    text: "gal/s",
    value: "gal/s",
  },
  {
    text: "gal/min",
    value: "gal/min",
  },
  {
    text: "gal/h",
    value: "gal/h",
  },
  {
    text: "ft^3/s",
    value: "ft3/s",
  },
  {
    text: "ft^3/min",
    value: "ft3/min",
  },
  {
    text: "ft^3/h",
    value: "ft3/h",
  },
  {
    text: "yd^3/s",
    value: "yd3/s",
  },
  {
    text: "yd^3/min",
    value: "yd3/min",
  },
  {
    text: "yd^3/h",
    value: "yd3/h",
  },

  // ==================== Temperature ====================
  // 'C' | 'K' | 'F' | 'R'
  {
    text: "C",
    value: "C",
  },
  {
    text: "K",
    value: "K",
  },
  {
    text: "F",
    value: "F",
  },
  {
    text: "R",
    value: "R",
  },

  // ==================== Time ====================
  // 'ns' | 'mu' | 'ms' | 's' | 'min' | 'h' | 'd' | 'week' | 'month' | 'year'
  {
    text: "ns",
    value: "ns",
  },
  {
    text: "mu",
    value: "mu",
  },
  {
    text: "ms",
    value: "ms",
  },
  {
    text: "s",
    value: "s",
  },
  {
    text: "min",
    value: "min",
  },
  {
    text: "h",
    value: "h",
  },
  {
    text: "d",
    value: "d",
  },
  {
    text: "week",
    value: "week",
  },
  {
    text: "month",
    value: "month",
  },
  {
    text: "year",
    value: "year",
  },

  // ==================== Frequency ====================
  //  'mHz'| 'Hz'| 'kHz'| 'MHz'| 'GHz'| 'THz'| 'rpm'| 'deg/s'| 'rad/s'
  {
    text: "mHz",
    value: "mHz",
  },
  {
    text: "Hz",
    value: "Hz",
  },
  {
    text: "kHz",
    value: "kHz",
  },
  {
    text: "MHz",
    value: "MHz",
  },
  {
    text: "GHz",
    value: "GHz",
  },
  {
    text: "THz",
    value: "THz",
  },
  {
    text: "rpm",
    value: "rpm",
  },
  {
    text: "deg/s",
    value: "deg/s",
  },
  {
    text: "rad/s",
    value: "rad/s",
  },

  // ==================== Speed ====================
  // 'm/s' | 'km/h' | 'mm/h' | 'mph' | 'knot' | 'ft/s' | 'ft/min' | 'in/h'
  {
    text: "m/s",
    value: "m/s",
  },
  {
    text: "km/h",
    value: "km/h",
  },
  {
    text: "mm/h",
    value: "mm/h",
  },
  {
    text: "mph",
    value: "mph",
  },
  {
    text: "knot",
    value: "knot",
  },
  {
    text: "ft/s",
    value: "ft/s",
  },
  {
    text: "ft/min",
    value: "ft/min",
  },
  {
    text: "in/h",
    value: "in/h",
  },

  // ==================== Pace ====================
  // 'min/km' | 's/m' | 'min/mi' | 's/ft'
  {
    text: "min/km",
    value: "min/km",
  },
  {
    text: "s/m",
    value: "s/m",
  },
  {
    text: "min/mi",
    value: "min/mi",
  },
  {
    text: "s/ft",
    value: "s/ft",
  },

  // ==================== Pressure ====================
  // 'Pa' | 'kPa' | 'MPa' | 'hPa' | 'bar' | 'torr' | 'mH2O' | 'mmHg' | 'psi' | 'ksi' | 'inHg'
  {
    text: "Pa",
    value: "Pa",
  },
  {
    text: "kPa",
    value: "kPa",
  },
  {
    text: "MPa",
    value: "MPa",
  },
  {
    text: "hPa",
    value: "hPa",
  },
  {
    text: "bar",
    value: "bar",
  },
  {
    text: "torr",
    value: "torr",
  },
  {
    text: "mH2O",
    value: "mH2O",
  },
  {
    text: "mmHg",
    value: "mmHg",
  },
  {
    text: "psi",
    value: "psi",
  },
  {
    text: "ksi",
    value: "ksi",
  },
  {
    text: "inHg",
    value: "inHg",
  },

  // ==================== Torque ====================
  // Nm' | 'lbf-ft'
  {
    text: "Nm",
    value: "Nm",
  },
  {
    text: "lbf-ft",
    value: "lbf-ft",
  },

  // ==================== Digital ====================
  // 'b' | 'Kb' | 'Mb' | 'Gb' | 'Tb' | 'B' | 'KB' | 'MB' | 'GB' | 'TB'
  {
    text: "b",
    value: "b",
  },
  {
    text: "Kb",
    value: "Kb",
  },
  {
    text: "Mb",
    value: "Mb",
  },
  {
    text: "Gb",
    value: "Gb",
  },
  {
    text: "Tb",
    value: "Tb",
  },
  {
    text: "B",
    value: "B",
  },
  {
    text: "KB",
    value: "KB",
  },
  {
    text: "MB",
    value: "MB",
  },
  {
    text: "GB",
    value: "GB",
  },
  {
    text: "TB",
    value: "TB",
  },

  // ==================== Illuminance ====================
  {
    text: "lx",
    value: "lx",
  },
  {
    text: "ft-cd",
    value: "ft-cd",
  },

  // ==================== Parts-Per ====================
  // 'ppm' | 'ppb' | 'ppt' | 'ppq'
  {
    text: "ppm",
    value: "ppm",
  },
  {
    text: "ppb",
    value: "ppb",
  },
  {
    text: "ppt",
    value: "ppt",
  },
  {
    text: "ppq",
    value: "ppq",
  },

  // ==================== Voltage ====================
  // 'V' | 'mV' | 'kV'
  {
    text: "V",
    value: "V",
  },
  {
    text: "mV",
    value: "mV",
  },
  {
    text: "kV",
    value: "kV",
  },

  // ==================== Current ====================
  // 'A' | 'mA' | 'kA'
  {
    text: "A",
    value: "A",
  },
  {
    text: "mA",
    value: "mA",
  },
  {
    text: "kA",
    value: "kA",
  },

  // ==================== Power ====================
  // 'W' | 'mW' | 'kW' | 'MW' | 'GW' | 'PS' | 'Btu/s' | 'ft-lb/s' | 'hp'
  {
    text: "W",
    value: "W",
  },
  {
    text: "mW",
    value: "mW",
  },
  {
    text: "kW",
    value: "kW",
  },
  {
    text: "MW",
    value: "MW",
  },
  {
    text: "GW",
    value: "GW",
  },
  {
    text: "PS",
    value: "PS",
  },
  {
    text: "Btu/s",
    value: "Btu/s",
  },
  {
    text: "ft-lb/s",
    value: "ft-lb/s",
  },
  {
    text: "hp",
    value: "hp",
  },

  // ==================== Apparent Power ====================
  // 'VA' | 'mVA' | 'kVA' | 'MVA' | 'GVA'
  {
    text: "VA",
    value: "VA",
  },
  {
    text: "mVA",
    value: "mVA",
  },
  {
    text: "kVA",
    value: "kVA",
  },
  {
    text: "MVA",
    value: "MVA",
  },
  {
    text: "GVA",
    value: "GVA",
  },

  // ==================== Reactive Power ====================
  // 'VAR' | 'mVAR' | 'kVAR' | 'MVAR' | 'GVAR'
  {
    text: "VAR",
    value: "VAR",
  },
  {
    text: "mVAR",
    value: "mVAR",
  },
  {
    text: "kVAR",
    value: "kVAR",
  },
  {
    text: "MVAR",
    value: "MVAR",
  },
  {
    text: "GVAR",
    value: "GVAR",
  },

  // ==================== Energy ====================
  // | 'Ws'| 'Wm'| 'Wh'| 'mWh'| 'kWh'| 'MWh'| 'GWh'| 'J'| 'kJ'| 'MJ'| 'GJ' | 'kcal' | 'cal'
  {
    text: "Ws",
    value: "Ws",
  },
  {
    text: "Wm",
    value: "Wm",
  },
  {
    text: "Wh",
    value: "Wh",
  },
  {
    text: "mWh",
    value: "mWh",
  },
  {
    text: "kWh",
    value: "kWh",
  },
  {
    text: "MWh",
    value: "MWh",
  },
  {
    text: "GWh",
    value: "GWh",
  },
  {
    text: "J",
    value: "J",
  },
  {
    text: "kJ",
    value: "kJ",
  },
  {
    text: "kcal",
    value: "kcal",
  },
  {
    text: "cal",
    value: "cal",
  },
  // ==================== Reactive Energy ====================
  // 'VARh' | 'mVARh' | 'kVARh' | 'MVARh' | 'GVARh'
  {
    text: "VARh",
    value: "VARh",
  },
  {
    text: "mVARh",
    value: "mVARh",
  },
  {
    text: "kVARh",
    value: "kVARh",
  },
  {
    text: "MVARh",
    value: "MVARh",
  },
  {
    text: "GVARh",
    value: "GVARh",
  },

  // ==================== Angle ====================
  // 'rad' | 'deg' | 'grad' | 'arcmin' | 'arcsec'
  {
    text: "deg",
    value: "deg",
  },
  {
    text: "rad",
    value: "rad",
  },
  {
    text: "grad",
    value: "grad",
  },
  {
    text: "arcmin",
    value: "arcmin",
  },
  {
    text: "arcsec",
    value: "arcsec",
  },

  // ==================== Each ====================
  // 'ea' | 'dz'
  {
    text: "ea",
    value: "ea",
  },
  {
    text: "dz",
    value: "dz",
  },

  // ==================== Pieces ====================
  // 'pcs' | 'bk-doz' | 'cp' | 'doz-doz' | 'doz' | 'gr-gr' | 'gros' | 'half-dozen' | 'long-hundred' | 'ream' | 'scores' | 'sm-gr' | 'trio'
  {
    text: "pcs",
    value: "pcs",
  },
  {
    text: "bk-doz",
    value: "bk-doz",
  },
  {
    text: "cp",
    value: "cp",
  },
  {
    text: "doz-doz",
    value: "doz-doz",
  },
  {
    text: "doz",
    value: "doz",
  },
  {
    text: "gr-gr",
    value: "gr-gr",
  },
  {
    text: "gros",
    value: "gros",
  },
  {
    text: "half-dozen",
    value: "half-dozen",
  },
  {
    text: "long-hundred",
    value: "long-hundred",
  },
  {
    text: "ream",
    value: "ream",
  },
  {
    text: "scores",
    value: "scores",
  },
  {
    text: "sm-gr",
    value: "sm-gr",
  },
  {
    text: "trio",
    value: "trio",
  },
];

const options: Array<{ key: string; text: string; value: string }> =
  unitOptions.map((x) => ({
    key: x.value,
    text: x.text,
    value: x.value,
  }));

type UnitSelectionProps = {
  readonly value: string;
  readonly onChange: (
    event: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => void;
  readonly disabled?: boolean;
  readonly id?: string;
  readonly columnTypeValue?: string;
  readonly elemId?: string;
};

export default function UnitSelection(props: UnitSelectionProps) {
  const { value, onChange, disabled = false, id } = props;

  function extractNumber(input: string): number | null {
    // Regular expression to match the number at the end of the string
    const regex = /-(\d+)$/;
    const match = input.match(regex);
    // Return the number if found, otherwise null
    return match ? parseInt(match[1], 10) : null;
  }

  const isUnitDisabled = () => {
    if (props.id && props.elemId === extractNumber(props.id)?.toString())
      return props.columnTypeValue
        ? !isNumericType(props.columnTypeValue)
        : true;
    return false;
  };

  const unitDisabled = isUnitDisabled();

  const dropdown = (
    // wrapped in a div so that popup would work on disabled dropdown
    <div>
      <Dropdown
        id={id}
        fluid
        search
        selection
        clearable
        placeholder="Select Unit"
        value={value}
        onChange={onChange}
        options={options}
        style={{ width: "100%" }}
        disabled={disabled || unitDisabled}
      />
    </div>
  );

  return disabled ? ( // show only disabled dropdown without popup
    dropdown
  ) : unitDisabled && props.columnTypeValue ? (
    <Popup
      trigger={dropdown}
      content="Units are exclusive to numeric types!"
      position="top center"
      inverted
    />
  ) : (
    dropdown // enabled popup with numeric types
  );
}

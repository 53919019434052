import React, { useState } from "react";
import { Form, Button, Modal, Icon } from "semantic-ui-react";
import {
  StyledFileUploadButton,
  StyledFileInput,
} from "../../../DeviceManagement/Devices/ActionModals/SendFileModal";
import { uploadFile } from "../../../../../BytebeamClient";
import AnimatedEllipsis from "../../../common/AnimatedEllipsis";
import { OptionType } from "../../util";
import { beamtoast } from "../../../../common/CustomToast";

type SendFileActionModalProps = {
  readonly isOpen: boolean;
  readonly onClose: (...args: any[]) => any;
  readonly optionType: OptionType;
  readonly setSelectedVersion: (id: string) => void;
  readonly setUploadedFileName: (name: string) => void;
};

const SendFileActionModal = (props: SendFileActionModalProps) => {
  const {
    isOpen,
    onClose,
    optionType,
    setSelectedVersion,
    setUploadedFileName,
  } = props;
  const sendableItemTypes = ["file", "script"][
    optionType === OptionType.SendFile ? 0 : 1
  ];

  const [fileName, setFileName] = useState("");
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(0);
  const [fileTotal, setFileTotal] = useState(0);
  const [uploadedFileResponse, setUploadedFileResponse] = useState({
    status: 0,
    data: { id: "" },
  });

  const fileUpload = async (file: File, fileName: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);

    try {
      const url = `/api/v1/file`;
      const res = await uploadFile(url, formData, (p) => {
        setFileLoaded(p.loaded);
        setFileTotal(p.total);
      });
      setUploadedFileResponse(res);
    } catch (error) {
      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413")) {
        beamtoast.error("Upload failed due to size limit!");
      } else if (String(error).includes("401")) {
        beamtoast.error("Upload failed as access is not there!");
      } else beamtoast.error(`Failed to upload ${sendableItemTypes}`);
    }
  };

  const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setUploadedFileName(selectedFile.name);
      setShowUploadProgress(true);
      fileUpload(selectedFile, selectedFile.name);
    }
  };

  const triggerSendFileAction = () => {
    if (uploadedFileResponse.status === 201) {
      setSelectedVersion(uploadedFileResponse.data.id);
    }
  };

  // Reset state and close modal
  const onCloseModal = () => {
    setFileName("");
    setShowUploadProgress(false);
    setFileLoaded(0);
    setFileTotal(0);
    setUploadedFileResponse({
      status: 0,
      data: { id: "" },
    });
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onCloseModal} size="tiny" className="dark">
      <Modal.Header>Send {sendableItemTypes}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Upload {sendableItemTypes} from the system: </label>
            <div style={{ position: "relative", marginTop: "12px" }}>
              <StyledFileUploadButton
                fluid
                content={`Select ${sendableItemTypes}`}
                labelPosition="left"
                icon="file"
              />
              <StyledFileInput type="file" id="file" onChange={onSelect} />
            </div>
            <label style={{ marginTop: "12px" }}>
              Selected {sendableItemTypes}:{" "}
            </label>
            <span>
              {fileName !== "" ? fileName : `${sendableItemTypes} not selected`}
            </span>
          </Form.Field>

          {showUploadProgress && (
            <Form.Field>
              <label htmlFor="file-progress">
                {uploadedFileResponse.status === 0 ? (
                  <span>
                    {sendableItemTypes} uploading
                    <AnimatedEllipsis spacing={3} dotSize={"8px"} />
                  </span>
                ) : (
                  <span>{sendableItemTypes} uploaded successfully</span>
                )}
              </label>
              <progress id="file-progress" max={fileTotal} value={fileLoaded} />
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            onCloseModal();
          }}
        >
          <Icon name="remove" /> Discard
        </Button>
        <Button
          disabled={uploadedFileResponse.status !== 201}
          primary
          onClick={() => {
            triggerSendFileAction();
            onCloseModal();
          }}
        >
          <Icon name="checkmark" /> Done
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default SendFileActionModal;

import { MicelioStatusMetaData } from "./PanelDef";
import React from "react";

import {
  EditComponentProps,
  PanelEditComponent,
  PartialMetaData,
} from "../../PanelDef";
import {
  EditAnimatedMetaDropdown,
  EditAnimatedMetaInput,
  EditMetaRoot,
  EditMetaRow,
  EditPanelFormContainer,
  StyledInputDiv,
  ThinDivider,
} from "../../util";
import { Tab } from "semantic-ui-react";

export type MicelioEditStatusMetaState = {
  table: string;
  error: boolean;
};

export class EditMicelioStatusMeta extends PanelEditComponent<
  MicelioStatusMetaData,
  MicelioEditStatusMetaState
> {
  descriptionRef = React.createRef<HTMLInputElement>();
  titleRef = React.createRef<HTMLInputElement>();

  constructor(props: EditComponentProps<MicelioStatusMetaData>) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        table: props.panelMeta.table || "device_shadow_latest",
        error: false,
      };
    } else {
      this.state = {
        table: "device_shadow_latest",
        error: false,
      };
    }
  }

  getPanelMeta(type): PartialMetaData<MicelioStatusMetaData> {
    const meta: MicelioStatusMetaData = {
      type: "micelio_status",
      title: this.titleRef.current?.value || "Status",
      description: this.descriptionRef.current?.value || "",
      id: this.props.panelMeta.id,
      table: this.state.table,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: MicelioStatusMetaData, type?: string) {
    const isValid = !!meta.table;

    // type is used here to differentiate between submit and refresh in edit mode
    if (!isValid && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return isValid;
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
    });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                    error={this.state.error && !this.titleRef.current?.value}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table || "Select Stream"}
                    fluid
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.state.table}
                    value={this.state.table}
                    elementID={`tableMicelioStatusTable`}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}

import * as React from "react";
import { SVGProps } from "react";

const LineChartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 100 83"
    {...props}
  >
    <path d="M97.068 83H2.932C1.312 83 0 81.677 0 80.045V2.955C0 1.323 1.313 0 2.932 0s2.931 1.323 2.931 2.955v74.137H97.07c1.62 0 2.932 1.323 2.932 2.955 0 1.632-1.313 2.955-2.932 2.955L97.068 83Z" />
    <path d="M17.639 68.175c-1.62 0-2.93-1.325-2.93-2.956 0-.583.172-1.152.492-1.636L38.734 28a2.917 2.917 0 0 1 3.75-1.004l15.532 7.827L80.07 7.04a2.917 2.917 0 0 1 4.112-.54 2.972 2.972 0 0 1 .535 4.144c-.022.03-.045.057-.069.087L61.115 40.377a2.914 2.914 0 0 1-3.6.788L42.18 33.436 20.08 66.86a2.923 2.923 0 0 1-2.441 1.315Z" />
    <path d="M82.36 26.664c-1.62 0-2.932-1.322-2.932-2.954V11.84H67.65c-1.619 0-2.931-1.324-2.931-2.956 0-1.631 1.312-2.954 2.931-2.954h14.71c1.618 0 2.931 1.323 2.931 2.954V23.71c0 1.632-1.313 2.954-2.932 2.954Z" />
  </svg>
);

export default LineChartIcon;

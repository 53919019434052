import React, { useEffect } from "react";
import {
  NotificationRule,
  SlackNotificationChannel,
} from "../CreateNotificationRule";
import { StyledInput, StyledLabel } from "../CreateAlertRuleModal";

type CreateSlackNotificationChannelProps = {
  readonly channel: SlackNotificationChannel;
  readonly setChannel: (channel: SlackNotificationChannel) => void;
  readonly setIsValid: (isValid: boolean) => void;
  readonly notificationRule: NotificationRule;
};

export default function CreateSlackNotificationChannel(
  props: CreateSlackNotificationChannelProps
) {
  const { setIsValid, channel, setChannel, notificationRule } = props;

  useEffect(() => {
    const isValid =
      channel?.botToken?.length > 0 &&
      channel?.channelId?.length > 0 &&
      notificationRule.alertRuleId !== "";
    setIsValid(isValid);
  }, [channel, setIsValid, notificationRule.alertRuleId]);

  const setSlackChannel = (slackChannel: string) => {
    setChannel({
      ...channel,
      channelId: slackChannel,
    });
  };

  const setBotToken = (slackBotToken: string) => {
    setChannel({
      ...channel,
      botToken: slackBotToken,
    });
  };

  return (
    <>
      <div>
        <StyledInput labelPosition="left">
          <StyledLabel>Channel Id</StyledLabel>
          <input
            value={channel.channelId}
            onChange={(e) => setSlackChannel(e.target.value)}
            placeholder="Channel ID"
          />
        </StyledInput>
      </div>

      <div>
        <StyledInput labelPosition="left">
          <StyledLabel>Bot Token</StyledLabel>
          <input
            value={channel.botToken}
            type="password"
            onChange={(e) => setBotToken(e.target.value)}
            placeholder="Token"
          />
        </StyledInput>
      </div>
    </>
  );
}

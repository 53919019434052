import {
  StateTimelineColumn,
  StateTimelineMetaData,
  StateColor,
} from "./PanelDef";
import React from "react";

import { Tab } from "semantic-ui-react";

import RelativeTimePicker from "../../Datetime/RelativeTimePicker";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  ThinDivider,
  // LabelHeading,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  EditPanelFormContainer,
  // StyledSketchPickerTrigger,
  StyledInputDiv,
  ToggleLabel,
} from "../util";
// import { SketchPicker } from "react-color";
import { TableInfo } from "../../../../../BytebeamClient";
import ToggleSwitch from "../../../common/ToggleSwitch";

export type EditStateTimelineMetaProps = {
  panelMeta: StateTimelineMetaData;
  tables: TableInfo;
};

export type EditStateTimelineMetaState = {
  table: string;
  columns: Array<StateTimelineColumn>;
  colorMapping: StateColor[];
  toggleBarClick: boolean;
  error: boolean;
};

export class EditStateTimelineMeta extends PanelEditComponent<
  StateTimelineMetaData,
  EditStateTimelineMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  durationRef = React.createRef<RelativeTimePicker>();
  divisionCountRef = React.createRef<HTMLInputElement>();
  valUnitRef = React.createRef<HTMLInputElement>();

  constructor(props: EditStateTimelineMetaProps) {
    super(props);

    if (props.panelMeta) {
      // fetch values from panelMeta for Edit
      this.state = {
        table: props.panelMeta?.table,
        columns: props.panelMeta?.columns,
        error: false,
        toggleBarClick: props.panelMeta.toggleBarClick ?? false,
        colorMapping: props.panelMeta.colorMapping
          ? props.panelMeta.colorMapping
          : [
              {
                state: "on",
                color: "#7def85",
                pickerOpen: false,
              },
              {
                state: "off",
                color: "#f93839",
                pickerOpen: false,
              },
            ],
      };
    } else {
      // Put default values
      this.state = {
        table: "",
        columns: [],
        error: false,
        toggleBarClick: false,
        colorMapping: [
          {
            state: "on",
            color: "#7def85",
            pickerOpen: false,
          },
          {
            state: "off",
            color: "#f93839",
            pickerOpen: false,
          },
        ],
      };
    }
  }

  getPanelMeta(type): PartialMetaData<StateTimelineMetaData> {
    const meta: StateTimelineMetaData = {
      type: "state_timeline", // Have to change before pushing
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table || "",
      columns: this.state.columns,
      stateColumn: this.state.columns[0]?.name,
      toggleBarClick: this.state.toggleBarClick,
      colorMapping: this.state.colorMapping,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: StateTimelineMetaData, type?: string) {
    if (!(!!meta.table && meta.columns?.length > 0) && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return (
      !!meta.table && meta.columns?.length > 0 && meta.stateColumn?.length > 0
    );
    // return (
    //   !!meta.table && meta.columns.length > 0 && meta.stateColumn.length > 0
    // );
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      columns: [],
    });
  }

  setColumn(index: number, key: string, value?: any) {
    const v = { ...this.state.columns[index] };

    v[key] = value;

    this.setState({
      columns: [
        ...this.state.columns.slice(0, index),
        v,
        ...this.state.columns.slice(index + 1),
      ],
    });
  }

  addColumn(value?: any) {
    if (value) {
      this.setState({
        columns: [{ name: value }],
      });
    }
  }

  handlePickerClick(e, key) {
    e.stopPropagation();
    this.setState(({ colorMapping }) => ({
      colorMapping: [
        ...colorMapping.slice(0, key),
        {
          ...colorMapping[key],
          pickerOpen: !colorMapping[key]["pickerOpen"],
        },
        ...colorMapping.slice(key + 1),
      ],
    }));
  }

  handlePickerColorChange(color, key) {
    this.setState(({ colorMapping }) => ({
      colorMapping: [
        ...colorMapping.slice(0, key),
        {
          ...colorMapping[key],
          color: color.hex,
        },
        ...colorMapping.slice(key + 1),
      ],
    }));
  }

  handleStateTextChange(value, key) {
    this.setState(({ colorMapping }) => ({
      colorMapping: [
        ...colorMapping.slice(0, key),
        {
          ...colorMapping[key],
          state: value,
        },
        ...colorMapping.slice(key + 1),
      ],
    }));
  }

  handleOutsideClick() {
    this.setState(({ colorMapping }) => ({
      colorMapping: colorMapping?.map((i) => {
        return { ...i, pickerOpen: false };
      }),
    }));
  }

  handleAddButtonClick() {
    this.setState(({ colorMapping }) => ({
      colorMapping: [
        ...colorMapping,
        {
          state: "New State",
          color: "#7db2ef",
          pickerOpen: false,
        },
      ],
    }));
  }

  handleDeleteButtonClick() {
    // Should put with each color not just last
    this.setState(({ colorMapping }) => ({
      colorMapping: colorMapping?.slice(0, colorMapping?.length - 1),
    }));
  }

  handleToggleBarClick() {
    this.setState(({ toggleBarClick }) => ({
      toggleBarClick: !toggleBarClick,
    }));
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;

    const tableOptions = Object.keys(this.props.tables)?.map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnOptions: Array<{ key: string; value: string; text: string }> = [];

    if (!!this.state.table) {
      const columns = this.props?.tables?.[this.state.table]?.filter(
        (column) =>
          this.state.columns?.filter((c) => c?.name === column?.name)
            ?.length === 0
      );

      columnOptions = columns?.map((f: { name: string; type: string }) => {
        return {
          key: f.name,
          text: f.name,
          value: f.name,
        };
      });
    }

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key="general">
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table || "Select Stream"}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.state.table}
                    value={this.state.table}
                    elementid={"StateTimelineTable"}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Field"
                    text={
                      this.state.columns?.length
                        ? this.state.columns[0]["name"]
                        : "Select Field"
                    }
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={columnOptions}
                    onChange={(_, data) => this.addColumn(data.value)}
                    // defaultValue={this.props.panelMeta.columns[0]}
                    elementid={"StateTimelineColumn"}
                    error={this.state.error && this.state.columns?.length === 0}
                  />
                </StyledInputDiv>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key="view">
            <EditPanelFormContainer>
              {/* <EditMetaRow>
                <LabelHeading>Choose State Colors</LabelHeading>
                {this.state.colorMapping.map((colorPicker, key) => {
                  return (
                    <div style={{ marginTop: "15px" }} key={key}>
                      <EditMetaRow>
                        <Input
                          className="add-panel-title-input"
                          labelPosition="left"
                        >
                          <Label>State</Label>
                          <input
                            type="text"
                            defaultValue={colorPicker.state}
                            placeholder={""}
                            onChange={(e) =>
                              this.handleStateTextChange(e.target.value, key)
                            }
                          />
                        </Input>
                        <StyledSketchPickerTrigger
                          id="default_color"
                          style={{
                            backgroundColor: `${colorPicker.color}`,
                            marginTop: "4px",
                          }}
                          onClick={(e) => this.handlePickerClick(e, key)}
                        />
                        <div
                          style={{
                            display: colorPicker.pickerOpen ? "block" : "none",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <SketchPicker
                            color={colorPicker.color}
                            onChange={(color) => {
                              this.handlePickerColorChange(color, key);
                            }}
                          />
                        </div>
                      </EditMetaRow>
                    </div>
                  );
                })}
              </EditMetaRow>
              <EditMetaRow>
                <Button
                  inverted
                  color="blue"
                  style={{ margin: "5px", whiteSpace: "nowrap" }}
                  onClick={this.handleAddButtonClick.bind(this)}
                >
                  Add State
                </Button>
                <Button
                  inverted
                  color="red"
                  style={{ margin: "5px", whiteSpace: "nowrap" }}
                  onClick={this.handleDeleteButtonClick.bind(this)}
                  disabled={this.state.colorMapping.length < 3}
                >
                  Delete State
                </Button>
              </EditMetaRow> */}
              <EditMetaRow>
                <ToggleLabel>Click State to change Timerange</ToggleLabel>
                <ToggleSwitch
                  id="state_timeline_click_toggle"
                  defaultChecked={this.state.toggleBarClick}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={this.handleToggleBarClick.bind(this)}
                />
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <div onClick={() => this.handleOutsideClick()}>
          <Tab menu={{}} panes={panes} renderActiveOnly={false} />
        </div>
      </EditMetaRoot>
    );
  }
}

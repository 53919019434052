import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewPieChart } from "./ViewPieChart";
import { EditPieChartMeta } from "./EditPieChartMeta";

export class PieChartMetaData {
  type = "pie_chart";
  table: string = "";
  aggregateColumn: string = "";
  groupByColumn: string = "";
  aggregator: string = "";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
}

export type PieChartValue = {
  group: string;
  value: number;
};

export type PieChartData = PieChartValue[];

export const PieChartPanelDef: PanelDef<PieChartMetaData, PieChartData> = {
  ViewComponent: ViewPieChart,
  EditComponent: EditPieChartMeta,
  formatData: (data) => data,
  metaConstructor: PieChartMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

// Renders a toggle switch component with a loading indicator.
import React from "react";
import Toggle from "../../../common/Toggle";
import { TabSettings } from "../../../../util";

interface ToggleWithLoadingIndicatorProps {
  tabSettings: TabSettings;
  tabName: string;
  updateTabsLoading: boolean;
  toggleTab: (tabName: string) => Promise<void>;
}

const ToggleWithLoadingIndicator: React.FC<ToggleWithLoadingIndicatorProps> = ({
  tabSettings,
  tabName,
  updateTabsLoading,
  toggleTab,
}) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <Toggle
      id={`${tabName}_toggle`}
      style={{ cursor: updateTabsLoading ? "not-allowed" : "pointer" }}
      checked={tabSettings?.show}
      isLocked={updateTabsLoading}
      onChange={() => {
        if (!updateTabsLoading) {
          toggleTab(tabName);
        }
      }}
    />
  </div>
);

export default ToggleWithLoadingIndicator;

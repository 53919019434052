import uuid from "uuid";
import { TimeRange } from "../../Datetime/TimeRange";
import { ReplayState } from "../../DashboardHeader";
import { PanelDef } from "../PanelDef";
import { ViewGaugeChart } from "./ViewGaugeChart";
import { EditGaugeChartMeta } from "./EditGaugeChartMeta";

export type GaugeChartColumn = {
  name: string;
};

export class GaugeChartMetaData {
  type = "gauge_chart";
  table: string = "";
  columns: Array<GaugeChartColumn> = [];
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  showPercentage: boolean = false;
  divisionCount: number = 5;
  valUnit: string = "";
  maxThreshold: number | undefined = undefined;
  minThreshold: number | undefined = undefined;
  startColor: string = "#39fa39";
  endColor: string = "#fa1c1c";
}

export type TimestampedValue = {
  timestamp: number;
  [key: string]: number;
};

export type GaugeChartData = {
  data: TimestampedValue[];
};

function formatData(
  panelData: GaugeChartData,
  timeRange: TimeRange,
  replayState: ReplayState,
  replayStep: number
): GaugeChartData {
  if (replayState === ReplayState.ReplayStopped) {
    return panelData;
  }

  const data = panelData.data;
  const newData: TimestampedValue[] = [];
  let index = 0;
  const startTimestamp = timeRange.getStartTime().toDate().valueOf();
  const endTimestamp = timeRange.getEndTime().toDate().valueOf();

  for (let i = startTimestamp; i <= endTimestamp; i += replayStep) {
    while (index < data.length) {
      if (data[index].timestamp > i) {
        break;
      }

      index++;
    }

    index = index === 0 ? 0 : index - 1;

    newData.push(data[index]);
  }

  return {
    data: newData,
  };
}

export const GaugeChartPanelDef: PanelDef<GaugeChartMetaData, GaugeChartData> =
  {
    ViewComponent: ViewGaugeChart,
    EditComponent: EditGaugeChartMeta,
    formatData: formatData,
    metaConstructor: GaugeChartMetaData,
    previewAspectRatio: { width: 500, height: 300 },
  };

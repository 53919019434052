import * as React from "react";
import { SVGProps } from "react";

const StreamStatusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 90 90"
    {...props}
  >
    <g stroke="currentColor">
      <line x1="0" y1="9" x2="15" y2="9" strokeWidth="5" />
      <line x1="0" y1="42" x2="15" y2="42" strokeWidth="5" />
      <line x1="0" y1="78" x2="15" y2="78" strokeWidth="5" />
      <line x1="25" y1="5" x2="25" y2="85" strokeWidth="2" />
      <line x1="60" y1="5" x2="60" y2="85" strokeWidth="2" />
    </g>
    <path
      fill="currentColor"
      d="M77.5 15a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
    />
    <path
      fill="transparent"
      stroke="currentColor"
      d="M49.5 7.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0ZM84.5 42.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
    />
    <path
      fill="currentColor"
      d="M42.5 50a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15ZM77.5 85a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
    />
    <path
      fill="transparent"
      stroke="currentColor"
      d="M49.5 77.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
    />
  </svg>
);

export default StreamStatusIcon;

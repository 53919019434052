import { MicelioAlertsPanelMetaData } from "./PanelDef";
import React from "react";

import {
  EditComponentProps,
  PanelEditComponent,
  PartialMetaData,
} from "../../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  EditPanelFormContainer,
  EditAnimatedMetaInput,
  StyledInputDiv,
  ThinDivider,
} from "../../util";
import { Tab } from "semantic-ui-react";

type MicelioAlertsPanelState = {
  error: boolean;
};

export class EditMicelioAlertsPanelMeta extends PanelEditComponent<
  MicelioAlertsPanelMetaData,
  MicelioAlertsPanelState
> {
  deviceDashboardIdRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  titleRef = React.createRef<HTMLInputElement>();

  constructor(props: EditComponentProps<MicelioAlertsPanelMetaData>) {
    super(props);

    this.state = {
      error: false,
    };
  }

  getPanelMeta(type): PartialMetaData<MicelioAlertsPanelMetaData> {
    const meta: MicelioAlertsPanelMetaData = {
      type: "micelio_alerts_panel",
      title: this.titleRef.current?.value || "Alerts",
      description: this.descriptionRef.current?.value || "",
      id: this.props.panelMeta.id,
      deviceDashboardId: this.deviceDashboardIdRef.current?.value || "",
      rowsPerPage: 5,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: MicelioAlertsPanelMetaData, type?: string) {
    const isValid = true;

    // type is used here to differentiate between submit and refresh in edit mode
    if (!isValid && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return isValid;
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const deviceDashboardId = this.props.panelMeta.deviceDashboardId || "";

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                    error={this.state.error && !this.titleRef.current?.value}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.deviceDashboardIdRef}
                    defaultValue={deviceDashboardId}
                    label="Device dashboard ID"
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}

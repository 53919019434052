import uuid from "uuid";
import { PanelDef } from "../../PanelDef";
import { ViewMicelioAlertsPanel } from "./ViewMicelioAlertsPanel";
import { EditMicelioAlertsPanelMeta } from "./EditMicelioAlertsPanelMeta";

export class MicelioAlertsPanelMetaData {
  type = "micelio_alerts_panel";
  title = "Alerts";
  description: string = "";
  id = uuid.v4();
  rowsPerPage: number = 5;
  deviceDashboardId?: string;
}

export type Alert = {
  id: number;
  startTimestamp: number;
  endTimestamp: number;
  metadata: { [key: string]: string };
  alertType: string;
  alertSubType: string;
};

export type MicelioAlertsPanelData = {
  alerts: Alert[];
  counts: { [key: string]: number };
};

export const MicelioAlertsPanelDef: PanelDef<
  MicelioAlertsPanelMetaData,
  MicelioAlertsPanelData
> = {
  ViewComponent: ViewMicelioAlertsPanel,
  EditComponent: EditMicelioAlertsPanelMeta,
  formatData: (data) => data,
  previewAspectRatio: { width: 500, height: 300 },
  metaConstructor: MicelioAlertsPanelMetaData,
};

import React, { useEffect, useState } from "react";
import { Checkbox, Grid, Popup, Progress, Table } from "semantic-ui-react";
import moment from "moment";
import LoadingAnimation from "../../../common/Loader";
import { styleActionProgress } from "../../DeviceManagement/Devices/LastActionInfoTable";
import {
  ActionStatusDetail,
  ActionStatusType,
  TableInfo,
  completeAllActionsForDevices,
  fetchTableInfo,
} from "../../../../BytebeamClient";
import { ActionTable, ActionTableRow } from "./LiveActionsList";
import { capitalizeFirstLetter, filterTableInfo } from "../../util";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  OperationsButtonIcon,
  checkLogs,
  convertDecimalToRoman,
} from "../util";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import ConfirmationModal from "../../common/ConfirmationModal";
import ConfirmationModalMessageNonDelete from "../../common/ConfirmationModalMessageNonDelete";
import { useUser } from "../../../../context/User.context";
import { beamtoast } from "../../../common/CustomToast";
import { styled } from "styled-components";
import TextWithToolTip from "../../DeviceManagement/Devices/TextWithToolTip";
import ActionLogModal from "../../DeviceManagement/Devices/ActionLogModal";

const CheckboxTableHeaderCell = styled(Table.HeaderCell)`
  & .checkbox-hidden {
    opacity: 0;
  }

  &:hover .checkbox-hidden {
    opacity: 1;
  }
`;

interface DeviceListProps {
  readonly allDevicesSelected: boolean;
  readonly selectedDevices: { deviceID: number; deviceStatus: string }[];
  readonly actionsData: ActionStatusType;
  readonly deviceData?: ActionStatusDetail[];
  readonly actionID?: any;
  readonly loading: boolean;
  readonly allowedActions: string[] | "all";
  readonly handleRefetchDeviceStatusDetails: () => Promise<void>;
  readonly handleAllDevicesSelectedClick: () => void;
  readonly handleCheckboxClick: (
    deviceId: number,
    deviceStatus: string
  ) => void;
}

function DeviceListV3(props: DeviceListProps) {
  const history = useHistory();
  const matchedPath = useRouteMatch("/projects/:tenant/actions");
  const { user } = useUser();
  const permissions = user.role.permissions;

  const [streamsList, setStreamsList] = useState<Record<string, string[]>>({});

  const handleMarkAllDeviceActions = async (deviceID) => {
    try {
      if (deviceID) {
        let res = await completeAllActionsForDevices({
          id: [`${deviceID}`],
        });
        if (res && res?.count !== undefined) {
          beamtoast.success(`Marked all actions as complete`);
          await props.handleRefetchDeviceStatusDetails();
        }
      }
    } catch (e) {
      console.log(e);
      beamtoast.error("Failed to mark all actions as complete.");
    }
  };

  useEffect(() => {
    async function getStreamList() {
      try {
        const res: TableInfo = await fetchTableInfo();
        setStreamsList(filterTableInfo(res));
      } catch (e) {
        console.log(e);
      }
    }

    getStreamList();
  }, []);
  return (
    <Grid style={{ marginBottom: "20px" }}>
      <Grid.Row>
        <Grid.Column>
          <ActionTable id="actions_device-list" selectable fixed>
            <Table.Header>
              <Table.Row textAlign="center">
                {permissions.allowedActions.includes(
                  props.actionsData?.type
                ) && (
                  <CheckboxTableHeaderCell width={1}>
                    <Checkbox
                      className={
                        props.allDevicesSelected ? "" : "checkbox-hidden"
                      }
                      disabled={props.loading || props.deviceData?.length === 0}
                      onClick={() => {
                        if (!props.loading || props.deviceData?.length !== 0) {
                          props.handleAllDevicesSelectedClick();
                        }
                      }}
                      checked={props.allDevicesSelected}
                    />
                  </CheckboxTableHeaderCell>
                )}
                <Table.HeaderCell width={2}>
                  {props?.deviceData?.[0]?.["-serial_metadata"]
                    ? `#${capitalizeFirstLetter(Object?.keys(props.deviceData[0]?.["-serial_metadata"]).toString())}`
                    : "Device ID"}
                </Table.HeaderCell>
                {!props?.actionsData?.statuses_phased?.[-1] && (
                  <Table.HeaderCell width={2}>Phase</Table.HeaderCell>
                )}
                <Table.HeaderCell width={2}>Updated At</Table.HeaderCell>
                <Table.HeaderCell width={2}>Action State</Table.HeaderCell>
                <Table.HeaderCell width={3}>Errors</Table.HeaderCell>
                <Table.HeaderCell width={4}>Progress</Table.HeaderCell>
                <Table.HeaderCell width={2}>Operations</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.loading && (
                <Table.Row>
                  <Table.Cell colSpan={8}>
                    <LoadingAnimation
                      loadingText="Loading Device List"
                      loaderSize="50px"
                      fontSize="18px"
                      marginTopText="10px"
                      loaderContainerMargin="60px 0px 60px 0px"
                    />
                  </Table.Cell>
                </Table.Row>
              )}
              {!props.loading &&
                props.deviceData?.length !== 0 &&
                props.deviceData?.map((data) => {
                  return (
                    <ActionTableRow
                      key={data?.device_id}
                      textAlign={"center"}
                      onDoubleClick={(e: Event) => {
                        e.preventDefault();
                        e.stopPropagation();
                        history.push(
                          `?action_id=${props.actionID}&device_id=${data?.device_id}`
                        );
                      }}
                    >
                      {props.allowedActions.includes(props.actionsData.type) ? (
                        <Table.Cell>
                          <Checkbox
                            className={
                              props.selectedDevices.some(
                                (device) =>
                                  device.deviceID === Number(data.device_id)
                              ) || props.allDevicesSelected
                                ? ""
                                : "checkbox-hidden"
                            }
                            onClick={() =>
                              props.handleCheckboxClick(
                                Number(data.device_id),
                                data?.status
                              )
                            }
                            disabled={props.allDevicesSelected}
                            checked={
                              props.selectedDevices.some(
                                (device) =>
                                  device.deviceID === Number(data.device_id)
                              ) || props.allDevicesSelected
                            }
                          />
                        </Table.Cell>
                      ) : null}
                      <Table.Cell>
                        <TextWithToolTip
                          text={
                            data?.["-serial_metadata"]
                              ? data?.["-serial_metadata"][
                                  Object.keys(data?.["-serial_metadata"])[0]
                                ]
                              : data?.device_id
                          }
                          textList={
                            data?.["-serial_metadata"]
                              ? [`Device Id: ${data?.device_id}`]
                              : []
                          }
                        />
                      </Table.Cell>
                      {!props?.actionsData?.statuses_phased?.[-1] && (
                        <Table.Cell>{`Phase ${convertDecimalToRoman(data?.phase + 1)}`}</Table.Cell>
                      )}
                      <Table.Cell>
                        <Popup
                          content={moment(data?.updated_at).format(
                            "ddd, MMM Do YYYY, HH:mm:ss"
                          )}
                          position="top center"
                          inverted
                          trigger={
                            <div>
                              {data.updated_at
                                ? capitalizeFirstLetter(
                                    moment(data.updated_at).fromNow()
                                  )
                                : "--"}
                            </div>
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <TextWithToolTip text={data?.status} />
                      </Table.Cell>
                      <Table.Cell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: `${data?.errors?.length !== 0 ? "2rem" : "0"}`,
                          }}
                        >
                          <TextWithToolTip
                            text={
                              data.errors && data.errors?.length > 0
                                ? Array.isArray(data.errors)
                                  ? data.errors.join(", ")
                                  : data.errors
                                : "None"
                            }
                          />
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0px 18px",
                          }}
                        >
                          <Progress
                            style={{ width: "100%", marginBottom: "0px" }}
                            progress="percent"
                            percent={
                              styleActionProgress(data?.status, data?.progress)[
                                "progress"
                              ]
                            }
                            indicating={
                              data?.status !== "Completed" &&
                              data?.status !== "Failed"
                            }
                            size="medium"
                            className={`action-progress ${styleActionProgress(data?.status, data?.progress)["className"]}`}
                          />
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {permissions.allowMarkActionAsCompleted && (
                            <Popup
                              content="Mark All Action as completed"
                              position="top center"
                              inverted
                              trigger={
                                <div>
                                  <ConfirmationModal
                                    prefixContent="Mark All Actions as Complete"
                                    expectedText={"Yes"}
                                    onConfirm={() => {
                                      handleMarkAllDeviceActions(
                                        data?.device_id
                                      );
                                    }}
                                    trigger={
                                      <OperationsButtonIcon name="check square" />
                                    }
                                    message={
                                      <ConfirmationModalMessageNonDelete
                                        name={"Mark All Actions as Complete"}
                                        expectedText={"Yes"}
                                        type={""}
                                        specialMessage={`This will mark all Actions triggered on this Device(${props?.deviceData?.[0]?.["-serial_metadata"] && data?.["-serial_metadata"] ? `${Object?.keys(props.deviceData[0]?.["-serial_metadata"]).toString()}: ${data?.["-serial_metadata"][Object.keys(data?.["-serial_metadata"])[0]]}` : `id: ${data?.device_id}`}) which are not in completed/failed states as completed.`}
                                      />
                                    }
                                  />
                                </div>
                              }
                            />
                          )}
                          {Object.entries(streamsList).filter(
                            ([stream, fields]) => checkLogs(stream)
                          )?.length > 0 && (
                            <Popup
                              content="Operations logs"
                              position="top center"
                              inverted
                              trigger={
                                <div>
                                  <OperationsButtonIcon
                                    name="file alternate outline"
                                    onClick={() => {
                                      history.push(
                                        `${matchedPath?.url}/logs/device-log?action_id=${props.actionID}&device_id=${data?.device_id}`
                                      );
                                    }}
                                  />
                                </div>
                              }
                            />
                          )}
                          <ActionLogModal
                            actionId={props.actionID.toString()}
                            deviceId={data.device_id.toString()}
                            actionStatus={data.status}
                            trigger={
                              <div>
                                <Popup
                                  content="Action Progress Logs"
                                  position="top center"
                                  inverted
                                  trigger={
                                    <div>
                                      <OperationsButtonIcon
                                        name="arrow alternate circle up outline"
                                        disabled={
                                          !user.role.permissions.allowedActions.includes(
                                            props.actionsData.type
                                          )
                                        }
                                      />
                                    </div>
                                  }
                                />
                              </div>
                            }
                          />
                          <Popup
                            content="More details"
                            position="top center"
                            inverted
                            trigger={
                              <div>
                                <OperationsButtonIcon
                                  name="eye"
                                  onClick={() => {
                                    history.push(
                                      `?action_id=${props.actionID}&device_id=${data?.device_id}`
                                    );
                                  }}
                                />
                              </div>
                            }
                          />
                        </div>
                      </Table.Cell>
                    </ActionTableRow>
                  );
                })}
              {!props.loading && props.deviceData?.length === 0 && (
                <Table.Row textAlign="center">
                  <Table.Cell colSpan={8}>
                    <StyledHeader as="h4">No Devices Found!</StyledHeader>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </ActionTable>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default DeviceListV3;

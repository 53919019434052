import * as React from "react";
import { SVGProps } from "react";

const SessionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="19.5 68.5 117 15"
    {...props}
  >
    <path
      fill="currentColor"
      d="M44.5 68.5h50v3h42v9h-42v3h-50v-4c-8.252-.316-16.419.017-24.5 0-.498-2.317-.665-5.65-.3-8.1 8.534-.265 16.867-.932 24.8-.5Z"
      style={{
        opacity: 1,
      }}
    />
  </svg>
);

export default SessionsIcon;

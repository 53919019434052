import * as React from "react";
import { SVGProps } from "react";

const LedPanelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 90 90"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.5 15a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15ZM77.5 15a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
    />
    <path
      fill="transparent"
      stroke="currentColor"
      d="M49.5 7.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0ZM14.5 42.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0ZM84.5 42.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
    />
    <path
      fill="currentColor"
      d="M42.5 50a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15ZM7.5 85a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15ZM77.5 85a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
    />
    <path
      fill="transparent"
      stroke="currentColor"
      d="M49.5 77.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
    />
  </svg>
);

export default LedPanelIcon;

import { TimeseriesTableMetaData } from "./PanelDef";
import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Accordion, Icon, Radio, Tab, Table } from "semantic-ui-react";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  EditMetaDropdown,
  ThinDivider,
  DeleteIconInPanel,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  EditPanelFormContainer,
  ToggleLabel,
  ReorderIconInPanel,
  DraggableItem,
  StyledInputDiv,
  EditMetaRowDraggable,
} from "../util";
import { TableInfo } from "../../../../../BytebeamClient";
import { QuerySelector, queryToObject } from "../../../common/QuerySelector";
import ToggleSwitch from "../../../common/ToggleSwitch";
import { ClearFilterSpanWrapper } from "../LastValue/EditLastValueMeta";
import {
  AddAllColText,
  PrecisionInlineInput,
} from "../../../../common/commonStyledComps";
type QuerySelectorRefsType = { [key: number]: React.RefObject<QuerySelector> };

export type EditTimeseriesTableMetaProps = {
  panelMeta: TimeseriesTableMetaData;
  tables: TableInfo;
};

export type EditTimeseriesTableMetaState = {
  table: string;
  sortOrder: string;
  columns: string[];
  rowKeys: number[];
  queryRefs: QuerySelectorRefsType;
  activeIndex: number;
  filter: any;
  error: boolean;
  showNumericValuesInHex: boolean;
  enableUnits: boolean;
  autoScaleUnits: boolean;
  enableRoundoff: boolean;
  roundOffPrecision: number;
};

export class EditTimeseriesTableMeta extends PanelEditComponent<
  TimeseriesTableMetaData,
  EditTimeseriesTableMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  rowsPerPageRef = React.createRef<HTMLInputElement>();

  static defaultState(): EditTimeseriesTableMetaState {
    return {
      table: "",
      sortOrder: "desc",
      columns: [],
      rowKeys: [0],
      queryRefs: { 0: React.createRef<QuerySelector>() },
      activeIndex: -1,
      filter: null,
      error: false,
      showNumericValuesInHex: false,
      enableUnits: false,
      autoScaleUnits: false,
      enableRoundoff: true,
      roundOffPrecision: 3,
    };
  }

  getStateFromPanelMeta(props) {
    // Additional validation to convert HoneySQL query to object
    if (
      props.panelMeta?.query &&
      Array.isArray(props.panelMeta.query) &&
      props.panelMeta.query.length > 0 &&
      props.panelMeta.query[0] === "and"
    ) {
      props.panelMeta.query = queryToObject(props.panelMeta.query);
    }
    let rowKeys = [0];
    if (props.panelMeta.query)
      rowKeys = props.panelMeta.query.map((_m, i) => i);

    if (!rowKeys || rowKeys.length === 0) {
      rowKeys = [0];
    }

    const queryRefs = {};

    rowKeys.forEach((k) => {
      queryRefs[k] = React.createRef<QuerySelector>();
    });

    return {
      table: props.panelMeta?.table,
      columns: props.panelMeta?.columns,
      sortOrder: props.panelMeta.sortOrder ? props.panelMeta.sortOrder : "desc",
      rowKeys: rowKeys,
      queryRefs: queryRefs,
      filter: props.panelMeta?.query,
      activeIndex: props.panelMeta?.query ? 0 : -1,
      error: false,
      showNumericValuesInHex: props.panelMeta?.showNumericValuesInHex,
      enableUnits: props.panelMeta?.enableUnits ?? false,
      autoScaleUnits: props.panelMeta?.autoScaleUnits ?? false,
      enableRoundoff: props.panelMeta?.enableRoundoff ?? true,
      roundOffPrecision: props.panelMeta.roundOffPrecision ?? 3,
    };
  }

  constructor(props: EditTimeseriesTableMetaProps) {
    super(props);
    if (props.panelMeta) {
      this.state = this.getStateFromPanelMeta(props);
    } else {
      this.state = EditTimeseriesTableMeta.defaultState();
    }
  }

  getPanelMeta(type): PartialMetaData<TimeseriesTableMetaData> {
    const validQuery = this.state.rowKeys
      .map((key) => this.state.queryRefs[key])
      .filter((ref) => {
        return ref?.current?.isFilled();
      })
      .map((ref) => {
        if (!ref.current) {
          throw new Error("Should not happen");
        }
        return ref.current.getQuery();
      });

    const meta: TimeseriesTableMetaData = {
      type: "timeseries_table",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table,
      columns: this.state.columns,
      sortOrder: this.state.sortOrder,
      rowsPerPage: parseInt(this.rowsPerPageRef.current?.value || "20"),
      query: validQuery,
      showNumericValuesInHex: this.state.showNumericValuesInHex,
      enableUnits: this.state.enableUnits,
      autoScaleUnits: this.state.autoScaleUnits,
      enableRoundoff: this.state.enableRoundoff,
      roundOffPrecision: this.state.roundOffPrecision || 3,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: TimeseriesTableMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (!(!!meta.table && meta.columns.length > 0) && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return !!meta.table && meta.columns.length > 0;
  }

  addRow() {
    this.setState(({ rowKeys, queryRefs }) => {
      const newRowKey = Math.max(...rowKeys) + 1;
      const newRefs = Object.assign({}, queryRefs, {
        [newRowKey]: React.createRef(),
      });

      return {
        rowKeys: [...rowKeys, newRowKey],
        queryRefs: newRefs,
      };
    });
  }

  removeRow(i: number) {
    delete this.state.queryRefs[i];

    this.setState({
      rowKeys: this.state.rowKeys.filter((v) => v !== i),
      queryRefs: this.state.queryRefs,
    });
  }

  getQuery() {
    return this.state.rowKeys.map((key) => {
      const ref = this.state.queryRefs[key];

      if (ref.current == null) {
        throw new Error("Empty ref");
      }

      return ref.current.getQuery();
    });
  }

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  setTable(_event, data) {
    this.setState({
      table: data.value,
      columns: [],
    });
  }

  removeColumn(index: number) {
    this.setState({
      columns: [
        ...this.state.columns.slice(0, index),
        ...this.state.columns.slice(index + 1),
      ],
    });
  }

  setColumn(index: number, value?: any) {
    if (value) {
      this.setState({
        columns: [
          ...this.state.columns.slice(0, index),
          value,
          ...this.state.columns.slice(index + 1),
        ],
      });
    }
  }

  addColumn(value?: any) {
    if (value) {
      this.setState({
        columns: [...this.state.columns, value],
      });
    }
  }

  addAllColumns(columnOptions) {
    const remainingColumns = columnOptions.map((c) => c.value);
    this.setState({
      columns: [...this.state.columns, ...remainingColumns],
    });
  }

  changeSortOrder(_event, data) {
    if (data.checked) {
      this.setState({ sortOrder: "asc" });
    } else {
      this.setState({ sortOrder: "desc" });
    }
  }

  clearFilter(event?: any) {
    event.stopPropagation();
    for (let i = 0; i < this.state.rowKeys.length; i++) {
      this.removeRow(i);
    }
    this.setState({ filter: {} }, () => {
      this.setState(
        {
          rowKeys: [0],
          queryRefs: { 0: React.createRef<QuerySelector>() },
        },
        () => {
          this.state.queryRefs[this.state.rowKeys[0]]?.current?.clearForm();
        }
      );
    });
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedColumns = Array.from(this.state.columns);
    const [removed] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, removed);

    this.setState({
      columns: reorderedColumns,
    });
  };

  enableUnitsToggle() {
    const toggled = !this.state.enableUnits;
    this.setState({ enableUnits: toggled });
  }

  enableAutoScaleUnitsToggle() {
    const toggled = !this.state.autoScaleUnits;
    this.setState({ autoScaleUnits: toggled });
  }

  enableRoundoffToggle() {
    const toggled = !this.state.enableRoundoff;
    this.setState({ enableRoundoff: toggled });
  }

  handleRoundOffPrecisionChange = (e) => {
    const value = e.target.value;
    if (!value || Number.isNaN(value)) {
      return;
    }
    const newValue = parseInt(value, 10);
    if (newValue > 0) {
      this.setState({
        roundOffPrecision: newValue,
      });
    } else {
      // Reset to the default value or previous valid value
      e.target.value = this.state.roundOffPrecision;
    }
  };

  render() {
    const { activeIndex } = this.state;
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const rowKeys = this.state.rowKeys;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnOptions: Array<{
      key: string;
      value: string;
      text: string;
      type: string;
    }> = [];
    let filterColumnOptions: Array<{
      key: string;
      value: string;
      text: string;
      type: string;
    }> = [];

    if (
      !!this.state.table &&
      !!this.props.tables &&
      this.props.tables[this.state.table]
    ) {
      const columns: Array<{ name: string; type: string }> = this.props.tables[
        this.state.table
      ].filter(
        (column: { name: string; type: string }) =>
          !this.state.columns.includes(column.name)
      );

      columnOptions = columns.map((f: { name: string; type: string }) => {
        return {
          key: f.name,
          text: f.name,
          value: f.name,
          type: f.type,
        };
      });

      const filterColumns = this.props.tables[this.state.table];

      filterColumnOptions = filterColumns.map(
        (f: { name: string; type: string }) => {
          return {
            key: f.name,
            text: f.name,
            value: f.name,
            type: f.type,
          };
        }
      );
    }

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.props.panelMeta.table}
                    elementid={"TimeseriesTable"}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {this.state.columns.map((column, index) => (
                        <Draggable
                          key={column}
                          draggableId={column}
                          index={index}
                        >
                          {(provided) => (
                            <DraggableItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {/* Your column rendering logic is here */}

                              <EditMetaRowDraggable key={column}>
                                <StyledInputDiv width="50%" marginTop="10px">
                                  <EditMetaDropdown
                                    placeholder="Select Field"
                                    text={column}
                                    search
                                    selection
                                    options={columnOptions}
                                    onChange={(_, data) =>
                                      this.setColumn(index, data.value)
                                    }
                                    defaultValue={column}
                                    staticLabel
                                  />
                                </StyledInputDiv>

                                <DeleteIconInPanel
                                  color="red"
                                  onClick={() => this.removeColumn(index)}
                                >
                                  <Icon name="minus" />
                                </DeleteIconInPanel>

                                <ReorderIconInPanel
                                  {...provided.dragHandleProps}
                                >
                                  <Icon name="sort" />
                                </ReorderIconInPanel>
                              </EditMetaRowDraggable>
                            </DraggableItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Field"
                    text="Select Field"
                    defaultValue={""}
                    value={""}
                    search
                    selection
                    options={columnOptions}
                    onChange={(_, data) => this.addColumn(data.value)}
                    disabled={columnOptions.length === 0}
                    error={this.state.error && this.state.columns.length === 0}
                  />
                </StyledInputDiv>
                {columnOptions.length === 0 ? null : (
                  <AddAllColText
                    onClick={() => {
                      this.addAllColumns(columnOptions);
                    }}
                  >
                    Add all columns
                  </AddAllColText>
                )}
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <label style={{ paddingRight: "10px", width: "180px" }}>
                  {" "}
                  Sort Order : Ascending{" "}
                </label>
                <Radio
                  toggle
                  checked={this.state.sortOrder === "asc"}
                  onChange={(_event, data) =>
                    this.changeSortOrder(_event, data)
                  }
                />
              </EditMetaRow>
              <Accordion inverted>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  <span style={{ fontWeight: "bold" }}>Filter</span>
                  {this.state.rowKeys.length > 0 &&
                    this.state.queryRefs[
                      this.state.rowKeys[0]
                    ]?.current?.isFilled() && (
                      <ClearFilterSpanWrapper
                        onClick={(event) => this.clearFilter(event)}
                      >
                        Clear filter
                      </ClearFilterSpanWrapper>
                    )}
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === 0}
                  style={{ paddingLeft: "0px" }}
                >
                  <div style={{ width: "100%", marginTop: "16px" }}>
                    {rowKeys.map((rowKey, i) => {
                      return (
                        <React.Fragment key={rowKey}>
                          {i ? (
                            <div style={{ marginLeft: "45%", color: "grey" }}>
                              AND
                            </div>
                          ) : (
                            ""
                          )}

                          <Table key={`tab-${rowKey}`}>
                            <Table.Body>
                              <QuerySelector
                                key={rowKey}
                                ref={this.state.queryRefs[rowKey]}
                                defaultValue={
                                  this.state.filter ? this.state.filter[i] : {}
                                }
                                showRemoveIcon={rowKeys.length > 1}
                                showAddIcon={i === rowKeys.length - 1}
                                onRemoveRow={() => this.removeRow(rowKey)}
                                onAddRow={this.addRow.bind(this)}
                                columns={filterColumnOptions}
                                elementid={i.toString()}
                              />
                            </Table.Body>
                          </Table>
                        </React.Fragment>
                      );
                    })}

                    <div style={{ width: "100%", height: "10px" }} />
                  </div>
                </Accordion.Content>
              </Accordion>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              <EditMetaRow>
                <ToggleLabel>Show numeric values in hex</ToggleLabel>
                <ToggleSwitch
                  id="connectNullToggle"
                  defaultChecked={this.state.showNumericValuesInHex}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() =>
                    this.setState({
                      showNumericValuesInHex:
                        !this.state.showNumericValuesInHex,
                    })
                  }
                />
              </EditMetaRow>
              <EditMetaRow>
                <ToggleLabel style={{ fontWeight: "bold" }}>
                  Enable Units
                </ToggleLabel>
                <ToggleSwitch
                  id="enableUnits"
                  defaultChecked={this.props.panelMeta.enableUnits}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() => {
                    this.enableUnitsToggle();
                  }}
                />
              </EditMetaRow>
              {this.state.enableUnits && (
                <EditMetaRow>
                  <ToggleLabel style={{ fontWeight: "bold" }}>
                    Auto Scale Units
                  </ToggleLabel>
                  <ToggleSwitch
                    id="autoScaleUnits"
                    defaultChecked={this.props.panelMeta.autoScaleUnits}
                    disabled={false}
                    Text={["Yes", "No"]}
                    onToggleChange={() => {
                      this.enableAutoScaleUnitsToggle();
                    }}
                  />
                </EditMetaRow>
              )}
              <EditMetaRow>
                <ToggleLabel style={{ fontWeight: "bold" }}>
                  Roundoff values to{" "}
                  <PrecisionInlineInput
                    autoFocus
                    id="roundOffPrecision"
                    defaultValue={this.state.roundOffPrecision}
                    placeholder="3"
                    type="number"
                    input={{
                      min: 1,
                      max: 9,
                      maxLength: 1,
                    }}
                    onChange={this.handleRoundOffPrecisionChange}
                  />{" "}
                  decimal places
                </ToggleLabel>
                <ToggleSwitch
                  id="enableRoundoff"
                  defaultChecked={this.state.enableRoundoff}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() => {
                    this.enableRoundoffToggle();
                  }}
                />
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}

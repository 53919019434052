import React, { useState } from "react";
import { Button, Modal, Icon, Popup } from "semantic-ui-react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import RemoteShell from "./RemoteShell/RemoteShell";
import { markActionAsCompleted } from "../../../../../BytebeamClient";
import { useUser } from "../../../../../context/User.context";
import { capitalizeFirstLetter } from "../../../util";

type RemoteShellModalProps = {
  readonly deviceId: number;
  readonly deviceSerialMetadata: string;
  readonly isOpen: boolean;
  readonly close: () => void;
};

export function RemoteShellModal(props: RemoteShellModalProps) {
  const { isOpen, deviceSerialMetadata, deviceId } = props;
  const [actionId, setActionId] = useState();
  const fullScreenHandle = useFullScreenHandle();

  const { user } = useUser();
  const serial_metadata = user?.["tenant-settings"]?.["serial-key"] ?? "";

  const modalTitleDeviceMetadata = serial_metadata
    ? `#${capitalizeFirstLetter(serial_metadata)}: ${deviceSerialMetadata}`
    : `#Device ID: ${deviceId}`;

  // Close the modal and mark the action as completed.
  const handleRemoteShellClose = async () => {
    if (actionId) {
      try {
        await markActionAsCompleted(actionId, { all: true });
      } catch (e) {
        console.log("Failed to mark action as completed", e);
      }
    }
    props.close();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleRemoteShellClose}
      size="fullscreen"
      className="dark"
      closeOnDimmerClick={false}
      style={{ marginLeft: "2em", height: "fit-content" }}
    >
      <Modal.Header>
        {`Remote Shell (${modalTitleDeviceMetadata})`}
        {!fullScreenHandle.active ? (
          <span className="bytebeam-panel-icons">
            <span className="bytebeam-panel-icon">
              <Popup
                content="View full screen"
                position="top center"
                inverted
                trigger={
                  <Icon
                    name="expand"
                    color="blue"
                    link
                    onClick={fullScreenHandle.enter}
                  />
                }
              />
            </span>
          </span>
        ) : (
          ""
        )}
      </Modal.Header>
      <Modal.Content>
        {/* @ts-ignore */}
        <FullScreen handle={fullScreenHandle}>
          {fullScreenHandle.active ? (
            <span className="bytebeam-panel-icons">
              <span className="bytebeam-panel-icon">
                <Popup
                  content="Exit full screen"
                  position="top center"
                  inverted
                  trigger={
                    <Icon
                      name="compress"
                      color="yellow"
                      link
                      onClick={fullScreenHandle.exit}
                    />
                  }
                />
              </span>
            </span>
          ) : (
            ""
          )}
          <RemoteShell
            setActionId={setActionId}
            deviceId={props.deviceId}
            isFullScreen={fullScreenHandle.active}
          />
        </FullScreen>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => handleRemoteShellClose()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

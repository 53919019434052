import React from "react";
import { StreamStatusData, StreamStatusMetaData } from "./PanelDef";
import { PanelViewComponent } from "../PanelDef";
import { TableContainer } from "../util";
import { Popup, Table } from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../../util";

interface LedProps {
  size: number;
  color: string;
}

const LedBulb = (props: LedProps) => (
  <svg
    height={props.size}
    width={props.size}
    style={{
      cursor: "pointer",
    }}
  >
    <circle
      cx={props.size / 2}
      cy={props.size / 2}
      r={props.size / 2 - 1}
      stroke="black"
      strokeWidth="2"
      fill={props.color}
    />
  </svg>
);

interface DeviceData {
  id: string;
  serial_metadata?: Record<string, string | number>;
  streams: {
    [streamName: string]: {
      timestamp: number;
    };
  };
}

class MultiStreamStatusTable extends PanelViewComponent<
  StreamStatusMetaData,
  StreamStatusData
> {
  formatData(data) {
    const deviceMap: Record<string, DeviceData> = {};

    for (const item of data as Record<
      string,
      {
        id: string;
        timestamp: number;
        "-serial_metadata"?: Record<string, string | number>;
      }[]
    >[]) {
      for (const [streamName, entries] of Object.entries(item)) {
        entries.forEach(
          (entry: {
            id: string;
            timestamp: number;
            "-serial_metadata"?: Record<string, string | number>;
          }) => {
            const { id, timestamp, "-serial_metadata": serialMetadata } = entry;

            if (!deviceMap[id]) {
              deviceMap[id] = {
                id,
                streams: {},
              };
            }

            if (serialMetadata && !deviceMap[id].serial_metadata) {
              deviceMap[id]["-serial_metadata"] = serialMetadata;
            }

            deviceMap[id].streams[streamName] = { timestamp };
          }
        );
      }
    }
    return Object.values(deviceMap);
  }

  render() {
    const data: DeviceData[] = this.formatData(this.props.data["all-data"]),
      tables = this.props.panelMeta.tables,
      offStateColor = this.props.panelMeta.offStateColor,
      onStateColor = this.props.panelMeta.onStateColor,
      size = 30;

    const serialMetadataKey = Object.keys(
      data[0]?.["-serial_metadata"] ?? {}
    )[0];
    return (
      <TableContainer>
        <div className="tableContentContainer">
          <Table
            compact
            selectable
            unstackable
            style={{ minWidth: "230px" }}
            textAlign="center"
          >
            <Table.Header>
              <Table.Row>
                {serialMetadataKey ? (
                  <Table.HeaderCell key="serial-metadata">
                    {`#${capitalizeFirstLetter(serialMetadataKey)}`}
                  </Table.HeaderCell>
                ) : (
                  <Table.HeaderCell key="id">Device ID</Table.HeaderCell>
                )}
                {tables.map((table) => {
                  return (
                    <Table.HeaderCell key={table}>
                      {capitalizeFirstLetter(table)}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((device, index) => (
                <Table.Row key={index}>
                  {serialMetadataKey ? (
                    <Table.Cell>
                      {device?.["-serial_metadata"][serialMetadataKey]}
                    </Table.Cell>
                  ) : (
                    <Table.Cell>{device.id}</Table.Cell>
                  )}
                  {tables.map((table) => {
                    return (
                      <Popup
                        key={table}
                        position="top center"
                        hideOnScroll
                        inverted
                        content={
                          device.streams?.[table]?.timestamp === 0
                            ? "No data"
                            : new Date(
                                device.streams?.[table]?.timestamp
                              ).toLocaleString("en-GB")
                        }
                        trigger={
                          <Table.Cell key={table}>
                            <LedBulb
                              size={size}
                              color={
                                device.streams?.[table]?.timestamp > 0
                                  ? onStateColor
                                  : offStateColor
                              }
                            />
                          </Table.Cell>
                        }
                      />
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </TableContainer>
    );
  }
}

export class ViewStreamStatus extends PanelViewComponent<
  StreamStatusMetaData,
  StreamStatusData
> {
  render() {
    const { data } = this.props;
    if (data["all-data"].length > 0) {
      let sortedData = data["all-data"].map((item) => {
        const sortedById = {};
        sortedById[Object.keys(item)[0]] = item[Object.keys(item)[0]].sort(
          (a, b) => a.id - b.id
        );
        return sortedById;
      });
      data["all-data"] = sortedData;

      return <MultiStreamStatusTable {...this.props} />;
    } else {
      return <div className="panel-no-data">No Data</div>;
    }
  }
}

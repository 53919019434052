import { HistogramData, HistogramMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import Plot from "react-plotly.js";
import { UserContext } from "../../../../../context/User.context";
import ThemeSchema from "../../../../../theme/schema";

export class ViewHistogram extends PanelViewComponent<
  HistogramMetaData,
  HistogramData
> {
  render() {
    const plotStyle = {
      width: "calc(100% - 4px)",
      height: "calc(100% - 29px)", //25px panel title + 4px margin
      marginTop: "2px",
      marginLeft: "2px",
    };

    const layout = {
      autosize: true,
      responsive: true,
      margin: {
        l: 50,
        r: 50,
        b: 50,
        t: 50,
      },
      xaxis: {
        ticklen: 4,
      },
      yaxis: {
        ticklen: 4,
        showline: true,
      },
    } as Partial<Plotly.Layout>;

    const x = this.props.data.map((d) => d.start);
    const y = this.props.data.map((d) => d.height);
    const hoverText = this.props.data.map(
      (d) => `${d.start.toFixed(2)}-${d.end.toFixed(2)}`
    );

    const data = {
      x: x,
      y: y,
      type: "bar",
      marker: {
        color: "#90B2E5",
      },
      text: hoverText,
    } as Plotly.Data;

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <Plot
            data={[data]}
            style={plotStyle}
            layout={{
              ...layout,
              xaxis: {
                ...layout.xaxis,
                tickfont: {
                  color:
                    ThemeSchema.data[
                      userContext.user?.settings?.theme ?? "dark"
                    ]?.colors["chart-text-color"],
                },
              },
              yaxis: {
                ...layout.yaxis,
                tickfont: {
                  color:
                    ThemeSchema.data[
                      userContext.user?.settings?.theme ?? "dark"
                    ]?.colors["chart-text-color"],
                },
              },
            }}
            useResizeHandler={true}
            config={{ displayModeBar: false }}
          />
        )}
      </UserContext.Consumer>
    );
  }
}

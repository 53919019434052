import React, { useState, useEffect } from "react";
import { Button, Modal, Input, Table, Icon, Label } from "semantic-ui-react";
// import ComputedStream from "./ComputedStream"
import TypeSelection from "./TypeSelection";
import { filter, updateColumns, mapColumnIf } from "./functional";
import { createNonComputedStream } from "../../../../BytebeamClient";
import { Mixpanel } from "../../common/MixPanel";
import { hasSpecialCharacters, isNumericType } from "../../util";
import { beamtoast } from "../../../common/CustomToast";
import UnitSelection from "./UnitSelection";
import { useUser } from "../../../../context/User.context";

function ButtonSet({ pos, onAdd, onDelete }) {
  if (pos === "only" || pos === "last") {
    return <Button onClick={onAdd} icon="plus" primary />;
  } else {
    return <Button onClick={onDelete} icon="minus" secondary />;
  }
}

function ColumnRow({
  elemId,
  columnName,
  setColumnName,
  columnType,
  setColumnType,
  columnUnit,
  setColumnUnit,
  onDelete,
  onAdd,
  last,
  only,
  disable,
}) {
  return (
    <Table.Row>
      <Table.Cell width={5}>
        <Input
          placeholder="Field Name"
          value={columnName}
          onChange={(_e, d) => setColumnName(d.value)}
          disabled={disable}
          id={`input-columnName-${elemId}`}
          style={{ width: "100%" }}
        />
      </Table.Cell>

      <Table.Cell width={5}>
        <TypeSelection
          value={columnType}
          onChange={(_e, d) => setColumnType(d.value)}
          disabled={disable}
          id={`input-columnType-${elemId}`}
          editMode={false}
        />
      </Table.Cell>

      <Table.Cell width={3}>
        <UnitSelection
          value={columnUnit}
          columnTypeValue={columnType}
          elemId={elemId}
          onChange={(_e, d) => setColumnUnit(d.value)}
          disabled={disable}
          id={`input-columnUnit-${elemId}`}
        />
      </Table.Cell>

      <Table.Cell width={2}>
        {only ? (
          <ButtonSet pos="only" onAdd={onAdd} />
        ) : (
          <>
            {last ? (
              <ButtonSet pos="last" onAdd={onAdd} onDelete={onDelete} />
            ) : (
              <>
                {disable ? "" : <ButtonSet onAdd={onAdd} onDelete={onDelete} />}
              </>
            )}
          </>
        )}
      </Table.Cell>
    </Table.Row>
  );
}

export default function CreateStreamModal({ onClose, sourceStreams }) {
  const { getCurrentUser } = useUser();

  const [open, setOpen] = useState(false);
  const [columnNameSet, setColumnNameSet] = useState(
    new Set(["sequence", "timestamp", "id"])
  );
  const [streamNamesSet, setStreamNamesSet] = useState(new Set());

  const csVisibile = false;
  /*
    const [csVisibile, setCsVisible] = useState(false); //dev only; change to true for easier testing
    const [computation, setComputation] = useState({});
  */

  useEffect(() => {
    const allStreamNames = new Set(
      sourceStreams.map((stream) => stream.tableName)
    );
    setStreamNamesSet(allStreamNames);

    // subscribe event
    window.addEventListener("keydown", handleEscapeKey);
    return () => {
      // unsubscribe event
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [table, setTable] = useState({
    nextKey: 1,
    streamName: "",
    cols: [
      {
        key: 0,
        columnName: "",
        columnType: "",
        columnUnit: null,
      },
    ],
  });

  /*
  const [computedStreamValidity, setComputedStreamValidity] = useState(false);

  const ComputedStreamToggleBtn = () => <Radio style = {{marginLeft : "2vh"}} toggle checked={csVisibile} onChange={()=>{
    setCsVisible(!csVisibile)
  }}/>
  */

  const handleEscapeKey = (event) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const handler = (mapper) => {
    setTable(mapper(table));
  };

  const setStreamName = (streamName) => {
    streamName = streamName?.replace(/\s+/g, "_")?.trim();
    if (hasSpecialCharacters(streamName)) {
      beamtoast.error(
        "Stream Name cannot contain special characters except underscore"
      );
    }
    // Validation for varchar(64) length limit
    else if (streamName.length > 64) {
      beamtoast.error("Stream Name cannot be more than 64 characters");
    } else
      setTable({
        ...table,
        streamName,
      });
  };

  const setColumnName = (key, columnName) => {
    columnName = columnName?.replace(/\s+/g, "_")?.trim();
    if (hasSpecialCharacters(columnName)) {
      beamtoast.error(
        "Field Name cannot contain special characters except underscore"
      );
    } else {
      handler(
        mapColumnIf(
          (col) => col.key === key,
          (col) => ({
            ...col,
            columnName,
          })
        )
      );
    }
  };

  const setColumnType = (key, columnType) => {
    handler(
      mapColumnIf(
        (col) => col.key === key,
        (col) => ({
          ...col,
          columnType,
          columnUnit: !isNumericType(columnType) ? null : col.columnUnit,
        })
      )
    );
  };

  const setColumnUnit = (key, columnUnit) => {
    handler(
      mapColumnIf(
        (col) => col.key === key,
        (col) => ({
          ...col,
          columnUnit,
        })
      )
    );
  };

  const deleteCol = (key) => {
    handler(
      updateColumns(
        filter((col) => {
          if (col.key === key) {
            const newSet = new Set([...columnNameSet]);
            newSet.delete(
              col.columnName?.toLowerCase()?.replace(/\s+/g, "_")?.trim()
            );
            setColumnNameSet(newSet);
          }
          return col.key !== key;
        })
      )
    );
  };

  const onAddCol = (name, type, unit) => {
    name = name?.replace(/\s+/g, "_")?.trim();
    if (columnNameSet.has(name)) {
      beamtoast.error(`${name} is already present`);
    } else if (hasSpecialCharacters(name)) {
      beamtoast.error(
        "Field Name cannot contain special characters except underscore"
      );
    } else if (String(name) === "") {
      beamtoast.error("Field Name is empty");
    } else if (String(type) === "") {
      beamtoast.error("Field Type is empty");
    } else if (
      String(name) !== "" &&
      String(type) !== "" &&
      !hasSpecialCharacters(name)
    ) {
      const newSet = new Set([...columnNameSet])?.add(name?.toLowerCase());
      setColumnNameSet(newSet);
      handler((table) => ({
        ...table,
        nextKey: table.nextKey + 1,
        cols: [
          ...table.cols,
          {
            key: table.nextKey,
            columnName: "",
            columnType: "",
            columnUnit: null,
          },
        ],
      }));
    }
  };

  const hasBadField = (columns) => {
    const cols = [...columns];
    let ret = false;
    if (
      cols.length > 1 &&
      cols[cols.length - 1].columnName === "" &&
      cols[cols.length - 1].columnType === ""
    ) {
      cols.pop();
    }

    for (const col of cols) {
      if (col.columnName === "") {
        ret = true;
        break;
      }
      if (col.columnType === "") {
        ret = true;
        break;
      }
    }
    return ret;
  };

  const hasDuplicateField = (cols) => {
    const names = cols.map(({ columnName }) => columnName);
    return (
      new Set([
        ...names.map((name) => name.toLowerCase()),
        "sequence",
        "timestamp",
        "id",
      ]).size !==
      cols.length + 3
    );
  };

  const isStreamNotValid =
    table.streamName === "" ||
    hasBadField(table.cols) ||
    hasDuplicateField(table.cols) ||
    hasSpecialCharacters(table.streamName);

  const isNotValid = isStreamNotValid;
  /*
  const isNotValid = csVisibile ? 
    (isStreamNotValid || !computedStreamValidity) :
    isStreamNotValid;
  */

  const toColSpec = (cols) => {
    const spec = {};
    cols.forEach((col, index) => {
      spec[col.columnName] = {
        index,
        type: col.columnType,
        unit: col.columnUnit,
      };
    });
    return spec;
  };

  const createStream = async () => {
    if (csVisibile) {
      /*
        createComputedStream({
          stream: {
            streamName : table.streamName,
            fields: toColSpec(table.cols)
          },
          computation 
        }).then(resp => {
          if (!resp.ok) {
            beamtoast.error("Error creating stream");
          }
        }).then(() => {
          setOpen(false)
        })
          .then(() => onClose());
      */
    } else {
      try {
        if (streamNamesSet.has(table.streamName)) {
          beamtoast.error(`${table.streamName} is already present`);
        } else {
          if (
            table.cols.length > 1 &&
            table.cols[table.cols.length - 1].columnName === "" &&
            table.cols[table.cols.length - 1].columnType === ""
          ) {
            table.cols.pop();
          }

          for (let i = 0; i < table.cols.length; i++) {
            const col = table.cols[i];
            if (col.columnUnit === "") {
              delete col.columnUnit;
            }
          }

          await createNonComputedStream({
            streamName: table.streamName,
            fields: toColSpec(table.cols),
          });

          await getCurrentUser();
          beamtoast.success(`Stream ${table.streamName} created successfully`);
          Mixpanel.track("Created Stream", {
            StreamName: table.streamName,
          });
          onClose();
          onModalClose();
        }
      } catch (e) {
        Mixpanel.track("Failure", {
          type: "Stream creation",
          error: JSON.stringify(e),
        });
        beamtoast.error(`Error creating stream ${table.streamName}`);
        console.log(e);
      }
    }
  };

  // Setting states every time Opens mounts
  function onModalOpen() {
    const allStreamNames = new Set(
      sourceStreams.map((stream) => stream.tableName)
    );
    setStreamNamesSet(allStreamNames);
    setTable({
      nextKey: 1,
      streamName: "",
      cols: [
        {
          key: 0,
          columnName: "",
          columnType: "",
          columnUnit: null,
        },
      ],
    });
    setOpen(true);
  }

  // Reset the modal state when it closes
  function onModalClose() {
    setTable({
      nextKey: 1,
      streamName: "",
      cols: [
        {
          key: 0,
          columnName: "",
          columnType: "",
          columnUnit: null,
        },
      ],
    });
    setColumnNameSet(new Set(["sequence", "timestamp", "id"]));
    setOpen(false);
  }

  return (
    <Modal
      className="dark"
      onClose={() => onModalClose()}
      onOpen={() => onModalOpen()}
      size="small"
      open={open}
      trigger={
        <Button primary labelPosition="left" floated="right" icon>
          <Icon name="plus" />
          Create Stream
        </Button>
      }
    >
      <Modal.Header>Create Stream</Modal.Header>
      <Modal.Content>
        <div style={{ width: "100%" }}>
          <Input
            fluid
            className="add-panel-title-input"
            labelPosition="left"
            placeholder="Enter the stream name eg: GPS, IMU, etc"
          >
            <Label>Stream Name</Label>
            <input
              autoFocus
              value={table.streamName}
              onChange={(e) => setStreamName(e.target.value)}
            />
          </Input>
        </div>
        <Table fixed>
          <Table.Body>
            <ColumnRow
              columnName={"id"}
              columnType={"String"}
              columnUnit={null}
              disable={true}
            />
            <ColumnRow
              columnName={"sequence"}
              columnType={"UInt32"}
              columnUnit={null}
              disable={true}
            />
            <ColumnRow
              columnName={"timestamp"}
              columnType={"DateTime64(3)"}
              columnUnit={null}
              disable={true}
            />
            {table.cols.length > 1
              ? table.cols.map(
                  ({ key, columnName, columnType, columnUnit }, i) => {
                    if (i === table.cols.length - 1) {
                      return (
                        <ColumnRow
                          key={key}
                          elemId={key.toString()}
                          columnName={columnName}
                          columnType={columnType}
                          columnUnit={columnUnit}
                          setColumnName={(colName) =>
                            setColumnName(key, colName)
                          }
                          setColumnType={(colType) =>
                            setColumnType(key, colType)
                          }
                          setColumnUnit={(colUnit) =>
                            setColumnUnit(key, colUnit)
                          }
                          onDelete={() => deleteCol(key)}
                          onAdd={() =>
                            onAddCol(columnName, columnType, columnUnit)
                          }
                          last
                        />
                      );
                    } else {
                      return (
                        <ColumnRow
                          key={key}
                          elemId={key.toString()}
                          columnName={columnName}
                          columnType={columnType}
                          columnUnit={columnUnit}
                          setColumnName={(colName) =>
                            setColumnName(key, colName)
                          }
                          setColumnType={(colType) =>
                            setColumnType(key, colType)
                          }
                          setColumnUnit={(colUnit) =>
                            setColumnUnit(key, colUnit)
                          }
                          onDelete={() => deleteCol(key)}
                          onAdd={() =>
                            onAddCol(columnName, columnType, columnUnit)
                          }
                        />
                      );
                    }
                  }
                )
              : table.cols.map(
                  ({ key, columnName, columnType, columnUnit }) => (
                    <ColumnRow
                      key={key}
                      elemId={key.toString()}
                      columnName={columnName}
                      columnType={columnType}
                      columnUnit={columnUnit}
                      setColumnName={(colName) => setColumnName(key, colName)}
                      setColumnType={(colType) => setColumnType(key, colType)}
                      setColumnUnit={(colUnit) => setColumnUnit(key, colUnit)}
                      onDelete={() => deleteCol(key)}
                      onAdd={() => onAddCol(columnName, columnType)}
                      only
                    />
                  )
                )}
            {/*
          <Table.Row style={{display: "none"}}>
            <Table.Cell colSpan={3}>
                <div style = {{display : "flex",marginTop : "1vh"}}>
                    <h3>Do you want to create a Computed Stream?</h3>
                    <ComputedStreamToggleBtn/>
                </div>
                <ComputedStream
                  onValidityChange={setComputedStreamValidity}
                  visibility={csVisibile}
                  sourceStreams={sourceStreams}
                  destStream={table}
                  onComputationChange={setComputation} 
                  />
            </Table.Cell>
          </Table.Row>      */}
          </Table.Body>
        </Table>
      </Modal.Content>

      <Modal.Actions>
        <Button secondary onClick={() => onModalClose()} content="Cancel" />
        <Button
          disabled={isNotValid} //dev only; comment this out for enabling save and close without needing to run the code with test inputs
          content="Submit"
          onClick={createStream}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
}

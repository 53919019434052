import React from "react";
import { Tab } from "semantic-ui-react";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  ThinDivider,
  EditAnimatedMetaInput,
  EditPanelFormContainer,
  StyledInputDiv,
} from "../util";
import { IframeMetaData } from "./PanelDef";
import { isURLValid } from "../../../../../util";

export type EditIframeMetaState = {
  url: string;
  error: boolean;
};

export class EditIframeMeta extends PanelEditComponent<
  IframeMetaData,
  EditIframeMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  urlRef = React.createRef<HTMLInputElement>();

  constructor(props: any) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        url: props.panelMeta.url,
        error: false,
      };
    } else {
      this.state = {
        url: "",
        error: false,
      };
    }
  }

  getPanelMeta(type): PartialMetaData<IframeMetaData> {
    const meta: IframeMetaData = {
      type: "iframe_panel",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      url: this.urlRef.current?.value || "",
    };

    if (meta.url.length > 0 && !isURLValid(meta.url)) {
      return {
        meta: meta,
        complete: false,
        errorMessage: "Please Enter valid URL",
      };
    } else {
      return {
        meta: meta,
        complete: this.isValidPanelMeta(meta, type),
      };
    }
  }

  isValidPanelMeta(meta: IframeMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    const validURL = meta.url.length > 0 && isURLValid(meta.url);

    if ((!meta.url && type === "submit") || (!validURL && type === "submit")) {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }

    return validURL && meta.url.length > 0;
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const url = this.props.panelMeta.url;

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key="general">
            <EditPanelFormContainer>
              <EditMetaRoot>
                <div style={{ width: "100%", marginTop: "16px" }} />

                <EditMetaRow>
                  <StyledInputDiv width="48%">
                    <EditAnimatedMetaInput
                      autoFocus={true}
                      defaultRef={this.titleRef}
                      defaultValue={title}
                      label="Title"
                    />
                  </StyledInputDiv>
                  <StyledInputDiv width="48%">
                    <EditAnimatedMetaInput
                      defaultRef={this.descriptionRef}
                      defaultValue={description}
                      label="Description"
                    />
                  </StyledInputDiv>
                </EditMetaRow>

                <ThinDivider />

                <EditMetaRow>
                  <div style={{ width: "100%", marginTop: "10px" }}>
                    <EditAnimatedMetaInput
                      defaultRef={this.urlRef}
                      defaultValue={url}
                      label="URL"
                      error={this.state.error}
                    />
                  </div>
                </EditMetaRow>
              </EditMetaRoot>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <div>
          <Tab menu={{}} panes={panes} renderActiveOnly={false} />
        </div>
      </EditMetaRoot>
    );
  }
}

import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import pluralize from "pluralize";
import { capitalizeFirstLetter } from "../../util";

type SelectedFilterProps = {
  id?: number;
  filterName: string;
  filterValues: string[];
  filterNameIcon: any;
  filterValueIcon?: any;
  filterConditionSign?: string;
  filterValueColor?: any;
  onCrossClick: (arg0: any, arg1: any) => void;
  multiple?: boolean;
};

const SelectedFilterWrapper = styled.div`
  background-color: transparent;
  color: ${(props) => props.theme.colors["text"]};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  font-size: 0.9rem;
  margin-right: 1rem;
  margin-bottom: 10px;
`;

const FilterCrossButton = styled.div`
  cursor: pointer;
  background-color: ${(props) =>
    props.theme.colors["card_pagination-item-background-color"]};
  padding: 0 0.5rem;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: ${(props) => props.theme.colors["text"]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.1rem;

  & #icon i.icon {
    margin: 0 !important;
    transition: all 100ms ease-in;
  }

  &:hover #icon i.icon {
    transform: scale(1.2);
  }
`;

const SelectedFilterCenterElement = styled.div`
  background-color: ${(props) =>
    props.theme.colors["card_pagination-item-background-color"]};
  color: ${(props) => props.theme.colors["text"]};
  font-weight: bolder;
  height: 100%;
  margin: 0 0.1rem 0 0.1rem;
  padding: 0 0.5rem 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 100ms ease-in;

  &:hover {
    background-color: ${(props) =>
      props.theme.colors["card_pagination-item-background-hover-color"]};
  }
`;

const IconContainer = styled.div`
  transition: 0.15s ease all;
`;

const FilterSection = styled.div`
  cursor: pointer;
  transition: all 100ms ease-in;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem 0 1rem;
  background-color: ${(props) =>
    props.theme.colors["card_pagination-item-background-color"]};

  &.filter-name {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:hover {
    background-color: ${(props) =>
      props.theme.colors["card_pagination-item-background-hover-color"]};
  }
`;

const ColorIcons = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.theme.colors[props.color]};
  margin-right: 0.5rem;
`;

export default function SelectedFilter(props: SelectedFilterProps) {
  const removeMetadataValue = () => {
    if (props.multiple) {
      props.onCrossClick(props.filterName, []);
    } else props.onCrossClick(props.filterValues[0], []);
  };

  return (
    <SelectedFilterWrapper>
      <FilterSection className="filter-name">
        {typeof props.filterNameIcon !== "object" ? (
          <Icon
            name={props.filterNameIcon}
            style={{ marginRight: "10px", marginBottom: "5px" }}
          />
        ) : (
          props.filterNameIcon
        )}
        {capitalizeFirstLetter(props.filterName)}
      </FilterSection>
      <SelectedFilterCenterElement>
        {props.filterConditionSign && props.filterConditionSign !== "="
          ? props.filterConditionSign
          : props.filterValues?.length === 1
            ? "is"
            : "is any of"}
      </SelectedFilterCenterElement>
      <FilterSection className="filter-value">
        {props.filterValueIcon ? (
          typeof props.filterValueIcon !== "object" ? (
            <Icon
              name={props.filterValueIcon}
              style={{ marginRight: "10px", marginBottom: "5px" }}
            />
          ) : (
            props.filterValueIcon
          )
        ) : props.filterValueColor ? (
          <ColorIcons color={props.filterValueColor} />
        ) : (
          <></>
        )}
        {props.filterValues?.length === 1
          ? props.filterValues[0] === "PendingApproval"
            ? "Pending Approval"
            : props.filterValues[0]
          : `${props.filterValues?.length} ${capitalizeFirstLetter(pluralize(props.filterName))}`}
      </FilterSection>
      <FilterCrossButton onClick={() => removeMetadataValue()}>
        <IconContainer id="icon">
          <Icon name="close" />
        </IconContainer>
      </FilterCrossButton>
    </SelectedFilterWrapper>
  );
}

import React, { SyntheticEvent, useEffect, useState } from "react";
import styled from "styled-components";
import {
  CreateActionContainer,
  NewActionLabelContainer,
  NewActionWrapper,
  StyledNonBoldHeader,
} from "./NewAction";
import CreateOrEditFirmwareModal from "../Inventory/FirmwareVersions/CreateOrEditFirmwareModal";
import CreateJSONConfigModal from "./ActionTypesModal/CreateJSONConfigModal";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import { EditAnimatedMetaDropdown } from "../../Dashboards/Panel/util";
import { OptionType } from "../util";
import { DropdownOptionType } from "../../DeviceManagement/Devices/ActionModals/UpdateConfigModal";
import {
  ActionDropdownType,
  FirmwareType,
  IDeviceComponent,
  // IDeviceComponentResult,
  // fetchAllDeviceComponents,
  fetchAllDeviceConfiguration,
  fetchAllFirmwareBundles,
  fetchAllFirmwares,
  fetchAllComponentsFirmwares,
} from "../../../../BytebeamClient";
import { capitalizeFirstLetter } from "../../util";
import { useUser } from "../../../../context/User.context";
import CreateGeofenceConfigModal from "./ActionTypesModal/CreateGeofenceConfigModal";
import CustomActionModal from "./ActionTypesModal/CustomActionModal";
import { StyledFileUploadButton } from "../../DeviceManagement/Devices/ActionModals/SendFileModal";
import SendFileActionModal from "./ActionTypesModal/SendFileActionModal";
import { CardContainer } from "../../../common/ActionsUtils";
import ThemeSchema from "../../../../theme/schema";
import {
  Button,
  // Checkbox,
  DropdownProps,
  Icon,
  Input,
  Popup,
} from "semantic-ui-react";
import { ActionType } from "../../../../util";

const NewActionFieldContainer = styled.div`
  display: flex;
  align-items: center;
  width: 450px;
  margin-bottom: 16px;
`;

const ActionSelectOrUploadContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors["action-border-color"]};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
`;

type CreateORSelectActionPayloadProps = {
  readonly selectedAction: ActionDropdownType;
  readonly versionSelectionLoader: boolean;
  readonly componentList: IDeviceComponent[];
  readonly componentsEnabled: boolean;
  readonly payload: Record<string, any> | string;
  readonly allExistingFirmwareVersions: FirmwareType[];
  readonly optionType: OptionType;
  readonly setOptionType: (optionType: OptionType) => void;
  readonly selectedVersions: {
    id: number;
    value: string;
    device_component?: string;
  }[];
  readonly currentVersion: {
    id: number;
    value: string;
    device_component?: string;
  };
  readonly setSelectedVersions: (
    versions: { id: number; value: string; device_component?: string }[]
  ) => void;
  readonly theme: string;
  readonly setUploadedFileName: (value: string) => void;
  readonly payloadVersionOptions: DropdownOptionType[];
  readonly updateFirmwareMode: "select_bundle" | "select_versions";

  readonly setPayload: (payload: Record<string, any> | string) => void;
  readonly handleOptionType: () => void;
  readonly handleActionType: (
    selectedAction: ActionDropdownType,
    update_firmware_mode: "select_bundle" | "select_versions"
  ) => void;
  readonly fillFirmwareDropdownOptions: (
    update_firmware_mode?: "select_bundle" | "select_versions"
  ) => Promise<void>;
};

function CreateORSelectActionPayload(props: CreateORSelectActionPayloadProps) {
  const {
    selectedAction,
    versionSelectionLoader,
    componentList,
    componentsEnabled,
    payload,
    allExistingFirmwareVersions,
    optionType,
    setOptionType,
    currentVersion,
    selectedVersions,
    setSelectedVersions,
    theme,
    setUploadedFileName,
    payloadVersionOptions,
    setPayload,
    handleOptionType,
    updateFirmwareMode,
    handleActionType,
    fillFirmwareDropdownOptions,
  } = props;

  // Update Firmware Action State
  const [showUploadFirmwareModal, setShowUploadFirmwareModal] =
    useState<boolean>(false);
  // Update JSON Config Action State
  const [showCreateJSONConfigModal, setShowCreateJSONConfigModal] =
    useState<boolean>(false);
  // Update GeoFence Config Action State
  const [showCreateGeofenceConfigModal, setShowCreateGeofenceConfigModal] =
    useState<boolean>(false);
  // Custom Action Payload Modal State
  const [showCustomActionModal, setShowCustomActionModal] =
    useState<boolean>(false);

  // Send File Action Modal State
  const [showSendFileActionModal, setShowSendFileActionModal] =
    useState<boolean>(false);

  const handleModalOpen = () => {
    const { value: actionType } = selectedAction;

    if (actionType === "update_firmware") {
      setShowUploadFirmwareModal(true);
    } else if (actionType === "update_config") {
      setShowCreateJSONConfigModal(true);
    } else if (actionType === "update_geofence") {
      setShowCreateGeofenceConfigModal(true);
    } else if (actionType === "send_file" || actionType === "send_script") {
      setShowSendFileActionModal(true);
    } else {
      setShowCustomActionModal(true);
    }
  };

  function NewActionContentButtonText(): string {
    let buttonText = "Upload Action Payload";

    if (selectedAction.value === "update_firmware") {
      buttonText = "Upload Firmware File";
    } else if (selectedAction.value === "send_file") {
      buttonText = "Upload File";
    } else if (selectedAction.value === "send_script") {
      buttonText = "Upload Script";
    } else if (selectedAction.value === "update_config") {
      buttonText = "Add JSON Config";
    } else if (selectedAction.value === "update_geofence") {
      buttonText = "Add Geofence Config";
    } else if (selectedAction.payload_type === "json") {
      buttonText = "Add/Edit JSON Payload";
    } else if (selectedAction.payload_type === "text") {
      buttonText = "Add/Edit Text Payload";
    }

    return buttonText;
  }

  function handleSelectedVersions(value: string) {
    const updatedVersions = selectedVersions.map((version) => {
      if (version.id === currentVersion.id) {
        return {
          ...version,
          value,
          ...(componentsEnabled &&
            selectedAction.value === "update_firmware" &&
            updateFirmwareMode === "select_bundle" && {
              device_component: "update_firmware with bundle", // Using this component name to differentiate between update firmware with bundle and versions
              bundle_version:
                payloadVersionOptions.find((option) => option.key === value)
                  ?.text ?? "",
            }),
        };
      } else return version;
    });
    setSelectedVersions(updatedVersions);
  }

  function handleSelectComponent(
    event: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) {
    event.preventDefault();

    const updatedVersions = selectedVersions.map((version) => {
      if (version.id === currentVersion.id) {
        return {
          ...version,
          device_component: data.value as string,
          version: "",
        };
      } else return version;
    });
    setSelectedVersions(updatedVersions);
  }

  const shouldRenderUploadButton = () => {
    const isSendAction = ["send_file", "send_script"].includes(
      selectedAction.value
    );
    const isPayloadTypeNone = selectedAction.payload_type !== "none";
    const isComponentWiseUpdateFirmware =
      componentsEnabled && selectedAction.value === "update_firmware";
    const shouldSelectVersions = isComponentWiseUpdateFirmware
      ? updateFirmwareMode === "select_versions"
      : true;

    return (isSendAction || isPayloadTypeNone) && shouldSelectVersions;
  };

  return (
    <>
      <CreateOrEditFirmwareModal
        type="Add"
        isOpen={showUploadFirmwareModal}
        close={() => setShowUploadFirmwareModal(false)}
        allExistingFirmwareVersions={allExistingFirmwareVersions}
        setOptionType={(value) => setOptionType(value)}
        setSelectedVersion={(value) => handleSelectedVersions(value)}
        setUploadedFileName={(value) => setUploadedFileName(value)}
        componentsList={componentList.map((component) => component.name)}
        componentsEnabled={componentsEnabled}
        component={currentVersion.device_component ?? ""}
        fillFirmwareTable={(showDeactivatedFirmwares?: boolean) =>
          fillFirmwareDropdownOptions("select_versions")
        }
      />
      <CreateJSONConfigModal
        isOpen={showCreateJSONConfigModal}
        onClose={() => {
          setShowCreateJSONConfigModal(false);
          handleActionType(selectedAction, "select_versions");
        }}
        setOptionType={(value) => setOptionType(value)}
        setSelectedVersion={(value) => handleSelectedVersions(value)}
        allConfigOptions={
          new Set(payloadVersionOptions.map((o) => o.value?.toLowerCase()))
        }
      />
      <CreateGeofenceConfigModal
        isOpen={showCreateGeofenceConfigModal}
        onClose={() => {
          setShowCreateGeofenceConfigModal(false);
          handleActionType(selectedAction, "select_versions");
        }}
        setOptionType={(value) => setOptionType(value)}
        setSelectedVersion={(value) => handleSelectedVersions(value)}
        allConfigOptions={
          new Set(payloadVersionOptions.map((o) => o.value?.toLowerCase()))
        }
      />
      <SendFileActionModal
        isOpen={showSendFileActionModal}
        onClose={() => setShowSendFileActionModal(false)}
        optionType={optionType}
        setSelectedVersion={(value) => handleSelectedVersions(value)}
        setUploadedFileName={(value) => setUploadedFileName(value)}
      />
      <CustomActionModal
        isOpen={showCustomActionModal}
        onClose={() => setShowCustomActionModal(false)}
        selectedAction={selectedAction}
        payload={payload}
        setPayload={setPayload}
      />

      <ActionSelectOrUploadContainer
        style={{
          position: "relative",
          maxWidth: "350px",
          width: "350px",
        }}
      >
        {["update_firmware", "update_config", "update_geofence"].includes(
          selectedAction.value
        ) && (
          <>
            {componentsEnabled && selectedVersions?.length > 1 && (
              <Button
                basic
                circular
                icon="close"
                size="large"
                onClick={() => {
                  setSelectedVersions(
                    selectedVersions.filter(
                      (version) => version.id !== currentVersion.id
                    )
                  );
                }}
                style={{
                  position: "absolute",
                  top: "-22px",
                  right: "-26px",
                }}
              />
            )}
            {componentsEnabled &&
              selectedAction.value === "update_firmware" &&
              updateFirmwareMode === "select_versions" && (
                <EditAnimatedMetaDropdown
                  placeholder="Select Component"
                  search
                  selection
                  options={componentList.map((component) => ({
                    key: component.name,
                    value: component.name,
                    text: component.name,
                  }))}
                  onChange={handleSelectComponent}
                  loading={componentList?.length === 0}
                  disabled={componentList?.length === 0}
                  elementid={`select_component`}
                  labelBg={
                    ThemeSchema.data[theme]?.colors[
                      "action-card-container-background"
                    ]
                  }
                  style={{
                    marginTop: "12px",
                    minWidth: "312x",
                    width: "312px",
                  }}
                />
              )}
            <EditAnimatedMetaDropdown
              placeholder={`Select ${(() => {
                if (
                  selectedAction.value === "update_firmware" &&
                  updateFirmwareMode === "select_versions"
                ) {
                  return "Firmware Version";
                } else if (
                  selectedAction.value === "update_firmware" &&
                  updateFirmwareMode === "select_bundle"
                ) {
                  return "Firmware Bundle Version";
                } else if (selectedAction.value === "update_config") {
                  return "Config Version";
                } else if (selectedAction.value === "update_geofence") {
                  return "Geofence Version";
                } else {
                  return "Version";
                }
              })()}`}
              fluid
              search
              selection
              options={payloadVersionOptions.filter((option) =>
                componentsEnabled && updateFirmwareMode !== "select_bundle"
                  ? option["device_component"] ===
                    selectedVersions[currentVersion.id]?.device_component // Filtering out firmware versions based on selected component name
                  : true
              )}
              onChange={(e, data) => {
                e.preventDefault();

                handleSelectedVersions(data.value as string);
                handleOptionType();
              }}
              loading={versionSelectionLoader}
              disabled={versionSelectionLoader}
              elementid={`select_version`}
              labelBg={
                ThemeSchema.data[theme]?.colors[
                  "action-card-container-background"
                ]
              }
              style={{
                marginTop: "12px",
                minWidth: "312x",
                width: "312px",
              }}
            />
            {shouldRenderUploadButton() && (
              <StyledNonBoldHeader
                as="h4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "13px",
                  marginBottom: "12px",
                }}
              >
                OR
              </StyledNonBoldHeader>
            )}
          </>
        )}

        {shouldRenderUploadButton() && (
          <StyledFileUploadButton
            fluid
            content={NewActionContentButtonText()}
            labelPosition="left"
            icon="upload"
            disabled={versionSelectionLoader}
            onClick={handleModalOpen}
          />
        )}
      </ActionSelectOrUploadContainer>
    </>
  );
}

type CreateActionSectionProps = {
  readonly actionTypes: ActionType[];
  readonly setAction: (action: string) => void;
  readonly optionType: OptionType;
  readonly setOptionType: (optionType: OptionType) => void;
  readonly selectedVersions: {
    id: number;
    value: string;
    device_component?: string;
    bundle_version?: string;
  }[];
  readonly setSelectedVersions: (
    versions: { id: number; value: string; device_component?: string }[]
  ) => void;
  readonly payload: Record<string, any> | string;
  readonly setPayload: (payload: Record<string, any> | string) => void;
  readonly bundleVersion: string;
  readonly setBundleVersion: (bundleVersion: string) => void;
};

function CreateActionSection(props: CreateActionSectionProps) {
  const {
    actionTypes,
    setAction,
    optionType,
    setOptionType,
    selectedVersions,
    setSelectedVersions,
    payload,
    setPayload,
    bundleVersion,
    setBundleVersion,
  } = props;

  const { user } = useUser();
  const theme = user.settings?.theme ?? "dark";
  const allowedActions = user.role.permissions.allowedActions;
  const componentsEnabled =
    user?.["tenant-settings"]?.show_tabs?.device_components?.show ?? false;

  const [actionDropdownList, setActionDropdownList] = useState<
    ActionDropdownType[]
  >([]);
  const [selectedAction, setSelectedAction] = useState<ActionDropdownType>(
    {} as ActionDropdownType
  );
  const [versionSelectionLoader, setVersionSelectionLoader] =
    useState<boolean>(false);
  const [componentList] = useState<IDeviceComponent[]>([]);

  // Update Firmware Action State
  const [allExistingFirmwareVersions, setAllExistingFirmwareVersions] =
    useState<FirmwareType[]>([] as FirmwareType[]);
  const [uploadedFileName, setUploadedFileName] = useState<string>("");
  const [updateFirmwareMode, setUpdateFirmwareMode] = useState<
    "select_bundle" | "select_versions"
  >("select_versions");

  // Firmware, JSON config and Geofence Config Dropdown Options
  const [payloadVersionOptions, setPayloadVersionOptions] = useState<
    DropdownOptionType[]
  >([]);

  const fillFirmwareDropdownOptions = async (
    update_firmware_mode?: "select_bundle" | "select_versions"
  ) => {
    setVersionSelectionLoader(true);
    try {
      let options;
      if (componentsEnabled) {
        if (update_firmware_mode === "select_bundle") {
          let res = await fetchAllFirmwareBundles();
          options = res.results.flatMap((o) =>
            o.upload_status === "completed"
              ? [
                  {
                    key: o.id,
                    value: o.id,
                    text: o.version,
                  },
                ]
              : []
          );
        } else if (update_firmware_mode === "select_versions") {
          let res = await fetchAllComponentsFirmwares();
          setAllExistingFirmwareVersions(res);

          options = res.flatMap((o) =>
            o.is_deactivated
              ? []
              : [
                  {
                    key: o.version_number,
                    value: o.version_number,
                    text: o.version_number,
                    device_component: o.device_component_name,
                  },
                ]
          );
        }
      } else {
        let res = await fetchAllFirmwares();
        setAllExistingFirmwareVersions(res);

        options = res.flatMap((o) =>
          o.is_deactivated
            ? []
            : [
                {
                  key: o.version_number,
                  value: o.version_number,
                  text: o.version_number,
                  device_component: o.device_component_name,
                },
              ]
        );
      }
      setPayloadVersionOptions(options);
    } catch (e) {
      console.log(e);
    } finally {
      setVersionSelectionLoader(false);
    }
  };

  const fillConfigDropdownOptions = async (
    actionType: "update_config" | "update_geofence"
  ) => {
    setVersionSelectionLoader(true);
    try {
      const res = await fetchAllDeviceConfiguration();

      // Setting Device Config Options
      if (actionType === "update_config") {
        const JSONOptions = res.flatMap((config) =>
          (config.action_type === "update_config" ||
            config.action_type === null) &&
          !config.is_deactivated
            ? [
                {
                  key: config.version_name,
                  value: config.version_name,
                  text: config.version_name,
                },
              ]
            : []
        );
        setPayloadVersionOptions(JSONOptions);
      } else if (actionType === "update_geofence") {
        // Setting Geofence Config Options
        const GeofenceOptions = res.flatMap((config) =>
          config.action_type === "update_geofence" && !config.is_deactivated
            ? [
                {
                  key: config.version_name,
                  value: config.version_name,
                  text: config.version_name,
                },
              ]
            : []
        );
        setPayloadVersionOptions(GeofenceOptions);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setVersionSelectionLoader(false);
    }
  };

  const handlePayloadType = (payloadType, actionType) => {
    if (
      payloadType === "none" &&
      !(actionType === "send_file" || actionType === "send_script")
    ) {
      setOptionType(OptionType.NoPayloadOption);
    } else if (
      payloadType === "json" &&
      actionType !== "update_config" &&
      actionType !== "update_geofence"
    ) {
      setOptionType(OptionType.UploadCommonConfig);
    } else if (payloadType === "text") {
      setOptionType(OptionType.UploadText);
    } else {
      setOptionType(OptionType.NoOptionSelected);
    }
  };

  const handleActionType = (
    selectedAction: ActionDropdownType,
    update_firmware_mode:
      | "select_bundle"
      | "select_versions" = componentsEnabled
      ? "select_bundle"
      : "select_versions"
  ) => {
    const { value: actionType, payload_type: payloadType } = selectedAction;

    switch (actionType) {
      case "update_firmware":
        setOptionType(OptionType.ChooseFirmware);
        fillFirmwareDropdownOptions(update_firmware_mode);
        break;
      case "update_config":
        setOptionType(OptionType.ChooseConfig);
        fillConfigDropdownOptions("update_config");
        break;
      case "update_geofence":
        setOptionType(OptionType.ChooseGeofence);
        fillConfigDropdownOptions("update_geofence");
        break;
      case "send_file":
        setOptionType(OptionType.SendFile);
        break;
      case "send_script":
        setOptionType(OptionType.SendScript);
        break;
      default:
        handlePayloadType(payloadType, actionType);
        break;
    }
  };

  const setActionType = (_event, data) => {
    const selectedActionDropdownList = actionDropdownList.find(
      (actionType) => actionType.value === data.value
    );

    if (!selectedActionDropdownList) return;

    setSelectedAction(selectedActionDropdownList);
    setAction(selectedActionDropdownList.value);

    handleActionType(selectedActionDropdownList);

    setUploadedFileName("");
    setSelectedVersions([{ id: 0, value: "" }]);
    setPayload("");
  };

  const handleOptionType = () => {
    const { value: actionType } = selectedAction;

    if (actionType === "update_firmware") {
      setOptionType(OptionType.ChooseFirmware);
    } else if (actionType === "update_config") {
      setOptionType(OptionType.ChooseConfig);
    } else if (actionType === "update_geofence") {
      setOptionType(OptionType.ChooseGeofence);
    }
  };

  // function handleCheckboxes(e, value) {
  //   e.preventDefault();

  //   setUpdateFirmwareMode(value);
  //   handleActionType(selectedAction, value);
  //   setSelectedVersions([{ id: 0, value: "" }]);
  // }

  useEffect(() => {
    const getActionTypes = async () => {
      try {
        const actionTypesToRender = actionTypes.filter(
          (actionType) =>
            actionType.type !== "launch_shell" &&
            actionType.type !== "cancel_action" &&
            actionType.type !== "retry_action" &&
            allowedActions.includes(actionType.type)
        );

        const dropdownItems = actionTypesToRender.map((actionType) => ({
          text: capitalizeFirstLetter(actionType.type),
          value: actionType.type,
          icon: actionType.icon,
          payload_type: actionType.payload_type,
          json_schema: actionType.json_schema ?? "",
          json_ui_schema: actionType.json_ui_schema ?? "",
        }));

        setActionDropdownList(dropdownItems);
      } catch (error) {
        console.error("Error fetching action types:", error);
      }
    };

    if (allowedActions?.length !== 0) {
      getActionTypes();
    }
  }, [allowedActions, actionTypes]);

  // useEffect(() => {
  //   async function fetchAllComponents(): Promise<IDeviceComponentResult> {
  //     try {
  //       const res: IDeviceComponentResult = await fetchAllDeviceComponents();
  //       setComponentList(res.results);
  //       return res;
  //     } catch (e) {
  //       console.log(e);
  //       throw e;
  //     }
  //   }

  //   fetchAllComponents();
  // }, []);

  useEffect(() => {
    if (selectedAction.value === "update_firmware" && componentsEnabled) {
      setUpdateFirmwareMode("select_bundle");
    }
  }, [selectedAction.value, componentsEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CreateActionContainer>
      <CardContainer>
        <NewActionWrapper>
          <StyledHeader
            as="h2"
            style={{ marginTop: "0px", marginBottom: "16px" }}
          >
            Create a New Action
          </StyledHeader>

          <NewActionFieldContainer
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              ...(componentsEnabled &&
                selectedAction.value === "update_firmware" && {
                  marginBottom: "4px",
                }),
            }}
          >
            <StyledHeader
              as="h3"
              style={{
                marginTop: "0px",
                marginBottom: "15px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Select Action Type
            </StyledHeader>

            <EditAnimatedMetaDropdown
              placeholder="Action Type"
              search
              selection
              options={actionDropdownList}
              value={selectedAction.value}
              onChange={setActionType}
              elementid={`newActionDropdown1`}
              loading={actionDropdownList?.length === 0}
              disabled={actionDropdownList?.length === 0}
              labelBg={
                ThemeSchema.data[theme]?.colors[
                  "action-card-container-background"
                ]
              }
            />
          </NewActionFieldContainer>

          {/* {componentsEnabled && selectedAction.value === "update_firmware" && (
            <NewActionFieldContainer
              style={{ gap: "32px", marginBottom: "18px" }}
            >
              <Checkbox
                label="Component Level Firmware"
                name="firmware_mode"
                value="select_versions"
                onChange={(e, data) => handleCheckboxes(e, data.value)}
                checked={updateFirmwareMode === "select_versions"}
              />
              <Checkbox
                label="Bundled Firmware"
                name="firmware_mode"
                value="select_bundle"
                onChange={(e, data) => handleCheckboxes(e, data.value)}
                checked={updateFirmwareMode === "select_bundle"}
              />
            </NewActionFieldContainer>
          )} */}

          {componentsEnabled &&
            selectedAction.value === "update_firmware" &&
            updateFirmwareMode === "select_versions" && (
              <NewActionFieldContainer
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginBottom: "24px",
                }}
              >
                <StyledHeader
                  as="h3"
                  style={{
                    marginTop: "0px",
                    marginBottom: "4px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "15px",
                  }}
                >
                  Enter Firmware Bundle Version
                  <Popup
                    inverted
                    trigger={
                      <Icon
                        name="question circle"
                        style={{
                          marginBottom: "2px",
                          marginLeft: "6px",
                          fontSize: "14px",
                        }}
                      />
                    }
                    content={
                      "Only unique semantic version number of the firmware bundle are allowed For example, 1.0.0, 1.0.0-beta, etc."
                    }
                    position="top center"
                  />
                </StyledHeader>
                <Input
                  style={{
                    maxWidth: "350px",
                    width: "350px",
                    borderRadius: "5px",
                  }}
                >
                  <input
                    id="bundle_version_input"
                    autoFocus
                    autoComplete="off"
                    placeholder="1.0.0, 1.0.0-beta, etc."
                    value={bundleVersion}
                    onChange={(e) => setBundleVersion(e.target.value)}
                    style={{
                      background:
                        ThemeSchema.data[theme]?.colors[
                          "action-card-container-background"
                        ],
                    }}
                  />
                </Input>
              </NewActionFieldContainer>
            )}

          {selectedAction?.value && (
            <NewActionLabelContainer
              style={{
                flexWrap: "wrap",
                gap: "18px",
              }}
            >
              {([
                "update_firmware",
                "update_config",
                "update_geofence",
                "send_file",
                "send_script",
              ].includes(selectedAction.value) ||
                selectedAction.payload_type !== "none") &&
                selectedVersions.map((version) => (
                  <CreateORSelectActionPayload
                    key={version.id}
                    selectedAction={selectedAction}
                    versionSelectionLoader={versionSelectionLoader}
                    componentList={componentList}
                    componentsEnabled={componentsEnabled}
                    payload={payload}
                    setPayload={setPayload}
                    allExistingFirmwareVersions={allExistingFirmwareVersions}
                    optionType={optionType}
                    setOptionType={setOptionType}
                    selectedVersions={selectedVersions}
                    currentVersion={version}
                    setSelectedVersions={setSelectedVersions}
                    theme={theme}
                    handleOptionType={handleOptionType}
                    setUploadedFileName={setUploadedFileName}
                    payloadVersionOptions={payloadVersionOptions}
                    updateFirmwareMode={updateFirmwareMode}
                    handleActionType={handleActionType}
                    fillFirmwareDropdownOptions={fillFirmwareDropdownOptions}
                  />
                ))}

              {componentsEnabled &&
                selectedAction.value === "update_firmware" &&
                selectedVersions?.length !== componentList?.length &&
                updateFirmwareMode === "select_versions" && (
                  <Button
                    primary
                    icon
                    onClick={() => {
                      setSelectedVersions([
                        ...selectedVersions,
                        { id: selectedVersions?.length, value: "" },
                      ]);
                    }}
                  >
                    <Icon name="add" />
                  </Button>
                )}

              {(selectedVersions[0].value !== "" ||
                uploadedFileName !== "") && (
                <ActionSelectOrUploadContainer
                  style={{
                    marginLeft: "24px",
                    gap: "16px",
                    alignSelf: "stretch",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {selectedVersions[0].value !== "" &&
                    optionType !== OptionType.SendFile &&
                    optionType !== OptionType.SendScript &&
                    selectedVersions.map((version) => {
                      return (
                        <div
                          key={version.id}
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            justifyContent: "space-between",
                            gap: "16px",
                          }}
                        >
                          <StyledNonBoldHeader
                            as="h4"
                            style={{
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            Selected Version:
                          </StyledNonBoldHeader>
                          <StyledNonBoldHeader
                            as="h4"
                            style={{
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {`${version.bundle_version ?? version.value} ${
                              version.device_component &&
                              componentsEnabled &&
                              version.device_component !==
                                "update_firmware with bundle"
                                ? `(${version.device_component})`
                                : ""
                            }`}
                          </StyledNonBoldHeader>
                        </div>
                      );
                    })}

                  {uploadedFileName !== "" && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                        gap: "16px",
                      }}
                    >
                      <StyledNonBoldHeader
                        as="h4"
                        style={{
                          marginBottom: "0px",
                          fontSize: "14px",
                        }}
                      >
                        Uploaded File:
                      </StyledNonBoldHeader>
                      <StyledNonBoldHeader
                        as="h4"
                        style={{
                          marginBottom: "0px",
                          fontSize: "14px",
                        }}
                      >
                        {uploadedFileName}
                      </StyledNonBoldHeader>
                    </div>
                  )}
                </ActionSelectOrUploadContainer>
              )}
            </NewActionLabelContainer>
          )}
        </NewActionWrapper>
      </CardContainer>
    </CreateActionContainer>
  );
}

export default CreateActionSection;

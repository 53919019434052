import { PanelDef } from "../PanelDef";
import { ViewHeatmapChart } from "./ViewHeatmap";
import { EditHeatmapMeta } from "./EditHeatmapMeta";
import { PieChartMetaData, PieChartData } from "../PieChart/PanelDef";

export class HeatmapMetaData extends PieChartMetaData {
  type = "heatmap";
}

export type Value = {
  value: number;
};

export type HeatmapData = PieChartData;

export const HeatmapPanelDef: PanelDef<HeatmapMetaData, HeatmapData> = {
  ViewComponent: ViewHeatmapChart,
  EditComponent: EditHeatmapMeta,
  formatData: (data) => data,
  metaConstructor: HeatmapMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

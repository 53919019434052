import * as React from "react";
import { SVGProps } from "react";

const StatusTableIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="15.5 29.5 118.11 90"
    {...props}
  >
    {/* Top Row */}
    <path
      fill="currentColor"
      d="M15.5 29.5h21v16h-21v-16Z"
      style={{
        opacity: 0.984,
      }}
    />
    <circle cx="26" cy="37.5" r="4" fill="#000" />

    <path
      fill="currentColor"
      d="M46.5 29.5h32v16h-32v-16Z"
      style={{
        opacity: 0.968,
      }}
    />
    <circle cx="62.5" cy="37.5" r="4" fill="#000" />

    <path
      fill="currentColor"
      d="M86.5 29.5h47v16h-47v-16Z"
      style={{
        opacity: 0.952,
      }}
    />
    <circle cx="110" cy="37.5" r="4" fill="#000" />

    {/* Middle Row */}
    <path
      fill="currentColor"
      d="M15.5 53.5h21v17h-21v-17Z"
      style={{
        opacity: 0.926,
      }}
    />
    <circle cx="26" cy="62" r="4" fill="#000" />

    <path
      fill="currentColor"
      d="M46.5 53.5h32v17h-32v-17Z"
      style={{
        opacity: 0.911,
      }}
    />
    <circle cx="62.5" cy="62" r="4" fill="#000" />

    <path
      fill="currentColor"
      d="M86.5 53.5h47v17h-47v-17Z"
      style={{
        opacity: 0.896,
      }}
    />
    <circle cx="110" cy="62" r="4" fill="#000" />

    {/* Bottom Row */}
    <path
      fill="currentColor"
      d="M15.5 77.5h21v17h-21v-17Z"
      style={{
        opacity: 0.926,
      }}
    />
    <circle cx="26" cy="86" r="4" fill="#000" />

    <path
      fill="currentColor"
      d="M46.5 77.5h32v17h-32v-17Z"
      style={{
        opacity: 0.911,
      }}
    />
    <circle cx="62.5" cy="86" r="4" fill="#000" />

    <path
      fill="currentColor"
      d="M87.5 77.5c15.004-.167 30.004 0 45 .5a70.904 70.904 0 0 1 1 16.5h-47a77.766 77.766 0 0 1 1-17Z"
      style={{
        opacity: 0.898,
      }}
    />
    <circle cx="110" cy="86" r="4" fill="#000" />

    {/* Bottom-most Row */}
    <path
      fill="currentColor"
      d="M15.5 102.5h21v17h-21v-17Z"
      style={{
        opacity: 0.926,
      }}
    />
    <circle cx="26" cy="111" r="4" fill="#000" />

    <path
      fill="currentColor"
      d="M46.5 102.5h32v17h-32v-17Z"
      style={{
        opacity: 0.911,
      }}
    />
    <circle cx="62.5" cy="111" r="4" fill="#000" />

    <path
      fill="currentColor"
      d="M86.5 102.5h47v17h-47v-17Z"
      style={{
        opacity: 0.896,
      }}
    />
    <circle cx="110" cy="111" r="4" fill="#000" />
  </svg>
);

export default StatusTableIcon;

import React from "react";
import { ActionModalSearchResults } from "../action-modals/ActionModalSearchResults";
import { StyledNonBoldHeader } from "./NewAction";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import { StyledPagination } from "../../../common/commonStyledComps";
import { Device, SearchDeviceResponse } from "../../../../BytebeamClient";

type DeviceSelectionSectionProps = {
  devices: SearchDeviceResponse;
  allSelected: boolean;
  setAllSelected: (selectAll: boolean) => Promise<void>;
  selectedDevices: SearchDeviceResponse;
  setSelectedDevices: (value: SearchDeviceResponse) => void;
  selectDevice: (device: Device) => void;
  clearDevice: (device: Device) => void;
  page: number;
  pageLimit: number;
  metadataKeysToShow: string[];
  onPageChange: (e: any, data: { activePage: number }) => void;
  phase: string;
};

const DeviceSelectionSection = (props: DeviceSelectionSectionProps) => {
  const {
    devices,
    allSelected,
    setAllSelected,
    selectedDevices,
    setSelectedDevices,
    selectDevice,
    clearDevice,
    page,
    pageLimit,
    metadataKeysToShow,
    onPageChange,
    phase,
  } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <ActionModalSearchResults
        devices={devices}
        checkEnabled={true}
        allSelected={allSelected}
        setAllSelected={setAllSelected}
        selectedDevices={selectedDevices}
        setSelectedDevices={setSelectedDevices}
        selectDevice={selectDevice}
        metadataKeysToShow={metadataKeysToShow}
        clearDevice={clearDevice}
        phase={phase}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <StyledPagination
          boundaryRange={0}
          defaultActivePage={page}
          ellipsisItem={null}
          siblingRange={2}
          totalPages={Math.ceil((devices?.count ?? 0) / pageLimit)}
          onPageChange={onPageChange}
        />

        <StyledNonBoldHeader
          as="h3"
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            fontSize: "1.1rem",
            whiteSpace: "nowrap",
          }}
        >
          Selected number of Devices:{" "}
          <span>
            <StyledHeader
              as="h3"
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                marginLeft: "8px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
            >
              {selectedDevices?.count} out of {devices?.count}
            </StyledHeader>
          </span>
        </StyledNonBoldHeader>
      </div>
    </div>
  );
};

export default DeviceSelectionSection;

import React from "react";

import { TimeRange } from "../Datetime/TimeRange";
import { ReplayState } from "../DashboardHeader";
import { FetchParams } from "./util";
import { Settings } from "../../../../util";
import { DashboardsInfo } from "../ViewDashboard";
import { TableInfo } from "../../../../BytebeamClient";
import { DashboardMeta } from "../EditDashboardModal";
// import { PlotMouseEvent } from "plotly.js";

export type ViewComponentProps<MetaDataType, DataType> = {
  panelMeta: MetaDataType;
  data: DataType;
  timeRange: TimeRange;
  onTimeRangeChange?: (TimeRange: TimeRange) => void;
  replayStep: number;
  replayTimestamp: number;
  replayState: ReplayState;
  fetchParams: FetchParams;
  settings: Settings;
  dashboardMeta: DashboardMeta;
  dashboards: DashboardsInfo[];
  tables: TableInfo;
  editMode?: boolean;
  // onHoverPanel?: (event:PlotMouseEvent) => void;
  // onUnHoverPanel?: () => void;
  // hoverPointX?: any;
  // hover?:boolean;
};

export type EditComponentProps<MetaDataType> = {
  panelMeta: MetaDataType;
  tables: TableInfo;
  dashboards?: DashboardsInfo[];
  dashboardType?: string;
};

export type PartialMetaData<MetaDataType> = {
  meta: MetaDataType;
  complete: boolean;
  errorMessage?: string;
};

export class PanelViewComponent<
  MetaDataType,
  DataType,
  StateType = {},
> extends React.Component<
  ViewComponentProps<MetaDataType, DataType>,
  StateType
> {}

export abstract class PanelEditComponent<
  MetaDataType,
  EditComponentState = {},
> extends React.Component<
  EditComponentProps<MetaDataType>,
  EditComponentState
> {
  // type here defines, if the panel is being refreshed or being submitted for error handling
  abstract getPanelMeta(type: string): PartialMetaData<MetaDataType>;
}

type EditComponentType<MetaDataType> = new (
  props: EditComponentProps<MetaDataType>
) => PanelEditComponent<MetaDataType>;

type ViewComponentType<MetaDataType, DataType> = new (
  props: ViewComponentProps<MetaDataType, DataType>
) => PanelViewComponent<MetaDataType, DataType>;

export type PanelMetaData = {
  type: string;
  id: string;
  description: string;
  title: string;
  table?: string;
  columns?: string[];
  query?: any;
  timeseries?: any;
  groupBys?: string[];
};

export abstract class PanelDef<MetaDataType, DataType> {
  abstract ViewComponent: ViewComponentType<MetaDataType, DataType>;
  abstract EditComponent: EditComponentType<MetaDataType>;
  abstract formatData: (
    data: any,
    timeRange: TimeRange,
    replayState: ReplayState,
    relayStep: number
  ) => DataType;
  abstract metaConstructor: new () => MetaDataType;
  abstract previewAspectRatio: { height: number; width: number };
  abstract download?: "server" | ((meta: MetaDataType) => void);
}

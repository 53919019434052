import React from "react";
import { CarouselItemObject } from "./WelcomeCarousel";
import styled from "styled-components";
import { StyledSubHeader } from "./LogInForm";

type CarouselItemProps = {
  item: CarouselItemObject;
};

const CarouselItemContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const CarouselImage = styled.img<{ index: number }>`
  width: 100%;
  height: ${(p) => (p.index === 1 ? 480.62 : 373.38)}px; //289-372
  padding: 0 20px 0 20px;
  object-fit: cover;
`;

export default function CarouselItem(props: CarouselItemProps) {
  return (
    <CarouselItemContainer>
      <CarouselImage
        src={props.item.image}
        alt="Carousel Item"
        index={props.item.index}
      />
      <StyledSubHeader
        as="h2"
        style={{ textAlign: "center", marginTop: "40px", whiteSpace: "normal" }}
      >
        {props.item.caption}
      </StyledSubHeader>
    </CarouselItemContainer>
  );
}

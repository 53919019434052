import React from "react";
import { beamtoast } from "../../../common/CustomToast";
import {
  ActionStatusType,
  CancelOrRetryActionObj,
  cancelActions,
  markActionAsCompleted,
  MarkActionAsCompletedBodyType,
  retryActions,
} from "../../../../BytebeamClient";
import styled from "styled-components";
import ConfirmationModal from "../../common/ConfirmationModal";
import ActionButtonV3 from "../ActionsV3/ActionButton";
import ConfirmationModalMessageNonDelete from "../../common/ConfirmationModalMessageNonDelete";
import ThemeSchema from "../../../../theme/schema";
import { AtLeastOnePropRequired, Permission } from "../../../../util";
import { getSelectedPhaseIndex } from "../util";
import { useUser } from "../../../../context/User.context";
import { capitalizeFirstLetter } from "../../util";

const SelectedDevicesDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 30px;
  font-size: 16px;
  border-radius: 6px;
`;

type handleCancelOrRetryActionType = {
  actionID: string;
  operation: "cancel" | "retry";
};

type SelectedDevicesActionProps = {
  readonly actionID: string;
  readonly allDevicesSelected: boolean;
  readonly selectedDevices: { deviceID: number; deviceStatus: string }[];
  readonly selectedDeviceCount: number;
  readonly areFiltersApplied: boolean;
  readonly selectedPhase: string;
  readonly actionsData: ActionStatusType;

  readonly handleRefetchDeviceStatusDetails: () => void;
};

type ActionConfirmationMessageProps = {
  actionType: "cancel" | "retry" | "complete";
  allDevicesSelected: boolean;
  selectedPhase: string;
  areFiltersApplied: boolean;
  selectedDeviceIDs: number[];
  actionsData: ActionStatusType;
};

const ActionConfirmationMessage: React.FC<ActionConfirmationMessageProps> = ({
  actionType,
  allDevicesSelected,
  selectedPhase,
  areFiltersApplied,
  selectedDeviceIDs,
  actionsData,
}) => {
  const phaseMessageConfirmation =
    selectedPhase !== "all" ? `${selectedPhase}` : "all";

  const deviceInfo =
    allDevicesSelected && !areFiltersApplied
      ? `${phaseMessageConfirmation} devices`
      : actionType === "complete"
        ? `devices with "id: ${selectedDeviceIDs.join(", ")}"`
        : `"Ids: ${selectedDeviceIDs.join(", ")}"`;

  const name = `${capitalizeFirstLetter(actionType)} action on ${allDevicesSelected && !areFiltersApplied ? phaseMessageConfirmation : "the selected"} devices.`;

  const specialMessage =
    actionType === "complete"
      ? `This will mark the Action(id: ${actionsData.action_id}, type: ${actionsData.type}) as completed on ${deviceInfo}.`
      : `Type: ${actionsData.type}, ${deviceInfo}`;

  return (
    <ConfirmationModalMessageNonDelete
      name={name}
      expectedText={String(actionsData.action_id)}
      type={""}
      specialMessage={specialMessage}
    />
  );
};

const SelectedDevicesAction = (props: SelectedDevicesActionProps) => {
  const {
    allDevicesSelected,
    selectedDevices,
    selectedDeviceCount,
    actionID,
    areFiltersApplied,
    selectedPhase,
    actionsData,
    handleRefetchDeviceStatusDetails,
  } = props;

  const { user } = useUser();
  const theme = user?.settings?.theme;
  const permissions: Permission = user?.role?.permissions;

  const selectedDeviceIDs = selectedDevices.map((device) => device.deviceID);

  /**
   * Returns the action parameters based on the action type.
   * This is used to pass the parameters to the cancel or retry action and complete action.
   * @param actionType - The type of action ("cancel", "retry", or "complete").
   * @returns The action parameters object which is required for the cancel, retry, or complete action API.
   */
  const getActionsParams = (
    actionType: "cancel" | "retry" | "complete"
  ):
    | AtLeastOnePropRequired<MarkActionAsCompletedBodyType>
    | CancelOrRetryActionObj => {
    if (allDevicesSelected && !areFiltersApplied) {
      if (selectedPhase === "all") {
        return { all: true };
      } else {
        const selectedPhaseIndex = getSelectedPhaseIndex(selectedPhase);
        return { phase_idx: selectedPhaseIndex };
      }
    } else {
      return actionType === "complete"
        ? { metadata: { id: selectedDeviceIDs.map(String) } }
        : { device_ids: selectedDeviceIDs };
    }
  };

  /**
   * Handles the cancel or retry action for selected devices.
   * @param {handleCancelOrRetryActionType} params - The parameters for the cancel or retry action.
   * @returns {Promise<void>} - A promise that resolves when the action is completed.
   */
  const handleCancelOrRetryAction = async ({
    actionID,
    operation,
  }: handleCancelOrRetryActionType): Promise<void> => {
    try {
      let res;

      if (operation === "cancel") {
        res = await cancelActions(
          actionID,
          getActionsParams("cancel") as CancelOrRetryActionObj
        );
      } else if (operation === "retry") {
        res = await retryActions(
          actionID,
          getActionsParams("retry") as CancelOrRetryActionObj
        );
      }

      if (res && res !== undefined) {
        // Refetch the device status details, and show a success toast.
        handleRefetchDeviceStatusDetails();
        beamtoast.success(
          `${operation === "cancel" ? "Cancelled" : "Retried"} action successfully`
        );
      }
    } catch (e) {
      console.log(e);
      beamtoast.error(`Failed to ${operation} action on devices`);
    }
  };

  const handleMarkAsCompleteDeviceAction = async ({
    actionID,
  }: {
    actionID: string;
  }) => {
    try {
      if (actionID) {
        await markActionAsCompleted(
          actionID,
          getActionsParams(
            "complete"
          ) as AtLeastOnePropRequired<MarkActionAsCompletedBodyType>
        );
        // Refetch the device status details, and show a success toast.
        handleRefetchDeviceStatusDetails();
        beamtoast.success(
          `Marked action ${actionID} as completed on ${allDevicesSelected ? "all" : "selected"} devices`
        );
      }
    } catch (e) {
      console.log(e);
      beamtoast.error(
        `Failed to mark action ${actionID} as completed on  ${allDevicesSelected ? "all" : "selected"} devices`
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <SelectedDevicesDiv>
        {selectedDeviceCount} selected
        <ConfirmationModal
          prefixContent="Cancel Device Action"
          expectedText={String(actionID)}
          onConfirm={() => {
            handleCancelOrRetryAction({ actionID, operation: "cancel" });
          }}
          trigger={
            <ActionButtonV3
              onClick={() => {}}
              disabled={
                selectedDeviceCount === 0 ||
                selectedDevices.some(
                  (device) =>
                    device?.deviceStatus === "Failed" ||
                    device?.deviceStatus === "Completed"
                ) ||
                actionsData?.type === "cancel_action" ||
                actionsData?.type === "launch_shell"
              }
              icon={"close"}
              label={"Cancel Action"}
              margin_left={"30px"}
              border_color={`${
                ThemeSchema.data[theme ?? "dark"]?.colors[
                  "devices_list-action-button-border-color"
                ]
              }`}
              border_radius="4px"
              padding={"0.3rem 1.6rem"}
            />
          }
          message={
            <ActionConfirmationMessage
              actionType="cancel"
              allDevicesSelected={allDevicesSelected}
              selectedPhase={selectedPhase}
              areFiltersApplied={areFiltersApplied}
              selectedDeviceIDs={selectedDeviceIDs}
              actionsData={actionsData}
            />
          }
        />
        <ConfirmationModal
          prefixContent="Retry Device Action"
          expectedText={String(actionID)}
          onConfirm={() => {
            handleCancelOrRetryAction({ actionID, operation: "retry" });
          }}
          trigger={
            <ActionButtonV3
              onClick={() => {}}
              disabled={
                actionsData?.type === "cancel_action" ||
                actionsData?.type === "launch_shell" ||
                selectedDeviceCount === 0 ||
                selectedDevices.some(
                  (device) => device?.deviceStatus !== "Failed"
                )
              }
              icon={"redo"}
              label={"Retry Action"}
              margin_left={"30px"}
              border_color={`${
                ThemeSchema.data[theme ?? "dark"]?.colors[
                  "devices_list-action-button-border-color"
                ]
              }`}
              border_radius="4px"
              padding={"0.3rem 1.6rem"}
            />
          }
          message={
            <ActionConfirmationMessage
              actionType="retry"
              allDevicesSelected={allDevicesSelected}
              selectedPhase={selectedPhase}
              areFiltersApplied={areFiltersApplied}
              selectedDeviceIDs={selectedDeviceIDs}
              actionsData={actionsData}
            />
          }
        />
        {permissions?.allowMarkActionAsCompleted && (
          <ConfirmationModal
            prefixContent="Mark Device Action as Complete"
            expectedText={String(actionID)}
            onConfirm={() => {
              handleMarkAsCompleteDeviceAction({ actionID });
            }}
            trigger={
              <ActionButtonV3
                onClick={() => {}}
                disabled={
                  selectedDeviceCount === 0 ||
                  actionsData?.type === "cancel_action" ||
                  selectedDevices.every(
                    (device) =>
                      device?.deviceStatus === "Failed" ||
                      device?.deviceStatus === "Completed"
                  )
                }
                icon={"check"}
                label={"Complete Action"}
                margin_left={"30px"}
                border_color={`${
                  ThemeSchema.data[theme ?? "dark"]?.colors[
                    "devices_list-action-button-border-color"
                  ]
                }`}
                border_radius="4px"
                padding={"0.3rem 1.6rem"}
              />
            }
            message={
              <ActionConfirmationMessage
                actionType="complete"
                allDevicesSelected={allDevicesSelected}
                selectedPhase={selectedPhase}
                areFiltersApplied={areFiltersApplied}
                selectedDeviceIDs={selectedDeviceIDs}
                actionsData={actionsData}
              />
            }
          />
        )}
      </SelectedDevicesDiv>
    </div>
  );
};

export default SelectedDevicesAction;

import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownProps,
  Button,
  Icon,
  IconProps,
  Popup,
} from "semantic-ui-react";
import { TimeRangePicker } from "./TimeRangePicker";
import {
  RelativeTimeRange,
  TimeRange,
  NamedTimeRange,
  AbsoluteTimeRange,
} from "./TimeRange";
import { AbsoluteTimestamp, RelativeTimestamp, Timestamp } from "./Timestamp";
import styled from "styled-components";
import { useUser } from "../../../../context/User.context";
import { TenantSettings } from "../../../../util";
import moment, { DurationInputArg2 } from "moment";
import { HARDWARE_TYPES } from "../../util";
import { breakpoints } from "../../../common/breakpoints";

type DateTimeWidgetProps = {
  timeRange: TimeRange;
  onTimeRangeChange: (timeRange: TimeRange) => any;
  disabled: boolean;
  showControls: boolean;
};

const StyledDropdownButton = styled(Button)`
  width: 30px !important;
  padding: 0px !important;
  margin-right: 2px !important;
  background: ${(props) =>
    props.theme.colors["date_time_dropdown_button-background"]} !important;
  color: ${(props) =>
    props.theme.colors["date_time_dropdown_button-color"]} !important;
`;

const CustomTimeRangeDropdownWrapper = styled.div`
  & .selection.dropdown {
    .divider.text {
      @media (max-width: ${breakpoints.sm}px) {
        white-space: normal !important;
      }

      @media (max-width: ${breakpoints.xs}px) {
        max-width: 155px;
      }
    }
  }
`;

function DropdownButton(props: IconProps) {
  const newProps = Object.assign({}, props, { onClick: undefined });

  return (
    <StyledDropdownButton icon onClick={props.onClick}>
      <Icon {...newProps} />
    </StyledDropdownButton>
  );
}

export function DateTimeDropdown(props: DateTimeWidgetProps) {
  const { disabled, onTimeRangeChange, showControls, timeRange } = props;

  const [showTimeRangePicker, setShowTimeRangePicker] =
    useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<{
    key: string;
    text: string;
    value: number;
  }>({ key: "", text: "", value: 0 });

  const { user, getCurrentUser } = useUser();
  const tenant_settings: TenantSettings = user["tenant-settings"] ?? {
    common_settings: {
      pin_metadata: [],
    },
    "serial-key": null,
    dashboard_settings: {
      custom_time_ranges: {},
    },
    hardware_type: HARDWARE_TYPES[0],
    show_tabs: null,
    logo: {
      light: "",
      dark: "",
    },
    favicon: {
      light: "",
      dark: "",
    },
  };
  const dashboardSettings = tenant_settings?.dashboard_settings ?? {};
  const customTimeRanges = dashboardSettings?.custom_time_ranges ?? {};

  const customTimeRangesValue: Array<TimeRange> = Object.values(
    customTimeRanges
  )
    .map(
      (range) =>
        new AbsoluteTimeRange(getTimestamp(range.from), getTimestamp(range.to))
    )
    .reverse(); // Reverse so that the most recent custom time range is last
  let defaultRelativeTimeRanges: Array<TimeRange> = [
    new RelativeTimeRange(5, "minutes"),
    new RelativeTimeRange(15, "minutes"),
    new RelativeTimeRange(30, "minutes"),
    new RelativeTimeRange(1, "hour"),
    new RelativeTimeRange(3, "hours"),
    new RelativeTimeRange(6, "hours"),
    new RelativeTimeRange(12, "hours"),
    new RelativeTimeRange(24, "hours"),
    new RelativeTimeRange(2, "days"),
    new RelativeTimeRange(1, "week"),
    new RelativeTimeRange(1, "month"),
    new RelativeTimeRange(1, "year"),
    ...NamedTimeRange.timeRanges, // Adding to last, may remove after testing
  ];

  if (window.location.hostname === "numeros.bytebeam.io") {
    // updated time range options for numeros.bytebeam.io
    defaultRelativeTimeRanges = [
      ...NamedTimeRange.timeRanges, // this appears first here
      new RelativeTimeRange(5, "minutes"),
      new RelativeTimeRange(15, "minutes"),
      new RelativeTimeRange(30, "minutes"),
      new RelativeTimeRange(1, "hour"),
      new RelativeTimeRange(3, "hours"),
      new RelativeTimeRange(6, "hours"),
      new RelativeTimeRange(12, "hours"),
      new RelativeTimeRange(24, "hours"),
      new RelativeTimeRange(2, "days"), // it appears till here on Micelio
      new RelativeTimeRange(1, "week"),
      new RelativeTimeRange(1, "month"),
      new RelativeTimeRange(1, "year"),
    ];
  }

  if (window.location.hostname === "exponent.bytebeam.io") {
    // updated time range options for exponent.bytebeam.io
    defaultRelativeTimeRanges = [
      new RelativeTimeRange(5, "minutes"),
      new RelativeTimeRange(15, "minutes"),
      new RelativeTimeRange(30, "minutes"),
      new RelativeTimeRange(1, "hour"),
      new RelativeTimeRange(3, "hours"),
      new RelativeTimeRange(6, "hours"),
      new RelativeTimeRange(12, "hours"),
      new RelativeTimeRange(24, "hours"),
      new RelativeTimeRange(2, "days"),
      new RelativeTimeRange(1, "week"),
      new RelativeTimeRange(1, "month"),
    ];
  }

  const defaultRanges: Array<TimeRange> = [
    ...defaultRelativeTimeRanges,
    ...customTimeRangesValue,
  ];

  const customTimeRangeOption = {
    key: "customTimeRange",
    text: "Custom Time Range",
    value: 0,
    onClick: () => {
      setShowTimeRangePicker(true);
    },
  };

  const options = [
    customTimeRangeOption,
    ...defaultRanges.map((option, i) => {
      return {
        key: i + option.toString(), // Added index for handling duplicate keys
        text:
          i < defaultRelativeTimeRanges.length
            ? option.toString()
            : Object.keys(customTimeRanges).reverse()[ // Reverse so that the most recent custom time range is last
                i - defaultRelativeTimeRanges.length
              ],
        value: i + 1,
      };
    }),
  ];

  function onPredefinedTimeRangePicked(_event: any, data: DropdownProps) {
    let value = { key: "", text: "", value: 0 };

    // case when Custom Time Range is selected
    if (data.value === 0) {
      setCurrentValue(value);
    } else {
      data.options?.forEach((option) => {
        if (option.value === data.value)
          value = {
            key: option.key,
            text: option.text ? option.text.toString() : "",
            value: Number(option.value),
          };
      });
      setCurrentValue(value);
      onTimeRangeChange(defaultRanges[value.value - 1]);
    }
  }

  function onCustomTimeRangePicked(timeRange: TimeRange) {
    setCurrentValue({
      key: timeRange.toString(),
      text: timeRange.toString(),
      value: 0,
    });
    onTimeRangeChange(timeRange);
    setShowTimeRangePicker(false);
  }

  function onShiftTimeRangeLeft() {
    const currentTimeRange = timeRange;
    const start = currentTimeRange.getStartTime().toDate();
    const end = currentTimeRange.getEndTime().toDate();

    const newStart = new Date(
      start.valueOf() - (end.valueOf() - start.valueOf())
    );
    const newEnd = start;

    onCustomTimeRangePicked(
      new AbsoluteTimeRange(
        new AbsoluteTimestamp(newStart),
        new AbsoluteTimestamp(newEnd)
      )
    );
  }

  function onShiftTimeRangeRight() {
    const currentTimeRange = timeRange;
    const start = currentTimeRange.getStartTime().toDate();
    const end = currentTimeRange.getEndTime().toDate();

    const newStart = end;
    const newEnd = new Date(end.valueOf() + (end.valueOf() - start.valueOf()));

    onCustomTimeRangePicked(
      new AbsoluteTimeRange(
        new AbsoluteTimestamp(newStart),
        new AbsoluteTimestamp(newEnd)
      )
    );
  }

  function onZoomInTimeRange() {
    const currentTimeRange = timeRange;
    const start = currentTimeRange.getStartTime().toDate().valueOf();
    const end = currentTimeRange.getEndTime().toDate().valueOf();

    const newLength = (end - start) / 2;
    const midpoint = (start + end) / 2;
    const newStart = new Date(midpoint - newLength / 2);
    const newEnd = new Date(midpoint + newLength / 2);

    onCustomTimeRangePicked(
      new AbsoluteTimeRange(
        new AbsoluteTimestamp(newStart),
        new AbsoluteTimestamp(newEnd)
      )
    );
  }

  function onZoomOutTimeRange() {
    const currentTimeRange = timeRange;
    const start = currentTimeRange.getStartTime().toDate().valueOf();
    const end = currentTimeRange.getEndTime().toDate().valueOf();

    const newLength = (end - start) * 2;
    const midpoint = (start + end) / 2;
    const newStart = new Date(midpoint - newLength / 2);
    const newEnd = new Date(midpoint + newLength / 2);

    onCustomTimeRangePicked(
      new AbsoluteTimeRange(
        new AbsoluteTimestamp(newStart),
        new AbsoluteTimestamp(newEnd)
      )
    );
  }

  function getTimestamp(
    TimestampObject:
      | Timestamp
      | {
          date: string;
        }
      | {
          units: DurationInputArg2;
          duration: number;
        }
  ): Timestamp {
    if ("units" in TimestampObject && "duration" in TimestampObject) {
      const { units, duration } = TimestampObject;
      return new RelativeTimestamp(duration, units);
    } else if ("date" in TimestampObject) {
      const timestamp = moment(
        TimestampObject.date,
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      );
      return new AbsoluteTimestamp(timestamp.toDate());
    } else {
      throw new Error("Invalid TimestampObject");
    }
  }

  useEffect(() => {
    let exist = false;
    options.forEach((option) => {
      if (
        option.key.substring((option.value - 1).toString().length) ===
        timeRange.toString()
      ) {
        exist = true;
        setCurrentValue(option);
      }
    });

    if (!exist) {
      setCurrentValue({
        key: timeRange.toString(),
        text: timeRange.toString(),
        value: 0,
      });
    }
  }, [timeRange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TimeRangePicker
        isOpen={showTimeRangePicker}
        onCancel={() => {
          setShowTimeRangePicker(false);
        }}
        onTimeRangePicked={onCustomTimeRangePicked}
        timeRange={timeRange}
        user={user}
        getCurrentUser={getCurrentUser}
        allCustomTimeRangesLabel={new Set(Object.keys(customTimeRanges))}
        customTimeRangesValue={customTimeRangesValue}
        customTimeRanges={customTimeRanges}
      />

      <Button.Group>
        {showControls ? (
          <DropdownButton name="angle left" onClick={onShiftTimeRangeLeft} />
        ) : (
          <></>
        )}

        <Popup
          inverted
          position="top left"
          content={
            currentValue.value !== 0
              ? currentValue.key.substring(
                  (currentValue.value - 1).toString().length
                )
              : "Custom Time Range"
          }
          style={{ marginBottom: "6px" }}
          trigger={
            <CustomTimeRangeDropdownWrapper>
              <Dropdown
                style={{
                  minWidth: "150px",
                  borderRadius: "0px",
                  marginRight: "2px",
                  border: "none",
                  "&:focus": {
                    border: "none",
                  },
                }}
                text={
                  currentValue.value === 0 ? timeRange.toString() : undefined
                }
                options={options}
                button
                selection
                onChange={onPredefinedTimeRangePicked}
                disabled={disabled}
                value={currentValue.value}
              />
            </CustomTimeRangeDropdownWrapper>
          }
        />

        {showControls ? (
          <>
            <DropdownButton name="zoom in" onClick={onZoomInTimeRange} />
            <DropdownButton name="zoom out" onClick={onZoomOutTimeRange} />
            <DropdownButton
              name="angle right"
              onClick={onShiftTimeRangeRight}
            />
          </>
        ) : (
          <></>
        )}
      </Button.Group>
    </>
  );
}

import * as React from "react";
import { SVGProps } from "react";

const PieChartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="20.67 21 109.33 109.22"
    {...props}
  >
    <path
      fill="currentColor"
      d="M78 21c15.215 0 26.096 2.896 37.477 13.254C125.352 44.543 130 55.893 130 70H78V21Z"
    />
    <path
      fill="currentColor"
      d="M71 29v49h50c0 15.021-3.867 26.763-14.25 37.75-11.16 10.599-23.9 14.62-39.055 14.46-13.32-.76-25.562-6.438-34.695-16.21-11.313-13.747-13.251-27.725-12-45 .798-4.057 2.053-7.367 4-11l1.285-2.559c6.04-11.053 15.51-20.092 27.73-23.695C59.744 30.201 65.016 29 71 29ZM38 49c-8.157 9.425-12.354 20.507-12 33 1.463 13.602 6.877 24.29 17.387 33.094C53.74 122.646 64.302 125.14 77 124c12.441-2.43 22.693-8.073 30.277-18.32 4.672-7.267 6.64-13.016 7.723-21.68H65V36c-10.57 0-19.626 5.716-27 13Z"
    />
  </svg>
);

export default PieChartIcon;

import { useCallback, useEffect, RefObject } from "react";

/**
 * Hook that triggers a callback when a click occurs outside of the referenced elements.
 * @param elementsRef - A single ref or an array of refs to monitor for outside clicks.
 * @param onOutsideClick - A callback function to execute when an outside click is detected.
 */
export function useOutsideClickHandler<T extends HTMLElement>(
  elementsRef: RefObject<T> | Array<RefObject<T>>,
  onOutsideClick: () => void
): void {
  const elementsRefsArray = Array.isArray(elementsRef) // Ensure elementsRef is always an array to simplify handling.
    ? elementsRef
    : [elementsRef];

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (
        elementsRefsArray.every(
          (ref) => ref.current && !ref.current.contains(event.target as Node)
        )
      ) {
        onOutsideClick();
      }
    },
    [onOutsideClick, elementsRefsArray]
  );

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick, elementsRefsArray]);
}

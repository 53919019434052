import * as React from "react";
import { SVGProps } from "react";

const AggregateValueIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 60 98"
    {...props}
  >
    <path
      fill="currentColor"
      d="M31.56.936a1.5 1.5 0 0 0-2.12 0l-9.547 9.546a1.5 1.5 0 1 0 2.122 2.12L30.5 4.119l8.485 8.485a1.5 1.5 0 1 0 2.122-2.121L31.56.936ZM32 91.002V1.996h-3v89.006h3Z"
    />
    <path
      stroke="currentColor"
      fill="transparent"
      strokeWidth={3}
      d="m31.061 67.061-25 25M54.939 91.061l-25-25"
    />
    <circle
      cx={4}
      cy={94}
      r={3}
      fill="transparent"
      stroke="currentColor"
      strokeWidth={2}
    />
    <circle
      cx={30}
      cy={94}
      r={3}
      fill="transparent"
      stroke="currentColor"
      strokeWidth={2}
    />
    <circle
      cx={56}
      cy={94}
      r={3}
      fill="transparent"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);

export default AggregateValueIcon;

import { StreamStatusMetaData } from "./PanelDef";
import React, { RefObject, createRef } from "react";

import { Tab } from "semantic-ui-react";
import { SketchPicker } from "react-color";
import RelativeTimePicker from "../../Datetime/RelativeTimePicker";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  LabelHeading,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  ThinDivider,
  EditPanelFormContainer,
  StyledSketchPickerTrigger,
  StyledInputDiv,
} from "../util";
import { TableInfo } from "../../../../../BytebeamClient";

export type EditStreamStatusMetaProps = {
  panelMeta: StreamStatusMetaData;
  tables: TableInfo;
};

export type EditStreamStatusMetaState = {
  tables: string[];
  onStateColor: string;
  offStateColor: string;
  displayOnStatePicker: boolean;
  displayOffStatePicker: boolean;
  error: boolean;
};

export class EditStreamStatusMeta extends PanelEditComponent<
  StreamStatusMetaData,
  EditStreamStatusMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  durationRef = React.createRef<RelativeTimePicker>();
  prefixRef = React.createRef<HTMLInputElement>();
  suffixRef = React.createRef<HTMLInputElement>();
  ledSizeRef = React.createRef<HTMLInputElement>();

  activeInputRef: RefObject<HTMLInputElement> = createRef();
  inactiveInputRef: RefObject<HTMLInputElement> = createRef();

  constructor(props: EditStreamStatusMetaProps) {
    super(props);

    this.state = {
      tables: props.panelMeta?.tables || [],
      onStateColor: this.props.panelMeta.onStateColor
        ? this.props.panelMeta.onStateColor
        : "#39fa39",
      offStateColor: this.props.panelMeta.offStateColor
        ? this.props.panelMeta.offStateColor
        : "#fa1c1c",
      displayOnStatePicker: false,
      displayOffStatePicker: false,
      error: false,
    };
  }

  getPanelMeta(type): PartialMetaData<StreamStatusMetaData> {
    const meta: StreamStatusMetaData = {
      type: "stream_status",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      tables: this.state.tables || [],
      onStateColor: this.state.onStateColor
        ? this.state.onStateColor
        : "#39fa39",
      offStateColor: this.state.offStateColor
        ? this.state.offStateColor
        : "#fa1c1c",
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: StreamStatusMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (!Boolean(meta.tables.length) && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return Boolean(meta.tables.length);
  }

  setTable(_event, data) {
    this.setState({
      tables: data.value,
    });
  }

  handlePickerClick(e, pickerVal) {
    e.stopPropagation();
    pickerVal === "on"
      ? this.setState({
          displayOnStatePicker: !this.state.displayOnStatePicker,
        })
      : this.setState({
          displayOffStatePicker: !this.state.displayOffStatePicker,
        });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>

              <ThinDivider />

              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    search
                    selection
                    multiple
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={
                      this.state.tables?.length > 0 ? this.state.tables : []
                    }
                    value={
                      this.state.tables?.length > 0 ? this.state.tables : []
                    }
                    elementid={"StreamStatusTable"}
                    error={this.state.error}
                  />
                </StyledInputDiv>
              </EditMetaRow>

              <ThinDivider />
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              <EditMetaRow>
                <LabelHeading>Choose Active State Color</LabelHeading>
                <StyledSketchPickerTrigger
                  id="default_color"
                  style={{
                    backgroundColor: `${this.state.onStateColor}`,
                  }}
                  onClick={(e) => this.handlePickerClick(e, "on")}
                />
                <div
                  style={{
                    display: this.state.displayOnStatePicker ? "block" : "none",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SketchPicker
                    color={this.state.onStateColor}
                    onChange={(color) => {
                      this.setState({ onStateColor: color.hex });
                    }}
                  />
                </div>
              </EditMetaRow>

              <EditMetaRow>
                <LabelHeading>Choose Inactive State Color</LabelHeading>
                <StyledSketchPickerTrigger
                  id="default_color"
                  style={{
                    backgroundColor: `${this.state.offStateColor}`,
                  }}
                  onClick={(e) => this.handlePickerClick(e, "off")}
                />
                <div
                  style={{
                    display: this.state.displayOffStatePicker
                      ? "block"
                      : "none",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SketchPicker
                    color={this.state.offStateColor}
                    onChange={(color) => {
                      this.setState({ offStateColor: color.hex });
                    }}
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <div
          onClick={() => {
            this.setState({
              displayOffStatePicker: false,
              displayOnStatePicker: false,
            });
          }}
        >
          <Tab menu={{}} panes={panes} renderActiveOnly={false} />
        </div>
      </EditMetaRoot>
    );
  }
}

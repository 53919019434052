import React, { useState } from "react";
import { Modal, Input, Button } from "semantic-ui-react";
import { Mixpanel } from "./common/MixPanel";
import { User } from "../../util";
import { beamtoast } from "../common/CustomToast";
import { ActionButton } from "./DeviceManagement/Devices/ActionButtons";
import { ErrorMessage } from "../common/ErrorMessage";

type SelectProjectModalProps = {
  readonly isOpen: boolean;
  readonly tenants: string[];
  readonly user: User;
  readonly onClose: () => void;
  readonly setCurrentTenant: (tenant: string) => void;
  readonly setShowNewProjectModal: (show: boolean) => void;
  readonly currentTenant: string | null;
  readonly disableCancelButton: boolean;
};

function SelectProjectModal(props: SelectProjectModalProps) {
  const {
    isOpen,
    onClose,
    setCurrentTenant,
    tenants,
    user,
    setShowNewProjectModal,
    currentTenant,
    disableCancelButton,
  } = props;

  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredTenants = [...tenants, "demo"]
    .filter((tenant) => tenant.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => a.localeCompare(b));

  async function onSubmit(tenant) {
    if (tenant) {
      Mixpanel.track("Selected Project", {
        tenant: tenant,
        role: user?.role.name,
      });
      setCurrentTenant(tenant);
      if (currentTenant) beamtoast.info(`Switching to project "${tenant}"`);
      setTimeout(() => {
        window.location.href = `/projects/${tenant}/`;
      });
    }
  }

  const onModalClose = () => {
    setSearchTerm("");

    // Close the modal
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      size="tiny"
      onClose={onModalClose}
      closeOnDimmerClick={false}
      className="dark"
    >
      <Modal.Header>Select a Project</Modal.Header>
      <Modal.Content style={{ height: "35vh", overflowY: "hidden" }}>
        <div
          style={{
            position: "sticky",
            top: 0,
            padding: "4px 0px 18px 0px",
            zIndex: 2,
          }}
        >
          <Input
            id="search-projects-input"
            fluid
            autoFocus={true}
            icon="search"
            placeholder="Search projects..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
          />
        </div>
        <div style={{ overflowY: "auto", height: "calc(35vh - 60px)" }}>
          {" "}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              paddingBottom: "32px",
              margin: "0px",
            }}
          >
            {filteredTenants.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <ErrorMessage
                  marginTop="64px"
                  iconSize="big"
                  message="No projects found"
                />
              </div>
            )}
            {filteredTenants.length > 0 &&
              filteredTenants.map((tenant) => (
                <div key={tenant} style={{ margin: "10px 5px" }}>
                  <ActionButton
                    onClick={() => {
                      onSubmit(tenant);
                    }}
                  >
                    {tenant === "demo" ? "Demo Project" : tenant}
                  </ActionButton>
                </div>
              ))}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button primary onClick={() => setShowNewProjectModal(true)}>
          Create New Project
        </Button>
        <div>
          {!disableCancelButton && (
            <Button secondary onClick={onModalClose}>
              Cancel
            </Button>
          )}
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default SelectProjectModal;

import React, { useRef, useState } from "react";
import { Modal, Input, Button, Dropdown } from "semantic-ui-react";
import { Mixpanel } from "./common/MixPanel";
import { HARDWARE_TYPES } from "./util";
import { beamtoast } from "../common/CustomToast";

type NewProjectModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly setCurrentTenant: (selectedTenant: string) => void;
};

function NewProjectModal(props: NewProjectModalProps) {
  const { isOpen, onClose, setCurrentTenant } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [provisioning, setProvisioning] = useState(false);
  const [hardwareType, setHardwareType] = useState<string>(HARDWARE_TYPES[0]);

  const hardwareTypeOptions = HARDWARE_TYPES.map((hardwareType) => {
    return {
      key: hardwareType,
      value: hardwareType,
      text: hardwareType,
    };
  });

  const handleHardwareTypeChange = (_e, data) => {
    _e.preventDefault();
    const { value } = data;
    setHardwareType(value);
  };

  const onSubmit = async () => {
    if (inputRef.current?.value) {
      const tenantId = inputRef.current.value.trim().toLowerCase();
      const validTenant =
        tenantId.length < 255 && tenantId.match(/^[a-z][a-z0-9]*$/);
      if (!validTenant) {
        beamtoast.error("Project name can only contain alphabets and numbers");
        return;
      }
      setProvisioning(true);
      try {
        // TODO: Use createTenant from BytebeamClient instead of fetch api
        const resp = await fetch("/api/v1/tenants", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "tenant-id": tenantId,
            "device-type": hardwareType.toLowerCase(),
          }),
        });

        if (resp.ok) {
          beamtoast.success("Created project " + tenantId);
          Mixpanel.track("Created Project", {
            tenant: tenantId,
          });

          setCurrentTenant(tenantId);

          setTimeout(() => {
            window.location.href = `/projects/${tenantId}/`;
          }, 1000);
        } else if (resp.status === 409) {
          setProvisioning(false);
          beamtoast.error(
            "Project name already exists, please try another name."
          );

          Mixpanel.track("Failure", {
            type: "Project creation",
            error: "Duplicate tenant name error",
            response: JSON.stringify(resp),
          });
        } else {
          setProvisioning(false);
          beamtoast.error("Failed to create new project.");
          console.error("Failed to create new tenant", resp);

          Mixpanel.track("Failure", {
            type: "Project creation",
            error: "no backend error",
            response: JSON.stringify(resp),
          });
        }
      } catch (error) {
        setProvisioning(false);
        console.error("Error while creating new project", error);
        Mixpanel.track("Failure", {
          type: "Project creation",
          error: JSON.stringify(error),
        });
      }
    } else {
      beamtoast.error("Please enter a project name.");
    }
  };

  return (
    <Modal className="dark" open={isOpen} size="tiny" onClose={onClose}>
      <Modal.Header> Create a new project </Modal.Header>
      <Modal.Content>
        <div style={{ fontSize: "15px", marginBottom: "8px" }}>
          Please provide the name of the project you would like to create.
        </div>
        <Input fluid>
          <input
            autoFocus
            placeholder="Project Name"
            ref={inputRef}
            disabled={provisioning}
          />
        </Input>

        <div
          style={{ fontSize: "15px", marginBottom: "8px", marginTop: "18px" }}
        >
          Please provide the type of the hardware you would like to create.
        </div>
        <Dropdown
          selection
          search
          options={hardwareTypeOptions}
          onChange={handleHardwareTypeChange}
          value={hardwareType}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onClose}>
          Cancel
        </Button>
        <Button
          id="submit_createProject"
          primary
          onClick={onSubmit}
          disabled={provisioning}
        >
          Submit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default NewProjectModal;

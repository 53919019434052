import React from "react";
import { Header, HeaderSubheader, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { breakpoints } from "../../common/breakpoints";

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors["background-color"]};
  height: 80vh;
`;

const StyledIcon = styled(Icon)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: ${({ theme }) => theme.colors["primary-color"]};
  font-size: 144px !important;
  @media (max-width: ${breakpoints.sm}px) {
    font-size: 72px !important;
  }
`;

const StyledHeader = styled(Header)`
  font-size: 72px !important;
  color: ${({ theme }) => theme.colors["foreground-color"]} !important;
  @media (max-width: ${breakpoints.sm}px) {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
    font-size: 32px !important;
  }
`;

const StyledSubHeader = styled(HeaderSubheader)`
  color: ${({ theme }) => theme.colors["secondary-color"]} !important;
  font-size: 36px !important;
  font-weight: 600 !important;
  @media (max-width: ${breakpoints.sm}px) {
    font-size: 24px !important;
  }
`;

const FullPageMessage = ({ heading, message }) => (
  <PageWrapper>
    <StyledIcon name="settings" />
    <StyledHeader>{heading}</StyledHeader>
    <StyledSubHeader>{message}</StyledSubHeader>
  </PageWrapper>
);

export default FullPageMessage;

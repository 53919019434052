import React, { useState } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import useAsyncEffect from "../../common/useAsyncEffect";
import Layout from "../../common/Layout";
import { DisplayIf } from "../../util";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  fetchAllRoles,
  fetchAllApiKeys,
  createApiKey,
} from "../../../../BytebeamClient";
import { ApiKey, Role, Permission } from "../../../../util";
import { Mixpanel } from "../../common/MixPanel";
import CreateOrEditApiKeyModal from "./CreateOrEditApiKeyModal";
import APIKeyRow from "./APIKeyRow";
import LoadingAnimation from "../../../common/Loader";
import { beamtoast } from "../../../common/CustomToast";

interface CreateApikeyButtonProps {
  onUpdate: () => void;
  roles: Role[];
  allApiKeys: ApiKey[];
}

function CreateApikeyButton(props: CreateApikeyButtonProps) {
  return (
    <CreateOrEditApiKeyModal
      title="Create API Key"
      onSubmit={async (apiKey) => {
        try {
          await createApiKey(apiKey);
          Mixpanel.track("Created API Key", {});
          props.onUpdate();
          beamtoast.success("New API Key created successfully");
        } catch (e) {
          Mixpanel.track("Failure", {
            type: "API Key creation",
            error: JSON.stringify(e),
          });
          console.log(e);
          beamtoast.error("Failed to create API Key");
        }
      }}
      apiKey={{
        name: "",
        role: "",
      }}
      allApiKeys={props.allApiKeys}
      roles={props.roles}
      trigger={
        <Button primary floated="right" icon labelPosition="left">
          <Icon name="plus" />
          Create API Key
        </Button>
      }
    />
  );
}

export default function ApiKeys({ user }) {
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const permissions: Permission = user.role.permissions;

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const res = await fetchAllApiKeys();
      setApiKeys(res);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setErrorOccurred(true);
    }
  };

  useAsyncEffect(async () => {
    try {
      document.title = "API Keys | Bytebeam";
      const res = await fetchAllRoles();
      setRoles(res);
    } catch (e) {
      console.log(e);
      setErrorOccurred(true);
    }
  }, []);

  useAsyncEffect(handleUpdate, []);

  if (errorOccurred) {
    return <ErrorMessage marginTop="280px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading API Keys"
      />
    );
  }

  return (
    <Layout
      buttons={
        <CreateApikeyButton
          onUpdate={handleUpdate}
          roles={roles}
          allApiKeys={apiKeys}
        />
      }
    >
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Key</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Created By</Table.HeaderCell>
            <DisplayIf cond={permissions.viewRoles}>
              <Table.HeaderCell>Role</Table.HeaderCell>
            </DisplayIf>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.entries(apiKeys).length !== 0 ? (
            Object.entries(apiKeys).map(([keyId, apiKey]) => (
              <APIKeyRow
                key={keyId}
                apiKey={apiKey}
                allApiKeys={apiKeys}
                roles={roles}
                permissions={permissions}
                onUpdate={handleUpdate}
              />
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={5}>
                <ErrorMessage marginTop="30px" message={"No API Keys found!"} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Layout>
  );
}

import React from "react";
import { Popup, Table } from "semantic-ui-react";
import {
  NewActionWrapper,
  PhaseData,
  StyledNonBoldHeader,
  SuccessRateTriggerType,
  TimestampTriggerType,
} from "./NewAction";
import {
  convertDecimalToRoman,
  getSelectedPhaseIndex,
  OptionType,
} from "../util";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import styled from "styled-components";

const SummaryGridItem = styled.div`
  display: flex;
  width: "100%";
  align-items: center;
  height: "100%";
  padding: 10px 0;
`;

interface SummaryComponentProps {
  phasesData: PhaseData[];
  isPhasedRollout: boolean;
  action: string;
  optionType: OptionType;
  selectedVersions: {
    id: number;
    value: string;
    device_component?: string;
    bundle_version?: string;
  }[];
  shouldTriggerImmediately: boolean;
}

const SummaryComponent: React.FC<SummaryComponentProps> = ({
  phasesData,
  isPhasedRollout,
  action,
  optionType,
  selectedVersions,
  shouldTriggerImmediately,
}) => {
  const getFilterInfo = (
    phase: PhaseData
  ): { key: string; value: string }[] => {
    const filterInfo: { key: string; value: string }[] = [];

    if (phase.info.filter) {
      for (const [key, value] of Object.entries(phase.info.filter)) {
        const formattedValue = Array.isArray(value)
          ? value.join(", ")
          : String(value);
        filterInfo.push({ key, value: formattedValue });
      }
    }

    return filterInfo;
  };

  const getPhasesDeviceCount = (phase: PhaseData) => {
    const { type, filter, device_ids, fraction } = phase.info;

    if (type === "fixed-list") {
      if (filter && Object.keys(filter)?.length > 0) {
        return (
          <Popup
            inverted
            content={<p>Devices: {device_ids?.join(", ")}</p>}
            trigger={
              <div>
                {getFilterInfo(phase).map((filterItem) => (
                  <div key={filterItem.key}>
                    <strong>{filterItem.key}:</strong> {filterItem.value}
                  </div>
                ))}
              </div>
            }
          />
        );
      } else if (device_ids && device_ids?.length > 0) {
        return (
          <div>
            <strong>Device-Id:</strong> {`${device_ids.join(", ")}`}
          </div>
        );
      }
    } else if (type === "filter-fraction" || type === "filter-fraction-lazy") {
      if (filter && Object.keys(filter)?.length === 0 && !device_ids) {
        return "All devices are selected";
      } else {
        return (
          <div>
            <h4
              style={{ margin: "0px" }}
            >{`${fraction}% devices are selected from filter: `}</h4>
            {getFilterInfo(phase).map((filterItem, index) => (
              <div key={filterItem.key}>
                <strong>{filterItem.key}:</strong> {filterItem.value}
              </div>
            ))}
          </div>
        );
      }
    }

    return "No devices selected";
  };

  const getPhasesTriggerInfo = (phase: PhaseData) => {
    let phaseTriggerInfo = "";
    if (shouldTriggerImmediately && phase.name === "Phase I") {
      phaseTriggerInfo = "Immediately";
    } else if ((phase.trigger_on as TimestampTriggerType).timestamp) {
      phaseTriggerInfo = (
        phase.trigger_on as TimestampTriggerType
      ).timestamp?.toLocaleString("en-GB");
    } else if (
      (phase.trigger_on as SuccessRateTriggerType).success_percentage
    ) {
      phaseTriggerInfo = `When ${
        (phase.trigger_on as SuccessRateTriggerType).success_percentage
      }% success rate in Phase ${convertDecimalToRoman(getSelectedPhaseIndex(phase.name))}`;
    } else {
      phaseTriggerInfo = "Invalid trigger type";
    }
    return phaseTriggerInfo;
  };

  return (
    <NewActionWrapper>
      <StyledHeader as="h2" style={{ marginTop: "0px", marginBottom: "30px" }}>
        Summary
      </StyledHeader>
      <div
        style={{
          paddingLeft: "15px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateAreas: `"auto auto auto auto"`,
            marginBottom: "30px",
          }}
        >
          <SummaryGridItem>
            <StyledNonBoldHeader
              as="h3"
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
            >
              Action Type:
            </StyledNonBoldHeader>
          </SummaryGridItem>
          <SummaryGridItem>
            {action !== "" ? action : "No action selected"}
          </SummaryGridItem>
          {(optionType === OptionType.ChooseFirmware ||
            optionType === OptionType.UploadFirmware ||
            optionType === OptionType.ChooseConfig ||
            optionType === OptionType.UploadConfig ||
            optionType === OptionType.ChooseGeofence ||
            optionType === OptionType.UploadGeofence) && (
            <>
              <SummaryGridItem>
                <StyledNonBoldHeader
                  as="h3"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    fontSize: "1.1rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  Version:
                </StyledNonBoldHeader>
              </SummaryGridItem>
              <SummaryGridItem style={{ flexDirection: "column" }}>
                {selectedVersions.map((selectedVersion) => {
                  let version =
                    selectedVersion.value !== ""
                      ? selectedVersion.value
                      : "No version selected";

                  if (
                    selectedVersion.device_component &&
                    selectedVersion.device_component !== "" &&
                    selectedVersion.device_component !==
                      "update_firmware with bundle"
                  ) {
                    version = `${version} (${selectedVersion.device_component})`;
                  }
                  if (selectedVersion.bundle_version) {
                    version = selectedVersion.bundle_version;
                  }
                  return <SummaryGridItem>{version}</SummaryGridItem>;
                })}
              </SummaryGridItem>
            </>
          )}
          <SummaryGridItem>
            <StyledNonBoldHeader
              as="h3"
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
            >
              Start Date/Time:
            </StyledNonBoldHeader>
          </SummaryGridItem>
          <SummaryGridItem>
            {!shouldTriggerImmediately &&
            (phasesData[0].trigger_on as TimestampTriggerType).timestamp
              ? (
                  phasesData[0].trigger_on as TimestampTriggerType
                ).timestamp?.toLocaleString("en-GB")
              : "Immediately"}
          </SummaryGridItem>
        </div>

        <Table>
          <Table.Header>
            <Table.Row>
              {isPhasedRollout && (
                <Table.Cell
                  style={{
                    borderBottom: "1px solid #3B3F43",
                  }}
                >
                  Phases
                </Table.Cell>
              )}
              <Table.Cell
                style={{
                  borderBottom: "1px solid #3B3F43",
                }}
              >
                Devices/Filters
              </Table.Cell>
              <Table.Cell
                style={{
                  borderBottom: "1px solid #3B3F43",
                }}
              >
                When to Trigger
              </Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {phasesData.flatMap((phase) => (
              <Table.Row key={phase.id}>
                {isPhasedRollout && <Table.Cell>{phase.name}</Table.Cell>}
                <Table.Cell>{getPhasesDeviceCount(phase)}</Table.Cell>
                <Table.Cell>{getPhasesTriggerInfo(phase)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </NewActionWrapper>
  );
};

export default SummaryComponent;

import { HeatmapData, HeatmapMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";

export class ViewHeatmapChart extends PanelViewComponent<
  HeatmapMetaData,
  HeatmapData
> {
  render() {
    return <div>Not Implemented!</div>;
  }
}

import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { toObject } from "csvjson";
import * as xlsx from "xlsx";
import {
  StyledFileInput,
  StyledFileUploadButton,
} from "../../DeviceManagement/Devices/ActionModals/SendFileModal";
import ActionButtonV3 from "../ActionsV3/ActionButton";
import { ThinDivider } from "../../Dashboards/Panel/util";
import { TemplateDownloaderButton } from "../../DeviceManagement/Devices/ActionModals/BulkMetadataUpdateModal";
import { PhaseData } from "./NewAction";
import { convertDecimalToRoman } from "../util";
import moment from "moment";
import { Filters } from "../../../../BytebeamClient";
import { useUser } from "../../../../context/User.context";

type UploadDeviceListModalProps = {
  setIsPhasedRollout: (isPhasedRollout: boolean) => void;
  setPhasesData: (phases: PhaseData[]) => void;
  handlePhaseClick: (phaseName: string, phaseData?: PhaseData) => void;
  fetchMetadataKeys: () => void;
  refreshDevices: (pageNumber: number, myFilters: Filters) => void;
  disable: boolean;
};

function UploadDeviceListModal(props: UploadDeviceListModalProps) {
  const {
    setIsPhasedRollout,
    setPhasesData,
    handlePhaseClick,
    fetchMetadataKeys,
    refreshDevices,
    disable,
  } = props;

  const { user } = useUser();
  const serial_key = user?.["tenant-settings"]?.["serial-key"];

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string | null>(null);
  const [parsedData, setParsedData] = useState<any>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop()?.toLowerCase();
      setFileName(file.name);
      if (extension === "csv" || extension === "xlsx") {
        parseFile(file, extension);
      } else {
        alert("Please upload only CSV or XLSX files.");
        setParsedData(null);
      }
    }
  };

  function transformData(data: Array<Record<string, string>>) {
    const transformed = {};
    const metadata = data[0];

    for (const key in metadata) {
      transformed[key] = { metadataKey: metadata[key], metadataValues: [] };
    }
    for (let i = 1; i < data?.length; i++) {
      for (const key in data[i]) {
        transformed[key].metadataValues.push(data[i][key].toString());
      }
    }

    return transformed;
  }

  const parseFile = (file: File, extension: string | undefined) => {
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result) {
        let data;
        if (extension === "csv") {
          const options = { delimiter: ",", quote: '"' };
          data = toObject(e.target.result as string, options);
        } else if (extension === "xlsx") {
          const workbook = xlsx.read(e.target.result, { type: "binary" });
          data = {};
          workbook.SheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            data[sheetName] = xlsx.utils.sheet_to_json(worksheet);
          });
          data = Object.values(data)[0];
        }
        setParsedData(transformData(data));
      }
    };
    if (extension === "xlsx") {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsText(file);
    }
  };

  function handleModalClose() {
    setIsOpen(false);
    setFileName(null);
    setParsedData(null);
  }

  function handleAddDeviceList() {
    const noOfPhases = Object.keys(parsedData)?.length;

    const newPhasesData: PhaseData[] = [];

    if (noOfPhases > 1) {
      setIsPhasedRollout(true);
    }

    const currentDateTime = new Date();
    Object.values(parsedData).forEach((phase: any, idx: number) => {
      const newPhase: PhaseData = {
        id: idx + 1,
        name: `Phase ${convertDecimalToRoman(idx + 1)}`,
        trigger_on: {
          timestamp: moment(currentDateTime)
            .add(15 * (idx + 1), "minutes")
            .toDate(),
        },
        info: {
          type: "filter-fraction",
          filter: {
            [phase.metadataKey]: phase.metadataValues,
          },
          fraction: 100,
        },
      };

      newPhasesData.push(newPhase);
    });

    setPhasesData(newPhasesData);

    handlePhaseClick(`Phase ${convertDecimalToRoman(1)}`, newPhasesData[0]);
    fetchMetadataKeys();
    refreshDevices(1, newPhasesData[0].info.filter as Filters);

    handleModalClose();
  }

  function downloadTemplate(fileFormat: "csv" | "xlsx") {
    let template: {
      "Phase 1": string;
      "Phase 2": string;
    }[] = [
      {
        "Phase 1": serial_key ? serial_key : "id",
        "Phase 2": serial_key ? serial_key : "id",
      },
    ];

    for (let i = 0; i < 10; i++) {
      let sheetRow = {
        "Phase 1": String(i + 1),
        "Phase 2": String(i + 11),
      };

      template.push(sheetRow);
    }

    if (fileFormat === "xlsx") {
      const wb = xlsx.utils.book_new();
      const ws = xlsx.utils.json_to_sheet(template);

      xlsx.utils.book_append_sheet(wb, ws, "Template");
      xlsx.writeFile(wb, "template.xlsx");
    } else if (fileFormat === "csv") {
      const csvContent = template
        .map((row) => Object.values(row).join(","))
        .join("\n");

      const header = "Phase 1,Phase 2\n";
      const csvData = header + csvContent;

      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "template.csv";
      a.click();
      URL.revokeObjectURL(downloadUrl);
    }
  }

  return (
    <Modal
      className="dark"
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      size="tiny"
      trigger={
        <ActionButtonV3
          disabled={disable}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          label={"Device List"}
          icon={"upload"}
          selected={false}
          border_radius={"6px"}
          padding={"0.5rem 1rem"}
          style={{
            position: "absolute",
            right: "0",
            top: "0",
          }}
        />
      }
    >
      <Modal.Header>Upload Device List</Modal.Header>
      <Modal.Content>
        <div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: 700,
              paddingBottom: "18px",
            }}
          >
            Download the provided template to easily select your devices.
          </div>
          Click on{" "}
          <TemplateDownloaderButton
            id="downloadTemplateButton"
            onClick={() => downloadTemplate("csv")}
          >
            CSV
          </TemplateDownloaderButton>{" "}
          or{" "}
          <TemplateDownloaderButton
            id="downloadTemplateButton"
            onClick={() => downloadTemplate("xlsx")}
          >
            XLSX
          </TemplateDownloaderButton>{" "}
          to download the template file.
        </div>

        <ThinDivider
          style={{
            marginTop: "15px",
          }}
        />

        <Form>
          <Form.Field>
            <label>
              Please upload CSV/XLSX file that includes your device list. Ensure
              that the device list for each phase is organized into separate
              columns on a single sheet.
            </label>
            <div style={{ position: "relative", marginTop: "12px" }}>
              <StyledFileUploadButton
                fluid
                content="Select File"
                labelPosition="left"
                icon="file"
              />
              <StyledFileInput
                accept=".csv, .xlsx"
                type="file"
                id="file"
                onChange={handleFileChange}
              />
            </div>
            <label style={{ marginTop: "12px" }}>File Chosen: </label>
            {fileName ? fileName : "No File Chosen"}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          content="Close"
          id="closeButton"
          color="black"
          onClick={handleModalClose}
        />
        <Button
          primary
          content="Device List"
          id="addDeviceListButton"
          labelPosition="left"
          icon="plus"
          onClick={handleAddDeviceList}
          disabled={!parsedData}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default UploadDeviceListModal;

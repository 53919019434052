import { useEffect, DependencyList } from "react";

type AsyncEffectCallback = () => Promise<void>;

const useAsyncEffect = (
  effect: AsyncEffectCallback,
  dependencies: DependencyList
) =>
  useEffect(
    () => {
      effect();
    },
    // eslint-disable-next-line
    dependencies
  );

export default useAsyncEffect;

import React from "react";
import Plot from "react-plotly.js";
import { useUser } from "../../../../context/User.context";
import ThemeSchema from "../../../../theme/schema";
import { ChartContainer } from "../../../common/ActionsUtils";
import ActionV3Legend from "../ActionsV3/ActionV3Legend";

interface ActionStatusBarChartProps {
  selectedPhase: string;
  phasedData: any;
  height: number;
  width: number;
  textInfo: boolean;
}

export function ActionStatusBarChart(props: ActionStatusBarChartProps) {
  const { user } = useUser();
  const theme = user?.settings?.theme;
  let selectedData = props.phasedData[props.selectedPhase];
  let graphValues = [
    selectedData?.PendingApproval ?? 0,
    selectedData?.Scheduled ?? 0,
    selectedData?.Queued ?? 0,
    selectedData?.Initiated ?? 0,
    selectedData?.InProgress ?? 0,
    selectedData?.Completed ?? 0,
    selectedData?.Failed ?? 0,
  ];
  let statusCategories = [
    "Pending Approval",
    "Scheduled",
    "Queued",
    "Initiated",
    "In Progress",
    "Completed",
    "Failed",
  ];

  if (props.phasedData?.[props.selectedPhase]?.["Total"]) {
    return (
      <ChartContainer>
        {selectedData?.["Total"] === 0 ||
        selectedData?.["Total"] === undefined ? (
          <div>
            <p>
              <b>No devices</b>
            </p>
          </div>
        ) : (
          <div
            style={{
              marginRight: "30px",
            }}
          >
            <Plot
              data={[
                {
                  name: "Actions Summary",
                  x: graphValues, // Swap the x and y data
                  y: statusCategories, // Swap the x and y data
                  type: "bar",
                  orientation: "h", // This makes the bar chart horizontal
                  marker: {
                    color:
                      ThemeSchema.data[theme ?? "dark"]?.colors[
                        "chart_state-color-scheme-array"
                      ],
                  },
                  text: graphValues,
                  textposition: "auto",
                  hoverinfo: "none",
                },
              ]}
              layout={{
                height: props.height,
                width: props.width,
                margin: {
                  l: 100,
                  r: 0,
                  b: 20,
                  t: 20,
                },
                xaxis: {
                  color:
                    ThemeSchema.data[theme ?? "dark"]?.colors[
                      "chart-text-color"
                    ],
                  showline: true,
                  showgrid: false,
                  fixedrange: true,
                  tickmode: "auto",
                },
                yaxis: {
                  color:
                    ThemeSchema.data[theme ?? "dark"]?.colors[
                      "chart-text-color"
                    ],
                  showgrid: false,
                  fixedrange: true,
                  ticklen: 2,
                },
                paper_bgcolor: "transparent",
                plot_bgcolor: "transparent",
              }}
              config={{
                displayModeBar: false,
              }}
            />
          </div>
        )}
        <ActionV3Legend
          filterStatus={false}
          liStyles={{
            paddingBottom: "0.5rem",
          }}
        />
      </ChartContainer>
    );
  } else {
    return (
      <ChartContainer>
        <b>No devices</b>
      </ChartContainer>
    );
  }
}

import React, { useRef, useCallback } from "react";
import ActionButtonV3 from "../ActionsV3/ActionButton";
import ActionDisplayDropdown from "../ActionsV3/ActionDisplayDropdown";
import { Key } from "../../../../BytebeamClient";
import { useOutsideClickHandler } from "../../../../hooks/useOutsideClickHandler";

interface ActionDisplaySectionProps {
  showActionDisplayDropdown: boolean;
  setShowActionDisplayDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  allowedMetadataKeys: Key[];
  metadataKeysToShow: string[];
  setMetadataKeysToShow: React.Dispatch<React.SetStateAction<string[]>>;
}

const ActionDisplaySection: React.FC<ActionDisplaySectionProps> = ({
  showActionDisplayDropdown,
  setShowActionDisplayDropdown,
  allowedMetadataKeys,
  metadataKeysToShow,
  setMetadataKeysToShow,
}) => {
  const wrapperRef = useRef(null);
  const displayButtonRef = useRef(null);

  const toggleDropdown = () => {
    setShowActionDisplayDropdown((prev) => !prev);
  };

  const handleOutsideClick = useCallback(() => {
    setShowActionDisplayDropdown(false);
  }, [setShowActionDisplayDropdown]);

  useOutsideClickHandler([wrapperRef, displayButtonRef], handleOutsideClick);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        margin: "20px 0",
      }}
    >
      <div style={{ position: "relative" }}>
        <ActionButtonV3
          label="Display"
          icon="desktop"
          selected={showActionDisplayDropdown}
          onClick={toggleDropdown}
          ref={displayButtonRef}
        />
        {showActionDisplayDropdown && (
          <div ref={wrapperRef}>
            <ActionDisplayDropdown
              allowedMetadataKeys={allowedMetadataKeys}
              metadataKeysToShow={metadataKeysToShow}
              setMetadataKeysToShow={setMetadataKeysToShow}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionDisplaySection;

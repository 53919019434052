import React from "react";
import StyledSVGIcon from "./StyledSVGIcon";

const svgContent = `<svg width="16" height="14" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.25 13.8889L15.95 8.88889L8.25 3.88889V13.8889ZM6.6 20V17.7778H2.2C1.595 17.7778 1.07708 17.5602 0.64625 17.125C0.215417 16.6898 0 16.1667 0 15.5556V2.22222C0 1.61111 0.215417 1.08796 0.64625 0.652778C1.07708 0.217593 1.595 0 2.2 0H19.8C20.405 0 20.9229 0.217593 21.3538 0.652778C21.7846 1.08796 22 1.61111 22 2.22222V15.5556C22 16.1667 21.7846 16.6898 21.3538 17.125C20.9229 17.5602 20.405 17.7778 19.8 17.7778H15.4V20H6.6ZM2.2 15.5556H19.8V2.22222H2.2V15.5556Z" fill="white"/>
</svg>`;

type LiveActionsIconProps = {
  height: string;
  // Allowing all other props
  [x: string]: any;
};

function LiveActionsIcon(props: LiveActionsIconProps) {
  const { height, ...rest } = props;

  return <StyledSVGIcon height={height} {...rest} svgContent={svgContent} />;
}

export default LiveActionsIcon;

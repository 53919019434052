import React, { useState } from "react";
import { Button, Header, Image, Modal } from "semantic-ui-react";
import changelogImage from "./changelog.jpg";
import changelogImageLight from "./changelog-light.jpg";
import { useUser } from "../../../context/User.context";
import { Permission, UserSettings, currentReleaseId } from "../../../util";
import { updateUserSettings } from "../../../BytebeamClient";

type ChangeLogModalProps = {
  readonly currentTenant: string;
};

function ChangeLogModal(props: ChangeLogModalProps) {
  const { currentTenant } = props;
  const { user, getCurrentUser } = useUser();

  const [open, setOpen] = useState(false);

  const userSettings = user?.settings ?? ({} as UserSettings);
  const theme = userSettings?.theme ?? "dark";

  const permissions: Permission = user?.role?.permissions;

  async function handleModalHiding() {
    setOpen(false);
    if (currentReleaseId !== userSettings?.changelog_id) {
      try {
        const res = await updateUserSettings({
          settings: {
            ...userSettings,
            changelog_id: currentReleaseId,
          },
        });
        if (res !== undefined && res !== null) {
          await getCurrentUser();
        }
      } catch (error) {
        console.log("Error while updating user settings", error);
      }
    }
  }

  // Commented out because we don't want to show the changelog modal for now
  // useEffect(() => {
  //   let isModalOpen =
  //     currentReleaseId !== userSettings?.changelog_id &&
  //     window.location.host !== "localhost:3000";
  //   setOpen(isModalOpen);
  // }, [user?.settings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      style={{
        width: "96vw",
      }}
      className="dark"
      closeOnDimmerClick={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>Changelog</Modal.Header>
      <Modal.Content
        image
        style={{
          padding: "72px 24px",
        }}
      >
        <Modal.Description
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            width: "36%",
            paddingRight: "4%",
          }}
        >
          <Header style={{ border: "none", fontSize: "42px" }}>
            Inventory has found a new home in Actions tab
          </Header>
          <p
            style={{
              fontSize: "16px",
            }}
          >
            We've updated our layout! You can now find Firmware Files, Device
            Configurations, and Action Status under the 'Actions' tab, instead
            of 'Device Management'. Look for Firmware Files and Device
            Configurations inside the 'Inventory' section within 'Actions'.
          </p>
        </Modal.Description>
        <Image
          style={{
            width: "60%",
          }}
          rounded
          size="large"
          src={theme === "light" ? changelogImage : changelogImageLight}
        />
      </Modal.Content>
      <Modal.Actions
        style={{
          height: "fit-content",
        }}
      >
        <Button secondary onClick={() => handleModalHiding()}>
          Got it
        </Button>
        {permissions?.viewFirmwares || permissions?.viewDeviceConfigs ? (
          <Button
            positive
            content="Take me to Inventory"
            labelPosition="right"
            icon="checkmark"
            onClick={() => {
              handleModalHiding();
              setTimeout(() => {
                window.location.href = `/projects/${currentTenant}/actions/inventory`;
              }, 500);
            }}
          />
        ) : null}
      </Modal.Actions>
    </Modal>
  );
}

export default ChangeLogModal;

export const homepageOptions = [
  {
    key: "/devices",
    value: "/device-management",
    text: "Devices",
  },
  {
    key: "/firmwares",
    value: "/actions/inventory/firmware-files",
    text: "Manage Firmware Versions",
  },
  {
    key: "/configurations",
    value: "/actions/inventory/json-configurations",
    text: "Manage Device Configs",
  },
  {
    key: "/actions",
    value: "/actions",
    text: "View Actions",
  },
  {
    key: "/actions/live-actions",
    value: "/actions/live-actions",
    text: "View Actions History",
  },
  {
    key: "/actions/inventory",
    value: "/actions/inventory",
    text: "Manage Action Inventory",
  },
];

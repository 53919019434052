import React from "react";
import styled from "styled-components";
import { FilterState } from "./DevicesFilterUI";
import SelectedFilter from "../../../Actions/ActionsV3/SelectedFilter";
import { StyledNonBoldHeader } from "../../../Actions/NewAction/NewAction";
import { capitalizeFirstLetter } from "../../../util";

interface FiltersDisplayProps {
  filters: FilterState[];
  resetFilters: () => void;
  removeFilter: (filterId: string) => void;
}

const FilterButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FiltersTabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;

  > div {
    margin: 5px 0px;
  }
`;

/**
 * This component displays the filters applied to the devices and provides an option to reset them.
 */
const DevicesFiltersTabRow: React.FC<FiltersDisplayProps> = ({
  filters,
  resetFilters,
  removeFilter,
}) => {
  // Get the icon for the filter based on the type.
  const getFilterIcon = (filter: FilterState) => {
    if (filter.type === "state") {
      return "barcode";
    }
    return filter.field === "id" ? "info circle" : "tag";
  };

  // Group filters by type and field for better readability.
  const groupedFilters = Object.entries(
    filters.reduce(
      (accumulator, filter) => {
        const key = `${filter.type}:${filter.field}`;
        if (!accumulator[key]) {
          accumulator[key] = [];
        }
        accumulator[key].push(filter);
        return accumulator;
      },
      {} as Record<string, FilterState[]>
    )
  );

  /**
   * Retrieves the filter operator based on the provided filter and field filters.
   * If the filter field is not "id" and the filter type is "metadata" and there is only one field filter,
   * the operator "contains" is returned as we will be doing relative filtering.
   * Otherwise, the existing operator of the filter is returned.
   *
   * @param filter - The filter state object.
   * @param fieldFilters - An array of field filter states.
   * @returns The filter operator.
   */
  const getFilterOperator = (
    filter: FilterState,
    fieldFilters: FilterState[]
  ) => {
    if (
      filter.field !== "id" &&
      filter.type === "metadata" &&
      fieldFilters.length === 1
    ) {
      return "contains"; // when only one value exists for the filter field
    }
    return filter.operator;
  };

  return (
    <>
      {filters.length > 0 && (
        <FilterButtonsContainer>
          <FiltersTabsContainer>
            {groupedFilters.map(([_, fieldFilters], fieldGroupIndex) => (
              <React.Fragment key={`field-group-${fieldGroupIndex}`}>
                {/* Show & between different field groups */}
                {fieldGroupIndex > 0 && <p style={{ margin: "0 5px" }}>AND</p>}
                {fieldFilters.map((filter, filterIndex) => (
                  <React.Fragment key={`filter-${filter.id}`}>
                    {/* Show || between metadata filters of the same field, otherwise show & */}
                    {filterIndex > 0 && (
                      <p style={{ margin: "0 5px" }}>
                        {filter.type === "metadata" &&
                        fieldFilters[filterIndex - 1].type === "metadata"
                          ? "OR"
                          : "AND"}
                      </p>
                    )}
                    <SelectedFilter
                      key={filter.id}
                      filterName={capitalizeFirstLetter(filter.field)}
                      filterValues={[filter.value]}
                      filterConditionSign={getFilterOperator(
                        filter,
                        fieldFilters
                      )}
                      filterNameIcon={getFilterIcon(filter)}
                      onCrossClick={() => removeFilter(filter.id)}
                    />
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </FiltersTabsContainer>
          <div style={{ alignSelf: "center" }}>
            <StyledNonBoldHeader
              as="h3"
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                fontSize: "1.1rem",
                whiteSpace: "nowrap",
              }}
              className={`${
                filters.length === 0
                  ? "color-disabled"
                  : "selectable-item underline"
              }`}
              onClick={resetFilters}
            >
              Reset filters
            </StyledNonBoldHeader>
          </div>
        </FilterButtonsContainer>
      )}
    </>
  );
};

export default DevicesFiltersTabRow;

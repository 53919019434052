import { MicelioStatusTableData, MicelioStatusTableMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../../PanelDef";
import { Table } from "semantic-ui-react";
import { TableContainer } from "../../util";
import { stateIcons } from "../../map-util";

import styled from "styled-components";

const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusIcon = styled.img`
  width: 32px;
  margin-right: 16px;
`;

export class ViewMicelioStatusTable extends PanelViewComponent<
  MicelioStatusTableMetaData,
  MicelioStatusTableData
> {
  render() {
    return (
      <TableContainer>
        <div className="tableContentContainer">
          <Table singleLine compact selectable size="small" basic="very">
            <Table.Body>
              {Object.keys(stateIcons)?.map((state) => (
                <Table.Row key={state}>
                  <Table.Cell>
                    <Status>
                      <StatusIcon src={stateIcons[state]?.noalert} alt="" />
                      <div>{state}</div>
                    </Status>
                  </Table.Cell>

                  <Table.Cell>
                    {this.props.data?.statuses[state]?.count}
                  </Table.Cell>
                </Table.Row>
              ))}

              <Table.Row>
                <Table.Cell>
                  <Status>
                    <StatusIcon />
                    <div>Total</div>
                  </Status>
                </Table.Cell>

                <Table.Cell>{this.props.data?.total}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </TableContainer>
    );
  }
}

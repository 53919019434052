import {
  LedPanelColumn,
  LedPanelMetaData,
  offStateDefaultValues,
  onStateDefaultValues,
} from "./PanelDef";
import React, { RefObject, createRef } from "react";

import { Icon, Input, Label, Tab } from "semantic-ui-react";
import { SketchPicker } from "react-color";
import RelativeTimePicker from "../../Datetime/RelativeTimePicker";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  EditMetaRoot,
  EditMetaRow,
  EditMetaDropdown,
  DeleteIconInPanel,
  LabelHeading,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  ThinDivider,
  EditPanelFormContainer,
  DisplayValueDiv,
  StyledSketchPickerTrigger,
  StyledInputDiv,
  DraggableItem,
  EditMetaRowDraggable,
  ReorderIconInPanel,
  ToggleLabel,
} from "../util";
import { TableInfo } from "../../../../../BytebeamClient";
import SelectedDashboardFilter from "../../../../common/SelectedDashboardFilter";
import { ErrorMessage } from "../../../Settings/roles/CreateOrEditRoleModal";
import { AddAllColText } from "../../../../common/commonStyledComps";
import ToggleSwitch from "../../../common/ToggleSwitch";
import { DashboardType } from "../../EditDashboardModal";

export type EditLedPanelMetaProps = {
  panelMeta: LedPanelMetaData;
  tables: TableInfo;
};

export type ErrorMessageState = {
  showActiveValidationMessage: boolean;
  showInActiveValidationMessage: boolean;
  activeStateErrorMessage: string;
  inActiveStateErrorMessage: string;
};

export type EditLedPanelMetaState = {
  table: string;
  columns: Array<LedPanelColumn>;
  onStateColor: string;
  offStateColor: string;
  onStateTextList: string[];
  offStateTextList: string[];
  displayOnStatePicker: boolean;
  displayOffStatePicker: boolean;
  ledSize: number;
  error: boolean;
  fleetTableView: boolean;
};

// Function to check if a value is a default value
const isDefaultValue = (value, defaultValues) => {
  return defaultValues.includes(value);
};

export class EditLedPanelMeta extends PanelEditComponent<
  LedPanelMetaData,
  EditLedPanelMetaState & ErrorMessageState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  durationRef = React.createRef<RelativeTimePicker>();
  prefixRef = React.createRef<HTMLInputElement>();
  suffixRef = React.createRef<HTMLInputElement>();
  ledSizeRef = React.createRef<HTMLInputElement>();

  activeInputRef: RefObject<HTMLInputElement> = createRef();
  inactiveInputRef: RefObject<HTMLInputElement> = createRef();

  constructor(props: EditLedPanelMetaProps) {
    super(props);

    this.state = {
      table: props.panelMeta?.table || "",
      columns: props.panelMeta?.columns || [],
      onStateColor: this.props.panelMeta.onStateColor
        ? this.props.panelMeta.onStateColor
        : "#39fa39",
      offStateColor: this.props.panelMeta.offStateColor
        ? this.props.panelMeta.offStateColor
        : "#fa1c1c",
      onStateTextList: this.props.panelMeta.onStateTextList
        ? this.props.panelMeta.onStateTextList
        : onStateDefaultValues,
      offStateTextList: this.props.panelMeta.offStateTextList
        ? this.props.panelMeta.offStateTextList
        : offStateDefaultValues,
      displayOnStatePicker: false,
      displayOffStatePicker: false,
      error: false,
      ledSize: props.panelMeta?.ledSize || 30,
      showActiveValidationMessage: false,
      showInActiveValidationMessage: false,
      activeStateErrorMessage: "",
      inActiveStateErrorMessage: "",
      fleetTableView: props.panelMeta.fleetTableView ?? false,
    };
  }

  getPanelMeta(type): PartialMetaData<LedPanelMetaData> {
    const meta: LedPanelMetaData = {
      type: "led_panel",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table || "",
      columns: this.state.columns,
      ledSize: this.state.ledSize || 30,
      onStateColor: this.state.onStateColor
        ? this.state.onStateColor
        : "#39fa39",
      onStateTextList: this.state.onStateTextList
        ? this.state.onStateTextList
        : onStateDefaultValues,
      offStateTextList: this.state.offStateTextList
        ? this.state.offStateTextList
        : offStateDefaultValues,
      offStateColor: this.state.offStateColor
        ? this.state.offStateColor
        : "#fa1c1c",
      fleetTableView: this.state.fleetTableView,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: LedPanelMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (!(!!meta.table && meta.columns.length > 0) && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return !!meta.table && meta.columns.length > 0;
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      columns: [],
    });
  }

  removeColumn(index: number) {
    this.setState({
      columns: [
        ...this.state.columns.slice(0, index),
        ...this.state.columns.slice(index + 1),
      ],
    });
  }

  setColumn(index: number, key: string, value?: any) {
    const v = { ...this.state.columns[index] };

    v[key] = value;

    this.setState({
      columns: [
        ...this.state.columns.slice(0, index),
        v,
        ...this.state.columns.slice(index + 1),
      ],
    });
  }

  addColumn(value?: any) {
    if (value) {
      this.setState({
        columns: [
          ...this.state.columns,
          { name: value, prefix: "", suffix: "" },
        ],
      });
    }
  }

  handlePickerClick(e, pickerVal) {
    e.stopPropagation();
    pickerVal === "on"
      ? this.setState({
          displayOnStatePicker: !this.state.displayOnStatePicker,
        })
      : this.setState({
          displayOffStatePicker: !this.state.displayOffStatePicker,
        });
  }

  addActiveState(e: any) {
    if (e.key === "Enter") {
      e.preventDefault(); // Ensure it is only this code that runs

      const inputActiveStateValue = e.target.value.trim();

      // Converting the values to lower case before checking.
      if (
        this.state.offStateTextList.some(
          (text) =>
            text.trim().toLowerCase() === inputActiveStateValue.toLowerCase()
        )
      ) {
        this.setState({
          showActiveValidationMessage: true,
          activeStateErrorMessage:
            "This state is already added in 'Inactive state'.",
        });
      } else if (
        this.state.onStateTextList.some(
          (text) =>
            text.trim().toLowerCase() === inputActiveStateValue.toLowerCase()
        )
      ) {
        this.setState({
          showActiveValidationMessage: true,
          activeStateErrorMessage: "value already exists!",
        });
      } else if (inputActiveStateValue.length > 0) {
        this.setState({
          onStateTextList: [
            ...this.state.onStateTextList,
            inputActiveStateValue,
          ],
        });
        // Clear the input field
        if (this.activeInputRef.current) {
          this.activeInputRef.current.value = "";
        }

        this.setState({
          showActiveValidationMessage: false,
          activeStateErrorMessage: "",
        });
      }
    }
  }

  removeActiveState(value: string) {
    this.setState({
      onStateTextList: this.state.onStateTextList.filter(
        (text) => text !== value
      ),
    });
  }

  addInactiveState(e: any) {
    if (e.key === "Enter") {
      const inputInactiveStateValue = e.target.value.trim();

      e.preventDefault(); // Ensure it is only this code that runs
      if (
        this.state.onStateTextList.some(
          (text) =>
            text.trim().toLowerCase() === inputInactiveStateValue.toLowerCase()
        )
      ) {
        this.setState({
          showInActiveValidationMessage: true,
          inActiveStateErrorMessage:
            "This state is already added in 'Active state'.",
        });
      } else if (
        this.state.offStateTextList.some(
          (text) =>
            text.trim().toLowerCase() === inputInactiveStateValue.toLowerCase()
        )
      ) {
        this.setState({
          showInActiveValidationMessage: true,
          inActiveStateErrorMessage: "value already exists!",
        });
      } else if (inputInactiveStateValue.length > 0) {
        this.setState({
          offStateTextList: [
            ...this.state.offStateTextList,
            inputInactiveStateValue,
          ],
        });
        // Clear the input field
        if (this.inactiveInputRef.current) {
          this.inactiveInputRef.current.value = "";
        }

        this.setState({
          showInActiveValidationMessage: false,
          inActiveStateErrorMessage: "",
        });
      }
    }
  }

  removeInactiveState(value: string) {
    this.setState({
      offStateTextList: this.state.offStateTextList.filter(
        (text) => text !== value
      ),
    });
  }

  handleSliderChange = (event) => {
    this.setState({ ledSize: parseFloat(event.target.value) });
  };

  addAllColumns(columnOptions) {
    let remainingColumns: LedPanelColumn[] = columnOptions.map((c) => ({
      name: c.value,
      prefix: "",
      suffix: "",
    }));

    this.setState({
      columns: [...this.state.columns, ...remainingColumns],
    });
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedColumns = Array.from(this.state.columns);
    const [removed] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, removed);

    this.setState({
      columns: reorderedColumns,
    });
  };

  fleetTableViewToggle() {
    const toggled = !this.state.fleetTableView;
    this.setState({ fleetTableView: toggled });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnOptions: Array<{ key: string; value: string; text: string }> = [];

    if (!!this.state.table) {
      const columns = this.props?.tables?.[this.state.table]?.filter(
        (column: { name: string; type: string }) =>
          this.state.columns?.filter((c) => c?.name === column?.name)
            ?.length === 0
      );

      columnOptions = columns
        ?.filter((f: { name: string; type: string }) => f?.name !== "timestamp")
        ?.map((f: { name: string; type: string }) => {
          return {
            key: f?.name,
            text: f?.name,
            value: f?.name,
          };
        });
    }

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>

              <ThinDivider />

              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table || "Select Stream"}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.state.table || ""}
                    value={this.state.table || ""}
                    elementid={"LEDPanelTable"}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
              </EditMetaRow>

              <ThinDivider />

              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {this.state.columns.map((column, index) => (
                        <Draggable
                          key={column.name}
                          draggableId={column.name}
                          index={index}
                        >
                          {(provided) => (
                            <DraggableItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {/* Your column rendering logic is here */}

                              <EditMetaRowDraggable key={column.name}>
                                <StyledInputDiv width="50%" marginTop="10px">
                                  <EditMetaDropdown
                                    placeholder="Select Field"
                                    text={column.name}
                                    search
                                    selection
                                    options={columnOptions}
                                    onChange={(_, data) =>
                                      this.setColumn(index, "name", data.value)
                                    }
                                    defaultValue={column.name}
                                    staticLabel
                                  />
                                </StyledInputDiv>

                                <DeleteIconInPanel
                                  color="red"
                                  onClick={() => this.removeColumn(index)}
                                >
                                  <Icon name="minus" />
                                </DeleteIconInPanel>

                                <ReorderIconInPanel
                                  {...provided.dragHandleProps}
                                >
                                  <Icon name="sort" />
                                </ReorderIconInPanel>
                              </EditMetaRowDraggable>
                            </DraggableItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Field"
                    text={"Select Field"}
                    defaultValue={""}
                    value={""}
                    search
                    selection
                    options={columnOptions}
                    onChange={(_, data) => this.addColumn(data.value)}
                    disabled={columnOptions?.length === 0}
                    elementid={"LEDPanelTableColumn"}
                    error={this.state.error && this.state.columns?.length === 0}
                  />
                </StyledInputDiv>
                {columnOptions?.length === 0 ? null : (
                  <AddAllColText
                    onClick={() => {
                      this.addAllColumns(columnOptions);
                    }}
                  >
                    Add all columns
                  </AddAllColText>
                )}
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              {this.props.dashboardType === DashboardType.FleetDashboard ? (
                <>
                  <EditMetaRow>
                    <ToggleLabel style={{ fontWeight: "bold" }}>
                      Enable Table View (Fleet Dashboard)
                    </ToggleLabel>
                    <ToggleSwitch
                      id="fleetTableView"
                      defaultChecked={this.props.panelMeta.fleetTableView}
                      disabled={false}
                      Text={["Yes", "No"]}
                      onToggleChange={() => {
                        this.fleetTableViewToggle();
                      }}
                    />
                  </EditMetaRow>
                  <ThinDivider />
                </>
              ) : (
                <></>
              )}
              <EditMetaRow>
                <LabelHeading>Choose Active State Color</LabelHeading>
                <StyledSketchPickerTrigger
                  id="default_color"
                  style={{
                    backgroundColor: `${this.state.onStateColor}`,
                  }}
                  onClick={(e) => this.handlePickerClick(e, "on")}
                />
                <div
                  style={{
                    display: this.state.displayOnStatePicker ? "block" : "none",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SketchPicker
                    color={this.state.onStateColor}
                    onChange={(color) => {
                      this.setState({ onStateColor: color.hex });
                    }}
                  />
                </div>
              </EditMetaRow>

              <EditMetaRow>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "15px",
                    width: "100%",
                  }}
                >
                  {this.state.onStateTextList.map((text, key) => {
                    return (
                      <SelectedDashboardFilter
                        disabled={isDefaultValue(text, onStateDefaultValues)}
                        name={text}
                        onRemoveClick={() => this.removeActiveState(text)}
                        marginLeft="10px"
                      />
                    );
                  })}
                </div>

                <EditMetaRow>
                  <Input className="add-panel-title-input" labelPosition="left">
                    <Label>Add Active State</Label>
                    <input
                      type="text"
                      placeholder={"Add state value and press Enter"}
                      onChange={() => {
                        if (this.state.showActiveValidationMessage)
                          this.setState({
                            showActiveValidationMessage: false,
                          });
                      }}
                      onKeyPress={(e) => {
                        this.addActiveState(e);
                      }}
                      ref={this.activeInputRef}
                    />
                  </Input>
                  {this.state.showActiveValidationMessage &&
                    this.state.activeStateErrorMessage !== "" && (
                      <ErrorMessage style={{ fontWeight: 700 }}>
                        {this.state.activeStateErrorMessage}
                      </ErrorMessage>
                    )}
                </EditMetaRow>
              </EditMetaRow>

              <EditMetaRow>
                <LabelHeading>Choose Inactive State Color</LabelHeading>
                <StyledSketchPickerTrigger
                  id="default_color"
                  style={{
                    backgroundColor: `${this.state.offStateColor}`,
                  }}
                  onClick={(e) => this.handlePickerClick(e, "off")}
                />
                <div
                  style={{
                    display: this.state.displayOffStatePicker
                      ? "block"
                      : "none",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SketchPicker
                    color={this.state.offStateColor}
                    onChange={(color) => {
                      this.setState({ offStateColor: color.hex });
                    }}
                  />
                </div>
              </EditMetaRow>

              <EditMetaRow>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "15px",
                    width: "100%",
                  }}
                >
                  {this.state.offStateTextList.flatMap((text, key) =>
                    text !== ""
                      ? [
                          <SelectedDashboardFilter
                            key={key}
                            disabled={isDefaultValue(
                              text,
                              offStateDefaultValues
                            )}
                            name={text}
                            onRemoveClick={() => this.removeInactiveState(text)}
                            marginLeft="10px"
                          />,
                        ]
                      : []
                  )}
                </div>

                <EditMetaRow>
                  <Input className="add-panel-title-input" labelPosition="left">
                    <Label>Add Inactive State</Label>
                    <input
                      type="text"
                      placeholder={"Add State value and press Enter"}
                      onChange={() => {
                        if (this.state.showInActiveValidationMessage)
                          this.setState({
                            showInActiveValidationMessage: false,
                          });
                      }}
                      onKeyPress={(e) => {
                        this.addInactiveState(e);
                      }}
                      ref={this.inactiveInputRef}
                    />
                  </Input>
                  {this.state.showInActiveValidationMessage &&
                    this.state.inActiveStateErrorMessage !== "" && (
                      <ErrorMessage style={{ fontWeight: 700 }}>
                        {this.state.inActiveStateErrorMessage}
                      </ErrorMessage>
                    )}
                </EditMetaRow>
              </EditMetaRow>

              <EditMetaRow>
                <LabelHeading> LED Size </LabelHeading>
                <DisplayValueDiv>{this.state.ledSize}</DisplayValueDiv>
                <div style={{ width: "100%", padding: "5px 35px 0px 5px" }}>
                  <input
                    style={{ width: "100%", height: "5%" }}
                    id="typeinp"
                    type="range"
                    min="10"
                    max="150"
                    value={this.state.ledSize}
                    onChange={this.handleSliderChange}
                    step="1"
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <div
          onClick={() => {
            this.setState({
              displayOffStatePicker: false,
              displayOnStatePicker: false,
            });
          }}
        >
          <Tab menu={{}} panes={panes} renderActiveOnly={false} />
        </div>
      </EditMetaRoot>
    );
  }
}

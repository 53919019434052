import { HistogramMetaData } from "./PanelDef";
import React from "react";

import { Tab } from "semantic-ui-react";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  ThinDivider,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  LabelHeading,
  DisplayValueDiv,
  EditPanelFormContainer,
  filterNumericalTypeColumnOptions,
  StyledInputDiv,
} from "../util";
import { TableInfo } from "../../../../../BytebeamClient";

export type EditHistogramMetaProps = {
  panelMeta: HistogramMetaData;
  tables: TableInfo;
};

export type EditHistogramState = {
  table: string;
  column: string;
  numBins: number;
  error: boolean;
};

export class EditHistogramMeta extends PanelEditComponent<
  HistogramMetaData,
  EditHistogramState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  numBinsRef = React.createRef<HTMLInputElement>();

  constructor(props: EditHistogramMetaProps) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        table: props.panelMeta.table,
        column: props.panelMeta.column,
        numBins: props.panelMeta.numBins,
        error: false,
      };
    } else {
      this.state = {
        table: "",
        column: "",
        numBins: 10,
        error: false,
      };
    }
  }

  getPanelMeta(type): PartialMetaData<HistogramMetaData> {
    const meta: HistogramMetaData = {
      type: "histogram",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      table: this.state.table || "",
      column: this.state.column || "",
      numBins: this.state.numBins || 10,
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: HistogramMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (!(!!meta.table && !!meta.column) && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return !!meta.table && !!meta.column;
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      column: "",
    });
  }

  setColumn(_event, data) {
    this.setState({
      column: data.value,
    });
  }

  handleSliderChange = (event) => {
    this.setState({ numBins: parseFloat(event.target.value) });
  };

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let columnTypeOptions: Array<{ key: string; value: string; text: string }> =
      [];

    if (!!this.state.table) {
      columnTypeOptions = filterNumericalTypeColumnOptions(
        this.props.tables[this.state.table]
      ).map((f: { name: string; type: string }) => {
        return {
          key: f.name,
          text: f.name,
          value: f.name,
        };
      });
    }

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>

              <ThinDivider />

              <EditMetaRow>
                <StyledInputDiv width="48%" marginTop="20px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.state.table}
                    value={this.state.table}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%" marginTop="20px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Field"
                    text={this.state.column || "Select Field"}
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={columnTypeOptions}
                    onChange={this.setColumn.bind(this)}
                    defaultValue={this.state.column || ""}
                    value={this.state.column || ""}
                    error={this.state.error && !this.state.column}
                  />
                </StyledInputDiv>

                <EditMetaRow>
                  <LabelHeading> Number of Bins </LabelHeading>
                  <DisplayValueDiv>{this.state.numBins}</DisplayValueDiv>
                  <div style={{ width: "100%", padding: "5px 35px 0px 5px" }}>
                    <input
                      style={{ width: "100%", height: "5%" }}
                      id="typeinp"
                      type="range"
                      min="1"
                      max="250"
                      value={this.state.numBins}
                      onChange={this.handleSliderChange}
                      step="1"
                    />
                  </div>
                </EditMetaRow>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}

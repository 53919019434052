import moment from "moment";
import React from "react";
import styled from "styled-components";
import { ActionStatusType } from "../../../../BytebeamClient";
import { convertDecimalToRoman, getSelectedPhaseIndex } from "../util";

interface ActionsMoreInfoCardProps {
  readonly action: ActionStatusType;
  readonly scheduledAt: Date | number | undefined;
  readonly selectedPhase: string;
}

const ActionMoreInfoCardStyle = styled.div`
  padding: 0px 10px;
  border-radius: 10px;
  width: 100%;

  .action-row {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .action-label {
    font-weight: bold;
    width: 120px;
    text-align: left;
    margin-right: 10px;
  }

  .action-value {
    text-align: left;
    white-space: nowrap;
    flex: 1;
  }
`;

const ActionsMoreInfoCard = (props: ActionsMoreInfoCardProps) => {
  const { action, scheduledAt, selectedPhase } = props;
  const previousPhaseNum = getSelectedPhaseIndex(selectedPhase);

  const getPhaseSuccessPercentage = (): number | undefined => {
    let phaseIndex = action?.schedule?.phases.findIndex(
      (phase) => phase.name === selectedPhase
    );

    if (phaseIndex >= 0) {
      let returnSuccessPercent =
        action?.schedule?.phases[phaseIndex].trigger_on?.success_percentage;
      return returnSuccessPercent;
    }
  };
  const scheduleSuccess = getPhaseSuccessPercentage();

  const getAbortPhasePercentage = (): number | undefined => {
    let phaseIndex = action?.schedule?.phases.findIndex(
      (phase) => phase.name === selectedPhase
    );

    if (phaseIndex >= 0) {
      let returnAbortPercent =
        action?.schedule?.phases[phaseIndex].trigger_on?.failure_percentage;
      return returnAbortPercent;
    }
  };
  const abortPhasePercentage = getAbortPhasePercentage();

  return (
    <ActionMoreInfoCardStyle>
      <div className="action-row">
        <span className="action-label">Action Type</span>
        <span className="action-value">: {action.type}</span>
      </div>
      <div className="action-row">
        <span className="action-label">Action ID</span>
        <span className="action-value">: {action.action_id}</span>
      </div>
      <div className="action-row">
        <span className="action-label">Triggered At</span>
        <span className="action-value">
          : {moment(action.created_at).format("ddd, MMM Do YYYY, HH:mm:ss")}
        </span>
      </div>
      {scheduledAt && (
        <div className="action-row">
          <span className="action-label">Scheduled At</span>
          <span className="action-value">
            : {moment(scheduledAt).format("ddd, MMM Do YYYY, HH:mm:ss")}
          </span>
        </div>
      )}
      {scheduleSuccess && (
        <div className="action-row">
          <span className="action-label">Scheduled On</span>
          <span className="action-value">
            :{" "}
            {`${scheduleSuccess}% success of previous phase${previousPhaseNum > 0 ? "(Phase " + convertDecimalToRoman(previousPhaseNum) + ")" : ""}`}
          </span>
        </div>
      )}
      {typeof abortPhasePercentage === "number" && (
        <div className="action-row">
          <span className="action-label">Abort On</span>
          <span className="action-value">
            :{" "}
            {`${abortPhasePercentage}% failure of previous phase${previousPhaseNum > 0 ? "(Phase " + convertDecimalToRoman(previousPhaseNum) + ")" : ""}`}
          </span>
        </div>
      )}
      {typeof action?.schedule?.retry_on_failure_until === "number" && (
        <div className="action-row">
          <span className="action-label">Auto Retry</span>
          <span className="action-value">
            :{" "}
            {action?.schedule?.retry_on_failure_until === 0 ? "False" : "True"}
          </span>
        </div>
      )}
      {action?.schedule?.end_timestamp && (
        <div className="action-row">
          <span className="action-label">Timeout at</span>
          <span className="action-value">
            :{" "}
            {action?.schedule?.end_timestamp === 4110201000000
              ? "Never"
              : moment(action?.schedule?.end_timestamp).format(
                  "ddd, MMM Do YYYY, HH:mm:ss"
                )}
          </span>
        </div>
      )}
    </ActionMoreInfoCardStyle>
  );
};

export default ActionsMoreInfoCard;

import { MicelioStatsPanelMetaData } from "./PanelDef";
import React from "react";

import { PanelEditComponent, PartialMetaData } from "../../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  EditPanelFormContainer,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  ToggleLabel,
  LabelHeading,
  DisplayValueDiv,
  StyledInputDiv,
  ThinDivider,
} from "../../util";
import { Tab } from "semantic-ui-react";
import { capitalizeFirstLetter } from "../../../../util";
import ToggleSwitch from "../../../../common/ToggleSwitch";

type MiceloStatsPanelState = {
  subType: string;
  textSize: number;
  autoTextSize: boolean;
  table: string;
  error: boolean;
};

const subTypes = [
  "total_runtime",
  "total_idle_time",
  "total_pause_time",
  "total_distance",
  "daily_average_distance",
  "monthly_average_distance",
  "bussiest_hour_kms",
  "bussiest_hour_mins",
  "bussiest_hour_vehicles",
  "most_idle_hour_mins",
  "most_idle_hour_kms",
  "most_idle_hour_vehicles",
  "bussiest_vehicle_kms",
  "bussiest_vehicle_mins",
  "most_idle_vehicle_kms",
  "most_idle_vehicle_mins",
  "max_pause_interval",
  "total_vehicles",
  "fleet_asset_utilization",
  "fleet_utilization",
];

// sub types that are querying from pg "windows" table
const subTypesQueryingFromWindowsTable = [
  "total_runtime",
  "total_idle_time",
  "total_pause_time",
  "bussiest_hour_mins",
  "most_idle_hour_mins",
  "most_idle_hour_vehicles",
  "bussiest_vehicle_mins",
  "most_idle_vehicle_mins",
  "max_pause_interval",
  "fleet_utilization",
];

// sub types that are querying from clickhouse "device_shadow_latest" by default
const subTypesQueryingFromDeviceShadowLatestTable = [
  "total_vehicles",
  "fleet_asset_utilization",
];

const subTypeOptions = subTypes.map((st) => {
  return {
    key: st,
    value: st,
    text: capitalizeFirstLetter(st),
  };
});

const defaultTable = (subType) => {
  if (subTypesQueryingFromDeviceShadowLatestTable.includes(subType)) {
    return "device_shadow_latest";
  } else if (subTypesQueryingFromWindowsTable.includes(subType)) {
    return "windows";
  } else if (subType === "") return "";
  else return "device_data_v3_parsed";
};

export class EditMicelioStatsPanelMeta extends PanelEditComponent<
  MicelioStatsPanelMetaData,
  MiceloStatsPanelState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  deviceDashboardIdRef = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        subType: props.panelMeta.subType,
        textSize: props.panelMeta.textSize,
        autoTextSize: props.panelMeta.autoTextSize,
        table: props.panelMeta.table || defaultTable(props.panelMeta.subType),
        error: false,
      };
    } else {
      this.state = {
        subType: subTypeOptions[0].value,
        textSize: 50,
        autoTextSize: true,
        table: defaultTable(subTypeOptions[0].value),
        error: false,
      };
    }
  }

  getPanelMeta(type): PartialMetaData<MicelioStatsPanelMetaData> {
    const meta: MicelioStatsPanelMetaData = {
      type: "micelio_stats_panel",
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      id: this.props.panelMeta.id,
      table: this.state.table,
      subType: this.state.subType,
      deviceDashboardId: this.deviceDashboardIdRef.current?.value || "",
      textSize: this.state.textSize || 50,
      autoTextSize: this.state.autoTextSize,
      suffix: "", // not used
      rowsPerPage: 20, // not used
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: MicelioStatsPanelMetaData, type?: string) {
    const isValid = Boolean(meta.table);

    // type is used here to differentiate between submit and refresh in edit mode
    if (!isValid && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return isValid;
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
    });
  }

  setSubType(_event, data) {
    this.setState({
      subType: data.value,
      table: defaultTable(data.value),
    });
  }

  handleSliderChange = (event) => {
    this.setState({ textSize: parseFloat(event.target.value) });
  };

  autoTextSizeToggle() {
    const toggled = !this.state.autoTextSize;
    this.setState({ autoTextSize: toggled });
  }

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const deviceDashboardId = this.props.panelMeta.deviceDashboardId || "";
    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                    error={this.state.error && !this.titleRef.current?.value}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <div style={{ width: "50%", marginTop: "10px" }}>
                  <EditAnimatedMetaDropdown
                    placeholder="Choose Type"
                    text={capitalizeFirstLetter(this.state.subType)}
                    fluid
                    search
                    selection
                    options={subTypeOptions}
                    onChange={this.setSubType.bind(this)}
                    defaultValue={this.state.subType}
                    elementID={`tableLastValue`}
                  />
                </div>
              </EditMetaRow>
              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    fluid
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    text={this.state.table || "Select Stream"}
                    defaultValue={this.state.table}
                    value={this.state.table}
                    elementID={`tableMicelioStats`}
                    disabled={
                      subTypesQueryingFromWindowsTable.includes(
                        this.state.subType
                      ) || this.state.subType === ""
                    }
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <div style={{ width: "48%" }}>
                  <EditAnimatedMetaInput
                    defaultRef={this.deviceDashboardIdRef}
                    defaultValue={deviceDashboardId}
                    label="Device dashboard ID"
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              <EditMetaRow>
                <p>* Below settings are not applicable for mobile layout</p>
              </EditMetaRow>
              <EditMetaRow>
                <ToggleLabel style={{ fontWeight: "bold" }}>
                  Auto resize the Text
                </ToggleLabel>
                <ToggleSwitch
                  id="showRangeZeroToggle"
                  defaultChecked={this.state.autoTextSize}
                  disabled={false}
                  Text={["Yes", "No"]}
                  onToggleChange={() => {
                    this.autoTextSizeToggle();
                  }}
                />
              </EditMetaRow>
              <EditMetaRow
                style={{
                  display: this.state.autoTextSize ? "none" : "flex",
                  marginLeft: "10px",
                }}
              >
                <LabelHeading style={{ fontWeight: "normal" }}>
                  Text Size
                </LabelHeading>
                <DisplayValueDiv>{this.state.textSize}</DisplayValueDiv>
                <div style={{ width: "100%", padding: "5px 35px 0px 5px" }}>
                  <input
                    style={{ width: "100%", height: "5%" }}
                    id="typeinp"
                    type="range"
                    min="15"
                    max="200"
                    value={this.state.textSize}
                    onChange={this.handleSliderChange}
                    step="1"
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}

import { TrackDevicesMetaData } from "./PanelDef";
import React from "react";

import { Tab, Radio } from "semantic-ui-react";
import {
  PanelEditComponent,
  EditComponentProps,
  PartialMetaData,
} from "../PanelDef";
import {
  EditMetaRow,
  EditMetaRoot,
  EditAnimatedMetaInput,
  EditAnimatedMetaDropdown,
  ThinDivider,
  filterNumericalTypeColumnOptions,
  EditPanelFormContainer,
  StyledInputDiv,
} from "../util";

export type EditMapWidgetMetaState = {
  table: string;
  latitude_column: string;
  longitude_column: string;
  trafficLayerFlag: boolean;
  satelliteLayerFlag: boolean;
  title: string;
  description: string;
  deviceDashboardId: string;
  error: boolean;
};

export class EditTrackDevicesMeta extends PanelEditComponent<
  TrackDevicesMetaData,
  EditMapWidgetMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  // deviceDashboardIdRef = React.createRef<HTMLInputElement>();

  constructor(props: EditComponentProps<TrackDevicesMetaData>) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        table: props.panelMeta.table,
        latitude_column: props.panelMeta.latitude_column,
        longitude_column: props.panelMeta.longitude_column,
        trafficLayerFlag: props.panelMeta.trafficLayerFlag
          ? props.panelMeta.trafficLayerFlag
          : false,
        satelliteLayerFlag: props.panelMeta.satelliteLayerFlag
          ? props.panelMeta.satelliteLayerFlag
          : false,
        title: props.panelMeta.title,
        description: props.panelMeta.description,
        deviceDashboardId: props.panelMeta.device_dashboard_id,
        error: false,
      };
    } else {
      this.state = {
        table: "",
        latitude_column: "",
        longitude_column: "",
        trafficLayerFlag: false,
        satelliteLayerFlag: false,
        title: "",
        description: "",
        deviceDashboardId: "",
        error: false,
      };
    }
  }

  getPanelMeta(type): PartialMetaData<TrackDevicesMetaData> {
    const meta: TrackDevicesMetaData = {
      type: "track_devices",
      id: this.props.panelMeta.id,
      // title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      title: this.state.title,
      table: this.state.table || "",
      latitude_column: this.state.latitude_column || "",
      longitude_column: this.state.longitude_column || "",
      device_dashboard_id: this.state.deviceDashboardId,
      trafficLayerFlag: this.state.trafficLayerFlag,
      satelliteLayerFlag: this.state.satelliteLayerFlag,
    };
    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: TrackDevicesMetaData, type?: string) {
    const isValid =
      !!meta.table && !!meta.latitude_column && !!meta.longitude_column;

    // type is used here to differentiate between submit and refresh in edit mode
    if (!isValid && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }
    return isValid;
  }

  setTable(_event, data) {
    this.setState({
      table: data.value,
      latitude_column: "",
      longitude_column: "",
    });
  }

  setLatitudeColumn(_event, data) {
    this.setState({
      latitude_column: data.value,
    });
  }

  setLongitudeColumn(_event, data) {
    this.setState({
      longitude_column: data.value,
    });
  }

  setTitleColumn(_event) {
    const val = _event.target.value;
    this.setState({
      title: val,
    });
  }

  setDashboardIdColumn(_event, data) {
    this.setState({
      deviceDashboardId: data.value,
    });
  }

  render() {
    const description = this.props.panelMeta.description;
    const { title } = this.state;

    const tableOptions = Object.keys(this.props.tables).map((t) => {
      return {
        key: t,
        text: t,
        value: t,
      };
    });

    let latitudeColumnOptions: Array<{
      key: string;
      value: string;
      text: string;
    }> = [];
    let longitudeColumnOptions: Array<{
      key: string;
      value: string;
      text: string;
    }> = [];

    if (!!this.state.table) {
      latitudeColumnOptions = filterNumericalTypeColumnOptions(
        this.props.tables[this.state.table]
      ).map((f: { name: string; type: string }) => {
        return {
          key: f.name,
          text: f.name,
          value: f.name,
        };
      });
    }

    if (!!this.state.table) {
      longitudeColumnOptions = filterNumericalTypeColumnOptions(
        this.props.tables[this.state.table]
      ).map((f: { name: string; type: string }) => {
        return {
          key: f.name,
          text: f.name,
          value: f.name,
        };
      });
    }

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key={"general"}>
            <EditPanelFormContainer>
              <div style={{ width: "100%", marginTop: "16px" }} />
              <EditMetaRow>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    autoFocus={true}
                    defaultRef={this.titleRef}
                    defaultValue={title}
                    label="Title"
                    onChangeEvent={(evt) => this.setTitleColumn(evt)}
                  />
                </StyledInputDiv>
                <StyledInputDiv width="48%">
                  <EditAnimatedMetaInput
                    defaultRef={this.descriptionRef}
                    defaultValue={description}
                    label="Description"
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <StyledInputDiv width="50%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Select Stream"
                    text={this.state.table || "Select Stream"}
                    search
                    selection
                    options={tableOptions}
                    onChange={this.setTable.bind(this)}
                    defaultValue={this.state.table}
                    value={this.state.table}
                    elementid={"TrackDevicesTable"}
                    error={this.state.error && !this.state.table}
                  />
                </StyledInputDiv>
              </EditMetaRow>
              <EditMetaRow>
                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Latitude Field"
                    text={this.state.latitude_column || "Latitude Field"}
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={latitudeColumnOptions}
                    onChange={this.setLatitudeColumn.bind(this)}
                    defaultValue={this.state.latitude_column || ""}
                    value={this.state.latitude_column || ""}
                    elementid={"TrackDevicesColumn"}
                    error={this.state.error && !this.state.latitude_column}
                  />
                </StyledInputDiv>

                <StyledInputDiv width="48%" marginTop="10px">
                  <EditAnimatedMetaDropdown
                    placeholder="Longitude Field"
                    text={this.state.longitude_column || "Longitude Field"}
                    search
                    selection
                    disabled={this.state.table === ""}
                    options={longitudeColumnOptions}
                    onChange={this.setLongitudeColumn.bind(this)}
                    defaultValue={this.state.longitude_column || ""}
                    value={this.state.longitude_column || ""}
                    elementid={"TrackDevicesLongitude"}
                    error={this.state.error && !this.state.longitude_column}
                  />
                </StyledInputDiv>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key={"view"}>
            <EditPanelFormContainer>
              <EditMetaRow>
                <label style={{ paddingRight: "10px", fontWeight: "bold" }}>
                  {" "}
                  Show Map Layers{" "}
                </label>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <label style={{ paddingRight: "10px", width: "150px" }}>
                  {" "}
                  Satellite Layer{" "}
                </label>
                <Radio
                  toggle
                  checked={this.state.satelliteLayerFlag}
                  onChange={(_event, data) => {
                    this.setState({
                      satelliteLayerFlag: data.checked || false,
                    });
                    if (data.checked) {
                      this.setState({ trafficLayerFlag: false });
                    }
                  }}
                />
              </EditMetaRow>
              <EditMetaRow>
                <label style={{ paddingRight: "10px", width: "150px" }}>
                  {" "}
                  Traffic Layer{" "}
                </label>
                <Radio
                  toggle
                  checked={this.state.trafficLayerFlag}
                  onChange={(_event, data) => {
                    this.setState({ trafficLayerFlag: data.checked || false });
                    if (data.checked) {
                      this.setState({ satelliteLayerFlag: false });
                    }
                  }}
                />
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <Tab menu={{}} panes={panes} renderActiveOnly={false} />
      </EditMetaRoot>
    );
  }
}

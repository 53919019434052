const j1939PGNs = [
  61483, 61489, 61490, 61484, 61485, 61538, 61712, 65134, 254, 510, 1278, 2302,
  2558, 2814, 3070, 3582, 7166, 7422, 7678, 7934, 39422, 53758, 54014, 61442,
  61443, 61444, 61450, 61452, 61453, 61459, 61460, 61461, 61462, 61463, 61464,
  61465, 61468, 61470, 61475, 61476, 61477, 61478, 61479, 61480, 61481, 61482,
  61487, 61488, 61494, 61495, 61496, 61497, 61498, 61499, 61500, 61501, 61502,
  61503, 61504, 61505, 61506, 61507, 61508, 61509, 61510, 61511, 61512, 61513,
  61514, 61515, 61516, 61517, 61539, 61540, 61541, 61542, 61543, 61544, 61545,
  61546, 61547, 61548, 61549, 61550, 61584, 61585, 61586, 61587, 61588, 61589,
  61590, 61600, 61640, 61641, 61643, 61644, 61648, 61649, 61650, 61651, 61669,
  61670, 61671, 61672, 61689, 61690, 61691, 61692, 61693, 61694, 61699, 61701,
  61704, 61705, 61706, 61707, 61708, 61711, 61714, 61715, 61716, 61717, 61719,
  61720, 61721, 61722, 61723, 61724, 61725, 61726, 61727, 61728, 61729, 61730,
  61731, 61732, 61733, 61734, 61735, 61736, 61737, 61739, 61740, 61744, 61745,
  64400, 64401, 64402, 64403, 64410, 64411, 64415, 64416, 64420, 64421, 64425,
  64426, 64430, 64431, 64442, 64443, 64444, 64445, 64448, 64449, 64450, 64451,
  64454, 64455, 64456, 64457, 64465, 64472, 64473, 64474, 64486, 64499, 64593,
  64601, 64661, 64702, 64778, 64779, 64780, 64781, 64782, 64783, 64784, 64785,
  64803, 64804, 64805, 64806, 64807, 64808, 64809, 64810, 64811, 64812, 64813,
  64814, 64815, 64816, 64914, 64915, 64934, 64972, 64982, 64983, 64984, 64985,
  64986, 64987, 64998, 64999, 65000, 65001, 65002, 65003, 65004, 65005, 65006,
  65007, 65008, 65009, 65010, 65011, 65012, 65013, 65014, 65015, 65016, 65017,
  65018, 65019, 65020, 65021, 65022, 65023, 65025, 65026, 65027, 65028, 65029,
  65030, 65089, 65111, 65114, 65132, 65137, 65138, 65139, 65140, 65141, 61447,
  61466, 61491, 61492, 61677, 61685, 61713, 61718, 64655, 64739, 64765, 64849,
  64850, 64930, 64931, 65135, 65142, 61518, 61519, 61520, 61521, 61522, 61523,
  61524, 61525, 61526, 61527, 61528, 61529, 61530, 61531, 61532, 61533, 61534,
  61535, 61536, 61537, 61710, 64530, 64531, 64533, 64534, 64535, 64562, 64563,
  64564, 64566, 64567, 64568, 64569, 64570, 64656, 64664, 64669, 64670, 64703,
  64704, 64750, 64751, 64763, 64790, 64791, 64830, 64851, 64872, 64874, 64897,
  2046, 3326, 27646, 28158, 29438, 29694, 29950, 30206, 30462, 30718, 30974,
  31230, 31486, 31742, 32254, 32510, 32766, 33022, 33278, 33534, 33790, 34046,
  34302, 34558, 34814, 35070, 36862, 37118, 37374, 37630, 38142, 38398, 38654,
  38910, 39166, 39934, 40190, 40446, 42494, 42750, 43006, 43262, 43518, 44798,
  53246, 53502, 54782, 57598, 61441, 61445, 61446, 61448, 61449, 61451, 61454,
  61455, 61456, 61457, 61458, 61469, 61473, 61486, 61642, 61645, 61654, 61655,
  61658, 61659, 61662, 61663, 61666, 61667, 61673, 61674, 61675, 61676, 61700,
  61702, 61703, 61709, 61738, 61741, 61742, 61743, 61746, 64404, 64405, 64406,
  64407, 64409, 64412, 64413, 64414, 64417, 64418, 64419, 64422, 64423, 64424,
  64427, 64428, 64429, 64432, 64433, 64434, 64435, 64437, 64438, 64439, 64440,
  64441, 64446, 64447, 64452, 64453, 64459, 64460, 64461, 64463, 64464, 64466,
  64469, 64470, 64476, 64477, 64478, 64479, 64484, 64485, 64491, 64492, 64493,
  64494, 64495, 64496, 64497, 64500, 64501, 64502, 64503, 64504, 64505, 64506,
  64507, 64508, 64509, 64510, 64511, 64513, 64514, 64515, 64516, 64517, 64518,
  64519, 64520, 64521, 64522, 64523, 64526, 64528, 64529, 64536, 64537, 64547,
  64548, 64549, 64550, 64551, 64553, 64554, 64555, 64556, 64557, 64558, 64561,
  64565, 64571, 64572, 64573, 64574, 64575, 64576, 64577, 64578, 64579, 64581,
  64582, 64583, 64584, 64585, 64586, 64587, 64588, 64589, 64590, 64591, 64592,
  64595, 64598, 64599, 64600, 64605, 64651, 64652, 64657, 64658, 64659, 64660,
  64662, 64663, 64665, 64666, 64667, 64668, 64671, 64689, 64690, 64691, 64692,
  64693, 64694, 64695, 64696, 64697, 64698, 64699, 64700, 64701, 64705, 64706,
  64707, 64708, 64709, 64712, 64713, 64714, 64715, 64716, 64719, 64720, 64725,
  64726, 64727, 64728, 64729, 64730, 64731, 64732, 64733, 64734, 64735, 64736,
  64737, 64738, 64740, 64741, 64742, 64743, 64744, 64745, 64747, 64748, 64749,
  64752, 64753, 64754, 64755, 64756, 64757, 64758, 64759, 64760, 64761, 64767,
  64769, 64772, 64773, 64774, 64775, 64777, 64786, 64787, 64788, 64789, 64794,
  64795, 64796, 64797, 64798, 64799, 64800, 64801, 64802, 64817, 64819, 64820,
  64821, 64822, 64823, 64824, 64825, 64826, 64827, 64828, 64829, 64831, 64832,
  64833, 64836, 64837, 64838, 64840, 64841, 64869, 64870, 64871, 64875, 64876,
  64877, 64878, 64879, 64880, 64881, 64882, 64883, 64884, 64885, 64886, 64887,
  64888, 64889, 64890, 64891, 64892, 64894, 64895, 64899, 64900, 64901, 64902,
  64903, 64904, 64905, 64907, 64908, 64909, 64910, 64911, 64913, 64916, 64917,
  64918, 64919, 64920, 64921, 64923, 64924, 64925, 64926, 64927, 64928, 64929,
  64932, 64933, 64936, 64937, 64938, 64940, 64942, 64943, 64944, 64945, 64946,
  64947, 64948, 64953, 64954, 64955, 64956, 64957, 64958, 64959, 64960, 64961,
  64962, 64963, 64964, 64966, 64967, 64968, 64970, 64971, 64973, 64976, 64978,
  64979, 64980, 64981, 64988, 64992, 64993, 64994, 64995, 64996, 64997, 65031,
  65088, 65098, 65099, 65100, 65102, 65103, 65104, 65105, 65106, 65107, 65108,
  65109, 65110, 65112, 65113, 65115, 65126, 65127, 65128, 65129, 65130, 65133,
  65136, 65165, 65167, 65168, 65172, 65174, 65175, 65176, 65177, 65178, 65180,
  65181, 65182, 65184, 65188, 65190, 65197, 65198, 65213, 65215, 65216, 65217,
  65237, 65238, 65241, 65242, 65243, 65244, 65245, 65246, 65247, 65248, 65249,
  65250, 65251, 65252, 65253, 65254, 65255, 65256, 65257, 65258, 65261, 65262,
  65263, 65264, 65265, 65266, 65267, 65268, 65269, 65270, 65271, 65272, 65273,
  65274, 65275, 65276, 65277, 65278, 65279, 29182, 56574, 56830, 57086, 64408,
  64467, 64468, 64471, 64480, 64481, 64482, 64483, 64487, 64488, 64490, 64524,
  64525, 64546, 64559, 64560, 64594, 64606, 64672, 64673, 64674, 64675, 64676,
  64677, 64678, 64679, 64680, 64681, 64682, 64683, 64684, 64685, 64686, 64687,
  64688, 64762, 64764, 64766, 64776, 64792, 64793, 64873, 64906, 64935, 64969,
  64977, 64991, 65101, 65143, 65144, 65145, 65146, 65147, 65148, 65149, 65150,
  65151, 65152, 65153, 65154, 65155, 65156, 65157, 65158, 65159, 65160, 65161,
  65162, 65163, 65164, 65166, 65169, 65170, 65171, 65173, 65179, 65183, 65185,
  65186, 65187, 65189, 65191, 65192, 65193, 65194, 65195, 65196, 65199, 65200,
  65201, 65202, 65203, 65204, 65205, 65206, 65207, 65208, 65209, 65210, 65211,
  65212, 65214, 65218, 65219, 65221, 65223, 61440, 64768, 65024, 9982, 10238,
  23550, 23806, 24062, 24318, 24574, 24830, 25086, 25342, 25598, 25854, 26110,
  26366, 26622, 26878, 27134, 27390, 61747, 61748, 61749, 61750, 61751, 61752,
  61753, 61754, 61755, 61756, 61757, 61760, 61761, 61762, 61763, 61764, 61765,
  61766, 61767, 61768, 61769, 61770, 61771, 61772, 61773, 61774, 61775, 61776,
  61777, 61778, 61779, 61780, 61781, 61782, 61783, 61784, 61785, 61786, 61787,
  61788, 61789, 61790, 61791, 61792, 61793, 61794, 61795, 61796, 61797, 61798,
  61799, 61800, 61801, 61802, 61803, 61804, 61805, 61806, 61807, 61808, 61809,
  61810, 61811, 61812, 61813, 61814, 61815, 61816, 61817, 61818, 61819, 61820,
  61821, 61822, 61823, 61824, 61825, 61826, 61827, 61828, 61829, 61830, 61831,
  61832, 61833, 61834, 64364, 64365, 64366, 64367, 64368, 64369, 64370, 64371,
  64372, 64373, 64374, 64375, 64376, 64377, 64378, 64379, 64381, 64382, 64383,
  64384, 64385, 64389, 64392, 64393, 64394, 64395, 64396, 64397, 64398, 64399,
  10494, 10750, 11006, 11262, 11518, 11774, 12030, 12286, 21502, 21758, 22014,
  22270, 22526, 22782, 23038, 23294, 61835, 61836, 61837, 61838, 61839, 61840,
  61841, 61842, 61843, 61844, 61845, 61846, 61847, 61848, 61849, 61850, 61851,
  61852, 61853, 61854, 61855, 61856, 61857, 61858, 61859, 61860, 61861, 61862,
  61863, 61864, 61865, 61866, 61867, 61868, 61869, 61870, 61871, 61872, 61873,
  61874, 61875, 61876, 61877, 61878, 61879, 61880, 61881, 61882, 61883, 61884,
  61885, 61886, 61887, 61888, 61889, 61890, 61891, 61892, 61893, 61894, 61895,
  61896, 61897, 61898, 61899, 61900, 61901, 61902, 61903, 61904, 61905, 61906,
  61907, 61908, 61909, 61910, 61911, 61912, 61913, 61914, 61915, 61916, 61917,
  61918, 61919, 61920, 61921, 61922, 61923, 61924, 61925, 61926, 61927, 61928,
  61929, 61930, 61931, 61932, 61933, 61934, 61935, 61936, 61937, 61938, 61939,
  61940, 61941, 61942, 61943, 61944, 61945, 61946, 61947, 61948, 61949, 61950,
  61951, 61952, 61953, 61954, 61955, 61956, 61957, 61958, 61959, 61960, 61961,
  61962, 61963, 61964, 61965, 61966, 61967, 64207, 64208, 64209, 64210, 64211,
  64212, 64213, 64214, 64237, 64238, 64239, 64240, 64241, 64242, 64243, 64244,
  64245, 64246, 64247, 64249, 64250, 64251, 64252, 64253, 64254, 64258, 64259,
  64260, 64261, 64262, 64263, 64264, 64265, 64266, 64267, 64268, 64269, 64270,
  64271, 64272, 64273, 64274, 64275, 64276, 64277, 64278, 64279, 64280, 64281,
  64282, 64283, 64284, 64285, 64286, 64287, 64288, 64289, 64290, 64291, 64292,
  64293, 64294, 64295, 64296, 64297, 64298, 64299, 64301, 64302, 64303, 64304,
  64305, 64306, 64307, 64308, 64309, 64310, 64311, 64312, 64313, 64314, 64315,
  64316, 64317, 64318, 64319, 64320, 64321, 64322, 64323, 64324, 64325, 64326,
  64327, 64328, 64329, 64330, 64331, 64332, 64333, 64334, 64335, 64336, 64337,
  64338, 64339, 64340, 64341, 64342, 64343, 64344, 64345, 64346, 64347, 64348,
  64349, 64350, 64351, 64352, 64353, 64354, 64355, 64356, 64357, 64358, 64359,
  64360, 64361, 64362, 64363, 12542, 12798, 13054, 13310, 20478, 20734, 20990,
  21246, 61972, 61973, 61974, 61975, 61976, 61977, 61978, 61979, 61980, 61981,
  61982, 61983, 61984, 61985, 61986, 61987, 61988, 61989, 61990, 61991, 61992,
  61993, 61994, 61995, 61996, 61997, 61998, 61999, 62000, 62001, 62002, 62003,
  62004, 62005, 62006, 62007, 62008, 62009, 62010, 62011, 62012, 62013, 62014,
  62015, 62016, 62017, 62018, 62019, 62020, 62021, 62022, 62023, 62024, 62025,
  62026, 62027, 62028, 62029, 62030, 62031, 64171, 64172, 64173, 64174, 64175,
  64176, 64177, 64178, 64180, 64181, 64182, 64183, 64184, 64185, 64186, 64187,
  64188, 64189, 64190, 64191, 64192, 64193, 64194, 64195, 64196, 64197, 64198,
  64200, 64205, 64206, 62032, 64165, 64166, 64167, 64168, 64169, 64170, 4094,
  4350, 4606, 4862, 5118, 5374, 5630, 5886, 6142, 6398, 6654, 6910, 8190, 8446,
  8702, 8958, 19710, 61551, 61552, 61553, 61554, 61555, 61556, 61557, 61558,
  61559, 61560, 61561, 61562, 61563, 61564, 61565, 61566, 61567, 61568, 61569,
  61570, 61571, 61572, 61573, 61574, 62033, 62034, 62035, 62036, 62037, 62038,
  62039, 62040, 62041, 62042, 62043, 62044, 62045, 62046, 62047, 62048, 62049,
  62050, 62051, 62052, 62053, 62054, 62055, 62056, 62057, 62058, 62059, 62060,
  62061, 62062, 62063, 62064, 62065, 62066, 62067, 62068, 62069, 62070, 62071,
  62072, 62073, 62074, 62075, 62076, 62077, 62078, 62079, 62080, 62081, 62082,
  62083, 62084, 62085, 62086, 62087, 62088, 62089, 62090, 62091, 62092, 62093,
  62094, 62095, 62096, 62097, 62098, 62099, 62100, 62101, 62102, 62103, 62104,
  62105, 62106, 62107, 62108, 62109, 62110, 62111, 62112, 62113, 62114, 62115,
  62116, 62117, 62118, 62119, 62120, 62121, 62122, 62123, 62124, 62125, 62126,
  62127, 62128, 62129, 62130, 62131, 62132, 62133, 62134, 62135, 62136, 62137,
  62138, 62139, 62140, 62141, 62142, 62143, 62144, 62145, 62146, 62147, 62148,
  62149, 62150, 62151, 62152, 62153, 62154, 62155, 62156, 62157, 62158, 62159,
  62160, 62161, 62162, 62163, 62164, 62165, 62166, 62167, 62168, 62169, 62170,
  62171, 62172, 62173, 62174, 62175, 62176, 62177, 62178, 62179, 62180, 62181,
  62182, 62183, 62184, 62185, 62186, 62187, 62188, 62189, 62190, 62191, 62192,
  62193, 62194, 62195, 62196, 62197, 62198, 62199, 62200, 62201, 62202, 62203,
  62204, 62205, 62206, 62207, 62208, 62209, 62210, 62211, 62212, 62213, 62214,
  62215, 62216, 62217, 62218, 62219, 62220, 62221, 62222, 62223, 62224, 62225,
  62226, 62227, 62228, 62229, 62230, 62231, 62232, 62233, 62234, 62235, 62236,
  62237, 62238, 62239, 62240, 62241, 62242, 62243, 62244, 62245, 62246, 62247,
  62248, 62249, 62250, 62251, 62252, 62253, 62254, 62255, 62256, 62257, 62258,
  62259, 62260, 62261, 62262, 62263, 62264, 62265, 62266, 62267, 62268, 62269,
  62270, 62271, 62272, 62273, 62274, 64075, 64076, 64077, 64078, 64079, 64080,
  64081, 64082, 64083, 64084, 64085, 64086, 64087, 64088, 64089, 64090, 64091,
  64092, 64095, 64096, 64098, 64099, 64100, 64101, 64102, 64103, 64104, 64105,
  64106, 64107, 64108, 64109, 64110, 64111, 64112, 64113, 64114, 64115, 64116,
  64117, 64118, 64119, 64120, 64121, 64122, 64123, 64124, 64125, 64126, 64127,
  64128, 64129, 64130, 64131, 64132, 64133, 64134, 64135, 64136, 64137, 64138,
  64139, 64140, 64141, 64142, 64143, 64144, 64145, 64146, 64147, 64148, 64149,
  64150, 64151, 64152, 64153, 64154, 64155, 64156, 64157, 64158, 64159, 64160,
  64161, 64162, 64163, 64164, 64538, 64539, 64617, 62275, 62276, 64068, 64069,
  64070, 64071, 64072, 64073, 62277, 62278, 62279, 62280, 62281, 64064, 64065,
  64067, 64645, 64646, 64647, 64648, 64649, 64650, 62282, 62283, 62284, 62285,
  62286, 62287, 62288, 62289, 62290, 62291, 62292, 62293, 62294, 62295, 62296,
  62297, 62298, 62299, 62300, 62301, 62302, 62303, 62304, 62305, 62306, 62307,
  64036, 64037, 64038, 64039, 64040, 64041, 64042, 64043, 64044, 64045, 64046,
  64047, 64048, 64049, 64050, 64053, 64054, 64055, 64056, 64057, 64058, 64059,
  64060, 64061, 64062, 64063, 64540, 64541, 14846, 15102, 15358, 19198, 19454,
  61575, 61576, 61684, 62308, 62309, 62310, 62311, 62312, 62313, 62314, 62315,
  62316, 62317, 62318, 62319, 62320, 64031, 64032, 64033, 64034, 64051, 64052,
  64498, 64512, 64542, 64543,
];

const pgnsWithMoreThan8bytes = [
  28158, 29438, 32766, 64412, 64417, 64422, 64427, 64432, 64440, 64441, 64446,
  64447, 64452, 64453, 64460, 64461, 64463, 64464, 64582, 64584, 64585, 64605,
  64665, 64690, 64691, 64888, 64889, 64920, 64921, 64956, 65168, 65249, 65251,
  64467, 64490, 64606, 64793, 65200, 65204, 65207, 65208, 65209, 65210, 65211,
  65212, 64384, 64207, 64241, 64242, 64243, 64244, 64245, 64246, 64247, 64251,
  64252, 64253, 64254, 64258, 64259, 64260, 64261, 64262, 64263, 64264, 64265,
  64266, 64267, 64268, 64269, 64270, 64271, 64272, 64273, 64274, 64275, 64276,
  64277, 64278, 64279, 64335, 64336, 64337, 64338, 64339, 64340, 64341, 64342,
  64343, 64344, 64345, 64346, 64347, 64348, 64349, 64350, 64351, 64352, 64353,
  64354, 64355, 64356, 64357, 64358, 20478, 64171, 64172, 64173, 64174, 64175,
  64176, 64177, 64178, 64189, 64190, 64191, 64192, 64193, 64194, 64168, 64112,
  64113, 64132, 64133, 64134, 64135, 64136, 64137, 64138, 64139, 64140, 64141,
  64142, 64143, 64144, 64145, 64146, 64147, 64148, 64149, 64150, 64151, 64152,
  64153, 64154, 64155, 64156, 64157, 64158, 64159, 64160, 64161, 64162, 64163,
  64512,
];

export const pgnsWithLessThan8bytes = j1939PGNs.filter(
  (pgn) => !pgnsWithMoreThan8bytes.includes(pgn)
);

import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewSessions } from "./ViewSessions";
import { EditSessionsMeta } from "./EditSessionsMeta";

export type SessionAggregate = {
  name: string;
  table: string;
  column: string;
  aggregator: string;
};

export class SessionsMetaData {
  type = "sessions";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  aggregates: SessionAggregate[] = [];
  sessionType: string = "";
  deviceDashboardIds: string[] = [];
}

export type Session = {
  device_id: number;
  "-serial_metadata"?: { [key: string]: string };
  session_type: string;
  start_time: number;
  end_time: number;
  aggregates: { [key: string]: any };
};

export type SessionsData = {
  data: Session[];
};

export const SessionsPanelDef: PanelDef<SessionsMetaData, SessionsData> = {
  ViewComponent: ViewSessions,
  EditComponent: EditSessionsMeta,
  formatData: (data) => data,
  metaConstructor: SessionsMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

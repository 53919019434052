import { StaticTextMetaData } from "./PanelDef";
import React from "react";
import { Tab, Dropdown } from "semantic-ui-react";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import {
  EditMetaRoot,
  EditMetaRow,
  ThinDivider,
  LabelHeading,
  DisplayValueDiv,
  EditAnimatedMetaInput,
  EditPanelFormContainer,
  StyledSketchPickerTrigger,
  StyledInputDiv,
} from "../util";
import { SketchPicker } from "react-color";

export type EditStaticTextMetaState = {
  text: string;
  textColor: string;
  textSize: number;
  textStyle: string;
  displayPicker: boolean;
  error: boolean;
};

export class EditStaticTextMeta extends PanelEditComponent<
  StaticTextMetaData,
  EditStaticTextMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  textRef = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);

    if (props.panelMeta) {
      this.state = {
        text: props.panelMeta.text,
        textColor: props.panelMeta.textColor
          ? props.panelMeta.textColor
          : "#939493",
        textSize: props.panelMeta.textSize ? props.panelMeta.textSize : 50,
        textStyle: props.panelMeta.textStyle
          ? props.panelMeta.textStyle
          : "normal",
        displayPicker: false,
        error: false,
      };
    } else {
      this.state = {
        text: "",
        textColor: "#939493",
        textSize: 50,
        textStyle: "normal",
        displayPicker: false,
        error: false,
      };
    }
  }

  getPanelMeta(type): PartialMetaData<StaticTextMetaData> {
    const meta: StaticTextMetaData = {
      type: "static_text",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "",
      description: this.descriptionRef.current?.value || "",
      text: this.textRef.current?.value || "",
      textColor: this.state.textColor ? this.state.textColor : "#939493",
      textSize: this.state.textSize ? this.state.textSize : 50,
      textStyle: this.state.textStyle ? this.state.textStyle : "normal",
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta, type),
    };
  }

  isValidPanelMeta(meta: StaticTextMetaData, type?: string) {
    // type is used here to differentiate between submit and refresh in edit mode
    if (!meta.text && type === "submit") {
      this.setState({ error: true });
    } else if (type === "submit") {
      this.setState({ error: false });
    }

    if (meta.text && meta.text.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  onStyleSelect = (e, data) => {
    this.setState({
      textStyle: data.value,
    });
  };

  handleSliderChange = (event) => {
    this.setState({ textSize: parseFloat(event.target.value) });
  };

  render() {
    const title = this.props.panelMeta.title;
    const description = this.props.panelMeta.description;
    const text = this.props.panelMeta.text;
    const styleOptions = [
      {
        key: 0,
        value: "normal",
        text: "Normal",
      },
      {
        key: 1,
        value: "italic",
        text: "Italic",
      },
    ];

    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key="general">
            <EditPanelFormContainer>
              <EditMetaRoot>
                <div style={{ width: "100%", marginTop: "16px" }} />

                <EditMetaRow>
                  <StyledInputDiv width="48%">
                    <EditAnimatedMetaInput
                      autoFocus={true}
                      defaultRef={this.titleRef}
                      defaultValue={title}
                      label="Title"
                    />
                  </StyledInputDiv>
                  <StyledInputDiv width="48%">
                    <EditAnimatedMetaInput
                      defaultRef={this.descriptionRef}
                      defaultValue={description}
                      label="Description"
                    />
                  </StyledInputDiv>
                </EditMetaRow>

                <ThinDivider />

                <EditMetaRow>
                  <div style={{ width: "100%", marginTop: "10px" }}>
                    <EditAnimatedMetaInput
                      defaultRef={this.textRef}
                      defaultValue={text}
                      label="Text"
                      error={this.state.error && !this.state.text}
                    />
                  </div>
                </EditMetaRow>
              </EditMetaRoot>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key="view">
            <EditPanelFormContainer>
              <EditMetaRow>
                <LabelHeading>Choose Text Style</LabelHeading>
                <Dropdown
                  placeholder="Text Style"
                  fluid
                  selection
                  options={styleOptions}
                  defaultValue={this.state.textStyle}
                  onChange={this.onStyleSelect}
                />
              </EditMetaRow>
              <ThinDivider />
              {/* separate component */}
              <EditMetaRow>
                <LabelHeading> Text Size </LabelHeading>
                <DisplayValueDiv>{this.state.textSize}</DisplayValueDiv>
                <div style={{ width: "100%", padding: "5px 35px 0px 5px" }}>
                  <input
                    style={{ width: "100%", height: "5%" }}
                    id="typeinp"
                    type="range"
                    min="50"
                    max="200"
                    value={this.state.textSize}
                    onChange={this.handleSliderChange}
                    step="1"
                  />
                </div>
              </EditMetaRow>
              <ThinDivider />
              <EditMetaRow>
                <LabelHeading> Choose Text Color </LabelHeading>
                <StyledSketchPickerTrigger
                  id="default_color"
                  style={{
                    backgroundColor: `${this.state.textColor}`,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ displayPicker: !this.state.displayPicker });
                  }}
                />
                <div
                  style={{
                    display: this.state.displayPicker ? "block" : "none",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <SketchPicker
                    color={this.state.textColor}
                    onChange={(color) => {
                      this.setState({ textColor: color.hex });
                    }}
                  />
                </div>
              </EditMetaRow>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <div
          onClick={() => {
            this.setState({ displayPicker: false });
          }}
        >
          <Tab menu={{}} panes={panes} renderActiveOnly={false} />
        </div>
      </EditMetaRoot>
    );
  }
}

import { BarChartData, BarChartMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import Plot from "react-plotly.js";
import { UserContext } from "../../../../../context/User.context";
import ThemeSchema from "../../../../../theme/schema";

export class ViewBarChart extends PanelViewComponent<
  BarChartMetaData,
  BarChartData
> {
  render() {
    const plotStyle = {
      width: "calc(100% - 4px)",
      height: "calc(100% - 29px)", //25px panel title + 4px margin
      marginTop: "2px",
      marginLeft: "2px",
    };

    let layout = {
      autosize: true,
      responsive: true,
      margin: {
        l: 50,
        r: 50,
        b: 50,
        t: 50,
      },
      xaxis: {
        ticklen: 4,
        showline: true,
        tickfont: { color: "#C0C0C0" },
      },
      yaxis: {
        ticklen: 4,
        showline: true,
        tickfont: { color: "#C0C0C0" },
      },
    } as Partial<Plotly.Layout>;

    const groupedData = {};
    this.props.data.forEach((d) => {
      const g = groupedData;

      if (this.props.panelMeta.subGroup) {
        const subGroupData = (g[d.group] = g[d.group] || {});

        subGroupData[d.subGroup] = d.value;
      } else {
        if (d.group) {
          g[d.group] = d.value;
        }
      }
    });

    let data = [] as Plotly.Data[];

    if (this.props.panelMeta.subGroup) {
      const groups = Object.keys(groupedData);
      const subGroups =
        groups.length > 0 ? Object.keys(groupedData[groups[0]]) : [];

      data = groups.map((g) => {
        return {
          x: subGroups,
          y: subGroups.map((sg) => groupedData[g][sg]),
          type: "bar",
          name: g,
        } as Plotly.Data;
      });
    } else {
      const groups = Object.keys(groupedData);

      data = [
        {
          x: groups,
          y: groups.map((g) => groupedData[g]),
          type: "bar",
        },
      ] as Plotly.Data[];
    }

    if (
      (this.props.panelMeta.minRange || this.props.panelMeta.minRange === 0) &&
      this.props.panelMeta.maxRange
    ) {
      let yAxisConfig = {
        range: [this.props.panelMeta.minRange, this.props.panelMeta.maxRange],
      };
      layout.yaxis = { ...layout.yaxis, ...yAxisConfig };
    }

    if (data && data.length > 0 && data[0]["x"] && data[0]["x"].length > 0) {
      return (
        <UserContext.Consumer>
          {(userContext) => (
            <Plot
              data={data}
              style={plotStyle}
              layout={{
                ...layout,
                xaxis: {
                  ...layout.xaxis,
                  tickfont: {
                    color:
                      ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"],
                  },
                },
                yaxis: {
                  ...layout.yaxis,
                  tickfont: {
                    color:
                      ThemeSchema.data[
                        userContext.user?.settings?.theme ?? "dark"
                      ]?.colors["chart-text-color"],
                  },
                },
              }}
              useResizeHandler={true}
              config={{ displayModeBar: false }}
            />
          )}
        </UserContext.Consumer>
      );
    } else {
      return <div className="panel-no-data">No Data</div>;
    }
  }
}

import React, { useState, useEffect } from "react";
import { Dropdown, DropdownProps, Icon } from "semantic-ui-react";
import {
  DeviceFilterOption,
  Filters,
  fetchDeviceFilterOptions,
} from "../../../BytebeamClient";

type DashboardFilterProps = {
  filterKeys: string[];
  selectedFilters: Filters;
  disabled: boolean;
  onFilterSelected: (filterName: string, filterValue: string[]) => void;
  disableSubmit?: (arg0: boolean) => void;
};

export function DashboardFilters(props: DashboardFilterProps) {
  const { selectedFilters, filterKeys, disableSubmit } = props;
  let filterSelectedTimeout: any = null;

  const onFilterChange = (
    data: DropdownProps,
    filterOption: DeviceFilterOption
  ) => {
    if (disableSubmit) disableSubmit(true);
    const filterName = filterOption.filterName;
    const filterValue = data.value as string[];
    clearTimeout(filterSelectedTimeout);
    filterSelectedTimeout = setTimeout(() => {
      props.onFilterSelected(filterName, filterValue);
    }, 1000);
  };

  const [filterOptions, setFilterOptions] = useState<DeviceFilterOption[]>([]);

  useEffect(() => {
    const defaultFilterOptions = filterKeys.map((filterName) => {
      return {
        filterName: filterName,
        filterValues: [],
      };
    });

    setFilterOptions(defaultFilterOptions);
  }, [filterKeys]);

  useEffect(() => {
    const refreshFilterOptions = async (filters: Filters) => {
      const filterOptions = await fetchDeviceFilterOptions(filters);
      const filterOptionsMap = Object.fromEntries(
        filterOptions.map(({ filterName, filterValues }) => [
          filterName,
          filterValues,
        ])
      );

      setFilterOptions(
        filterKeys.map((filterName) => {
          return {
            filterName: filterName,
            filterValues: filterOptionsMap[filterName] || [],
          };
        })
      );
      if (disableSubmit) disableSubmit(false);
    };

    let updatedFilters: Filters = props.selectedFilters;

    if (filterKeys.every((key) => key in props.selectedFilters))
      updatedFilters = Object.fromEntries(
        Object.entries(props.selectedFilters).filter(([key]) =>
          filterKeys.includes(key)
        )
      );

    refreshFilterOptions(updatedFilters);
  }, [props.selectedFilters, filterKeys, disableSubmit]);

  return (
    <div className="dashboard-filters">
      <Icon name="filter" />

      {filterOptions.map((filterOption) => {
        // Filtering out values containing only white spaces and Mapping to Dropdown options using FlatMap
        const options = filterOption.filterValues.flatMap((v) => {
          let value = String(v);
          if (value?.trim() !== "")
            return [
              {
                key: value,
                value: value,
                text: value,
              },
            ];
          else return [];
        });

        return (
          <span
            className="dashboard-header-dropdown"
            key={filterOption.filterName}
          >
            <Dropdown
              placeholder={filterOption.filterName}
              options={options}
              search
              multiple
              selection
              button
              labeled
              defaultValue={selectedFilters[filterOption.filterName]}
              onChange={(_event, data) => onFilterChange(data, filterOption)}
              disabled={props.disabled}
            />
          </span>
        );
      })}
    </div>
  );
}

import { apiCall } from "../../../BytebeamClient";

export async function submitData(
  name: string,
  email: string,
  message: string
): Promise<null | string> {
  const formData = new FormData();

  formData.append("name", name);
  formData.append("email", email);
  formData.append("message", message);

  try {
    await apiCall(
      "https://getform.io/f/3be0fdd1-1ab7-472e-a1ea-8dcd515ebae6",
      false,
      {
        method: "POST",
        body: formData,
      }
    );
    return null;
  } catch (error) {
    if (error instanceof Error) {
      console.log(error?.message);
      return error?.message;
    } else {
      console.log("Unexpected error", error);
      return "Unexpected error";
    }
  }
}

import React, { Fragment } from "react";
import { Button } from "semantic-ui-react";
import { TimeRange } from "./Datetime/TimeRange";
import { DashboardsInfo } from "./ViewDashboard";

type DashboardLinksProps = {
  dashboardIds: string[];
  allDashboards: DashboardsInfo[];
  timeRange?: TimeRange;
  deviceId?: string;
};

export function DashboardLinks(props: DashboardLinksProps) {
  return (
    <div className="dashboard-links">
      {props.dashboardIds.map((id) => {
        const dashboard = props.allDashboards.find(
          (dashboard) => String(dashboard.id) === id
        );
        const url = new URL(id, window.location.href);
        const queryParams = new URLSearchParams(window.location.search);

        queryParams.forEach((value, key) => url.searchParams.set(key, value));

        if (props.deviceId) {
          url.searchParams.set("id", props.deviceId);
        }

        if (props.timeRange) {
          const timeRange = JSON.stringify(props.timeRange.serialize());
          url.searchParams.set("timeRange", timeRange);
        }

        if (dashboard) {
          return (
            <Button
              key={id}
              as="a"
              href={url.toString()}
              target="_blank"
              style={{ height: "38px", marginRight: "10px" }}
              icon="external"
              secondary
              content={`${dashboard?.title}`}
            />
          );
        } else {
          return <Fragment key="0"></Fragment>;
        }
      })}
    </div>
  );
}

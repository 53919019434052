import { DeviceRoute } from "../../TrackDevices/PanelDef";

import { PanelDef } from "../../PanelDef";
import { MicelioViewLocateDevices } from "./ViewLocateDevices";
import { EditMicelioLocateDevicesMeta } from "./EditLocateDevicesMeta";
import { formatData } from "../../TrackDevices/PanelDef";
import { LocateDevicesMetaData } from "../../LocateDevices/PanelDef";

export class MicelioLocateDevicesMetaData extends LocateDevicesMetaData {
  type = "micelio_locate_devices";
}

export type MicelioLocateDevicesData = DeviceRoute[];

export const MicelioLocateDevicesPanelDef: PanelDef<
  MicelioLocateDevicesMetaData,
  MicelioLocateDevicesData
> = {
  ViewComponent: MicelioViewLocateDevices,
  EditComponent: EditMicelioLocateDevicesMeta,
  formatData: formatData,
  metaConstructor: MicelioLocateDevicesMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

import uuid from "uuid";
import { PanelDef } from "../../PanelDef";
import { ViewMicelioStatus } from "./ViewMicelioStatus";
import { EditMicelioStatusMeta } from "./EditMicelioStatusMeta";

export class MicelioStatusMetaData {
  type = "micelio_status";
  title = "Status";
  description: string = "";
  table: string = "";
  id = uuid.v4();
}

export type MicelioStatusData = {
  timestamp: number;
  status: string;
  alertLevel: string;
};

export const MicelioStatusPanelDef: PanelDef<
  MicelioStatusMetaData,
  MicelioStatusData
> = {
  ViewComponent: ViewMicelioStatus,
  EditComponent: EditMicelioStatusMeta,
  formatData: (data) => data,
  previewAspectRatio: { width: 500, height: 300 },
  metaConstructor: MicelioStatusMetaData,
};

import React, { useState } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import {
  fetchAllDevices,
  fetchDevicesCount,
} from "../../../../../BytebeamClient";
import { MessageContainer } from "./BulkMetadataUpdateModal";
import csvjson from "csvjson";
import { saveAs } from "file-saver";
import LoadingAnimation from "../../../../common/Loader";
import { useUser } from "../../../../../context/User.context";
import { Permission } from "../../../../../util";

function replaceNullWithEmptyString(obj: Record<string, string | number>) {
  for (let key in obj) {
    if (obj[key] === null) {
      obj[key] = "";
    }
  }
}

// Returns a csv file content with all devices metadata
export async function getAllDevicesMetadata(
  totalDevices: number,
  permissions: Permission,
  filterForEditMetadata: boolean = false
): Promise<string> {
  const allowedEditMetadataKeys = permissions.editMetadata;
  try {
    // totalDevices is the possible maximum number of devices (totalDevices = page * devicesPerPage)
    const devices = await fetchAllDevices(1, totalDevices);
    let metadata: { id: number; Metadata: Record<string, string | number> }[] =
      [];

    for (const device of devices) {
      const object: {
        id: number;
        Metadata: Record<string, string | number>;
      } = { id: 0, Metadata: {} };

      object["id"] = device["id"];

      // Filter metadata if filterForEditMetadata is true
      if (filterForEditMetadata && allowedEditMetadataKeys !== "all") {
        const filteredMetadata: Record<string, string | number> = {};
        for (const key of allowedEditMetadataKeys) {
          if (device["metadata"].hasOwnProperty(key)) {
            filteredMetadata[key] = device["metadata"][key];
          }
        }
        replaceNullWithEmptyString(filteredMetadata);
        object["Metadata"] = filteredMetadata;
      } else {
        replaceNullWithEmptyString(
          device["metadata"] as Record<string, string | number>
        );
        object["Metadata"] = device["metadata"] as Record<
          string,
          string | number
        >;
      }

      metadata.push(object);
    }

    let csvData: string = csvjson.toCSV(metadata, { delimiter: "," });

    // To give a proper header to the csv file
    csvData = csvData.replace("[].", "");
    csvData = csvData.replaceAll("[].Metadata.", "");
    return csvData;
  } catch (error) {
    console.error("Error While Fetching all devices data: ", error);
    throw error;
  }
}

type DownloadMetadataModalProps = {
  readonly close: (...args: any[]) => any;
};

export function DownloadMetadataModal(props: DownloadMetadataModalProps) {
  const { close } = props;
  const { user } = useUser();
  const permissions: Permission = user.role.permissions;

  const [buttonDisabled, setButtonDisabled] = useState(false);

  async function downloadAllDevicesMetadata() {
    try {
      // Have to fetch total devices count again, because the props will have totalDevices count of the filtered selection
      let totalDevices = await fetchDevicesCount("active");
      const csvData: BlobPart = await getAllDevicesMetadata(
        totalDevices,
        permissions
      );
      setButtonDisabled(false);

      const file = new File([csvData], "metadata.csv", {
        type: "text/csv",
      });
      saveAs(file);
    } catch (error) {
      console.log("Error in downloading metadata: ", error);
    }
  }

  return (
    <>
      <Modal.Content>
        <Modal.Description>
          <MessageContainer>
            {buttonDisabled ? (
              <LoadingAnimation
                loadingText="Downloading all devices metadata..."
                loaderBorderSize="3px"
                loaderSize="30px"
                fontSize="14px"
              />
            ) : (
              <p>
                Download metadata for all the devices by clicking on the{" "}
                <b>Download Metadata</b> button. Alternatively, click on the{" "}
                <b>Back</b> button to return to the previous screen.
              </p>
            )}
          </MessageContainer>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            close();
          }}
        >
          <Icon name="angle left" /> Back
        </Button>
        {/* disable the button unless parsing and fetching is done */}
        <Button
          primary
          disabled={buttonDisabled}
          onClick={async () => {
            setButtonDisabled(true);
            await downloadAllDevicesMetadata();
            close();
          }}
        >
          <Icon name="checkmark" /> Download Metadata
        </Button>
      </Modal.Actions>
    </>
  );
}

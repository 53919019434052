import React, { useState, useMemo, useEffect } from "react";
import { ALL_ICONS_IN_ALL_CONTEXTS } from "semantic-ui-react/dist/commonjs/lib/SUI";
import {
  Button,
  Dropdown,
  Icon,
  Input,
  Label,
  Modal,
  SemanticICONS,
} from "semantic-ui-react";
import { ActionType } from "../../../../util";
import styled from "styled-components";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";

import { Form as JsonSchemaForm } from "@rjsf/semantic-ui";
import validator from "@rjsf/validator-ajv8";
import { useUser } from "../../../../context/User.context";
import Toggle from "../../../common/Toggle";
import ThemeSchema from "../../../../theme/schema";
import { beamtoast } from "../../../common/CustomToast";
import { isJSONValid } from "../../util";
import TimeoutDropdown from "../../../common/TimeoutDropdown";

export const StyledLabel = styled(Label)`
  margin: 0px !important;
  min-width: 80px;
`;

const StyledLabelIcon = styled(Label)`
  margin: 0px !important;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 14px;
  height: 36px;
  width: 100%;
`;

export type ActionTypeModalProps = {
  isOpen: boolean;
  actionType?: ActionType;
  isBuiltInAction: boolean;
  title: string;
  onSubmit: (at: ActionType) => void;
  onCancel: () => void;
  actionTypesSet: Set<string>;
  key?: string | number | null | undefined;
};

type IconDropDownProps = {
  disabled: boolean;
  defaultValue: SemanticICONS;
  onChange: (SemanticICONS) => void;
};

const IconDropDown = React.memo((props: IconDropDownProps) => {
  const options = useMemo(
    () =>
      ALL_ICONS_IN_ALL_CONTEXTS.map((str) => ({
        key: str,
        text: str,
        value: str,
        icon: str,
      })),
    []
  );

  return (
    <Dropdown
      search
      fluid
      options={options}
      disabled={props.disabled}
      defaultValue={props.defaultValue}
      onChange={(e, d) => {
        props.onChange(d.value as SemanticICONS);
      }}
      style={{
        padding: "8px 12px",
        border: "none",
        cursor: props.disabled ? "not-allowed" : "auto",
      }}
    />
  );
});

const PreviewModal = ({ isOpen, onClose, jsonSchema, uiSchema }) => {
  const [formData, setFormData] = useState({});

  const { user } = useUser();
  const theme = user?.settings?.theme;

  const parsedSchema = JSON.parse(jsonSchema);
  const parsedUISchema = JSON.parse(uiSchema);

  if (
    typeof parsedUISchema === "object" &&
    parsedUISchema !== null &&
    !Array.isArray(parsedUISchema)
  ) {
    // Disable submit button
    parsedUISchema["ui:submitButtonOptions"] = {
      norender: true,
    };
  }

  return (
    <Modal basic onClose={onClose} open={isOpen} size="large" className="dark">
      <Modal.Header>Form Preview</Modal.Header>
      <Modal.Content>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: "1", paddingRight: "10px" }}>
            <JsonSchemaForm
              schema={parsedSchema}
              uiSchema={parsedUISchema}
              formData={formData}
              onChange={({ formData }) => {
                setFormData(formData);
              }}
              validator={validator}
            />
          </div>
          <div style={{ flex: "1", paddingLeft: "10px" }}>
            <AceEditor
              readOnly
              value={JSON.stringify(formData, null, 2)}
              mode="json"
              theme={
                ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-theme"]
              }
              name="preview-json"
              fontSize={16}
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={true}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              style={{
                marginBottom: "16px",
                borderRadius: "4px",
                border: `${
                  ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-border"]
                }`,
                boxShadow: `${
                  ThemeSchema.data[theme ?? "dark"]?.colors[
                    "ace-editor-box-shadow"
                  ]
                }`,
              }}
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default React.memo((props: ActionTypeModalProps) => {
  const defaultActionType: ActionType = props.actionType ?? {
    type: "",
    icon: "pencil",
    payload_type: "none",
  };
  const [actionType, setActionType] = useState<ActionType>(defaultActionType);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [useForm, setUseForm] = useState(
    actionType.json_schema && actionType.json_ui_schema ? true : false
  );
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const { user } = useUser();
  const theme = user?.settings?.theme;

  const payloadOptions = [
    { text: "None", value: "none" },
    { text: "JSON", value: "json" },
    { text: "Text", value: "text" },
    // { text: 'File', value: 'file' }
  ];

  const enablePreviewButton = () => {
    if (actionType.payload_type !== "json") {
      return false;
    }

    if (!actionType.json_schema || !actionType.json_ui_schema) {
      return false;
    }

    try {
      JSON.parse(actionType.json_schema);
      JSON.parse(actionType.json_ui_schema);

      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (actionType.type.length > 0) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [actionType]);

  return (
    <Modal
      className="dark"
      basic
      onClose={props.onCancel}
      open={props.isOpen}
      size="small"
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>
        <StyledInput
          labelPosition="left"
          style={{
            cursor: props.actionType !== undefined ? "not-allowed" : "auto",
            opacity: props.actionType !== undefined ? 0.6 : 1,
          }}
        >
          <StyledLabel>Type</StyledLabel>
          <input
            autoFocus
            placeholder="Type"
            value={actionType.type}
            disabled={props.actionType !== undefined}
            onChange={(e) =>
              setActionType({ ...actionType, type: e.target.value })
            }
          />
        </StyledInput>
        <StyledInput
          labelPosition="left"
          style={{
            cursor: props.isBuiltInAction ? "not-allowed" : "auto",
            opacity: props.isBuiltInAction ? 0.6 : 1,
          }}
        >
          <StyledLabelIcon>
            <Icon
              name={actionType.icon}
              style={{
                margin: "0px",
              }}
            />
          </StyledLabelIcon>
          <IconDropDown
            disabled={props.isBuiltInAction}
            defaultValue={defaultActionType.icon}
            onChange={(icon) => setActionType({ ...actionType, icon })}
          />
        </StyledInput>
        <StyledInput
          labelPosition="left"
          style={{
            cursor: props.isBuiltInAction ? "not-allowed" : "auto",
            opacity: props.isBuiltInAction ? 0.6 : 1,
          }}
        >
          <StyledLabel>Payload</StyledLabel>
          <Dropdown
            placeholder="Select Custom Payload"
            selection
            fluid
            disabled={props.isBuiltInAction}
            options={payloadOptions}
            defaultValue={actionType.payload_type}
            onChange={(_event, data) =>
              setActionType({
                ...actionType,
                payload_type: data.value as string,
              })
            }
            style={{
              padding: "8px 12px",
              border: "none",
              cursor: props.isBuiltInAction ? "not-allowed" : "auto",
            }}
          />
        </StyledInput>
        <StyledInput labelPosition="left">
          <StyledLabel>Timeout</StyledLabel>
          <TimeoutDropdown
            allowNever={false}
            maxwidthdropdown="unset"
            widthdropdown="100%"
            timeoutDuration={actionType.timeout}
            allowClearable={true}
            setTimeoutDuration={(timeout) => {
              setActionType({ ...actionType, timeout: timeout });
            }}
          />
        </StyledInput>
        {actionType.payload_type === "json" && (
          <>
            <div
              style={{
                marginBottom: "14px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <label style={{ marginRight: "10px" }}>
                {" "}
                Allow user to input data via form{" "}
              </label>

              <Toggle
                id="json-toggle"
                size="medium"
                style={{
                  marginLeft: "8px",
                  top: "2px",
                }}
                checked={useForm}
                onChange={() => {
                  setUseForm(!useForm);
                  setActionType({
                    ...actionType,
                    json_schema: null,
                    json_ui_schema: null,
                  });
                }}
              />
            </div>

            {useForm && (
              <>
                <div>
                  <AceEditor
                    height="150px"
                    width="100%"
                    placeholder={"Enter JSON Schema"}
                    mode="json"
                    theme={
                      ThemeSchema.data[theme ?? "dark"]?.colors[
                        "ace-editor-theme"
                      ]
                    }
                    name="custom-json"
                    fontSize={16}
                    value={actionType.json_schema || undefined}
                    onChange={(val) => {
                      setActionType({ ...actionType, json_schema: val });
                    }}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    setOptions={{
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2,
                    }}
                    style={{
                      marginBottom: "16px",
                      resize: "both",
                      borderRadius: "4px",
                      border: `${
                        ThemeSchema.data[theme ?? "dark"]?.colors[
                          "ace-editor-border"
                        ]
                      }`,
                      boxShadow: `${
                        ThemeSchema.data[theme ?? "dark"]?.colors[
                          "ace-editor-box-shadow"
                        ]
                      }`,
                    }}
                  />
                </div>
                <div>
                  <AceEditor
                    height="150px"
                    width="100%"
                    placeholder={"Enter JSON UI Schema"}
                    mode="json"
                    theme={
                      ThemeSchema.data[theme ?? "dark"]?.colors[
                        "ace-editor-theme"
                      ]
                    }
                    name="custom-json"
                    fontSize={16}
                    value={actionType.json_ui_schema || undefined}
                    onChange={(val) => {
                      setActionType({ ...actionType, json_ui_schema: val });
                    }}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    setOptions={{
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2,
                    }}
                    style={{
                      marginBottom: "16px",
                      resize: "both",
                      borderRadius: "4px",
                      border: `${
                        ThemeSchema.data[theme ?? "dark"]?.colors[
                          "ace-editor-border"
                        ]
                      }`,
                      boxShadow: `${
                        ThemeSchema.data[theme ?? "dark"]?.colors[
                          "ace-editor-box-shadow"
                        ]
                      }`,
                    }}
                  />
                </div>

                <Button
                  primary
                  onClick={() => setIsPreviewOpen(true)}
                  disabled={!enablePreviewButton()}
                >
                  Preview Form
                </Button>
              </>
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={props.onCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          primary
          disabled={buttonDisabled}
          onClick={() => {
            let actionTypesSet = props.actionTypesSet;
            if (props?.actionType?.type !== undefined) {
              actionTypesSet.delete(props.actionType.type); // Removed props passed action type from set to avoid duplicate role name error on edit
            }

            if (props.actionTypesSet.has(actionType.type)) {
              beamtoast.error(`Action Type ${actionType.type} already exists`);
            } else if (
              useForm &&
              isJSONValid(actionType.json_schema ?? "") === false
              // && typeof JSON.parse(actionType.json_schema ?? )
            ) {
              beamtoast.error("Invalid JSON Schema");
            } else if (
              useForm &&
              isJSONValid(actionType.json_ui_schema ?? "") === false
            ) {
              beamtoast.error("Invalid JSON UI Schema");
            } else {
              let actionTypeValue = { ...actionType };
              if (!actionTypeValue.timeout) actionTypeValue.timeout = null;
              props.onSubmit(actionTypeValue);
            }
          }}
        >
          Submit
        </Button>
      </Modal.Actions>

      {enablePreviewButton() && (
        <PreviewModal
          isOpen={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
          jsonSchema={actionType.json_schema}
          uiSchema={actionType.json_ui_schema}
        />
      )}
    </Modal>
  );
});

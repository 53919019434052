import React from "react";
import { User } from "../../../../../util";
import LogoAndFaviconImageSettings from "./LogoAndFaviconImageSettings";

type FaviconSettingsProps = {
  user: User;
  getCurrentUser: () => Promise<void>;
  currentTenant: string;
};

export const defaultLightFavicon =
  "data:image/png;base64,AAABAAEAOy8CAAEAAQAgAwAAFgAAACgAAAA7AAAAXgAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/////f///4P////8P///g/////4P//+D/////wP//4P/////gP//g/////+AP/+D/////8AP/4P/////4AP/g//////wAP+D//////AAP4P/////+AAPg//////8AAOD//////4AA4P//////wA/g///////B/+D/////+Af/4P////8AA//g////4AAD/+D///wAAAH/4P//wAAAAP/g//gAAAAAf+D/AAAAAAA/4OAAAAAAAD/gAAAAAAAAH+DAAAAAAAA/4P4AAAAAAD/g//AAAAAAf+D//4AAAAD/4P///AAAAf/g////wAAB/+D////+AAP/4P/////wB//g//////+D/+D//////8Af4P//////gADg//////8AAOD//////gAD4P/////+AA/g//////wAP+D/////+AD/4P/////wA//g/////+AP/+D/////4D//4P/////A///g/////4P//+D/////D///4P////8////g";

export const defaultDarkFavicon =
  "data:image/png;base64,AAABAAEAOy8CAAEAAQAgAwAAFgAAACgAAAA7AAAAXgAAAAEAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/////f///4P////8P///g/////4P//+D/////wP//4P/////gP//g/////+AP/+D/////8AP/4P/////4AP/g//////wAP+D//////AAP4P/////+AAPg//////8AAOD//////4AA4P//////wA/g///////B/+D/////+Af/4P////8AA//g////4AAD/+D///wAAAH/4P//wAAAAP/g//gAAAAAf+D/AAAAAAA/4OAAAAAAAD/gAAAAAAAAH+DAAAAAAAA/4P4AAAAAAD/g//AAAAAAf+D//4AAAAD/4P///AAAAf/g////wAAB/+D////+AAP/4P/////wB//g//////+D/+D//////8Af4P//////gADg//////8AAOD//////gAD4P/////+AA/g//////wAP+D/////+AD/4P/////wA//g/////+AP/+D/////4D//4P/////A///g/////4P//+D/////D///4P////8////g";

const FaviconSettings = (props: FaviconSettingsProps) => {
  const { user, getCurrentUser, currentTenant } = props;

  return (
    <LogoAndFaviconImageSettings
      user={user}
      getCurrentUser={getCurrentUser}
      currentTenant={currentTenant}
      settingsType="favicon"
      defaultLightImage={defaultLightFavicon}
      defaultDarkImage={defaultDarkFavicon}
      fileSizeLimit={30 * 1024}
      allowGif={false}
      maxDimensions={{ width: 100, height: 100 }}
      note="NOTE: Favicon images should be less than 30KB in size and should be 100x100 pixels or less."
    />
  );
};

export default FaviconSettings;

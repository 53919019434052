import React, { ReactNode } from "react";
import { Grid } from "semantic-ui-react";

interface LayoutProps {
  buttons: ReactNode;
  children: ReactNode;
}
const Layout = (props: LayoutProps) => (
  <Grid>
    <Grid.Row>
      <Grid.Column>{props.buttons}</Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>{props.children}</Grid.Column>
    </Grid.Row>
  </Grid>
);

export default Layout;

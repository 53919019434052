import { TimeRange } from "../Datetime/TimeRange";
import React, { useState, useRef } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  Icon,
  Modal,
  Popup,
} from "semantic-ui-react";
import { PanelMetaData, PanelDef } from "./PanelDef";
import { ReplayState } from "../DashboardHeader";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  TableInfo,
  fetchStreamTableFields,
  getTenantFromURL,
} from "../../../../BytebeamClient";
import { FetchParams, downloadFileWithStream } from "./util";
import { Settings } from "../../../../util";
import { DashboardsInfo } from "../ViewDashboard";
import LoadingAnimation from "../../../common/Loader";
import styled from "styled-components";
import { objectToQuery } from "../../common/QuerySelector";
import { Timeseries } from "./LineChart/PanelDef";
import { beamtoast } from "../../../common/CustomToast";
import { DashboardMeta } from "../EditDashboardModal";

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors["panel-background"]} !important;
  border-radius: 0px 9px 0px 0px !important;
  padding: 15px !important;
  border: none !important;
  .icon.ellipsis {
    color: ${({ theme }) => theme.colors["panel-title"]} !important;
  }
`;

type DownloadPanelDataModalProps = {
  readonly panelMeta: PanelMetaData;
  readonly fetchParams: FetchParams;
  readonly mobileView?: boolean;
};

function DownloadPanelDataModal(props: DownloadPanelDataModalProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formats, setFormats] = useState<{ [key: string]: string }>({});
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});

  const abortControllersRef = useRef<{ [tableName: string]: AbortController }>(
    {}
  );

  /**
   * Resets the AbortController for a specific table to allow for a new download session
   */
  const resetAbortController = (tableName: string) => {
    if (abortControllersRef.current?.[tableName]) {
      abortControllersRef.current?.[tableName].abort(); // Cancel any ongoing requests for this table
    }

    // Create a new AbortController and store it in the ref for this table
    abortControllersRef.current[tableName] = new AbortController();
  };

  // function to fetch all columns of a stream/table
  async function setColumnsForPanel(table: string) {
    try {
      let res = await fetchStreamTableFields(table);
      let columns = Object.keys(res.result);
      return columns;
    } catch (error) {
      beamtoast.error("An error occured while downlaoding. Please try again");
      console.log(error);
    }
  }

  const downloadRequestBody = async (format: string, tableName: string) => {
    const panel = { ...props.panelMeta };
    const { timeRange, filterBys, groupBys } = props.fetchParams;

    const startTime = Math.round(timeRange.getStartTime().toDate().valueOf());
    const endTime = Math.round(timeRange.getEndTime().toDate().valueOf());

    const aggregationInterval = Math.max(
      Math.round((endTime - startTime) / 200),
      1
    );
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    document.cookie = `x-bytebeam-tenant=${getTenantFromURL()}`;

    // For filter query, converting UI object to HoneySql query
    if (
      props.panelMeta.query &&
      props.panelMeta.query[0] !== "and" &&
      props.panelMeta.query.length > 0
    ) {
      panel.query = objectToQuery(props.panelMeta.query);
    } else if (props.panelMeta.query?.length === 0) {
      delete panel["query"];
    }

    // For logs panel and line chart, converting to timeseries_table to get complete data in given time range
    if (["logs", "line_chart"].includes(props.panelMeta.type)) {
      panel.type = "timeseries_table";
      panel.columns = await setColumnsForPanel(tableName);
      if (props.panelMeta.type === "line_chart") panel.table = tableName;
    }

    // panel.columns = ["level", "message", "tag"];

    // filterBys Cleanup due to state param begin appended from url
    if (filterBys.state) {
      delete filterBys.state;
    }

    return {
      panel,
      startTime,
      endTime,
      filterBys,
      groupBys,
      aggregationInterval,
      timezone,
      format,
    };
  };

  /**
   * Handles the download button click
   * @param {string} tableName - The name of the table to download data for
   */
  const handleDownloadClick = async (tableName: string) => {
    try {
      setLoadingStates((prevState) => ({ ...prevState, [tableName]: true }));

      const format = formats[tableName];

      // Generate the request body
      const result = await downloadRequestBody(format, tableName);
      const formData = new URLSearchParams();
      formData.append("json", JSON.stringify(result)); // Format as x-www-form-urlencoded

      // Reset the AbortController before starting a new request for this specific table
      resetAbortController(tableName);
      const { signal } = abortControllersRef.current[tableName];

      // Fetch data from the API
      const response = await fetch(
        `/api/v1/panel-data-csv?tenant=${getTenantFromURL()}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formData.toString(),
          signal,
        }
      );

      if (!response.ok) {
        beamtoast.error("Failed to download data. Please try again");
        throw new Error("Failed to download data");
      }

      // Retrieve filename from the Content-Disposition header
      const disposition = response.headers.get("Content-Disposition");
      let filename = "downloaded-file"; // Default filename
      if (disposition?.includes?.("filename=")) {
        const filenameMatch = disposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        if (filenameMatch?.[1]) {
          filename = filenameMatch[1].replace(/['"]/g, ""); // Extract the filename and remove quotes
        }
      }

      // download the file in chunks using readable stream
      await downloadFileWithStream(response, filename);
    } catch (error) {
      if (abortControllersRef.current?.[tableName]?.signal?.aborted) {
        beamtoast.info("Download was cancelled.");
      } else {
        beamtoast.error("Error downloading data. Please try again");
        console.error("Error downloading data:", error);
      }
    } finally {
      setLoadingStates((prevState) => ({ ...prevState, [tableName]: false }));
    }
  };

  /**
   * Cancels the ongoing download process for a specific table
   * @param {string} tableName - The name of the table to cancel the download for
   */
  const cancelDownload = (tableName: string) => {
    if (abortControllersRef.current?.[tableName]) {
      abortControllersRef.current?.[tableName].abort();
      beamtoast.info("Download cancelled!");
    }
  };

  /**
   * Cancels the ongoing download process for all tables
   */
  const cancelAllDownloads = () => {
    // Loop through all abort controllers and cancel the download if in progress
    Object.values(abortControllersRef.current).forEach((controller) => {
      if (controller) {
        controller?.abort();
      }
    });
  };

  const formatOptions = [
    { key: "xlsx", text: "XLSX", value: "xlsx" },
    { key: "csv", text: "CSV", value: "csv" },
  ];

  return (
    <Modal
      closeIcon
      size="mini"
      className="dark"
      closeOnDimmerClick={false}
      onClose={() => {
        cancelAllDownloads();
        setLoadingStates({});
        setIsOpen(false);
      }}
      onOpen={() => setIsOpen(true)}
      open={isOpen}
      trigger={
        props.mobileView ? (
          <DropdownItem className="mob-view">
            <Icon name="download" />
            Download
          </DropdownItem>
        ) : (
          <span className="bytebeam-panel-icon desktop-view">
            <Popup
              content="Download panel data"
              position="top center"
              inverted
              trigger={<Icon name="download" link />}
            />
          </span>
        )
      }
    >
      <Modal.Header>Download Panel Data</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            Download panel data in your preferred format. Choose between XLSX
            and CSV formats.
          </p>
          {props.panelMeta.type === "line_chart" ? (
            Array.from(
              new Map<string, Timeseries>(
                props.panelMeta.timeseries.map((timeseries: Timeseries) => [
                  timeseries.table,
                  timeseries,
                ])
              ).values() // unique results only according to table/stream name
            ).map((timeseries: Timeseries) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "20px",
                  }}
                  key={timeseries.table}
                >
                  <label
                    style={{ marginBottom: "10px", wordWrap: "break-word" }}
                  >
                    Stream Name: <b>{timeseries.table}</b>
                  </label>
                  <Dropdown
                    placeholder="Select Format"
                    fluid
                    selection
                    options={formatOptions}
                    value={formats[timeseries.table] || "csv"} // default to csv
                    style={{ marginBottom: "14px" }}
                    onChange={(_, data) =>
                      setFormats((prevFormats) => ({
                        ...prevFormats,
                        [timeseries.table]: data.value as string,
                      }))
                    }
                  />
                  <div style={{ display: "flex" }}>
                    <Button
                      fluid
                      secondary
                      icon="download"
                      content="Download Data"
                      labelPosition="right"
                      disabled={!!loadingStates[timeseries.table]}
                      loading={!!loadingStates[timeseries.table]} // Load only for the specific button
                      onClick={() => handleDownloadClick(timeseries.table)}
                    />
                    {!!loadingStates[timeseries.table] && (
                      <Button
                        negative
                        icon="cancel"
                        onClick={() => cancelDownload(timeseries.table)}
                      />
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "14px",
              }}
            >
              <Dropdown
                placeholder="Select Format"
                fluid
                selection
                options={formatOptions}
                value={formats?.[props.panelMeta.table ?? ""] || "csv"}
                onChange={(_, data) =>
                  setFormats((prevFormats) => ({
                    ...prevFormats,
                    [props.panelMeta.table ?? ""]: data.value as string,
                  }))
                }
              />
              <Button
                type="submit"
                secondary
                icon
                disabled={!!loadingStates?.[props.panelMeta?.table ?? ""]}
                loading={!!loadingStates?.[props.panelMeta?.table ?? ""]} // Load only for the specific button
                onClick={() => handleDownloadClick(props.panelMeta.table ?? "")}
              >
                <Icon name="download" link />
              </Button>
            </div>
          )}

          {/* Conditionally Display Warning Text */}
          {formats[props.panelMeta.table ?? ""] === "xlsx" && (
            <p style={{ fontSize: "12px", color: "gray", marginTop: "5px" }}>
              XLSX option allows downloading around{" "}
              {process?.env?.REACT_APP_PANEL_DOWNLOAD_LIMIT || 10000} rows of
              data only.
            </p>
          )}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

type PanelContainerProps<MetaDataType extends PanelMetaData, DataType> = {
  panelMeta: MetaDataType;
  panelDef: PanelDef<MetaDataType, DataType>;
  loading?: boolean;
  error?: boolean; // Tells whether error is there or not
  errorMessage?: string; // Error message
  onTimeRangeChange?: (timeRange: TimeRange) => void;
  timeRange: TimeRange;
  data?: DataType;
  onEdit?: (panelId: string) => void;
  onClone?: (panelId: string) => void;
  onDelete?: (panelId: string) => void;
  onRefresh?: (panelId: string) => void;
  replayStep: number;
  replayTimestamp: number;
  replayState: ReplayState;
  fullPage?: boolean;
  onToggleFullPage?: (panelId: string) => void;
  editable: boolean;
  fetchParams: FetchParams;
  settings: Settings;
  dashboards: DashboardsInfo[];
  dashboardMeta: DashboardMeta;
  tables: TableInfo;
  editMode?: boolean;
  // onHoverPanel?: (event: PlotMouseEvent) => void;
  // onUnHoverPanel?: () => void;
  // hoverPointX?: any;
  // hover?:boolean;
};

export function PanelContainer<MetaDataType extends PanelMetaData, DataType>(
  props: PanelContainerProps<MetaDataType, DataType>
) {
  const onDelete = () => {
    if (props.onDelete) {
      props.onDelete(props.panelMeta.id);
    }
  };

  const onEdit = () => {
    if (props.onEdit) {
      props.onEdit(props.panelMeta.id);
    }
  };

  const onClone = () => {
    if (props.onClone) {
      props.onClone(props.panelMeta.id);
    }
  };

  const onRefresh = () => {
    if (props.onRefresh) {
      props.onRefresh(props.panelMeta.id);
    }
  };

  const getPanelContent = () => {
    const checkDataIfArray = (data) => {
      if (Array.isArray(data)) {
        return data.length > 0;
      }
      return true;
    };

    if (props.data && checkDataIfArray(props.data)) {
      return (
        <div className="bytebeam-panel-content">
          <props.panelDef.ViewComponent
            timeRange={props.timeRange}
            onTimeRangeChange={props.onTimeRangeChange}
            panelMeta={props.panelMeta}
            data={props.data}
            replayStep={props.replayStep}
            replayTimestamp={props.replayTimestamp}
            replayState={props.replayState}
            fetchParams={props.fetchParams}
            settings={props.settings}
            dashboards={props.dashboards}
            dashboardMeta={props.dashboardMeta}
            tables={props.tables}
            editMode={props.editMode}
            // onHoverPanel={props.onHoverPanel}
            // onUnHoverPanel={props.onUnHoverPanel}
            // hoverPointX={props.hoverPointX}
            // hover={props.hover}
          />
          {props.panelMeta.description ? (
            <Popup
              content={props.panelMeta.description}
              position="left center"
              inverted
              trigger={
                <div className="info-icon">
                  <Icon name="info circle" link />
                </div>
              }
            />
          ) : (
            <></>
          )}
        </div>
      );
    } else {
      return <div className="panel-no-data">No Data</div>;
    }
  };

  const fullScreenHandle = useFullScreenHandle();

  const fullPage = props.fullPage;

  const onToggleFullPage = () => {
    if (props.onToggleFullPage) {
      props.onToggleFullPage(props.panelMeta.id);
    }
  };

  const panelMeta = props.panelMeta || {};
  const expandIcon = fullPage ? "compress" : "expand arrows alternate";
  const isEditable =
    props.editable &&
    !props.fullPage &&
    props.replayState === ReplayState.ReplayStopped;

  const isCompareDashboard = window.location.pathname.includes(
    "/compare-dashboards/"
  );

  return (
    // @ts-ignore
    <FullScreen handle={fullScreenHandle}>
      <div className="bytebeam-panel">
        <div className="bytebeam-panel-header">
          <div className="bytebeam-panel-title">
            {panelMeta.title || "Untitled"}
          </div>
          {props.loading ? (
            <span className="panel-loader-icon">
              <LoadingAnimation
                loaderSize="15px"
                marginTopText="0px"
                loaderBorderSize="2px"
              />
            </span>
          ) : (
            ""
          )}
          {props.error ? (
            <span className="panel-error-icon">
              <Popup
                content={
                  props.errorMessage
                    ? props.errorMessage + ", click here to retry"
                    : "Failed to fetch panel data, click here to retry"
                } // Changed text to returned error message in panel-data error
                position="top center"
                inverted
                trigger={<Icon name="redo" color="red" onClick={onRefresh} />}
              />
            </span>
          ) : (
            ""
          )}
          {!isCompareDashboard && (
            <>
              {!fullScreenHandle.active ? (
                <span className="bytebeam-panel-icons desktop-view">
                  <span className="bytebeam-panel-icon">
                    <Popup
                      content={
                        props.fullPage ? "Exit full page" : "View full page"
                      }
                      position={props.fullPage ? "bottom right" : "top center"}
                      inverted
                      trigger={
                        <Icon
                          name={expandIcon}
                          link
                          onClick={onToggleFullPage}
                        />
                      }
                    />
                  </span>

                  <span className="bytebeam-panel-icon desktop-view">
                    <Popup
                      content="View full screen"
                      position={props.fullPage ? "bottom right" : "top center"}
                      inverted
                      trigger={
                        <Icon
                          name="expand"
                          link
                          onClick={fullScreenHandle.enter}
                        />
                      }
                    />
                  </span>

                  {props.loading ? (
                    <span className="bytebeam-panel-icon">
                      <LoadingAnimation
                        loaderSize="15px"
                        marginTopText="0px"
                        loaderBorderSize="2px"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                  {props.onEdit && isEditable ? (
                    <span className="bytebeam-panel-icon">
                      <Popup
                        content="Edit panel"
                        position="top center"
                        inverted
                        trigger={<Icon name="pencil" link onClick={onEdit} />}
                      />
                    </span>
                  ) : (
                    ""
                  )}

                  {props.onClone && isEditable ? (
                    <span className="bytebeam-panel-icon">
                      <Popup
                        content="Clone panel"
                        position="top center"
                        inverted
                        trigger={<Icon name="clone" link onClick={onClone} />}
                      />
                    </span>
                  ) : (
                    ""
                  )}

                  {props.onDelete && isEditable ? (
                    <span className="bytebeam-panel-icon">
                      <Popup
                        content="Delete panel"
                        position="top center"
                        inverted
                        trigger={<Icon name="trash" link onClick={onDelete} />}
                      />
                    </span>
                  ) : (
                    ""
                  )}

                  {props.panelDef?.download === "server" ? (
                    <DownloadPanelDataModal
                      panelMeta={props.panelMeta}
                      fetchParams={props.fetchParams}
                    />
                  ) : (
                    ""
                  )}
                </span>
              ) : (
                ""
              )}

              {!fullScreenHandle.active ? (
                <span
                  className="bytebeam-panel-icons mob-view"
                  style={{ padding: "0px" }}
                >
                  <Dropdown
                    style={{ border: "none" }}
                    direction="left"
                    trigger={
                      <StyledButton floated="right" icon>
                        <Icon name="ellipsis vertical" />
                      </StyledButton>
                    }
                    icon={null}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={onToggleFullPage}>
                        <Icon name={expandIcon} />
                        {props.fullPage ? "Exit Full Page" : "View Full Page"}
                      </Dropdown.Item>
                      {props.onEdit && isEditable && (
                        <Dropdown.Item onClick={onEdit}>
                          <Icon name="pencil" />
                          Edit Panel
                        </Dropdown.Item>
                      )}
                      {props.onEdit && isEditable && (
                        <Dropdown.Item onClick={onClone}>
                          <Icon name="clone" />
                          Clone Panel
                        </Dropdown.Item>
                      )}
                      {props.onEdit && isEditable && (
                        <Dropdown.Item onClick={onDelete}>
                          <Icon name="trash" />
                          Delete Panel
                        </Dropdown.Item>
                      )}
                      {props.panelDef?.download === "server" && (
                        <DownloadPanelDataModal
                          panelMeta={props.panelMeta}
                          fetchParams={props.fetchParams}
                          mobileView={true}
                        />
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        {getPanelContent()}
      </div>
    </FullScreen>
  );
}

import React from "react";
import StyledSVGIcon from "../../components/common/StyledSVGIcon";

const svgContent = `<svg
width="24"
height="17"
viewBox="0 0 24 17"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M6 17C4.34545 17 2.93182 16.4422 1.75909 15.3266C0.586364 14.2109 0 12.8474 0 11.2359C0 9.85469 0.427273 8.62396 1.28182 7.54375C2.13636 6.46354 3.25455 5.77292 4.63636 5.47188C5.09091 3.84271 6 2.52344 7.36364 1.51406C8.72727 0.504688 10.2727 0 12 0C14.1273 0 15.9318 0.721615 17.4136 2.16484C18.8955 3.60807 19.6364 5.36562 19.6364 7.4375C20.8909 7.57917 21.9318 8.10599 22.7591 9.01797C23.5864 9.92995 24 10.9969 24 12.2188C24 13.5469 23.5227 14.6758 22.5682 15.6055C21.6136 16.5352 20.4545 17 19.0909 17H13.0909C12.4909 17 11.9773 16.7919 11.55 16.3758C11.1227 15.9596 10.9091 15.4594 10.9091 14.875V9.40313L9.16364 11.05L7.63636 9.5625L12 5.3125L16.3636 9.5625L14.8364 11.05L13.0909 9.40313V14.875H19.0909C19.8545 14.875 20.5 14.6182 21.0273 14.1047C21.5545 13.5911 21.8182 12.9625 21.8182 12.2188C21.8182 11.475 21.5545 10.8464 21.0273 10.3328C20.5 9.81927 19.8545 9.5625 19.0909 9.5625H17.4545V7.4375C17.4545 5.96771 16.9227 4.71484 15.8591 3.67891C14.7955 2.64297 13.5091 2.125 12 2.125C10.4909 2.125 9.20455 2.64297 8.14091 3.67891C7.07727 4.71484 6.54545 5.96771 6.54545 7.4375H6C4.94545 7.4375 4.04545 7.80052 3.3 8.52656C2.55455 9.2526 2.18182 10.1292 2.18182 11.1562C2.18182 12.1833 2.55455 13.0599 3.3 13.7859C4.04545 14.512 4.94545 14.875 6 14.875H8.72727V17H6Z"
  fill="#fff"
/>
</svg>`;

type BackupIconProps = {
  height: string;
  // Allowing all other props
  [x: string]: any;
};

function BackupIcon(props: Readonly<BackupIconProps>) {
  const { height, ...rest } = props;

  return <StyledSVGIcon height={height} {...rest} svgContent={svgContent} />;
}

export default BackupIcon;

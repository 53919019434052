import React from "react";
import { Icon, Table } from "semantic-ui-react";

type TableHeaderCellWithSortingProps = {
  readonly label: string;
  readonly columnKey: string;
  readonly onSort: (key: string) => void;
  readonly sortConfig: {
    key: string;
    direction: "ascending" | "descending" | undefined;
  };
};

export const TableHeaderCellWithSorting = (
  props: TableHeaderCellWithSortingProps
) => {
  const { label, columnKey, onSort, sortConfig } = props;
  const handleSortClick = () => {
    onSort(columnKey);
  };

  return (
    <Table.HeaderCell onClick={handleSortClick}>
      {label}
      {sortConfig.key === columnKey && (
        <Icon
          style={{ marginLeft: "8px" }}
          name={
            sortConfig.direction === "ascending" ? "caret up" : "caret down"
          }
        />
      )}
    </Table.HeaderCell>
  );
};

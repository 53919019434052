import { PieChartData, PieChartMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import Plot from "react-plotly.js";

export class ViewPieChart extends PanelViewComponent<
  PieChartMetaData,
  PieChartData
> {
  render() {
    const plotStyle = {
      width: "calc(100% - 4px)",
      height: "calc(100% - 29px)", //25px panel title + 4px margin
      marginTop: "2px",
      marginLeft: "2px",
    };

    const layout = {
      autosize: true,
      responsive: true,
      margin: {
        l: 25,
        r: 25,
        b: 25,
        t: 50,
      },
    } as Partial<Plotly.Layout>;

    const values = this.props.data.map((d) => d.value);
    const labels = this.props.data.map((d) => d.group);

    const data = {
      values: values,
      labels: labels,
      type: "pie",
    } as Plotly.Data;

    return (
      <Plot
        data={[data]}
        style={plotStyle}
        layout={layout}
        useResizeHandler={true}
        config={{ displayModeBar: false }}
      />
    );
  }
}

import * as React from "react";
import { SVGProps } from "react";

const IframePanelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="6 51.33 138 52.06"
    style={{ scale: "1.3" }}
    scale="1.5"
    fill="none"
    stroke="currentColor"
    strokeWidth="3"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    {/* Outer rectangle */}
    <rect x="10" y="25" width="130" height="95" rx="5" ry="5" />

    {/* Inner rectangle (Iframe) */}
    <rect x="20" y="35" width="110" height="75" rx="4" ry="4" />

    {/* Content lines */}
    <line x1="25" y1="50" x2="125" y2="50" />
    <line x1="25" y1="65" x2="125" y2="65" />

    {/* Embed ICon */}
    <polyline points="55,80 45,90 55,100" />
    <polyline points="95,80 105,90 95,100" />
  </svg>
);

export default IframePanelIcon;

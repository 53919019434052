import uuid from "uuid";
import { TimeRange } from "../../Datetime/TimeRange";
import { ReplayState } from "../../DashboardHeader";
import { PanelDef } from "../PanelDef";
import { ViewStreamStatus } from "./ViewStreamStatus";
import { EditStreamStatusMeta } from "./EditStreamStatusMeta";

// Define default values for on and off states
export const onStateDefaultValues = ["on", "true"];
export const offStateDefaultValues = ["off", "false", ""];

export class StreamStatusMetaData {
  type = "stream_status";
  tables: string[] = ["device_shadow"];
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  onStateColor: string = "#39fa39";
  offStateColor: string = "#fa1c1c";
}

export type TimestampedValue = {
  timestamp: number;
  [key: string]: number;
};

export type StreamStatusData = {
  data: TimestampedValue[];
};

function formatData(
  panelData: StreamStatusData,
  timeRange: TimeRange,
  replayState: ReplayState,
  replayStep: number
): StreamStatusData {
  if (replayState === ReplayState.ReplayStopped) {
    return panelData;
  }

  const data = panelData.data;
  const newData: TimestampedValue[] = [];
  let index = 0;
  const startTimestamp = timeRange.getStartTime().toDate().valueOf();
  const endTimestamp = timeRange.getEndTime().toDate().valueOf();

  for (let i = startTimestamp; i <= endTimestamp; i += replayStep) {
    while (index < data.length) {
      if (data[index].timestamp > i) {
        break;
      }

      index++;
    }

    index = index === 0 ? 0 : index - 1;

    newData.push(data[index]);
  }

  return {
    data: newData,
  };
}

export const StreamStatusPanelDef: PanelDef<
  StreamStatusMetaData,
  StreamStatusData
> = {
  ViewComponent: ViewStreamStatus,
  EditComponent: EditStreamStatusMeta,
  formatData: formatData,
  metaConstructor: StreamStatusMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

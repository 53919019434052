import React from "react";
import { Modal, Icon, Button } from "semantic-ui-react";
import { deactivateDeviceConfig } from "../../../../../BytebeamClient";
import { beamtoast } from "../../../../common/CustomToast";

type DeactivateConfigModalProps = {
  isOpen: boolean;
  version: string;
  config_type: string;
  onClose: () => void;
  fillConfigsTable: () => Promise<void>;
};

function DeactivateConfigModal(props: DeactivateConfigModalProps) {
  const { isOpen, version, onClose, fillConfigsTable, config_type } = props;

  async function deactivateConfig() {
    try {
      const data = await deactivateDeviceConfig(version);
      beamtoast.success("Deactivated config " + data.version_name + ".");
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Modal open={isOpen} size="tiny" className="dark" onClose={onClose}>
      <Modal.Header>
        Deactivate {config_type === "json" ? "Device" : "Geofence"}{" "}
        Configuration Version {version}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div
            style={{
              fontSize: "16px",
              fontWeight: 600,
              marginBottom: "12px",
            }}
          >
            Are you sure you want to deactivate{" "}
            {config_type === "json" ? "device" : "geofence"} config version{" "}
            <b>{version}</b>?
          </div>
          <p
            style={{
              marginBottom: "12px",
            }}
          >
            Please note that if you proceed with deactivating the config version{" "}
            <b>{version}</b> , you will not be able to update devices with this
            config version in the future. Would you still like to proceed?
          </p>
          Click the <b>Deactivate</b> button to proceed with the deactivation
          process, or click the <b>Discard</b> button.
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => onClose()}>
          <Icon name="remove" /> Discard
        </Button>
        <Button
          primary
          onClick={async () => {
            await deactivateConfig();
            await fillConfigsTable();
            onClose();
          }}
        >
          <Icon name="checkmark" /> Deactivate
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DeactivateConfigModal;

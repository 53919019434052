import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewStaticText } from "./ViewStaticText";
import { EditStaticTextMeta as EditStaticText } from "./EditStaticTextMeta";

export class StaticTextMetaData {
  type = "static_text";
  text: string = "";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  textColor: string = "";
  textSize: number = 50;
  textStyle: string = "";
}

export type StaticTextData = {};

export const StaticTextPanelDef: PanelDef<StaticTextMetaData, StaticTextData> =
  {
    ViewComponent: ViewStaticText,
    EditComponent: EditStaticText,
    formatData: (data) => data,
    metaConstructor: StaticTextMetaData,
    previewAspectRatio: { width: 500, height: 300 },
  };

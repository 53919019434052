import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewHistogram } from "./ViewHistogram";
import { EditHistogramMeta } from "./EditHistogramMeta";

export class HistogramMetaData {
  type = "histogram";
  table: string = "";
  column: string = "";
  numBins: number = 10;

  id: string = uuid.v4();
  title: string = "";
  description: string = "";
}

export type HistogramValue = {
  start: number;
  end: number;
  height: number;
};

export type HistogramData = HistogramValue[];

export const HistogramPanelDef: PanelDef<HistogramMetaData, HistogramData> = {
  ViewComponent: ViewHistogram,
  EditComponent: EditHistogramMeta,
  formatData: (data) => data,
  metaConstructor: HistogramMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

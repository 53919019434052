import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewLogs } from "./ViewLogs";
import { EditLogsMeta } from "./EditLogsMeta";

export class LogsMetaData {
  type = "logs";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  logLevel?: string;
  tags?: string[];
  searchString?: string;
  table: string = "";
  page?: number = 1;
  rowsPerPage: number = 50;
  showSearchBar: boolean = false;
  numBins: number = 10;
}

export type LogsPoint = {
  id: string;
  timestamp: number;
  [key: string]: any;
};

export type LogsData = {
  data: LogsPoint[];
  tags: string[];
  totalRows: number;
  histogram: LogHistogramData;
};

export type LogHistogramData = {
  start: number;
  end: number;
  height: number;
}[];

export const LogsPanelDef: PanelDef<LogsMetaData, LogsData> = {
  ViewComponent: ViewLogs,
  EditComponent: EditLogsMeta,
  formatData: (data) => data,
  metaConstructor: LogsMetaData,
  previewAspectRatio: { width: 500, height: 500 },
  download: "server",
};

export const logLevelOptions = ["Trace", "Debug", "Info", "Warn", "Error"].map(
  (level) => {
    return {
      key: level,
      value: level,
      text: level,
    };
  }
);

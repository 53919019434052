import React from "react";
import { Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { breakpoints } from "../../common/breakpoints";

type CardsValuesProps = {
  clickedCards: { [key: string]: boolean };
  cardKey: string;
  heading: string;
  description: string;
  onClickExplore: () => void;
};

type CardStylingProps = {
  isChecked: boolean;
};

const CardStyling = styled.div<CardStylingProps>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  min-width: 200px;
  min-height: 200px;
  padding: 20px;
  background: ${({ theme }) =>
    theme.colors["get-started-cards-background-color"]};
  box-shadow: ${({ theme }) =>
    theme.colors["get-started-cards-box-shadow"]} !important;
  border-radius: 12px;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-width: 0px 0px 60px 60px;
    border-style: solid;
    border-color: ${({ theme }) => `
    ${theme.colors["get-started-cards-peel-background-color"]}
    ${theme.colors["background-color"]}
    ${theme.colors["background-color"]}
    ${theme.colors["get-started-cards-peel-background-color"]}
    `};
    border-radius: 12px 0 0 0;
  }

  h2 {
    font-size: 22px;
  }

  .cardDescription {
    font-size: 20px;
  }

  .checkIcon {
    position: absolute;
    margin: 0;
    bottom: 0;
    right: 0;
    font-size: 26px;
    color: ${({ isChecked, theme }) =>
      isChecked
        ? theme.colors["get-started-cards-read-ticked-color"]
        : theme.colors["get-started-cards-peel-background-color"]};
  }

  @media (max-width: ${breakpoints.sm}px) {
    min-height: 170px;

    h2 {
      font-size: 20px;
    }

    .cardDescription {
      font-size: 18px;
    }
  }
`;

const ExploreButton = styled(Button)`
  margin-top: 15px !important;
  width: fit-content;
  padding: 5px 30px !important;
  background-color: ${({ theme }) =>
    theme.colors["get-started-explore-button-background-color"]} !important;
  border-radius: 44px !important;
  color: ${({ theme }) =>
    theme.colors["get-started-explore-button-text-color"]} !important;
  font-weight: 700 !important;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors[
        "get-started-explore-button-background-hover-color"
      ]} !important;
  }
`;

const GetStartedCard = ({
  cardKey,
  heading,
  description,
  onClickExplore,
  clickedCards,
}: CardsValuesProps) => {
  return (
    <CardStyling isChecked={clickedCards?.[cardKey]}>
      <div>
        <h2>{heading}</h2>
        <p className="cardDescription">{description}</p>
      </div>
      <ExploreButton onClick={() => onClickExplore()}>
        <p>Explore</p>
      </ExploreButton>
      <Icon
        id={`${clickedCards?.[cardKey] ? "check" : "unchecked"}`}
        className="checkIcon"
        name={"check circle"}
      />
    </CardStyling>
  );
};

export default GetStartedCard;

import {
  Timestamp,
  RelativeTimestamp,
  deserializeTimestamp,
  SerializedTimestamp,
  AbsoluteTimestamp,
} from "./Timestamp";

import moment from "moment";

export type SerializedRelativeTimeRange = {
  type: "RelativeTimeRange";
  duration: number;
  units: moment.DurationInputArg2;
};

export type SerializedAbsoluteTimeRange = {
  type: "AbsoluteTimeRange";
  from: SerializedTimestamp;
  to: SerializedTimestamp;
};

export type SerializedNamedTimeRange = {
  type: "NamedTimeRange";
  name: String;
};

export type SerializedTimeRange =
  | SerializedAbsoluteTimeRange
  | SerializedRelativeTimeRange
  | SerializedNamedTimeRange;

export interface TimeRange {
  getStartTime(): Timestamp;
  getEndTime(): Timestamp;
  toString(): string;
  serialize(): SerializedTimeRange;
}

export function deserializeTimeRange(o: SerializedTimeRange) {
  if (o?.type === "RelativeTimeRange") {
    return RelativeTimeRange.fromSerialized(o);
  } else if (o?.type === "AbsoluteTimeRange") {
    return AbsoluteTimeRange.fromSerialized(o);
  } else {
    return NamedTimeRange.fromSerialized(o);
  }
}

export class RelativeTimeRange implements TimeRange {
  duration: number;
  units: moment.DurationInputArg2;

  static fromSerialized(o: SerializedRelativeTimeRange) {
    return new RelativeTimeRange(o.duration, o.units);
  }

  constructor(duration, units) {
    this.duration = duration;
    this.units = units;
  }

  getStartTime() {
    return new RelativeTimestamp(this.duration, this.units);
  }

  getEndTime() {
    return new RelativeTimestamp(0, "seconds");
  }

  toString() {
    return `Last ${this.duration} ${this.units}`;
  }

  serialize(): SerializedRelativeTimeRange {
    return {
      type: "RelativeTimeRange",
      duration: this.duration,
      units: this.units,
    };
  }
}

export class AbsoluteTimeRange implements TimeRange {
  from: Timestamp;
  to: Timestamp;

  static fromSerialized(o: SerializedAbsoluteTimeRange) {
    return new AbsoluteTimeRange(
      deserializeTimestamp(o.from),
      deserializeTimestamp(o.to)
    );
  }

  constructor(from: Timestamp, to: Timestamp) {
    this.from = from;
    this.to = to;
  }

  getStartTime() {
    return this.from;
  }

  getEndTime() {
    return this.to;
  }

  toString() {
    return `${this.from.toString()} to ${this.to.toString()}`;
  }

  serialize(): SerializedAbsoluteTimeRange {
    return {
      type: "AbsoluteTimeRange",
      from: this.from.serialize(),
      to: this.to.serialize(),
    };
  }
}

export class NamedTimeRange implements TimeRange {
  name: string;

  static timeRanges = [
    new NamedTimeRange("Today"),
    new NamedTimeRange("Yesterday"),
    new NamedTimeRange("This week"),
    new NamedTimeRange("Previous week"),
    new NamedTimeRange("This month"),
    new NamedTimeRange("Previous month"),
    new NamedTimeRange("This year"),
    new NamedTimeRange("Previous year"),
  ];

  static fromSerialized(o: SerializedNamedTimeRange) {
    return NamedTimeRange.timeRanges.filter(
      (timeRange) => timeRange?.name === o?.name
    )[0];
  }

  constructor(name: string) {
    this.name = name;
  }

  getStartTime() {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const yesterday = new Date(new Date(today).setDate(today.getDate() - 1));
    const startOfWeek = new Date(
      new Date(today).setDate(
        today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
      )
    );
    const startOfPreviousWeek = new Date(
      new Date(startOfWeek).setDate(startOfWeek.getDate() - 7)
    );
    const startOfMonth = new Date(new Date(today).setDate(1));
    const startOfPreviousMonth = new Date(
      new Date(startOfMonth).setMonth(startOfMonth.getMonth() - 1)
    );
    const startOfYear = new Date(new Date(startOfMonth).setMonth(0));
    const startOfPreviousYear = new Date(
      new Date(startOfYear).setFullYear(startOfYear.getFullYear() - 1)
    );

    switch (this.name) {
      case "Today":
        return new AbsoluteTimestamp(today);

      case "Yesterday":
        return new AbsoluteTimestamp(yesterday);

      case "This week":
        return new AbsoluteTimestamp(startOfWeek);

      case "Previous week":
        return new AbsoluteTimestamp(startOfPreviousWeek);

      case "This month":
        return new AbsoluteTimestamp(startOfMonth);

      case "Previous month":
        return new AbsoluteTimestamp(startOfPreviousMonth);

      case "This year":
        return new AbsoluteTimestamp(startOfYear);

      case "Previous year":
        return new AbsoluteTimestamp(startOfPreviousYear);
    }

    return new AbsoluteTimestamp(today);
  }

  getEndTime() {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const startOfWeek = new Date(
      new Date(today).setDate(
        today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
      )
    );
    const startOfMonth = new Date(new Date(today).setDate(1));
    const startOfYear = new Date(new Date(startOfMonth).setMonth(0));

    switch (this.name) {
      case "Today":
      case "This week":
      case "This month":
      case "This year":
        return new RelativeTimestamp(0, "seconds");

      case "Yesterday":
        return new AbsoluteTimestamp(today);

      case "Previous week":
        return new AbsoluteTimestamp(startOfWeek);

      case "Previous month":
        return new AbsoluteTimestamp(startOfMonth);

      case "Previous year":
        return new AbsoluteTimestamp(startOfYear);
    }

    return new AbsoluteTimestamp(today);
  }

  toString() {
    return this.name;
  }

  serialize(): SerializedNamedTimeRange {
    return {
      type: "NamedTimeRange",
      name: this.name,
    };
  }
}

import { DeviceStatusMetaData } from "./PanelDef";
import React from "react";
import { Tab } from "semantic-ui-react";
import { PanelEditComponent, PartialMetaData } from "../PanelDef";
import { EditMetaRoot, EditPanelFormContainer } from "../util";

export type EditDeviceStatusMetaState = {
  text: string;
  textColor: string;
  textSize: number;
  textStyle: string;
  displayPicker: boolean;
};

export class EditDeviceStatusMeta extends PanelEditComponent<
  DeviceStatusMetaData,
  EditDeviceStatusMetaState
> {
  titleRef = React.createRef<HTMLInputElement>();
  descriptionRef = React.createRef<HTMLInputElement>();
  textRef = React.createRef<HTMLInputElement>();

  getPanelMeta(): PartialMetaData<DeviceStatusMetaData> {
    const meta: DeviceStatusMetaData = {
      type: "device_status",
      id: this.props.panelMeta.id,
      title: this.titleRef.current?.value || "Device Status",
    };

    return {
      meta: meta,
      complete: this.isValidPanelMeta(meta),
    };
  }

  isValidPanelMeta(meta: DeviceStatusMetaData) {
    return true;
  }

  render() {
    const panes = [
      {
        menuItem: "General",
        pane: (
          <Tab.Pane key="general">
            <EditPanelFormContainer>
              <EditMetaRoot>Nothing to configure</EditMetaRoot>
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View",
        pane: (
          <Tab.Pane key="view">
            <EditPanelFormContainer>
              Nothing to configure
            </EditPanelFormContainer>
          </Tab.Pane>
        ),
      },
    ];

    return (
      <EditMetaRoot>
        <div>
          <Tab menu={{}} panes={panes} renderActiveOnly={false} />
        </div>
      </EditMetaRoot>
    );
  }
}

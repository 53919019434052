import React, { useState } from "react";
import { Grid, Icon, Popup } from "semantic-ui-react";
import TypeSelection from "./TypeSelection";
import ConfirmationModal from "../../common/ConfirmationModal";
import { formatNames } from "./Streams";

type TwoItemsProps = {
  readonly left: JSX.Element;
  readonly right: JSX.Element;
};

export const TwoItems = ({ left, right }: TwoItemsProps) => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={12}>{left}</Grid.Column>
      <Grid.Column
        width={4}
        verticalAlign="middle"
        floated="right"
        textAlign="right"
        style={{ paddingLeft: "0px", paddingRight: "36px" }}
      >
        {right}
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

type ColumnTypeProps = {
  readonly tableName: string;
  readonly required: boolean;
  readonly value: string;
  readonly pendingType: string;
  readonly status: string;
  readonly columnName: string;
  readonly dbcParserStreamName: Map<string, string[]>;
  readonly onChange: (newType: string) => void;
  readonly permission: boolean;
};

export default function ColumnType(props: ColumnTypeProps) {
  const {
    tableName,
    required,
    value,
    pendingType,
    status,
    columnName,
    dbcParserStreamName,
    onChange,
    permission,
  } = props;

  const [newType, setNewType] = useState(value);
  const [editMode, setEditMode] = useState(false);

  const isDBCParserStream = dbcParserStreamName.has(tableName);
  const streamDbcParserNames = dbcParserStreamName.get(tableName) ?? [];

  if (required) {
    return value;
  }

  if (status === "editing" && pendingType !== value) {
    return value + " -> " + pendingType;
  }
  if (!editMode) {
    let right = <></>;
    if (status === "ready" && permission) {
      if (isDBCParserStream) {
        right = (
          <Popup
            id="edit-stream-column-dbc-parser-popup"
            content={`Cannot edit column type in stream used as input stream in DBC Parser: ${formatNames(streamDbcParserNames)}`}
            inverted
            position="top center"
            trigger={<Icon link name="edit" disabled={isDBCParserStream} />}
          />
        );
      } else {
        right = <Icon link name="edit" onClick={() => setEditMode(true)} />;
      }
    }

    return <TwoItems left={<p>{value}</p>} right={right} />;
  } else {
    return (
      <TwoItems
        left={
          <TypeSelection
            value={newType}
            onChange={(_e, d) => setNewType(d.value)}
            disabled={false}
            id={"edit_column-type"}
            editMode={true}
          />
        }
        right={
          <>
            <ConfirmationModal
              prefixContent="Edit column type"
              expectedText={columnName}
              onConfirm={() => {
                setEditMode(false);
                onChange(newType);
              }}
              trigger={
                <Icon
                  link
                  name="save"
                  disabled={newType === value}
                  style={{ marginRight: "8px" }}
                />
              }
              message={
                <>
                  <p>
                    <span>Are you absolutely sure?</span>
                    <br />
                    You are going to edit <b>{columnName}</b>, this action will
                    edit column type for {columnName} column.
                  </p>
                  <div
                    style={{
                      padding: "8px",
                      color: "#c50000",
                      textAlign: "center",
                      background: "#f7d4d6",
                      border: "1px solid #c50000",
                      borderRadius: "4px",
                    }}
                  >
                    <b>Warning:</b> This action is not reversible. Please be
                    certain.
                  </div>
                  <p>
                    <br /> Please type <b>{columnName}</b> to proceed or press
                    Cancel to close.
                  </p>
                </>
              }
            />
            <Icon
              link
              name="cancel"
              onClick={() => {
                setEditMode(false);
                setNewType(value);
              }}
            />
          </>
        }
      />
    );
  }
}

import * as React from "react";
import { SVGProps } from "react";

const BarChartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 53 66"
    fill="none"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#a)">
      <path d="M3.054 28.78h6.883a3.053 3.053 0 0 1 3.054 3.04v31.126a3.053 3.053 0 0 1-3.053 3.041H3.053A3.053 3.053 0 0 1 0 62.946V31.82a3.053 3.053 0 0 1 3.054-3.042ZM24.08 0h4.853a4.054 4.054 0 0 1 4.063 4.047v57.88a4.054 4.054 0 0 1-4.063 4.047H24.08a4.054 4.054 0 0 1-4.063-4.047V4.047C20.005 1.817 21.829 0 24.08 0ZM43.606 14.396h5.797A3.582 3.582 0 0 1 53 17.98v44.439A3.582 3.582 0 0 1 49.403 66h-5.797a3.582 3.582 0 0 1-3.597-3.583V17.967c0-1.972 1.617-3.57 3.597-3.57Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="transparent" d="M0 0h53v66H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default BarChartIcon;

import * as React from "react";
import { SVGProps } from "react";

const TrackDevicesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_269_6)">
      <path
        d="M22.0656 46.6765H18.1185C17.0835 46.6765 16.1484 47.293 15.7386 48.2419L4.81348 73.6793L30.7588 60.4276C27.6521 56.1292 24.5799 51.39 22.0639 46.6748L22.0656 46.6765Z"
        fill="currentColor"
      />
      <path
        d="M72.26 48.2412C71.8519 47.2923 70.915 46.6758 69.8818 46.6758H65.9347C61.1834 55.5791 54.2622 64.7786 49.8605 69.8399C46.7641 73.3909 41.2327 73.3875 38.1415 69.8399C37.8246 69.4766 36.1748 67.565 33.8999 64.6254L29.2227 67.0157L46.9156 84.6759L80.4418 67.2946L72.26 48.2412Z"
        fill="currentColor"
      />
      <path
        d="M1.62268 81.1135L0.212271 84.3993C-0.517905 86.1008 0.734071 87.9985 2.59051 87.9985H40.5838C40.8214 87.8212 40.8249 87.8332 42.0992 87.1719L24.3787 69.4893L1.62268 81.1135Z"
        fill="currentColor"
      />
      <path
        d="M87.7883 84.4007L82.4876 72.0566L51.7324 88H85.4101C87.2631 88 88.5202 86.1039 87.7883 84.4007Z"
        fill="currentColor"
      />
      <path
        d="M46.5888 23.259C46.5888 21.8348 45.4281 20.6758 44.0004 20.6758C42.5728 20.6758 41.4121 21.8348 41.4121 23.259C41.4121 24.6831 42.5728 25.8421 44.0004 25.8421C45.4281 25.8421 46.5888 24.6831 46.5888 23.259Z"
        fill="currentColor"
      />
      <path
        d="M45.9532 66.2769C46.8246 65.2764 67.2936 41.5991 67.2936 25.3234C67.2936 -8.23718 20.707 -8.64705 20.707 25.3234C20.707 41.5991 41.1761 65.2764 42.0475 66.2769C43.079 67.46 44.9234 67.46 45.9532 66.2769ZM36.2353 23.2586C36.2353 18.986 39.7192 15.5108 44.0003 15.5108C48.2815 15.5108 51.7636 18.986 51.7636 23.2586C51.7636 27.5311 48.2798 31.0063 44.0003 31.0063C39.7209 31.0063 36.2353 27.5311 36.2353 23.2586Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_269_6">
        <rect width="88" height="88" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);

export default TrackDevicesIcon;

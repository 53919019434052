import React, { useState, useEffect, useRef, FormEvent } from "react";
import { Icon, Button, Grid, Form, Breadcrumb } from "semantic-ui-react";
import { uploadFile, FirmwareType } from "../../../../BytebeamClient";
import { Mixpanel } from "../../common/MixPanel";
import { useHistory, useLocation } from "react-router-dom";
import AnimatedEllipsis from "../../common/AnimatedEllipsis";
import { beamtoast } from "../../../common/CustomToast";

type EditFirmwareProps = {
  type: string;
  id: string;
  title: string;
  actionButtonText: string;
};

function EditFirmware(props: EditFirmwareProps) {
  const { type, id, title, actionButtonText } = props;
  const [versionNumber, setVersionNumber] = useState<string>("");
  const [existingFirmwareVersions, setExistingFirmwareVersions] = useState<
    Set<string>
  >(new Set());
  const [file, setFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<{ versionNumber: string }>({
    versionNumber: "",
  });
  const [fileLoaded, setFileLoaded] = useState<number>(0);
  const [fileTotal, setFileTotal] = useState<number>(0);
  const [showUploadProgress, setShowUploadProgress] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const fileInput = useRef<HTMLInputElement | null>(null);

  const updateFirmware = async () => {
    const url =
      type === "Edit" ? `/api/v1/firmwares/${id}` : `/api/v1/firmwares`;
    let formData = new FormData();
    formData.append("version-number", versionNumber);
    if (file !== null) formData.append("file", file);

    try {
      const res = await uploadFile(url, formData, (p) => {
        setFileLoaded(p.loaded);
        setFileTotal(p.total);
      });
      if (res.status === 201) {
        beamtoast.success(
          "Created Firmware version " + res.data["version-number"]
        );
        Mixpanel.track("Uploaded Firmware", {
          Firmware: res.data["version-number"],
        });
        history.push("/device-management/firmwares");
      }
    } catch (error) {
      Mixpanel.track("Failure", {
        type: "Upload Firmware",
      });
      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413"))
        beamtoast.error("Upload failed due to size limit!");
      else beamtoast.error("Failed to create Firmware version :(");
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (fileInput.current?.files) {
      const fileCount: number = fileInput.current.files.length;
      if (versionNumber.length === 0) {
        setErrors({
          versionNumber: "Please Enter a Version number",
        });
      } else if (existingFirmwareVersions.has(versionNumber)) {
        setErrors({
          versionNumber: "This firmware version already exists",
        });
      } else if (fileCount === 0) {
        setTimeout(() => beamtoast.error("A firmware file must be selected!"));
      } else {
        // form is valid
        setShowUploadProgress(true);
        await updateFirmware();
      }
    } else {
      setTimeout(() => beamtoast.error("A firmware file must be selected!"));
    }
  };

  useEffect(() => {
    const existingVersions: Set<string> = new Set();
    location.state.forEach((firmware: FirmwareType) => {
      existingVersions.add(firmware.version_number);
    });
    setExistingFirmwareVersions(existingVersions);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              link
              onClick={() => {
                history.push("/device-management/firmwares");
              }}
            >
              Firmwares
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section active>New Firmware</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row centered>
        <Grid.Column width="12">
          <h3>{title}</h3>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row centered>
        <Grid.Column width="12">
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Firmware Version Number</label>
              <Form.Input
                id="firmware_version_number"
                autoFocus
                error={
                  errors.versionNumber !== "" ? errors.versionNumber : null
                }
                placeholder="1.0.0"
                value={versionNumber}
                onChange={(e) => {
                  setVersionNumber(e.target.value);
                  setErrors({
                    versionNumber: "",
                  });
                }}
              />
            </Form.Field>
            <Form.Field>
              <input
                id="upload_file"
                type="file"
                ref={fileInput}
                onChange={(e) => {
                  if (e.target.files) setFile(e.target.files[0]);
                }}
              />
            </Form.Field>
            {showUploadProgress && (
              <Form.Field>
                <label htmlFor="file-progress">
                  Uploading Firmware File
                  <AnimatedEllipsis spacing={3} dotSize={"8px"} />
                </label>
                <progress
                  id="file-progress"
                  max={fileTotal}
                  value={fileLoaded}
                />
              </Form.Field>
            )}
            <Button.Group size="tiny">
              <Button
                id="cancel_button"
                labelPosition="left"
                icon
                secondary
                onClick={() => {
                  history.push("/device-management/firmwares");
                }}
              >
                <Icon name="cancel" />
                Discard
              </Button>
              <Button.Or />
              <Button
                id="submit_button"
                labelPosition="left"
                icon
                primary
                type="submit"
              >
                <Icon name="check" />
                {actionButtonText}
              </Button>
            </Button.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default EditFirmware;

import React, { useState, useEffect } from "react";
import { Table, Icon, Button, Grid, Popup } from "semantic-ui-react";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  fetchAllFirmwares,
  downloadFirmware,
  FirmwareType,
} from "../../../../BytebeamClient";
import ActivateFirmwareModal from "./ActivateFirmwareModal";
import { DisplayIf, getHumanReadableFileSizeString } from "../../util";
import { User } from "../../../../util";
import { useHistory } from "react-router-dom";
import Toggle from "../../../common/Toggle";
import LoadingAnimation from "../../../common/Loader";

type FirmwareVersionsProps = {
  user: User;
};

function FirmwareVersions(props: FirmwareVersionsProps) {
  const permissions = props.user?.role?.permissions || {};

  const [loading, setLoading] = useState<boolean>(true);
  const [firmwareVersion, setFirmwareVersion] = useState<FirmwareType>(
    {} as FirmwareType
  );
  const [firmwareVersions, setFirmwareVersions] = useState<FirmwareType[]>(
    [] as FirmwareType[]
  );
  const [allFirmwareVersions, setAllFirmwareVersions] = useState<
    FirmwareType[]
  >([] as FirmwareType[]);
  const [activateFirmwareModalIsOpen, setActivateFirmwareModalIsOpen] =
    useState<boolean>(false);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [showDeactivated, setShowDeactivated] = useState<boolean>(false);
  const [toggleIsLocked, setToggleIsLocked] = useState<boolean>(true);

  const history = useHistory();

  const openModal = () => {
    setActivateFirmwareModalIsOpen(true);
  };

  const closeModal = () => {
    setActivateFirmwareModalIsOpen(false);
  };

  async function fillFirmwareTable(showDeactivatedFirmwares?: boolean) {
    setLoading(true);
    try {
      const response = await fetchAllFirmwares();
      const filteredResponse = response.filter(
        (version) =>
          (typeof showDeactivatedFirmwares === "boolean"
            ? showDeactivatedFirmwares
            : showDeactivated) || !version.is_deactivated
      );
      setAllFirmwareVersions(response);
      setFirmwareVersions(filteredResponse);
    } catch (error) {
      setErrorOccurred(true);
    } finally {
      setLoading(false);
    }
  }

  async function downloadFile(data: Blob, fileName: string) {
    try {
      const element = document.createElement("a");
      const file = new Blob([data], {
        type: "text/plain",
      });

      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      setTimeout(() => element.click());
    } catch (error) {
      console.log("Error while creating file: ", error);
    }
  }

  async function downloadFirmwareByVersion(version: string, file: string) {
    try {
      const res = await downloadFirmware(version);
      const blob: Blob = await res?.blob();
      downloadFile(blob, `firmware-${version}-${file}`);
    } catch (error) {
      console.log("Error while downloading firmware: ", error);
    }
  }

  async function handleShowDeactivatedToggle() {
    setShowDeactivated(!showDeactivated);
    await fillFirmwareTable(!showDeactivated);
  }

  useEffect(() => {
    document.title = "Firmware files | Bytebeam";
    fillFirmwareTable();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorOccurred) {
    return <ErrorMessage marginTop="30px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading firmware versions"
      />
    );
  }

  return (
    <Grid>
      {/* Modal for activating and deactivating firmware */}
      <ActivateFirmwareModal
        isOpen={activateFirmwareModalIsOpen}
        close={closeModal}
        firmwareVersion={firmwareVersion}
        fillFirmwareTable={fillFirmwareTable}
        toggleHandler={() => setToggleIsLocked(!toggleIsLocked)}
      />

      <Grid.Row>
        <Grid.Column width="3">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "12px",
              marginTop: "24px",
            }}
          >
            <div>Show deactivated</div>
            <div>
              <Toggle
                checked={showDeactivated}
                onClick={async () => await handleShowDeactivatedToggle()}
                style={{
                  top: "2px",
                }}
              />
            </div>
          </div>
        </Grid.Column>

        <Grid.Column floated="right" width="3">
          <DisplayIf cond={permissions.editFirmwares}>
            <Button
              id="upload_firmware_button"
              primary
              floated="right"
              labelPosition="left"
              icon
              onClick={() => {
                history.push(
                  "/device-management/firmwares/add",
                  allFirmwareVersions
                );
              }}
              style={{ whiteSpace: "nowrap" }}
            >
              <Icon name="upload" />
              Upload New Firmware
            </Button>
          </DisplayIf>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Version Number</Table.HeaderCell>
                <Table.HeaderCell>File Name</Table.HeaderCell>
                <Table.HeaderCell>File Size</Table.HeaderCell>
                <Table.HeaderCell>Created At</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <DisplayIf cond={permissions.editFirmwares}>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </DisplayIf>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {firmwareVersions.length !== 0 ? (
                firmwareVersions.map((firmware) => {
                  return (
                    <Table.Row key={firmware.version_number}>
                      <Table.Cell>{firmware.version_number}</Table.Cell>
                      <Table.Cell>{firmware.file_name}</Table.Cell>
                      <Table.Cell>
                        {getHumanReadableFileSizeString(
                          firmware.content_length
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {new Date(firmware.created_at).toLocaleString()}
                      </Table.Cell>
                      <Table.Cell>
                        {firmware.is_deactivated ? "Deactivated" : "Active"}
                      </Table.Cell>
                      {/* check access to edit firmware */}
                      <DisplayIf cond={permissions.editFirmwares}>
                        <Table.Cell>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                            }}
                          >
                            <Popup
                              trigger={
                                <Toggle
                                  id={`${firmware.version_number} deactivateFirmwareToggle`}
                                  checked={!firmware.is_deactivated}
                                  isLocked={toggleIsLocked}
                                  onClick={() => {
                                    openModal();
                                    setFirmwareVersion(firmware);
                                    setToggleIsLocked(true);
                                  }}
                                />
                              }
                              content={`Click to ${
                                firmware.is_deactivated
                                  ? "activate"
                                  : "deactivate"
                              } firmware version`}
                              position="top center"
                              inverted
                              style={{ whiteSpace: "nowrap" }}
                            />
                            <Popup
                              trigger={
                                <Icon
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "4px",
                                  }}
                                  name="download"
                                  onClick={() => {
                                    downloadFirmwareByVersion(
                                      firmware.version_number,
                                      firmware.file_name
                                    );
                                  }}
                                />
                              }
                              content="Download Firmware Version"
                              position="top center"
                              inverted
                            />
                          </div>
                        </Table.Cell>
                      </DisplayIf>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={permissions.editFirmwares ? "6" : "5"}>
                    <ErrorMessage
                      marginTop="30px"
                      message={"No Firmwares Uploaded!"}
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default FirmwareVersions;

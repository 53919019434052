import React, { Component } from "react";
import { Modal, Button, Icon, Form, TextArea } from "semantic-ui-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import { ActionType } from "../../../../../util";
import { Form as JsonSchemaForm } from "@rjsf/semantic-ui";
import validator from "@rjsf/validator-ajv8";
import ThemeSchema from "../../../../../theme/schema";
import { beamtoast } from "../../../../common/CustomToast";
import AdvanceSettingsSection from "../../../Actions/NewAction/AdvanceSettingsSection";
import { ThinDivider } from "../../../Dashboards/Panel/util";
import { capitalizeFirstLetter } from "../../../util";

type ActionConfirmationModalProps = {
  actionType: ActionType;
  isOpen: boolean;
  close: (...args: any[]) => any;
  triggerAction: (
    arg1: string, // name
    arg2: {},
    ...args: any[]
  ) => any;
  selectedDevicesCount: number;
  allSelected: boolean;
  theme: string;
  endTimestamp: Date | number;
  updateActionTimeout: (timeout: Date | number) => void;
  updateAutoRetry: (retryUntil: number | Date) => void;
};

type ActionConfirmationModalState = {
  customJSON: string;
  customText: string;
};

export class ActionConfirmationModal extends Component<
  ActionConfirmationModalProps,
  ActionConfirmationModalState
> {
  state = { customJSON: "{}", customText: "" };

  confirmationSubmit(selectedAction) {
    let payload = {};
    if (selectedAction?.payload_type === "json") {
      if (this.state.customJSON === "{}") {
        beamtoast.error("Please enter JSON payload.");
        return;
      } else {
        try {
          payload = JSON.parse(this.state.customJSON);
        } catch (e) {
          beamtoast.error("Please enter correct JSON payload.");
          console.log("Error parsing JSON payload", e);
          return;
        }
      }
    } else if (selectedAction?.payload_type === "text") {
      if (!this.state.customText) {
        beamtoast.error("Please enter text payload.");
        return;
      }
      payload = this.state.customText;
    }
    this.props.triggerAction(this.props.actionType.type, payload);
    this.closeModal();
  }

  // Reset state and close modal
  closeModal = () => {
    this.setState({ customJSON: "{}", customText: "" });
    this.props.close();
  };

  handleTextChange = (e) => {
    this.setState({ customText: e.target.value });
  };

  renderForm() {
    if (this.props.actionType.payload_type === "json") {
      if (
        this.props.actionType.json_schema &&
        this.props.actionType.json_ui_schema
      ) {
        const jsonSchema = JSON.parse(this.props.actionType.json_schema);
        const jsonUISchema = JSON.parse(this.props.actionType.json_ui_schema);

        jsonUISchema["ui:submitButtonOptions"] = {
          norender: true,
        };

        return (
          <JsonSchemaForm
            schema={jsonSchema}
            uiSchema={jsonUISchema}
            formData={JSON.parse(this.state.customJSON)}
            onChange={({ formData }) => {
              this.setState({
                customJSON: JSON.stringify(formData),
              });
            }}
            validator={validator}
          />
        );
      }

      return (
        <>
          <p>Enter JSON payload</p>
          <AceEditor
            height="300px"
            width="100%"
            placeholder={"Enter custom JSON payload"}
            mode="json"
            theme={
              ThemeSchema.data[this.props?.theme ?? "dark"]?.colors[
                "ace-editor-theme"
              ]
            }
            name="custom-json"
            fontSize={14}
            value={this.state.customJSON}
            onChange={(val) => {
              this.setState({
                customJSON: val,
              });
            }}
            focus={true}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
            }}
            style={{
              marginBottom: "16px",
              borderRadius: "4px",
              border: `${
                ThemeSchema.data[this.props?.theme ?? "dark"]?.colors[
                  "ace-editor-border"
                ]
              }`,
              boxShadow: `${
                ThemeSchema.data[this.props?.theme ?? "dark"]?.colors[
                  "ace-editor-box-shadow"
                ]
              }`,
            }}
          />
        </>
      );
    } else if (this.props.actionType.payload_type === "text") {
      return (
        <>
          <p>Enter Text payload</p>
          <Form style={{ marginBottom: "16px" }}>
            <TextArea
              autoFocus
              rows={5}
              placeholder="Text payload"
              value={this.state.customText}
              onChange={this.handleTextChange}
            />
          </Form>
        </>
      );
    }
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Modal
        open={isOpen}
        onClose={this.closeModal}
        size="tiny"
        className="dark"
      >
        <Modal.Header
          id={`${this.props.actionType.payload_type}_${
            this.props.actionType.payload_type === "json" &&
            this.props.actionType.json_schema &&
            this.props.actionType.json_ui_schema
              ? "schema_defined"
              : "no_schema"
          }`}
        >
          Trigger <b>{capitalizeFirstLetter(this.props.actionType.type)}</b>{" "}
          action
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div style={{ fontSize: "16px", marginBottom: "16px" }}>
              Are you ready to trigger <b>{this.props.actionType.type}</b>{" "}
              action on{" "}
              <b>
                {this.props.allSelected
                  ? // ? `All (${this.props.selectedDevicesCount} devices)` TODO: Fix this once device count on search is available
                    `All devices`
                  : `${
                      this.props.selectedDevicesCount === 1
                        ? "1 device"
                        : `${this.props.selectedDevicesCount} devices`
                    }`}
              </b>{" "}
              ?
            </div>
            {this.renderForm()}
            <ThinDivider style={{ margin: "18px 0px" }} />
            <AdvanceSettingsSection
              endTimestamp={this.props.endTimestamp}
              releaseNotes={""}
              action={this.props.actionType.type}
              setEndTimestamp={this.props.updateActionTimeout}
              setReleaseNotes={() => console.log("Not implemented")}
              setRetryUntil={this.props.updateAutoRetry}
            />
            <ThinDivider style={{ margin: "18px 0px" }} />
            This action will be triggered on all selected devices. Please click
            on <b>Yes</b> button if you would like to proceed, else click on{" "}
            <b>No</b> button.
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={() => this.closeModal()}>
            <Icon name="remove" /> No
          </Button>
          <Button
            primary
            onClick={() => this.confirmationSubmit(this.props.actionType)}
          >
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

import React, { useEffect, useState, useCallback } from "react";
import { Menu } from "semantic-ui-react";
import {
  Route,
  Switch,
  Link,
  useRouteMatch,
  useLocation,
  Redirect,
  withRouter,
} from "react-router-dom";
import { DisplayIf } from "../../util";

import { StyledNavLinks } from "../Inventory/InventoryManagement";
import ActionLog from "./ActionLog";
import DeviceLog from "./DeviceLog";
import Terminal from "./Terminal";
import { DateTimeDropdown } from "../../Dashboards/Datetime/DateTimeDropdown";
import {
  RelativeTimeRange,
  TimeRange,
} from "../../Dashboards/Datetime/TimeRange";
import styled from "styled-components";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import {
  Filters,
  FetchStreamsAPIResponse,
  fetchAllStreamsWithDetails,
  fetchDeviceFilterOptions,
  getTenantFromURL,
} from "../../../../BytebeamClient";
import { EditAnimatedMetaDropdown } from "../../Dashboards/Panel/util";
import ActionButtonV3 from "../ActionsV3/ActionButton";
import { CardContainer } from "../../../common/ActionsUtils";
import { checkLogs } from "../util";
import { useUser } from "../../../../context/User.context";
import ThemeSchema from "../../../../theme/schema";

const DateTimeDropdownWrapper = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? "none" : "block")};
  .ui.selection.button.dropdown {
    background: ${(props) =>
      props.theme.colors["date_time_dropdown_button-background"]} !important;
    color: ${(props) =>
      props.theme.colors["date_time_dropdown_button-color"]} !important;
  }
`;

function LogsManagement(props) {
  const { path } = useRouteMatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const resolver: Record<string, string> = {
    [`${path}/action-log`]: "Action Log",
    [`${path}/device-log`]: "Device Log",
    [`${path}/terminal`]: "Terminal",
  };

  const { user } = useUser();
  const theme = user.settings?.theme ?? "dark";

  const [actionId] = useState<any>(searchParams.get("action_id"));
  const [activeItem, setActiveItem] = useState<string>(
    resolver[location.pathname] || "Device Log"
  );
  const [showDeviceLogsTab, setShowDeviceLogsTab] = useState<boolean>(false);
  const [logsStreamNames, setLogsStreamNames] = useState<string[]>([]);
  const [deviceOptions, setDeviceOptions] = useState<
    { key: string; value: string; text: string }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [timeRange, setTimeRange] = useState<TimeRange>(
    new RelativeTimeRange(5, "minutes")
  );
  const [deviceIdFromState, setDeviceIdFromState] = useState<number>(
    parseInt(searchParams.get("device_id") ?? "1")
  );
  const [selectedLogStream, setSelectedLogStream] = useState<string>("");

  const handleItemClick = (name: string) => {
    setActiveItem(name);
  };

  const menuTab = (name: string, to: string, shouldDisplay: boolean) => (
    <DisplayIf cond={shouldDisplay}>
      <StyledNavLinks
        as={Link}
        to={to}
        name={name}
        active={activeItem === name}
        onClick={() => handleItemClick(name)}
      >
        {name}
      </StyledNavLinks>
    </DisplayIf>
  );

  const onTimeRangeChange = (timeRange: TimeRange) => {
    setTimeRange(timeRange);
  };

  const checkAndAddLogStreams = useCallback(
    async (res: FetchStreamsAPIResponse) => {
      let checkLogsExist = false;
      let logsArray: string[] = [];
      Object.keys(res).forEach((key) => {
        if (checkLogs(key)) {
          checkLogsExist = true;
          logsArray.push(key);
        }
      });
      if (checkLogsExist) {
        setShowDeviceLogsTab(true);
        setLogsStreamNames(logsArray);
        setSelectedLogStream(logsArray[0]);
        if (logsArray.includes("logs")) setSelectedLogStream("logs");
      } else setShowDeviceLogsTab(false);
    },
    [logsStreamNames] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const checkLogsStreamExistence = async () => {
    try {
      const res = await fetchAllStreamsWithDetails();
      await checkAndAddLogStreams(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const updateDeviceIdInSearchParams = (newDeviceId) => {
    setDeviceIdFromState(newDeviceId);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("device_id", newDeviceId);
    props.history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    document.title = "Logs | Bytebeam";

    switch (location.pathname) {
      case `${path}/action-log`:
        setActiveItem("Action Log");
        break;

      case `${path}/device-log`:
        setActiveItem("Device Log");
        break;

      case `${path}/terminal`:
        setActiveItem("Terminal");
        break;

      default:
        break;
    }
  }, [location.pathname, activeItem]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    checkLogsStreamExistence();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const refreshFilterOptions = async (filters: Filters) => {
      const filterOptions = await fetchDeviceFilterOptions(filters);

      setDeviceOptions(
        filterOptions
          .filter((option) => option.filterName === "id")[0]
          .filterValues.map((device_id) => {
            let option = {
              key: device_id,
              value: device_id,
              text: device_id,
            };
            return option;
          })
      );
    };

    refreshFilterOptions({ id: [String(deviceIdFromState)] });
  }, [deviceIdFromState]);
  return (
    <>
      <ActionButtonV3
        margin_bottom="30px"
        margin_left="0px"
        label="Back"
        icon="left chevron"
        onClick={() =>
          props.history.push(
            actionId
              ? `/projects/${getTenantFromURL()}/actions/live-actions?action_id=${actionId}`
              : `/projects/${getTenantFromURL()}/device-management/devices`
          )
        }
      />

      <CardContainer>
        <StyledHeader
          as="h1"
          style={{
            marginTop: "0px",
            marginBottom: "24px",
            fontSize: "32px",
            fontWeight: 700,
          }}
        >
          Logs
        </StyledHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginTop: 16,
          }}
        >
          <Menu
            pointing
            secondary
            style={{ border: "none" }}
            className="desktop-view"
          >
            {/* {menuTab("Action Log", `${path}/action-log?action_id=${actionId}&device_id=${deviceIdFromState}`, true)} hidden for now */}
            {showDeviceLogsTab &&
              menuTab(
                "Device Log",
                `${path}/device-log?${
                  actionId ? `action_id=${actionId}&` : ""
                }device_id=${deviceIdFromState}`,
                true
              )}
            {menuTab(
              "Terminal",
              `${path}/terminal?${
                actionId ? `action_id=${actionId}&` : ""
              }device_id=${deviceIdFromState}`,
              true
            )}
          </Menu>

          <div style={{ display: "flex", gap: "16px" }}>
            <DateTimeDropdownWrapper hide={activeItem === "Terminal"}>
              <EditAnimatedMetaDropdown
                placeholder="Stream"
                elementid={"select_stream"}
                search
                selection
                options={logsStreamNames.map((streamName) => ({
                  key: streamName,
                  value: streamName,
                  text: streamName,
                }))}
                value={selectedLogStream}
                defaultValue={selectedLogStream}
                onChange={(e, { value }) =>
                  setSelectedLogStream(value as string)
                }
                disabled={loading}
                labelBg={
                  ThemeSchema.data[theme]?.colors[
                    "action-card-container-background"
                  ]
                }
              />
            </DateTimeDropdownWrapper>
            <DateTimeDropdownWrapper style={{ width: "194px" }} hide={false}>
              <EditAnimatedMetaDropdown
                placeholder="Device ID"
                elementid={"select_device"}
                search
                selection
                options={deviceOptions}
                value={deviceIdFromState}
                defaultValue={deviceIdFromState}
                onChange={(e, { value }) =>
                  updateDeviceIdInSearchParams(value as number)
                }
                disabled={loading}
                labelBg={
                  ThemeSchema.data[theme]?.colors[
                    "action-card-container-background"
                  ]
                }
              />
            </DateTimeDropdownWrapper>
            <DateTimeDropdownWrapper hide={activeItem === "Terminal"}>
              <DateTimeDropdown
                showControls={true}
                timeRange={timeRange}
                onTimeRangeChange={onTimeRangeChange}
                disabled={loading}
              />
            </DateTimeDropdownWrapper>
          </div>
        </div>

        <Switch>
          <Route exact path={`${path}/action-log`}>
            <DisplayIf cond={true}>
              <ActionLog
                timeRange={timeRange}
                onTimeRangeChange={onTimeRangeChange}
                loading={loading}
                setLoading={setLoading}
                logStream={selectedLogStream}
                logsStreams={logsStreamNames}
                selectedDevice={String(deviceIdFromState)}
              />
            </DisplayIf>
          </Route>

          <Route exact path={`${path}/device-log`}>
            <DisplayIf cond={true}>
              <DeviceLog
                timeRange={timeRange}
                onTimeRangeChange={onTimeRangeChange}
                loading={loading}
                setLoading={setLoading}
                logStream={selectedLogStream}
                logsStreams={logsStreamNames}
                selectedDevice={String(deviceIdFromState)}
              />
            </DisplayIf>
          </Route>

          <Route exact path={`${path}/terminal`}>
            <DisplayIf cond={true}>
              <Terminal deviceId={deviceIdFromState} />
            </DisplayIf>
          </Route>

          <Redirect
            from={path}
            to={`${path}/device-log?${
              actionId ? `action_id=${actionId}&` : ""
            }device_id=${deviceIdFromState}`}
          />
        </Switch>
      </CardContainer>
    </>
  );
}

export default withRouter(LogsManagement);

import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewDevicePulse } from "./ViewDevicePulse";
import { EditDevicePulseMeta } from "./EditDevicePulseMeta";

export type DevicePulseThreshold = {
  threshold: number;
  color: string;
};

export class DevicePulseMetaData {
  type = "device_pulse";
  id: string = uuid.v4();
  table: string = "device_shadow";
  title: string = "";
  description: string = "";
  thresholds: DevicePulseThreshold[] = [];
  device_dashboard_id: string = "";
  includeAll: boolean = true;
}

export type DevicePulseDataItem = {
  "-serial_metadata"?: { [key: string]: string };
  id: string;
  pulse: number;
  metadata: { [key: string]: string };
};

export type DevicePulseData = {
  data: DevicePulseDataItem[];
};

export const DevicePulsePanelDef: PanelDef<
  DevicePulseMetaData,
  DevicePulseData
> = {
  ViewComponent: ViewDevicePulse,
  EditComponent: EditDevicePulseMeta,
  formatData: (data) => data,
  metaConstructor: DevicePulseMetaData,
  previewAspectRatio: { width: 500, height: 500 },
};

export const defaultThresholds = [
  {
    threshold: 10,
    color: "#2fc76a",
  },
  {
    threshold: 1 * 60,
    color: "#0083c7",
  },
  {
    threshold: 10 * 60,
    color: "#dbce4e",
  },
  {
    threshold: 1 * 60 * 60,
    color: "#e35251",
  },
  {
    threshold: 24 * 60 * 60,
    color: "#7f65a3",
  },
];

import * as React from "react";
import { SVGProps } from "react";

const LocateDevicesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 69 85"
    fill="none"
    {...props}
  >
    <g
      fill="CurrentColor"
      fillRule="evenodd"
      clipPath="url(#a)"
      clipRule="evenodd"
    >
      <path d="M34.501 0C19.821 0 7.887 12.073 7.887 26.966c0 5.044 2.216 11.302 5.44 17.546 7.102 13.747 18.93 27.592 18.93 27.592a2.953 2.953 0 0 0 2.244 1.036c.863 0 1.684-.38 2.244-1.036 0 0 11.829-13.845 18.93-27.592 3.225-6.244 5.44-12.502 5.44-17.546C61.116 12.073 49.18 0 34.502 0Zm0 15.813c-5.984 0-10.843 4.87-10.843 10.873 0 6.002 4.857 10.873 10.843 10.873 5.987 0 10.844-4.87 10.844-10.873 0-6.003-4.857-10.873-10.844-10.873Z" />
      <path d="M53.364 63.757c3.205.965 5.848 2.159 7.672 3.558 1.183.9 2.05 1.783 2.05 2.858 0 .632-.359 1.206-.864 1.779-.835.945-2.077 1.796-3.615 2.585-5.433 2.787-14.218 4.531-24.106 4.531-9.888 0-18.673-1.744-24.106-4.531-1.538-.791-2.78-1.64-3.616-2.585-.504-.573-.863-1.147-.863-1.78 0-1.074.867-1.956 2.05-2.857 1.826-1.399 4.467-2.593 7.672-3.558a2.969 2.969 0 0 0 1.98-3.693 2.958 2.958 0 0 0-3.684-1.989C9.053 59.55 5.26 61.57 2.98 63.803.978 65.76 0 67.951 0 70.173c0 2.776 1.565 5.543 4.748 7.852 5.584 4.049 16.797 6.973 29.753 6.973s24.17-2.926 29.753-6.973c3.182-2.31 4.748-5.076 4.748-7.852 0-2.222-.978-4.413-2.981-6.37-2.28-2.233-6.072-4.253-10.953-5.728a2.958 2.958 0 0 0-3.683 1.989 2.97 2.97 0 0 0 1.979 3.693Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h69v85H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default LocateDevicesIcon;

import React, { useState } from "react";
import { Modal, Popup, Table } from "semantic-ui-react";
import { ButtonGroupedIcon } from "../../../util";
import { FirmwareVersionDependency } from "../../../../../BytebeamClient";

type DisplayDependenciesProps = {
  readonly dependencies: FirmwareVersionDependency[];
  readonly componentName: string;
  readonly firmwareVersion: string;
};

export default function DisplayDependencies(props: DisplayDependenciesProps) {
  const { dependencies, componentName, firmwareVersion } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function onModalClose() {
    setIsOpen(false);
  }
  return (
    <Modal
      className="dark"
      open={isOpen}
      onClose={onModalClose}
      size="small"
      closeIcon
      trigger={
        <Popup
          trigger={
            <ButtonGroupedIcon
              icon={{ name: "chain" }}
              cornerIcon={{
                name: "eye",
                corner: "bottom right",
              }}
              onClick={(e) => {
                e.stopPropagation();

                setIsOpen(true);
              }}
              style={{
                marginBottom: "0.5rem",
                marginRight: "0",
                cursor: "pointer",
              }}
            />
          }
          content={"Display Firmware Version Dependencies"}
          position="top center"
          inverted
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Modal.Header>
        {`Dependencies for firmware "${firmwareVersion}" of "${componentName}" component`}
      </Modal.Header>
      <Modal.Content>
        <Table fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">
                Required Component
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Minimum Firmware Version
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(dependencies === undefined || dependencies?.length === 0) && (
              <Table.Row>
                <Table.Cell colSpan={2} textAlign="center">
                  No dependencies added
                </Table.Cell>
              </Table.Row>
            )}
            {dependencies?.map((dependency) => (
              <Table.Row key={dependency?.component_name}>
                <Table.Cell textAlign="center">
                  {dependency?.component_name}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {dependency?.min_version}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
}

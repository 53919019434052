import { MicelioStatusData, MicelioStatusMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../../PanelDef";

import styled from "styled-components";
import moment from "moment";

import { stateIcons } from "../../map-util";

const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusIcon = styled.img`
  width: 32px;
  margin-right: 15px;
`;

export class ViewMicelioStatus extends PanelViewComponent<
  MicelioStatusMetaData,
  MicelioStatusData
> {
  ref = React.createRef<HTMLDivElement>();
  state = {
    fontSize: 10,
  };

  canvas = document.createElement("canvas");

  getTextWidth(text, font) {
    const context = this.canvas.getContext("2d");
    if (context) {
      context.font = font;

      const metrics = context.measureText(text);
      return metrics.width;
    }

    return 0;
  }

  calculateFontSize(fontFamily, maxWidth, maxHeight) {
    const text = "ABCDEFGHIJ";
    let numIterations = 0;
    let start = 10;
    let end = Math.max(10, Math.min(200, maxHeight));

    while (true) {
      const fontSize = (start + end) / 2;
      const font = `${fontSize}px ${fontFamily}`;

      numIterations += 1;

      if (numIterations > 20) {
        return fontSize;
      }

      const textWidth = this.getTextWidth(text, font);
      if (Math.abs(textWidth - maxWidth) < 2 || Math.abs(end - start) < 2) {
        return fontSize;
      }

      if (textWidth < maxWidth) {
        start = fontSize;
      } else {
        end = fontSize;
      }
    }
  }

  componentDidMount() {
    if (this.ref.current) {
      const rect = this.ref.current.getBoundingClientRect();
      const fontFamily = window.getComputedStyle(this.ref.current).fontFamily;

      const fontSize = this.calculateFontSize(
        fontFamily,
        rect.width * 0.9,
        rect.height * 0.5
      );

      this.setState({
        fontSize: 0.8 * fontSize,
      });
    }
  }

  render() {
    const { status, alertLevel } = this.props.data;

    return (
      <div className="big-number-root" ref={this.ref}>
        <div className="big-number-value">
          <Status>
            <StatusIcon
              src={stateIcons[status][alertLevel]}
              style={{ width: `${this.state.fontSize * 1.1}px` }}
              alt=""
            />
            <div style={{ fontSize: `${this.state.fontSize}px` }}>{status}</div>
          </Status>
        </div>
        <div className="big-number-timestamp">
          At: {moment(this.props.data.timestamp).format("DD-MM-YY HH:mm:ss")}
        </div>
      </div>
    );
  }
}

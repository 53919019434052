// Same as standard collection.map in javascript
// except you can change the order :
// instead of coll.map(fn) you can do map(fn)(coll)
const map = (mapFn) => (coll) => coll.map(mapFn);

// Same as standard collection.filter in javascript
// except you can change the order :
// instead of coll.filter(fn) you can do filter(fn)(coll)
const filter = (predicate) => (coll) => coll.filter(predicate);

// Applies map function to all elements in the
// the collection which matches the predicate.
// arr = [1, 2, 3, 4, 5, 6, 7]
// mapIf(isEven, x => x + 1)(arr)
// will return [1, 3, 3, 5, 5, 7, 7 ]
const mapIf = (predicate, mapFn) =>
  map((row) => (predicate(row) ? mapFn(row) : row));

// Sometimes you only have to modify only table.cols, this is
// a simple shorthand to make that problem easier.
// to add a new column
// addCol = updateColumns(cols => [...newCol, cols]);
// This returns a function which can be invoked for any
// table.
// addCol(table1) -- will add a newCol to table1
const updateColumns = (mapFn) => (table) => ({
  ...table,
  cols: mapFn(table.cols),
});

// Further specialization of updateColumns when you only
// need to update a single column.
// setOk = mapColumnIf(col => col.name === 'mycolumn',
//    col => {...col, status: "ok"})
// For example changes all columns whose name is mycolumn to
// a new status "ok".
// setOk can now be applied to any table
// setOk(table1) will set table1's column with name mycolumn's
// status to "ok"
const mapColumnIf = (predicate, mapFn) =>
  updateColumns(mapIf(predicate, mapFn));

export { map, filter, mapIf, updateColumns, mapColumnIf };

import React, { useState, useCallback, useEffect, useRef } from "react";
import LoadingAnimation from "../../../common/Loader";
import styled from "styled-components";
import { StyledHeader } from "../ActionsV3/SelectableItem";
import LiveActionsList from "./LiveActionsList";
import { Divider, MenuItem, PaginationProps } from "semantic-ui-react";
import { SelectDevicesPerPage } from "../../DeviceManagement/Devices/Devices";
import {
  ActionsNavbarActiveItemType,
  validateWholeNumber,
} from "../../../../util";

import DeviceV3Overview from "./DeviceV3Overview";
import {
  ActionStatusResponse,
  ActionStatusType,
  Filters,
  devicesPerPageOptions,
  fetchAction,
  fetchActionFilterOptions,
  fetchAllActionStatus,
  fetchAllUsers,
} from "../../../../BytebeamClient";
import DeviceListView from "./DeviceListView";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import {
  StyledCardSearchPageInput,
  StyledPagination,
  StyledSecondaryDevicePerPageWidget,
} from "../../../common/commonStyledComps";
import { ErrorMessage } from "../../../common/ErrorMessage";
import Toggle from "../../../common/Toggle";
import { beamtoast } from "../../../common/CustomToast";
import {
  CardContainer,
  FlexContainer,
  NewActionContainer,
} from "../../../common/ActionsUtils";
import ActionsSummarySection from "./ActionsSummarySection";
import ActionFilterNestedDropdown from "../ActionsV3/ActionFilterNestedDropdown";
import { ThinDivider } from "../../Dashboards/Panel/util";
import {
  NewActionLabelContainer,
  StyledNonBoldHeader,
} from "../NewAction/NewAction";
import ActionButtonV3 from "../ActionsV3/ActionButton";
import SelectedFilter from "../ActionsV3/SelectedFilter";
import { AddFiltersButton } from "../NewAction/PhaseControlSection";
import {
  ButtonPosition,
  NestedDropdownOptions,
  extractEmail,
  getObjectDepth,
} from "../util";
import { debounce } from "lodash";
import { useUser } from "../../../../context/User.context";

const _ = require("lodash");

/**
 * function to get position of button which renders dropdown
 * this is in order to give the open dropdown a static position
 * so that even if the button moves the dropdown does not move with it
 * @useCase upon adding multi-filters add button moves ahead
 *          we do not want the dropdown to move with it - bad ux
 * @param id of the button
 */
export const getButtonPosition = (id: string, setButtonPostionState) => {
  const buttonElement = document.getElementById(id);
  if (buttonElement) {
    const buttonPosition = buttonElement.getBoundingClientRect();
    setButtonPostionState({
      top: buttonPosition.top,
      left: buttonPosition.left,
      width: buttonPosition.width,
      height: buttonPosition.height,
    });
  }
};

interface LiveActionsProps extends RouteComponentProps {
  readonly setDoParamsExist: (arg0: boolean) => void;
  readonly setActionIDParam: (arg0: any) => void;
  readonly setActiveItem: (arg: ActionsNavbarActiveItemType) => void;
}

const NewActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

function LiveActions(props: LiveActionsProps) {
  const { user } = useUser();
  const allowedActions = user.role.permissions.allowedActions;

  const [mainLoading, setMainLoading] = useState<boolean>(true);
  const [selectedPhase, setSelectedPhase] = useState<string>("all");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [actionIDParam, setActionIDParam] = useState<any>(
    searchParams.get("action_id")
  );
  const [deviceIDParam, setDeviceIDParam] = useState<any>(
    searchParams.get("device_id")
  );
  const [showIncompleteActions, setShowIncompleteActions] =
    useState<boolean>(false);

  const [openAddPhase, setOpenAddPhase] = useState<boolean>(false);

  // Flag state to track the operations like pagination and actions per page change
  const operationInProgressRef = useRef(false);
  const controllerRef = useRef(new AbortController());
  const recreateAbortController = () => {
    controllerRef.current?.abort?.(); // Abort any ongoing operation
    controllerRef.current = new AbortController(); // Create a new controller for the next operation
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.get("action_id")) {
      setActionIDParam(searchParams.get("action_id"));
      props.setActionIDParam(searchParams.get("action_id"));
    } else {
      setActionIDParam(null);
      props.setActionIDParam(searchParams.get("action_id"));
    }

    if (searchParams.get("device_id"))
      setDeviceIDParam(searchParams.get("device_id"));
    else setDeviceIDParam(null);

    if (searchParams.get("action_id") && searchParams.get("device_id"))
      props.setDoParamsExist(true);
    else props.setDoParamsExist(false);

    if (window.location.search) {
      const searchParams = new URLSearchParams(location.search);

      if (
        searchParams.get("action_id") &&
        searchParams.get("device_id") &&
        window.location.search.includes("action_id") &&
        !window.location.search.includes("device_id")
      ) {
        props.setDoParamsExist(false);
        setDeviceIDParam(null);
      }
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  // -------------------- Action data fetching logic start ---------------------- //

  const isFirstRender = useRef(true);
  const selectedFilterKey = useRef("");

  const [filteredData, setFilteredData] = useState(Array<ActionStatusType>());
  const [users, setUsers] = useState<{ name: string; email: string }[]>([]);
  const [liveActionsListLoading, setLiveActionsListLoading] =
    useState<boolean>(true);
  const [totalActionsForPagination, setTotalActionsForPagination] =
    useState<number>(0);

  const [nestedDropdownOptions, setNestedDropdownOptions] = useState<
    NestedDropdownOptions[]
  >([]);
  const [filters, setFilters] = useState<Filters>({});
  const [filtersLoading, setFiltersLoading] = useState<boolean>(true);

  const [filtersPageNumber, setFiltersPageNumber] = useState<number>(1);

  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);
  const [showInsideFilterDropdown, setShowInsideFilterDropdown] =
    useState<boolean>(false);

  const [infiniteLoading, setInfiniteLoading] = useState<boolean>(true);

  const [depth, setDepth] = useState(1);
  const [currentDepth, setCurrentDepth] = useState<number>(0);

  // determining static position of button so as to support static dropdown even if button moves
  // refer relevant function (getButtonPosition)
  const [addFiltersButtonPosition, setAddFiltersButtonPosition] =
    useState<ButtonPosition>();

  const [actionsPageNumber, setActionsPageNumber] = useState(1);
  const [actionsPageLimit, setActionsPageLimit] = useState(0);

  const [inputPageNumber, setInputPageNumber] = useState(0);

  const fetchAllOfTheUsers = async () => {
    const res = await fetchAllUsers();
    let userArray: { name: string; email: string }[] = [];
    Object.values(res.result).forEach((user) =>
      userArray.push({ name: user.name, email: user.email })
    );
    setUsers(userArray);
  };

  const handlePaginationInputChange = (event) => {
    const newValue = event.target.value;

    setInputPageNumber(newValue);
  };

  const getInputActivePage = (newValue: number) => {
    let pageNumber =
      newValue > 0
        ? newValue > Math.ceil(totalActionsForPagination / actionsPageLimit)
          ? Math.ceil(totalActionsForPagination / actionsPageLimit)
          : newValue
        : 1;

    return pageNumber;
  };

  const handlePaginationInputKeyDown = async (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      // If the pressed key is "Enter", trigger the function for changing active page
      if (validateWholeNumber(inputPageNumber?.toString())) {
        await onPaginationChange(event, {
          activePage: getInputActivePage(inputPageNumber),
          totalPages: Math.ceil(totalActionsForPagination / actionsPageLimit),
        });
        setInputPageNumber(0);
      } else {
        beamtoast.error("Please enter whole number for jump to page.");
      }
    }
  };

  const onPaginationChange = async (
    event: MouseEvent,
    data: PaginationProps
  ) => {
    const activePage = data.activePage as number;
    setLiveActionsListLoading(true);
    operationInProgressRef.current = true; // Setting the flag to indicate pagination operation is in progress

    try {
      setActionsPageNumber(activePage);

      recreateAbortController(); // abort ongoing api call
      await fetchPaginatedActions(
        activePage,
        actionsPageLimit,
        controllerRef.current.signal
      );
    } catch (e) {
      beamtoast.error("Failed to change page");
      console.error("Error in changeDeviceStatus: ", e);
    } finally {
      setLiveActionsListLoading(false);
      operationInProgressRef.current = false; // Reset flag when operation is complete
    }
  };

  const handleSortByIncompleteActionsToggle = () => {
    setLiveActionsListLoading(true);
    setActionsPageNumber(1);
    setShowIncompleteActions(!showIncompleteActions);
  };

  const fetchPaginatedActions = useCallback(
    async (pageNumber: number, pageLimit: number, signal: AbortSignal) => {
      try {
        const res: ActionStatusResponse = await fetchAllActionStatus(
          pageNumber,
          pageLimit,
          showIncompleteActions,
          signal,
          filters
        );
        setTotalActionsForPagination(res.count);
        let sortedActionList = [];

        // If show incomplete actions is true, need not to order the actions by created_at date
        sortedActionList = showIncompleteActions
          ? res.actions
          : _.orderBy(res.actions, "created_at", "desc");
        setFilteredData(sortedActionList);
      } catch (e) {
        console.log(e);
      }
    },
    [filters, showIncompleteActions]
  );

  const fetchAllActions = async (count, signal, activePageNumber) => {
    try {
      await fetchPaginatedActions(activePageNumber, count ?? 10, signal);
    } catch (e) {
      console.log(e);
    } finally {
      if (!operationInProgressRef.current) {
        setMainLoading(false);
        setLiveActionsListLoading(false);
      } // To Avoid setting main loading to false when pagination call cancel this call
    }
  };

  const changeActionsPerPage = useCallback(
    async (e, data) => {
      operationInProgressRef.current = true; // Setting the flag to indicate device per page change operation is in progress

      try {
        setLiveActionsListLoading(true);
        setActionsPageLimit(data.value);
        setActionsPageNumber(1);

        window.localStorage.setItem("actionsPerPage", data.value);

        recreateAbortController();
        await fetchAllActions(data.value, controllerRef.current?.signal, 1);
      } catch (e) {
        beamtoast.error("Failed to change number of devices per page");
        console.error("Error in changeDeviceStatus: ", e);
      } finally {
        operationInProgressRef.current = false; // Resetting flag when operation is complete
      }
    },
    [actionsPageLimit, actionsPageNumber] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // update filters state
  const onFilterChange = useCallback(
    (name: string, values: string[]) => {
      let currentFilters = { ...filters };

      if (values?.length > 0) {
        currentFilters[name] =
          name === "creators"
            ? (() => {
                // values received contains [name (email)]
                // need to extract email
                const extractedEmails = values.map((value) =>
                  extractEmail(value)
                );
                // reconfirming if emails are legit
                return users
                  .filter((user) => extractedEmails.includes(user.email))
                  .map((user) => user.email);
              })()
            : values;
        // if filterName has been received with empty values, meaning filter has been removed
      } else delete currentFilters[name];

      // update filters
      setFilters(currentFilters);
    },
    [filters, users]
  );

  const resetFilters = () => {
    setShowFilterDropdown(false);
    setShowInsideFilterDropdown(false);
    selectedFilterKey.current = "";
    setCurrentDepth(0);
    setFilters({});
  };

  useEffect(() => {
    document.title = "Action Details | Bytebeam";
    props.setActiveItem("Action Details");

    const actionsCountPerPage: number = parseInt(
      window.localStorage.getItem("actionsPerPage") ?? "10"
    );
    setActionsPageLimit(actionsCountPerPage);

    const currentDebouncedFetch = debouncedFetch.current;
    // Cleanup function to cancel the debounced call when the component unmounts
    return () => currentDebouncedFetch.cancel();
  }, [props]);

  useEffect(() => {
    if (allowedActions?.length !== 0) fetchAllOfTheUsers();
  }, [allowedActions]); // eslint-disable-line react-hooks/exhaustive-deps

  // retriggers to fetch actions
  useEffect(() => {
    let actionsCount: number = actionsPageLimit !== 0 ? actionsPageLimit : 10; // fallback value

    let isMounted: boolean = true;
    let timeoutId: NodeJS.Timeout | null = null;

    const fetchActions = async () => {
      if (!isMounted || actionIDParam !== null || deviceIDParam !== null)
        return;
      try {
        if (!operationInProgressRef.current && !openAddPhase) {
          await fetchAllActions(
            actionsCount,
            controllerRef.current?.signal,
            actionsPageNumber
          );
        }
      } catch (e) {
        console.log(e);
      } finally {
        if (isMounted) {
          timeoutId = setTimeout(() => {
            fetchActions();
          }, 2 * 1000);
        }
      }
    };

    /**
     * check if user exists
     * if user does not exist -> allowed actions & users are still loading
     * if user exists && allowed actions are empty -> no permissions
     */
    if (user.email) {
      if (allowedActions?.length) fetchActions();
      else setMainLoading(false);
    }

    return () => {
      isMounted = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actionsPageNumber,
    actionsPageLimit,
    filters,
    showIncompleteActions,
    actionIDParam,
    deviceIDParam,
    openAddPhase,
    allowedActions,
  ]);

  // function exclusive to infinite scrolling
  const loadMoreFilters = useCallback(async () => {
    try {
      setFiltersPageNumber((page) => page + 1);

      const res = await fetchActionFilterOptions(
        filtersPageNumber + 1, // state update is async
        filters
      );

      /**
       * if received data has an empty array for the currently open filter key in the dropdown
       * means no new data exists - stop infinite scrolling
       */
      if (res[selectedFilterKey.current]?.length === 0)
        setInfiniteLoading(false);
      else setInfiniteLoading(true);

      const array: NestedDropdownOptions[] = Object.keys(res).map(
        (filterName) => {
          const emailList = res[filterName] as string[];

          /**
           * calculated to get currently displayed data
           * for the currently open filter key in the dropdown
           */
          const prevOptions = nestedDropdownOptions
            .find((option) => option.label === filterName)
            ?.children?.map((value) => ({
              text: value.text,
              value: value.value,
            }));

          // custom display for creators
          // format - [name (email)]
          const items =
            filterName === "creators"
              ? users
                  .filter((user) => emailList.includes(user.email))
                  .map((user) => ({
                    text: `${user.name} (${user.email})`,
                    value: user.email,
                  }))
              : res[filterName].map((value) => ({ text: value, value }));

          return {
            label: filterName,
            children: prevOptions ? [...prevOptions, ...items] : items,
          };
        }
      );
      setNestedDropdownOptions(array);
    } catch (e) {
      console.log(e);
    }
  }, [filtersPageNumber, nestedDropdownOptions, filters, users]);

  const debouncedFetch = useRef(
    debounce(
      async (filters: Filters, users: { name: string; email: string }[]) => {
        try {
          const res = await fetchActionFilterOptions(1, filters);

          const array: NestedDropdownOptions[] = Object.keys(res).map(
            (filterName) => {
              const emailList = res[filterName] as string[];

              const prevOptions = nestedDropdownOptions
                .find((option) => option.label === filterName)
                ?.children?.map((value) => ({
                  text: value.text,
                  value: value.value,
                }));

              // custom display for creators
              // format - [name (email)]
              const items =
                filterName === "creators"
                  ? users
                      .filter((user) => emailList.includes(user.email))
                      .map((user) => ({
                        text: `${user.name} (${user.email})`,
                        value: user.email,
                      }))
                  : res[filterName].map((value) => ({ text: value, value }));

              return {
                label: filterName,
                children: prevOptions ? [...prevOptions, ...items] : items,
              };
            }
          );

          // reset page when filters update
          setActionsPageNumber(1);
          setNestedDropdownOptions(array);

          // calculate depth of the object being used as dropdown options
          // this is to set back buttons and such stuff
          setDepth(array?.length ? getObjectDepth(array) - 2 : 1);
        } catch (e) {
          console.log(e);
        } finally {
          operationInProgressRef.current = false; // Reset flag when operation is complete
          setFiltersLoading(false);
        }
      },
      1000
    )
  );

  useEffect(() => {
    if (users?.length === 0) return;

    // not running this during the initial render to improve page loading performance
    if (!isFirstRender.current) {
      recreateAbortController();
      operationInProgressRef.current = true; // Setting the flag to indicate device per page change operation is in progress
    } else isFirstRender.current = false; // set false after first render

    setFiltersLoading(true);
    setLiveActionsListLoading(true);

    debouncedFetch.current(filters, users);
  }, [filters, users]);

  // -------------------- Action data fetching logic ends ---------------------- //

  const [selectedAction, setSelectedAction] = useState<ActionStatusType>(
    filteredData[0]
  );

  const DeviceListViewRef = useRef<{
    handleRefetchDeviceStatusDetails: () => void;
  }>(null);

  /**
   * Triggers the refetch of device status details in the DeviceListView component.
   */
  const refetchDeviceStatusDetails = () => {
    if (DeviceListViewRef.current) {
      DeviceListViewRef.current.handleRefetchDeviceStatusDetails();
    }
  };

  const fetchActionData = async (actionID?: number) => {
    await fetchAction(actionID ?? Number(actionIDParam))
      .then((data) => {
        setSelectedAction(data);
        setMainLoading(false);
        setLiveActionsListLoading(false);
      })
      .catch((e) => console.log(e));
  };

  /**
   * Converts the filtered data array into a sorted and stringified dependency array.
   * The dependency array contains the action IDs of the filtered data.
   *
   * @param filteredData - The array of filtered data.
   * @returns The stringified dependency array.
   */
  const dependencyArray = JSON.stringify(
    filteredData.map((action) => action?.action_id).sort((a, b) => b - a)
  );

  /**
   * This useEffect runs whenever the dependency array (stringified array of sorted action IDs) changes.
   * If actionIDParam is present, it fetches action data by calling fetchActionData.
   * If actionIDParam is absent, it sets the selected action to the first element in filteredData and sets the selected phase to "all".
   */
  useEffect(() => {
    if (actionIDParam) {
      fetchActionData();
    } else {
      setSelectedAction(filteredData[0]);
      setSelectedPhase("all");
    }
    // Don't change the dependency array in the next line to avoid infinite loop
  }, [dependencyArray, actionIDParam]); // eslint-disable-line react-hooks/exhaustive-deps

  if (mainLoading && !(deviceIDParam && actionIDParam))
    return (
      <LoadingAnimation
        loadingText="Loading Live Actions"
        fontSize="20px"
        loaderContainerHeight="70vh"
      />
    );
  else if (
    !mainLoading &&
    !(deviceIDParam || actionIDParam) &&
    filteredData?.length === 0
  ) {
    return (
      <div
        style={{
          height: "65vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ErrorMessage
          marginTop="30px"
          errorMessage
          message={
            allowedActions?.length !== 0
              ? "Please trigger an Action to view its progress here."
              : "You do not have permissions to view Actions."
          }
        />
      </div>
    );
  } else {
    return (
      <>
        {deviceIDParam && actionIDParam ? (
          <NewActionContainer>
            <DeviceV3Overview
              deviceIDParam={deviceIDParam}
              actionIDParam={actionIDParam}
              fetchActionData={fetchActionData}
            />
          </NewActionContainer>
        ) : selectedAction?.action_id ? (
          <>
            <ActionsSummarySection
              actionIDParam={actionIDParam}
              deviceIDParam={deviceIDParam}
              selectedAction={selectedAction}
              selectedPhase={selectedPhase}
              setSelectedPhase={setSelectedPhase}
              setMainLoading={setMainLoading}
              openAddPhase={openAddPhase}
              setOpenAddPhase={setOpenAddPhase}
              fetchActionData={fetchActionData}
              refetchDeviceStatusDetails={refetchDeviceStatusDetails}
            />

            {actionIDParam ? (
              <DeviceListView
                ref={DeviceListViewRef}
                actionID={actionIDParam}
                actionsData={selectedAction}
                setSelectedAction={setSelectedAction}
                selectedPhase={selectedPhase}
              />
            ) : (
              <NewActionContainer>
                <NewActionWrapper>
                  {filteredData?.length !== 0 ? (
                    <CardContainer>
                      <FlexContainer
                        style={{
                          marginBottom: "30px",
                          justifyContent: "space-between",
                        }}
                      >
                        <StyledHeader as="h2">Actions List</StyledHeader>
                        {filteredData?.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "16px",
                            }}
                          >
                            <div>Sort by Incomplete Actions</div>
                            <div>
                              <Toggle
                                id="sortByIncompleteActions"
                                checked={showIncompleteActions}
                                onChange={() => {
                                  handleSortByIncompleteActionsToggle();
                                }}
                                style={{
                                  top: "4px",
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </FlexContainer>

                      {nestedDropdownOptions &&
                        nestedDropdownOptions?.length !== 0 && (
                          <>
                            <NewActionLabelContainer
                              style={{ marginBottom: "20px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexGrow: 1,
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    minHeight: "35px",
                                  }}
                                >
                                  <div
                                    className="dashboard-header"
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FlexContainer>
                                      <div>
                                        <ActionButtonV3
                                          onClick={() =>
                                            setShowInsideFilterDropdown(true)
                                          }
                                          border_style={"dashed"}
                                          label={"Filters"}
                                          icon={"filter"}
                                          margin_left="0px"
                                          id="filter-actions-button"
                                        />
                                        <div style={{ position: "relative" }}>
                                          {showInsideFilterDropdown && (
                                            <ActionFilterNestedDropdown
                                              setShowDropdown={
                                                setShowInsideFilterDropdown
                                              }
                                              depth={depth}
                                              onClick={onFilterChange}
                                              nestedDropdownOptions={
                                                nestedDropdownOptions
                                              }
                                              filters={filters}
                                              currentDepth={currentDepth}
                                              setCurrentDepth={setCurrentDepth}
                                              selectedFilterKey={
                                                selectedFilterKey
                                              }
                                              infiniteLoading={infiniteLoading}
                                              setInfiniteLoading={
                                                setInfiniteLoading
                                              }
                                              loadMore={loadMoreFilters}
                                              search
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </FlexContainer>
                                  </div>
                                </div>
                                {Object.keys(filters)?.length !== 0 && (
                                  <div
                                    style={{
                                      alignSelf: "center",
                                    }}
                                  >
                                    <StyledNonBoldHeader
                                      as="h3"
                                      style={{
                                        marginTop: "0px",
                                        marginBottom: "0px",
                                        fontSize: "1.1rem",
                                        whiteSpace: "nowrap",
                                      }}
                                      className={`${
                                        Object.keys(filters)?.length === 0
                                          ? "color-disabled"
                                          : "selectable-item underline hover-underline color-blue"
                                      }`}
                                      onClick={() => {
                                        resetFilters();
                                      }}
                                      id="action-filter-clear-all"
                                    >
                                      Clear All
                                    </StyledNonBoldHeader>
                                  </div>
                                )}
                              </div>
                            </NewActionLabelContainer>
                            {Object.keys(filters)?.length !== 0 ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                    marginRight: "10px",
                                    marginLeft: "10px",
                                    width: "100%",
                                  }}
                                >
                                  {Object.keys(filters).map((key, idx) => (
                                    <SelectedFilter
                                      key={idx}
                                      filterName={key}
                                      filterValues={
                                        key === "creators"
                                          ? users
                                              .filter(
                                                (user) =>
                                                  filters.creators &&
                                                  filters.creators.includes(
                                                    user.email
                                                  )
                                              )
                                              .map((user) => user.name)
                                          : filters[key]
                                      }
                                      filterNameIcon={"file"}
                                      filterValueIcon={"info circle"}
                                      onCrossClick={onFilterChange}
                                      multiple
                                    />
                                  ))}

                                  <AddFiltersButton
                                    name="plus"
                                    id={`add-action-filters-button`}
                                    onClick={() => {
                                      setShowFilterDropdown(true);
                                      getButtonPosition(
                                        "action-add-filters-button",
                                        setAddFiltersButtonPosition
                                      );
                                    }}
                                  />
                                  <div style={{ position: "relative" }}>
                                    {showFilterDropdown && (
                                      <ActionFilterNestedDropdown
                                        setShowDropdown={setShowFilterDropdown}
                                        depth={depth}
                                        onClick={onFilterChange}
                                        nestedDropdownOptions={
                                          nestedDropdownOptions
                                        }
                                        filters={filters}
                                        currentDepth={currentDepth}
                                        setCurrentDepth={setCurrentDepth}
                                        selectedFilterKey={selectedFilterKey}
                                        parentButtonPosition={
                                          addFiltersButtonPosition
                                        }
                                        infiniteLoading={infiniteLoading}
                                        setInfiniteLoading={setInfiniteLoading}
                                        loadMore={loadMoreFilters}
                                        search
                                      />
                                    )}
                                  </div>
                                  <Divider />
                                </div>
                                <ThinDivider />
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}

                      <LiveActionsList
                        users={users}
                        action={filteredData}
                        selectedAction={selectedAction}
                        setSelectedAction={setSelectedAction}
                        setSelectedPhase={setSelectedPhase}
                        liveActionsListLoading={
                          liveActionsListLoading || filtersLoading
                        }
                      />

                      {!liveActionsListLoading && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "nowrap",
                            gap: "16px",
                          }}
                        >
                          <StyledPagination
                            boundaryRange={0}
                            ellipsisItem={null}
                            siblingRange={2}
                            activePage={actionsPageNumber}
                            totalPages={Math.ceil(
                              totalActionsForPagination / actionsPageLimit
                            )}
                            onPageChange={onPaginationChange}
                          />

                          <StyledCardSearchPageInput
                            icon="search"
                            placeholder="Jump to page..."
                            name="activePage"
                            min={1}
                            onChange={handlePaginationInputChange}
                            onKeyDown={handlePaginationInputKeyDown}
                            type="number"
                            value={inputPageNumber ? inputPageNumber : ""}
                          />

                          <StyledSecondaryDevicePerPageWidget>
                            <MenuItem>Actions per page</MenuItem>
                            <MenuItem style={{ padding: "0px" }}>
                              <SelectDevicesPerPage
                                compact
                                selection
                                options={devicesPerPageOptions}
                                value={actionsPageLimit}
                                onChange={changeActionsPerPage}
                              />
                            </MenuItem>
                          </StyledSecondaryDevicePerPageWidget>
                        </div>
                      )}
                    </CardContainer>
                  ) : (
                    <div
                      style={{
                        height: "65vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ErrorMessage
                        marginTop="30px"
                        message="Please trigger an Action to view its progress here."
                      />
                    </div>
                  )}
                </NewActionWrapper>
              </NewActionContainer>
            )}
          </>
        ) : (
          <LoadingAnimation
            loadingText="Loading Live Actions"
            fontSize="20px"
            loaderContainerHeight="70vh"
          />
        )}
      </>
    );
  }
}

export default withRouter(LiveActions);

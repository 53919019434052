import React from "react";
import { Grid, Icon, Table } from "semantic-ui-react";
import { StyledGridColumn, LightMutedText } from "./Device";
import TextWithToolTip from "./TextWithToolTip";
import { capitalizeFirstLetter } from "../../util";
import { MetadataCell, StyledIcon } from "./MetadataCell";
import LastActionInfoTable, { LastActionType } from "./LastActionInfoTable";
import { Permission } from "../../../../util";
import { Device, FetchStreamsAPIResponse } from "../../../../BytebeamClient";
import { DeviceStyledTable } from "../../Actions/DeviceList";
import { formatValue } from "../../Dashboards/Panel/util";
import { useUser } from "../../../../context/User.context";
import styled from "styled-components";

const MetadataHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  .metadataHeaderText {
    margin: 0;
  }

  .metadataHeaderDeviceIdText {
    display: flex;
    gap: 5px;
    align-items: center;
    margin: 0 10px 0 0;
  }
`;

type DeviceOverviewType = {
  readonly stateKeys: string[];
  readonly device: Device;
  readonly deviceId: number;
  readonly lastAction: LastActionType;
  readonly metadataKeys: string[];
  readonly enablePinMetadata: boolean;
  readonly pinMetadata?: (key: string) => Promise<void>;
  readonly pinnedMetadataKeys?: string[];
  readonly editableMetadataKeys: Set<string>;
  readonly specificAction?: boolean;
  readonly detailedStreamsList?: FetchStreamsAPIResponse;
  readonly activeAccordionIndex?: number;
};

function DeviceOverview(props: DeviceOverviewType) {
  const { user } = useUser();
  const permissions: Permission = user.role.permissions;
  const serial_metadata = user?.["tenant-settings"]?.["serial-key"] ?? "";

  const {
    stateKeys,
    device,
    deviceId,
    lastAction,
    metadataKeys,
    enablePinMetadata,
    pinMetadata,
    pinnedMetadataKeys,
    editableMetadataKeys,
    specificAction,
    detailedStreamsList,
    activeAccordionIndex,
  } = props;

  const isMetadataEmpty: boolean = metadataKeys?.length !== 0;

  return (
    <Grid.Row>
      {Object.keys(permissions?.tables).length > 0 &&
        permissions.tables["device_shadow"] && (
          <StyledGridColumn style={{ alignItems: "flex-start" }}>
            <DeviceStyledTable compact fixed id={`device_shadow`}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    style={{ padding: "9px 9.8px" }}
                    colSpan={2}
                  >
                    Device Shadow
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {stateKeys.length !== 0 ? (
                  stateKeys.map((i) => {
                    const value = formatValue(
                      device?.state[i],
                      detailedStreamsList?.["device_shadow"]?.fields?.[i]?.unit,
                      false,
                      false,
                      3,
                      true,
                      false
                    );

                    let textContent = "";

                    if (
                      device?.state[`.${i}_timestamp`] &&
                      (device?.state[i] || device?.state[i] === 0)
                    ) {
                      if (
                        !(
                          device?.state[`.${i}_timestamp`] === 0 ||
                          new Date(
                            device?.state[`.${i}_timestamp`] + "Z"
                          ).getTime() === 0
                        )
                      ) {
                        textContent = `Updated: ${new Date(
                          device?.state[`.${i}_timestamp`] + "Z"
                        ).toLocaleString("en-GB")}`;
                      }
                    }
                    const textList = [textContent];

                    return (
                      i !== "timestamp" &&
                      i !== "sequence" &&
                      !i.startsWith(".") && ( // Streams info API does not return streams with "." prefix but still checking for safety
                        <Table.Row key={`${i}`}>
                          <Table.Cell>
                            <LightMutedText>
                              <TextWithToolTip
                                tooltipposition="top right"
                                notBold
                                text={capitalizeFirstLetter(i)}
                              />
                            </LightMutedText>
                          </Table.Cell>
                          <Table.Cell>
                            <TextWithToolTip
                              text={value === "-" ? "" : value}
                              textList={textList}
                            />
                          </Table.Cell>
                        </Table.Row>
                      )
                    );
                  })
                ) : (
                  <Table.Row key={1}>
                    <Table.Cell
                      colSpan={2}
                      textAlign="center"
                      style={{ padding: "20px 0" }}
                    >
                      <Icon name="warning circle" size={"large"} />
                      <span>Table is empty</span>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </DeviceStyledTable>
          </StyledGridColumn>
        )}

      <StyledGridColumn style={{ alignItems: "flex-start" }}>
        <DeviceStyledTable compact fixed id={`metadata`}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={{ padding: "9px 9.8px" }} colSpan={2}>
                <MetadataHeaderContainer>
                  <p className="metadataHeaderText">Metadata</p>
                  {
                    // This adds device ID to the metadata table header, when serial metadata is present.
                    serial_metadata && (
                      <p className="metadataHeaderDeviceIdText">
                        Device ID:{" "}
                        <TextWithToolTip
                          text={deviceId.toString()}
                          length={16}
                        />
                      </p>
                    )
                  }
                </MetadataHeaderContainer>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isMetadataEmpty ? (
              Object.keys(device?.metadata)?.map((key) => {
                return (
                  <Table.Row key={`${deviceId}-${key}`}>
                    <Table.Cell>
                      <LightMutedText className="metadata-cell-container">
                        <TextWithToolTip
                          notBold
                          text={capitalizeFirstLetter(key)}
                        />
                        {permissions.editTenantSettings && enablePinMetadata ? (
                          <StyledIcon
                            name="pin"
                            id={`pin-${key}`}
                            className="metadata-cell-icon"
                            style={
                              pinnedMetadataKeys?.includes(key)
                                ? {
                                    visibility: "visible",
                                    transform: "rotate(45deg)",
                                    cursor: "pointer",
                                  }
                                : {
                                    transform: "rotate(45deg)",
                                    cursor: "pointer",
                                  }
                            }
                            onClick={async () => {
                              if (
                                pinMetadata !== undefined &&
                                enablePinMetadata
                              )
                                await pinMetadata(key);
                            }}
                          />
                        ) : null}
                      </LightMutedText>
                    </Table.Cell>
                    <Table.Cell>
                      <MetadataCell
                        id={deviceId}
                        user={user}
                        activeAccordionIndex={activeAccordionIndex as number}
                        keyName={key}
                        text={device?.metadata[key]}
                        editable={editableMetadataKeys.has(key)}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })
            ) : (
              <Table.Row>
                <Table.Cell>No Metadata</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </DeviceStyledTable>
      </StyledGridColumn>

      <StyledGridColumn style={{ alignItems: "flex-start" }}>
        <LastActionInfoTable
          lastAction={lastAction}
          specificAction={specificAction}
          deviceId={deviceId}
          serialMetadata={device?.["-serial_metadata"]?.[serial_metadata]}
        />
      </StyledGridColumn>
    </Grid.Row>
  );
}

export default DeviceOverview;

import React from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";
import { createGlobalStyle } from "styled-components";

const CenterModalContainer = createGlobalStyle`
  #centered-modal.ui.page.modals {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100vh !important;
  }

  #centered-modal.ui.modal {
    margin: auto !important;
    position: relative !important;
  }
`;

type SimpleConfirmationModalProps = {
  readonly open: boolean;
  readonly header?: string;
  readonly noHeader?: boolean;
  readonly content: string;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
};

export default function SimpleConfirmationModal({
  open,
  header = "Are you sure?",
  noHeader = false,
  content,
  onClose,
  onConfirm,
}: SimpleConfirmationModalProps) {
  return (
    <>
      <CenterModalContainer />
      <Modal
        onClose={onClose}
        open={open}
        size="tiny"
        className="dark"
        closeOnDimmerClick={false}
        id="centered-modal"
      >
        {!noHeader && <ModalHeader>{header}</ModalHeader>}
        <ModalContent>
          <p>{content}</p>
        </ModalContent>
        <ModalActions>
          <Button secondary icon="close" content="Go back" onClick={onClose} />
          <Button primary icon="check" content="Proceed" onClick={onConfirm} />
        </ModalActions>
      </Modal>
    </>
  );
}

import React from "react";
import { Button, Modal, Icon } from "semantic-ui-react";

type ActivateDeviceConfirmationProps = {
  deviceId: number;
  deviceStatus: string;
  changeDeviceStatus: (id: number, status: string) => any;
  isOpen: boolean;
  close: (...args: any[]) => any;
};

export function ActivateDeviceConfirmationModal(
  props: ActivateDeviceConfirmationProps
) {
  const { deviceId, deviceStatus, changeDeviceStatus, isOpen, close } = props;
  return (
    <Modal open={isOpen} size="tiny" className="dark" onClose={close}>
      <Modal.Header>
        {deviceStatus === "active"
          ? `Deactivate Device-${deviceId}`
          : `Activate Device-${deviceId}`}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <h3>
            {`Are you sure you want to ${
              deviceStatus === "active" ? "deactivate" : "activate"
            } the device?`}
          </h3>
          <p>
            {`To confirm, please click on ${
              deviceStatus === "active"
                ? "Deactivate Device"
                : "Activate Device"
            } to proceed with ${
              deviceStatus === "active" ? "deactivation" : "activation"
            } or click Cancel
            and retain the current status of the device.`}
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => close()}>
          <Icon name="remove" /> Cancel
        </Button>
        <Button
          primary
          onClick={() => {
            changeDeviceStatus(
              deviceId,
              deviceStatus === "active" ? "inactive" : "active"
            );
            close();
          }}
        >
          <Icon
            name={deviceStatus === "active" ? "ban" : "check circle outline"}
          />{" "}
          {deviceStatus === "active" ? "Deactivate Device" : "Activate Device"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

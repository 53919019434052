import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { User } from "../../../util";
import styled from "styled-components";
import ViewDashboard from "./ViewDashboard";
import { breakpoints } from "../../common/breakpoints";

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  gap: 50px;

  @media (min-width: ${breakpoints.sm}px) {
    .overflowScroll {
      overflow-x: auto;
      height: max-content;
    }
  }

  &.split {
    flex-direction: row;
  }

  @media (max-width: ${breakpoints.sm}px) {
    &.split {
      flex-direction: column;
    }
  }

  > * {
    flex: 1;
  }
`;

interface CompareDashboardsProps extends RouteComponentProps {
  user: User;
  currentTenant: string;
  settings: any;
  dashboardId: string;
}

class CompareDashboards extends React.Component<CompareDashboardsProps> {
  componentDidMount(): void {
    document.title = "CompareDashboards | Bytebeam";
  }

  render() {
    return (
      <FlexContainer className={"split"}>
        <div className="overflowScroll">
          <ViewDashboard
            id={parseInt(this.props.dashboardId)}
            user={this.props.user}
            settings={this.props.settings}
            beingcompared={true}
            compareDashboardNumber={1}
          />
        </div>
        <div className="overflowScroll">
          <ViewDashboard
            id={parseInt(this.props.dashboardId)}
            user={this.props.user}
            settings={this.props.settings}
            beingcompared={true}
            compareDashboardNumber={2}
          />
        </div>
      </FlexContainer>
    );
  }
}

export default withRouter(CompareDashboards);

import React, { useState } from "react";
import ActionVoidDropdown from "./ActionVoidDropdown";
import { StyledNonBoldHeader } from "../NewAction/NewAction";
import SelectableItem from "./SelectableItem";
import { Key } from "../../../../BytebeamClient";
import { useUser } from "../../../../context/User.context";

type ActionDisplayDropdownProps = {
  metadataKeysToShow: string[];
  allowedMetadataKeys: Key[];
  setMetadataKeysToShow: React.Dispatch<React.SetStateAction<string[]>>;
};

// const ActionVoidDropdownContainer = styled.div`
//   display: flex;
//   width: 100%;
//   padding: 1.5rem 1rem;
//   align-items: center;
//   justify-content: space-between;
//   border-bottom: 1px solid
//     ${(props) => props.theme.colors["add-button-border-color"]};
// `;

const ActionDisplayDropdown: React.FC<ActionDisplayDropdownProps> = ({
  metadataKeysToShow,
  allowedMetadataKeys,
  setMetadataKeysToShow,
}) => {
  const { user } = useUser();
  const permissions = user?.role?.permissions;

  const [selectedItemList, setSelectedItemList] = useState<Key[]>(
    metadataKeysToShow.map((val) => ({ key: val }))
  );

  const viewMetadataPermission: "all" | string[] = permissions?.viewMetadata;
  let allowedMetadataKeysMut: Key[] = allowedMetadataKeys;
  allowedMetadataKeysMut = allowedMetadataKeysMut.filter((item) => {
    return (
      item.key !== "id" &&
      (viewMetadataPermission === "all" ||
        viewMetadataPermission.includes(item.key))
    );
  });

  const selectItem = (item: Key) => {
    if (checkItemSelected(item)) {
      // Create a new array without the unselected item
      const filteredArray = selectedItemList.filter(
        (element) => element.key !== item.key
      );
      setSelectedItemList(filteredArray);
      setMetadataKeysToShow(filteredArray.map((item) => item.key));
    } else {
      // Add the selected item to the array by creating a new array
      const newArray = [...selectedItemList, item];
      setSelectedItemList(newArray);
      setMetadataKeysToShow(newArray.map((item) => item.key));
    }
  };

  const checkItemSelected = (item: Key) => {
    return selectedItemList?.some((listItem) => item.key === listItem.key);
  };

  return (
    <ActionVoidDropdown width={"300px"}>
      <div>
        {/* <ActionVoidDropdownContainer>
          <StyledNonBoldHeader
            as="h3"
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              fontSize: "1.1rem",
              whiteSpace: "nowrap",
            }}
          >
            Order By
          </StyledNonBoldHeader>
          <ActionDropdown
            placeholder="Warning First"
            selection
            options={[
              { text: "1 day", value: "1 day" },
              { text: "5 days", value: "5 days" },
              { text: "30 days", value: "30 days" },
              { text: "Never", value: "never" },
            ]}
            style={{
              borderWidth: "1px",
              minWidth: "50px",
              width: "150px",
            }}
            // onChange={setActionType}
            elementid={`actionOptions4`}
            //   disabled={props.actionDropdownList.length === 0}
          />
        </ActionVoidDropdownContainer> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: "1.5rem 1rem",
          }}
        >
          <StyledNonBoldHeader
            as="h3"
            style={{
              marginTop: "0px",
              marginBottom: "1rem",
              fontSize: "1.1rem",
              whiteSpace: "nowrap",
            }}
          >
            Display Properties
          </StyledNonBoldHeader>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto",
              rowGap: "10px",
              columnGap: "10px",
            }}
          >
            {allowedMetadataKeysMut.flatMap((item) => {
              return (
                <SelectableItem
                  key={item.key}
                  title={item.key}
                  selected={checkItemSelected(item)}
                  onClick={() => selectItem(item)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </ActionVoidDropdown>
  );
};

export default ActionDisplayDropdown;

import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewIframe } from "./ViewIframe";
import { EditIframeMeta as EditIframe } from "./EditIframeMeta";

export class IframeMetaData {
  type = "iframe_panel";
  url: string = "";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
}

export type IframeData = {};

export const IframePanelDef: PanelDef<IframeMetaData, IframeData> = {
  ViewComponent: ViewIframe,
  EditComponent: EditIframe,
  formatData: (data) => data,
  metaConstructor: IframeMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useUser } from "../../../../context/User.context";
import ThemeSchema from "../../../../theme/schema";
import {
  ActionStatusType,
  fetchActionErrorStats,
} from "../../../../BytebeamClient";
import LoadingAnimation from "../../../common/Loader";

interface ErrorDistributionChartProps {
  height: number;
  width: number;
  action: ActionStatusType;
}

function ErrorDistributionChart(props: ErrorDistributionChartProps) {
  const { action } = props;

  const { user } = useUser();
  const theme = user?.settings?.theme;

  const [errorStatsData, setErrorStatsData] = useState<Record<string, number>>(
    {}
  );
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = React.useState(0);

  function getTotalCount(data: Record<string, number>) {
    return Object.values(data).reduce(
      (acc: number, curr: number) => acc + curr,
      0
    );
  }

  const totalDevices = Object.values(errorStatsData).reduce(
    (sum, value) => sum + value,
    0
  );

  useEffect(() => {
    async function fetchErrorStats() {
      try {
        setLoading(true);
        const res: Record<string, number> = await fetchActionErrorStats(
          action.action_id
        );
        setErrorStatsData(res);
        setTotalCount(getTotalCount(res));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchErrorStats();
  }, [JSON.stringify(action), action.action_id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <LoadingAnimation
        loaderSize="28px"
        fontSize="18px"
        loaderBorderSize="3px"
        marginTopText="6px"
        loadingText="Loading error distribution..."
      />
    );
  } else if (totalCount === 0) {
    return <div>No Error Found</div>;
  } else {
    return (
      <div>
        <Plot
          data={[
            {
              name: "Actions Summary",
              values: [...Object.values(errorStatsData)],
              labels: [...Object.keys(errorStatsData)],
              marker: {
                showscale: false,
                colors:
                  ThemeSchema.data[theme ?? "dark"]?.colors[
                    "error-stats-charts-color-scheme-array"
                  ],
              },
              type: "pie",
              showlegend: false,
              automargin: false,
              text: [...Object.entries(errorStatsData)].map(
                ([key, value]) => `${key} - ${value}`
              ),
              textinfo: "value",
              textposition: "inside",
              hole: 0.6,
              hovertemplate: "%{label} - %{value} <extra></extra>",
              hoverlabel: {
                bordercolor:
                  ThemeSchema.data[theme ?? "dark"]?.colors[
                    "bars-inner-text-color"
                  ],
                font: {
                  color:
                    ThemeSchema.data[theme ?? "dark"]?.colors[
                      "foreground-color"
                    ],
                  family: "Open Sans",
                  size: 16,
                },
                bgcolor:
                  ThemeSchema.data[theme ?? "dark"]?.colors["background-color"],
              },
              pull: Array(Object.keys(errorStatsData)?.length)?.fill(0.01),
            },
          ]}
          layout={{
            height: props.height,
            width: props.width,
            margin: {
              l: 0,
              r: 0,
              b: 20,
              t: 20,
            },
            paper_bgcolor: "transparent",
            plot_bgcolor: "transparent",
            annotations: [
              {
                x: 0.5,
                y: 0.5,
                xref: "paper",
                yref: "paper",
                text:
                  totalDevices === 1 ? "1 device" : `${totalDevices} devices`,
                showarrow: false,
                font: {
                  size: 16,
                  family: "Open Sans, sans-serif",
                  color:
                    ThemeSchema.data[theme ?? "dark"]?.colors[
                      "foreground-color"
                    ],
                },
                align: "center",
              },
            ],
          }}
          config={{
            displayModeBar: false,
          }}
        />
      </div>
    );
  }
}

export default ErrorDistributionChart;

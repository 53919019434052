import React from "react";
import { Checkbox, Icon, Popup, Progress, Table } from "semantic-ui-react";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import { ActionStatusDetail } from "../../../BytebeamClient";
import LoadingAnimation from "../../common/Loader";
import { styleActionProgress } from "../DeviceManagement/Devices/LastActionInfoTable";
import styled from "styled-components";
interface DeviceListProps {
  deviceData?: ActionStatusDetail[];
  actionID?: any;
  showInModal?: boolean;
  loading: boolean;
}

export const DeviceStyledTable = styled(Table)`
  border: 1px solid ${(props) => props.theme.colors["table-outer-border"]} !important;
`;

function DeviceList(props: DeviceListProps) {
  const history = useHistory();
  const matchedPath = useRouteMatch("/projects/demo/actions/:actionID");

  return (
    <DeviceStyledTable compact selectable textAlign="center">
      <Table.Header>
        <Table.Row>
          {props.showInModal === true && (
            <Table.HeaderCell>
              <Checkbox />
            </Table.HeaderCell>
          )}

          <Table.HeaderCell>#ID</Table.HeaderCell>
          <Table.HeaderCell>Last Heartbeat</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          {props.showInModal !== true && (
            <>
              <Table.HeaderCell>Errors</Table.HeaderCell>
              <Table.HeaderCell>Progress</Table.HeaderCell>
            </>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {props.loading && (
          <Table.Row>
            <Table.Cell colSpan={props.showInModal ? "4" : "5"}>
              <LoadingAnimation
                loadingText="Loading Device List"
                loaderSize="50px"
                fontSize="14px"
                marginTopText="5px"
                loaderContainerMargin="5px 0px 5px 0px"
              />
            </Table.Cell>
          </Table.Row>
        )}
        {!props.loading &&
          props.deviceData?.length !== 0 &&
          props.deviceData?.map((data) => (
            <Table.Row
              key={data?.device_id}
              onClick={() => {
                history.push(
                  `${matchedPath?.url}/device-overview/${data?.device_id}`
                );
              }}
              className="selectable-item"
            >
              {props.showInModal === true && (
                <Table.Cell>
                  <Checkbox />
                </Table.Cell>
              )}
              <Table.Cell>{data?.device_id}</Table.Cell>
              <Table.Cell>
                <Popup
                  content={`${new Date(data.updated_at).toDateString()}
                    ${new Date(data.updated_at).toLocaleTimeString()}`}
                  position="top center"
                  inverted
                  trigger={
                    <div>
                      {data.updated_at
                        ? moment
                            .duration(moment().diff(data.updated_at))
                            .humanize() + " ago"
                        : "--"}
                    </div>
                  }
                />
              </Table.Cell>
              <Table.Cell>{data?.status}</Table.Cell>
              {props.showInModal !== true && (
                <>
                  <Table.Cell
                    width={2}
                    textAlign={data?.errors?.length !== 0 ? "left" : "center"}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: `${
                          data?.errors?.length !== 0 ? "2rem" : "0"
                        }`,
                      }}
                    >
                      {data?.errors?.length !== 0 ? data?.errors : "None"}
                    </div>
                  </Table.Cell>
                  <Table.Cell width={4} textAlign="center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Progress
                        progress="percent"
                        percent={
                          styleActionProgress(data?.status, data?.progress)[
                            "progress"
                          ]
                        }
                        indicating={
                          data?.status === "Completed" ||
                          data?.status === "Failed"
                            ? false
                            : true
                        }
                        size="medium"
                        className={`action-progress progress-margin ${
                          styleActionProgress(data?.status, data?.progress)[
                            "className"
                          ]
                        }`}
                      />
                      <span>
                        <Icon
                          style={{ fontSize: "24px" }}
                          name={"angle right"}
                        />
                      </span>
                    </div>
                  </Table.Cell>
                </>
              )}
            </Table.Row>
          ))}
        {!props.loading && props.deviceData?.length === 0 && (
          <Table.Row>
            <Table.Cell colSpan={props.showInModal ? "4" : "5"}>
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                No Devices Found!
              </p>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </DeviceStyledTable>
  );
}

DeviceList.defaultProps = {
  showInModal: false,
};

export default DeviceList;

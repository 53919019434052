import React from "react";
import styled from "styled-components";

const VoidDropdownWrapper = styled.div<{ width: string }>`
  z-index: 10;
  position: absolute;
  overflow: hidden;
  right: 0;
  margin-top: 0.5rem;
  min-width: ${(props) => props.width};
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.theme.colors["display-dropdown-background-color"]};
  border: 1px solid
    ${(props) => props.theme.colors["display-dropdown-border-color"]};
`;

type ActionVoidDropdownProps = {
  readonly width: string;
  readonly children: React.ReactNode;
};
export default function ActionVoidDropdown(props: ActionVoidDropdownProps) {
  return (
    <VoidDropdownWrapper width={props.width}>
      {props.children}
    </VoidDropdownWrapper>
  );
}

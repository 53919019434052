import * as React from "react";
import { SVGProps } from "react";

const StaticTextIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="6 51.33 138 52.06"
    style={{ scale: "1.3" }}
    scale="1.5"
    {...props}
  >
    <path
      fill="currentColor"
      d="M59 52c29.667 0 29.667 0 35.5 5.25 2.113 3.874 3.149 7.3 1.988 11.684C95.128 72.397 94.136 73.909 91 76l2.375 1.688C96.219 80.192 97.168 81.338 98 85c-.01 4.906-.733 9.206-4 13-7.188 4.705-13.595 4.479-22 4.25L59 102V52Zm12 10v11c6.068.481 6.068.481 11.563-1.5L84 70a108.817 108.817 0 0 0-1-7c-2.525-1.262-4.312-1.099-7.125-1.063L71 62Zm0 20v11l4.75.188 2.672.105c3.08-.35 4.39-1.125 6.578-3.293.5-3.042.5-3.042 0-6-1.863-2.3-1.863-2.3-4.82-2.195l-3.305.07-3.32.055L71 82ZM25 52c2.82-.293 2.82-.293 6.125-.188l3.32.083L37 52c2.911 5.631 5.405 11.29 7.672 17.207l.992 2.567c.684 1.775 1.366 3.55 2.046 5.327 1.045 2.724 2.099 5.443 3.153 8.163.666 1.73 1.332 3.462 1.996 5.193l.957 2.462.87 2.287c.255.662.51 1.324.771 2.006C56 99 56 99 56 102H44c-4-8.75-4-8.75-4-11H22l-1.438 4.938c-1.437 4.937-1.437 4.937-2.562 6.062-2.02.072-4.042.084-6.063.063l-3.347-.028L6 102c.592-3.268 1.371-6.273 2.55-9.375l.951-2.518 1.026-2.677 1.058-2.782c.737-1.936 1.476-3.87 2.217-5.805 1.133-2.962 2.259-5.928 3.384-8.894.717-1.881 1.435-3.763 2.154-5.644l1.02-2.69.96-2.498.839-2.195C23 55 23 55 25 52Zm6 14c-.818 1.893-1.63 3.79-2.438 5.688l-1.37 3.199C25.804 78.249 25.804 78.249 25 83h12c-2.285-8.565-2.285-8.565-5-17h-1ZM142 56c1.203 2.14 1.203 2.14 2 4l-5 6-1.652-.691C131.69 63.152 126.895 62.169 121 64c-3.768 2.566-5.251 5.02-6.438 9.313-.796 4.6-.513 8.422 1.438 12.687 2.604 3.497 4.19 4.76 8.375 6 4.231 0 6.794-1.297 10.625-3 2.688 0 2.688 0 5 1 2.313 2.563 2.313 2.563 4 5-6.906 6.497-12.316 8.557-21.895 8.379-6.242-.762-11.767-3.547-16.043-8.192C100.927 87.25 100.136 80.344 101 71c1.556-7.037 5.021-11.857 11-15.75 8.925-5.508 21.448-5.17 30 .75Z"
    />
  </svg>
);

export default StaticTextIcon;

import React, { useEffect, useState } from "react";
import { Button, Modal, Table, TableHeaderCell } from "semantic-ui-react";
import LoadingAnimation from "../../../common/Loader";
import { ParsedDBCResponse } from "../../../../util";
import { ErrorMessage } from "../../../common/ErrorMessage";
import TextWithToolTip from "../../DeviceManagement/Devices/TextWithToolTip";
import { TableContainer } from "../../Dashboards/Panel/util";

interface CreateDBCModalProps {
  readonly open: boolean;
  readonly onOpen: () => void;
  readonly onClose: () => void;
  readonly parsedDBC?: ParsedDBCResponse;
}

export default function ViewDBCTableModal(props: CreateDBCModalProps) {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.parsedDBC) setLoading(false);
    else setLoading(true);
  }, [props.parsedDBC]);

  return (
    <Modal
      className="dark"
      size="fullscreen"
      onClose={props.onClose}
      onOpen={props.onOpen}
      open={props.open}
      style={{ height: "auto" }}
    >
      <Modal.Header>Combined CAN Message and Signals Table</Modal.Header>
      <Modal.Content>
        {loading ? (
          <LoadingAnimation
            loaderContainerHeight="70vh"
            fontSize="1.5rem"
            loadingText="Loading..."
          />
        ) : (
          <TableContainer
            style={{
              padding: "0px",
              height: "60vh",
            }}
          >
            <div className="tableContentContainer" style={{ padding: "0px" }}>
              <Table textAlign="center" compact fixed selectable size="small">
                <Table.Header>
                  <Table.Row>
                    <TableHeaderCell>CAN ID</TableHeaderCell>
                    <TableHeaderCell width={3}>Message Name</TableHeaderCell>
                    {/* emphasis given to Signal Name column */}
                    <TableHeaderCell width={3}>Signal Name</TableHeaderCell>
                    <TableHeaderCell width={1}>Start Bit</TableHeaderCell>
                    <TableHeaderCell width={1}>Length (Bits)</TableHeaderCell>
                    <TableHeaderCell width={1}>Scale</TableHeaderCell>
                    <TableHeaderCell width={1}>Offset</TableHeaderCell>
                    <TableHeaderCell width={1}>Byte Order</TableHeaderCell>
                    <TableHeaderCell width={1}>Choices</TableHeaderCell>
                    <TableHeaderCell width={1}>Is Signed</TableHeaderCell>
                    <TableHeaderCell width={1}>Is Float</TableHeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {props.parsedDBC &&
                  Object.keys(props.parsedDBC).length !== 0 &&
                  props.parsedDBC.results?.json?.messages.length !== 0 ? (
                    props.parsedDBC.results.json.messages.map((dbcMessage) => {
                      return dbcMessage.signals.length !== 0 ? (
                        dbcMessage.signals.map((signal) => {
                          return (
                            <Table.Row
                              key={`${dbcMessage.can_id}-${signal.start}`}
                            >
                              <Table.Cell>{dbcMessage.can_id}</Table.Cell>
                              <Table.Cell>
                                <TextWithToolTip text={dbcMessage.name} />
                              </Table.Cell>
                              <Table.Cell>
                                <TextWithToolTip text={signal.name} />
                              </Table.Cell>
                              <Table.Cell>
                                <TextWithToolTip
                                  text={signal.start.toString()}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <TextWithToolTip
                                  text={signal.length.toString()}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <TextWithToolTip
                                  text={signal.scale.toString()}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <TextWithToolTip
                                  text={signal.offset.toString()}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <TextWithToolTip text={signal.byte_order} />
                              </Table.Cell>
                              <Table.Cell>
                                <TextWithToolTip
                                  text={
                                    signal.choices
                                      ? JSON.stringify(signal.choices)
                                      : `${signal.choices}`
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <TextWithToolTip
                                  text={signal.is_signed.toString()}
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <TextWithToolTip
                                  text={signal.is_float.toString()}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      ) : (
                        <Table.Row key={dbcMessage.can_id}>
                          <Table.Cell>{dbcMessage.can_id}</Table.Cell>
                          <Table.Cell>{dbcMessage.name}</Table.Cell>
                          {Array.from({ length: 8 }).map((_, idx) => (
                            <Table.Cell key={idx}>--</Table.Cell>
                          ))}
                        </Table.Row>
                      );
                    })
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan={10}>
                        <ErrorMessage
                          message={"No data found!"}
                          iconSize="large"
                        />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </div>
          </TableContainer>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={props.onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { Table, Icon, Popup } from "semantic-ui-react";
import ConfirmationModal from "../../common/ConfirmationModal";
import AddNewColumn from "./AddNewColumn";
import ColumnType from "./ColumnType";
import CreateStreamModal from "./CreateStreamModal";
import { filter, mapIf, updateColumns, mapColumnIf } from "./functional";
import Layout from "../../common/Layout";
import { ErrorMessage } from "../../../common/ErrorMessage";
import useAsyncEffect from "../../common/useAsyncEffect";
import { DisplayIf, filterStreamTableInfo, isNumericType } from "../../util";
import {
  createStreamField,
  deleteStream,
  deleteStreamField,
  updateStreamField,
  fetchAllStreamsWithDetails,
  fetchAlertRules,
  StreamFieldDetails,
  fetchAllDBCs,
} from "../../../../BytebeamClient";
import ConfirmationModalMessage from "../../common/ConfirmationModalMessage";
import LoadingAnimation from "../../../common/Loader";
import { beamtoast } from "../../../common/CustomToast";
import ColumnUnit from "./ColumnUnit";
import SlicedTextPopUp from "../../DeviceManagement/Devices/SlicedTextPopUp";
import { useUser } from "../../../../context/User.context";

export const formatNames = (names: string[]) => {
  if (names.length <= 2) return names.join(", ");
  return `${names.slice(0, 2).join(", ")}...`;
};

type DeleteTableModalProps = {
  isStreamDeleting: Record<string, boolean>;
  tableName: string;
  onConfirm: () => Promise<void>;
  alertRulesStreamName: Map<string, string[]>;
  dbcParserStreamName: Map<string, string[]>;
};

const DeleteTableModal = (props: DeleteTableModalProps) => {
  const {
    isStreamDeleting,
    tableName,
    onConfirm,
    alertRulesStreamName,
    dbcParserStreamName,
  } = props;

  const isDBCParserStream = dbcParserStreamName.has(tableName);
  const streamDbcParserNames = dbcParserStreamName.get(tableName) ?? [];

  const isAlertRulesStream = alertRulesStreamName.has(tableName);
  const streamAlertRuleNames = alertRulesStreamName.get(tableName) ?? [];

  if (isStreamDeleting?.[tableName]) {
    return (
      <LoadingAnimation
        loaderSize="15px"
        marginTopText="0px"
        loaderBorderSize="2px"
      />
    );
  }

  if (isDBCParserStream || isAlertRulesStream) {
    return (
      <Popup
        id="delete-stream-alert-rule-popup"
        content={`
            ${isDBCParserStream && !isAlertRulesStream ? `Cannot delete stream with DBC parsers: ${formatNames(streamDbcParserNames)}` : ""}
            ${isAlertRulesStream && !isDBCParserStream ? `Cannot delete stream with active Alert Rules: ${formatNames(streamAlertRuleNames)}` : ""}
            ${isDBCParserStream && isAlertRulesStream ? `Cannot delete stream with active Alert rules: ${formatNames(streamAlertRuleNames)} and DBC parsers: ${formatNames(streamDbcParserNames)}` : ""}
          `}
        inverted
        position="top center"
        trigger={
          <Icon
            link
            name="trash"
            disabled={isDBCParserStream || isAlertRulesStream}
          />
        }
      />
    );
  }

  return (
    <ConfirmationModal
      prefixContent="Delete stream"
      expectedText={tableName}
      onConfirm={onConfirm}
      trigger={<Icon link name="trash" />}
      message={
        <ConfirmationModalMessage name={tableName} type={"Stream Table"} />
      }
    />
  );
};

type DeleteColModalProps = {
  tableName: string;
  columnName: string;
  onConfirm: () => void;
  dbcParserStreamName: Map<string, string[]>;
};

const DeleteColModal = ({
  tableName,
  columnName,
  onConfirm,
  dbcParserStreamName,
}: DeleteColModalProps) => {
  const isDBCParserStream = dbcParserStreamName.has(tableName);
  const streamDbcParserNames = dbcParserStreamName.get(tableName) ?? [];

  if (isDBCParserStream) {
    return (
      <Popup
        id="delete-stream-alert-rule-popup"
        content={`Cannot delete column type in stream used as input stream in DBC Parser: ${formatNames(streamDbcParserNames)}`}
        inverted
        position="top center"
        trigger={<Icon link name="trash" disabled={isDBCParserStream} />}
      />
    );
  } else
    return (
      <ConfirmationModal
        prefixContent="Delete column"
        expectedText={columnName}
        onConfirm={onConfirm}
        trigger={<Icon link name="trash" />}
        message={<ConfirmationModalMessage name={columnName} type={"Column"} />}
      />
    );
};

type ActiveTableProps = {
  tableName: string;
  cols: ColumnsType[];
  slatedForDeletion?: boolean;
  error?: string;
  alertRulesStreamName: Map<string, string[]>;
  dbcParserStreamName: Map<string, string[]>;
  setLoading: (loading: boolean) => void;
  tables: { tableName: string; cols: ColumnsType[] }[];
  setTables: (tables: { tableName: string; cols: ColumnsType[] }[]) => void;
};

const ActiveTable = (props: ActiveTableProps) => {
  const { user, getCurrentUser } = useUser();
  const permissions = user?.role?.permissions;

  const {
    tableName,
    cols,
    slatedForDeletion,
    error,
    alertRulesStreamName,
    dbcParserStreamName,
    setLoading,
    tables,
    setTables,
  } = props;
  const [isStreamDeleting, setIsStreamDeleting] = useState({});

  const columnNameSet = new Set(
    cols.map((col) => col.name.toLowerCase().replace(/\s+/g, "_").trim())
  );

  const tablesRef = useRef<{ tableName: string; cols: StreamFieldDetails[] }[]>(
    [{ tableName: "", cols: [] }]
  );
  tablesRef.current = tables;

  // Normally to add a new table, you would:
  // setTable([newTable, ...tables])
  // But if it is done in a callback, you will only
  // have access to old tables. To avoid this nuance, use
  // updateTables(tables => [newTable, ...tables])
  // instead.
  const updateTables = (transition) => {
    setLoading(true);
    setTables(transition(tablesRef.current));
    setLoading(false);
  };

  const addNewCol = async (tableName, columnName, columnType, columnUnit) => {
    const requestId = newRequestId();
    columnName = columnName.replace(/\s+/g, "_").trim();
    columnUnit = columnUnit === "" ? null : columnUnit;

    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        updateColumns((cols) => [
          ...cols,
          {
            requestId,
            name: columnName,
            type: columnType,
            unit: columnUnit,
            status: "initiated",
          },
        ])
      )
    );

    try {
      await createStreamField({
        streamName: tableName,
        fieldName: columnName,
        fieldType: columnType,
        fieldUnit: columnUnit,
      });

      updateTables(
        mapIf(
          (table) => table.tableName === tableName,
          mapColumnIf(
            (col) => col.requestId === requestId,
            (col) => ({ ...col, status: "ready" })
          )
        )
      );

      await getCurrentUser();

      beamtoast.success(
        `New column ${columnName} is created in ${tableName} stream successfully`
      );
    } catch (e) {
      beamtoast.error(
        `Error creating ${columnName} in ${tableName} stream column`
      );
      console.log(e);
    }
  };

  const deleteTable = async (tableName) => {
    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        (table) => ({ ...table, slatedForDeletion: true })
      )
    );

    setIsStreamDeleting((prev) => ({ ...prev, [tableName]: true }));
    try {
      await deleteStream(tableName);
      updateTables(filter((row) => row.tableName !== tableName));
      getCurrentUser();
      setIsStreamDeleting((prev) => ({ ...prev, [tableName]: false }));
      beamtoast.success(`${tableName} stream is deleted successfully`);
    } catch (e) {
      updateTables(
        mapIf(
          (table) => table.tableName === tableName,
          (table) => ({
            ...table,
            slatedForDeletion: false,
            status: "error",
            error: (e as Error)?.message,
          })
        )
      );
      setIsStreamDeleting((prev) => ({ ...prev, [tableName]: false }));
      beamtoast.error(`Error deleting ${tableName} stream`);
    }
  };

  const deleteColumn = (tableName, columnName) => {
    //
    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        mapColumnIf(
          (col) => col.name === columnName,
          (col) => ({ ...col, status: "deleting" })
        )
      )
    );

    deleteStreamField(tableName, columnName)
      .then(() => {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            updateColumns(filter((col) => col.name !== columnName))
          )
        );
        getCurrentUser();
        beamtoast.success(
          `column ${columnName} from ${tableName} stream is deleted successfully`
        );
      })
      .catch((err) => {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            mapColumnIf(
              (col) => col.name === columnName,
              (col) => ({ ...col, status: "error", error: err.message })
            )
          )
        );
        beamtoast.error(
          `Error deleting column ${columnName} from ${tableName} stream`
        );
      });
  };

  const editColumn = (tableName, columnName, newType, newUnit) => {
    newUnit = newUnit === "" ? null : newUnit;

    updateTables(
      mapIf(
        (table) => table.tableName === tableName,
        mapColumnIf(
          (col) => col.name === columnName,
          (col) => ({
            ...col,
            newType,
            newUnit,
            status: "editing",
          })
        )
      )
    );

    updateStreamField({
      streamName: tableName,
      fieldName: columnName,
      fieldType: newType,
      fieldUnit: newUnit,
    })
      .then(() =>
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            mapColumnIf(
              (col) => col.name === columnName,
              (col) => ({
                ...col,
                type: newType,
                unit: newUnit,
                status: "ready",
              })
            )
          )
        )
      )
      .then(() => {
        getCurrentUser();
        beamtoast.success(`column ${columnName} is updated successfully`);
      })
      .catch((err) => {
        updateTables(
          mapIf(
            (table) => table.tableName === tableName,
            mapColumnIf(
              (col) => col.name === columnName,
              (col) => ({
                ...col,
                newType: [col.type], // reset to old type!
                newUnit: [col.unit], // reset to old unit!
                status: "error",
                error: err.message,
              })
            )
          )
        );
      });
  };

  return [
    ...cols.map(
      ({ name, type, newType, unit, newUnit, required, status }, index) => (
        <Table.Row
          key={tableName + ":" + name}
          warning={type !== newType || unit !== newUnit}
        >
          {index === 0 && (
            <Table.Cell
              rowSpan={1 + cols.length}
              width={"3"}
              verticalAlign="top"
            >
              {/* table name only needed for first row */}
              <SlicedTextPopUp
                text={tableName}
                length={24}
                notBold={!slatedForDeletion}
                italic={slatedForDeletion}
              />
            </Table.Cell>
          )}

          <Table.Cell width={"3"}>{name}</Table.Cell>

          <Table.Cell width={"3"}>
            <ColumnType
              tableName={tableName}
              columnName={name}
              required={required}
              status={status}
              pendingType={newType}
              value={type}
              permission={permissions.editStreams}
              dbcParserStreamName={dbcParserStreamName}
              onChange={(newType) =>
                editColumn(
                  tableName,
                  name,
                  newType,
                  !isNumericType(newType) ? null : newUnit ?? unit
                )
              }
            />
          </Table.Cell>

          <Table.Cell width={"3"}>
            <ColumnUnit
              required={required}
              status={status}
              pendingUnit={newUnit === "null" ? "None" : newUnit ?? "None"}
              value={unit === "null" ? "None" : unit ?? "None"}
              columnType={type}
              permission={permissions.editStreams}
              onChange={(newUnit) =>
                editColumn(tableName, name, newType ?? type, newUnit)
              }
            />
          </Table.Cell>

          <Table.Cell width={"1"}>
            {status !== "ready" && status !== "error" && status}
            {status === "error" && JSON.stringify(error)}
            {!required && status === "ready" && (
              <DisplayIf cond={permissions.editStreams}>
                <DeleteColModal
                  tableName={tableName}
                  columnName={name}
                  dbcParserStreamName={dbcParserStreamName}
                  onConfirm={() => deleteColumn(tableName, name)}
                />
              </DisplayIf>
            )}
          </Table.Cell>

          {index === 0 &&
            tableName !== "device_shadow" &&
            tableName !== "action_status" && (
              <Table.Cell
                rowSpan={1 + cols.length}
                width={"1"}
                verticalAlign="top"
                textAlign="center"
              >
                {error && <p>{error}</p>}
                <DisplayIf cond={permissions.editStreams}>
                  <DeleteTableModal
                    isStreamDeleting={isStreamDeleting}
                    tableName={tableName}
                    alertRulesStreamName={alertRulesStreamName}
                    dbcParserStreamName={dbcParserStreamName}
                    onConfirm={() => deleteTable(tableName)}
                  />
                </DisplayIf>
              </Table.Cell>
            )}
          {/* delete table button only needed for first row */}
        </Table.Row>
      )
    ),

    permissions.editStreams ? (
      <AddNewColumn
        tableName={tableName}
        key={tableName + ":newcol"}
        addNewCol={addNewCol}
        columnNameSet={columnNameSet}
        dbcParserStreamName={dbcParserStreamName}
      />
    ) : (
      <Table.Row></Table.Row>
    ),
  ];
};

const sortColumns = (columns) => {
  columns.sort((a, b) => {
    let alphaCompare = a.name.localeCompare(b.name);
    if (a.required) {
      if (b.required) {
        return alphaCompare;
      } else {
        return -1;
      }
    } else {
      if (b.required) {
        return +1;
      } else {
        return alphaCompare;
      }
    }
  });
  return columns;
};

let requestIdInternal = 0;
const newRequestId = () => {
  return requestIdInternal++;
};

type ColumnsType = StreamFieldDetails & {
  name: string;
  newType: string;
  newUnit: string;
  status: string;
};

export default function Streams() {
  const { user } = useUser();
  const permissions = user?.role?.permissions;

  const [tables, setTables] = useState<
    { tableName: string; cols: ColumnsType[] }[]
  >([]);
  const [alertRulesStreamName, setAlertRulesStreamName] = useState<
    Map<string, string[]>
  >(new Map());
  const [dbcParserStreamName, setDBCParsersStreamName] = useState<
    Map<string, string[]>
  >(new Map());

  const [loading, setLoading] = useState<boolean>(true);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);

  /**
   * Fetches the streams with active alert rules and making a set of stream names.
   * @returns {Promise<void>}
   */
  const streamsWithActiveAlertRules = async () => {
    try {
      const alertsRes = await fetchAlertRules();

      const streamWithAlertRules = new Map();
      alertsRes.forEach((alertRule) => {
        if (streamWithAlertRules.has(alertRule.stream)) {
          let alertNames: string[] =
            streamWithAlertRules.get(alertRule.stream) ?? [];
          streamWithAlertRules.set(
            alertRule.stream,
            alertNames.concat(alertRule.name)
          );
        } else {
          streamWithAlertRules.set(alertRule.stream, [alertRule.name]);
        }
      });
      setAlertRulesStreamName(streamWithAlertRules);
    } catch (error) {
      console.error("An error occurred while fetching alert rules:", error);
    }
  };

  const streamsWithDBCParsers = async () => {
    try {
      const dbcRes = await fetchAllDBCs();

      const streamWithDBCParsers = new Map();
      dbcRes.results.forEach((dbc) => {
        if (streamWithDBCParsers.has(dbc.input_table)) {
          let parserNames: string[] =
            streamWithDBCParsers.get(dbc.input_table) ?? [];
          streamWithDBCParsers.set(
            dbc.input_table,
            parserNames.concat(dbc.name)
          );
        } else {
          streamWithDBCParsers.set(dbc.input_table, [dbc.name]);
        }
      });
      setDBCParsersStreamName(streamWithDBCParsers);
    } catch (error) {
      console.error("An error occurred while fetching dbc parsers:", error);
    }
  };

  const resetTable = async () => {
    setLoading(true);
    try {
      let response = await fetchAllStreamsWithDetails(),
        // Filtering the stream table info to remove streams name starting with uplink_ and ending with _local
        // and also filtering out columns ending with _timestamp
        filteredStreamsWithDetails = filterStreamTableInfo(response, true);

      let streamsWithDetails = Object.entries(filteredStreamsWithDetails).map(
        ([streamName, streamDetails]) => {
          let stream = {
            tableName: streamName,
            // Sorting the columns by name and adding newType, newUnit and status properties
            cols: sortColumns(streamDetails).map((col) => ({
              ...col,
              newType: col.type,
              newUnit: col.unit,
              status: "ready",
            })),
          };
          return stream;
        }
      );

      streamsWithActiveAlertRules();
      streamsWithDBCParsers();

      setTables(streamsWithDetails);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setErrorOccurred(true);
    }
  };

  useAsyncEffect(resetTable, []);

  useEffect(() => {
    document.title = "Streams | Bytebeam";
    // To scroll to top of the screen
    window.scrollTo(0, 0);
  }, []);

  if (errorOccurred) {
    return <ErrorMessage marginTop="270px" errorMessage />;
  }

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading streams"
      />
    );
  }

  return (
    <Layout
      buttons={
        <>
          <DisplayIf cond={permissions.editStreams}>
            <CreateStreamModal onClose={resetTable} sourceStreams={tables} />
          </DisplayIf>
          {/*
                <Button primary floated="right" icon labelPosition='left' as="a" href={streamProtobuff}>
                  <Icon name='download' />
                  Download proto!
                </Button>
            */}
        </>
      }
    >
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={"3"}>Stream Name</Table.HeaderCell>
            <Table.HeaderCell width={"3"}>Column Name</Table.HeaderCell>
            <Table.HeaderCell width={"3"}>Column Type</Table.HeaderCell>
            <Table.HeaderCell width={"3"}>Column Unit</Table.HeaderCell>

            <DisplayIf cond={permissions.editStreams}>
              <Table.HeaderCell width={"1"}>Column Ops</Table.HeaderCell>
            </DisplayIf>

            <DisplayIf cond={permissions.editStreams}>
              <Table.HeaderCell width={"1"}>Stream Ops</Table.HeaderCell>
            </DisplayIf>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tables.length !== 0 ? (
            tables.map((table) => (
              <ActiveTable
                key={table.tableName}
                tableName={table.tableName}
                cols={table.cols}
                alertRulesStreamName={alertRulesStreamName}
                dbcParserStreamName={dbcParserStreamName}
                setLoading={setLoading}
                tables={tables}
                setTables={setTables}
              />
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={`${permissions.editStreams ? "5" : "3"}`}>
                <ErrorMessage marginTop="30px" message={"No Streams Found!"} />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Layout>
  );
}

import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewAlerts } from "./ViewAlerts";
import { EditAlertsMeta } from "./EditAlertsMeta";
import { AlertRule, AlertRuleCriticality } from "../../../../../util";

export type AlertAggregate = {
  name: string;
  table: string;
  column: string;
  aggregator: string;
};

export class AlertsMetaData {
  type = "alerts";
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  aggregates: AlertAggregate[] = [];
  tabbedView: boolean = false;
  alert_names?: string[] = [];
  alert_groups?: string[] = [];
  tabByAlertGroups: boolean = false;
  tabList: string[] | "all" = "all";
  page: number = 1;
  limit: number = 20;
}

export type Alert = {
  "-serial_metadata"?: { [key: string]: string };
  device_id: number;
  start_time: number;
  end_time: number;
  id: string;
  is_active: boolean;
  is_alert: boolean;
  metadata: { [key: string]: any };
  status: string;
  tenant_id: string;
  aggregates: { [key: string]: any };
  alert_rule: {
    id: string;
    name: string;
    criticality: AlertRuleCriticality;
  };
};

type AlertsSummaryItem = {
  count: number;
  criticality: string;
};

export type AlertSummary = Record<string, Record<string, AlertsSummaryItem>>;

export type AlertsData = {
  alerts: Alert[];
  alertRules: AlertRule[];
  errors: string[];
  summary: AlertSummary;
  totalCount: number;
};

export const AlertsPanelDef: PanelDef<AlertsMetaData, AlertsData> = {
  ViewComponent: ViewAlerts,
  EditComponent: EditAlertsMeta,
  formatData: (data) => data,
  metaConstructor: AlertsMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

import React, { useEffect, useState } from "react";
import { Table, Input, Icon, Popup } from "semantic-ui-react";
import { hasSpecialCharacters, isNumericType } from "../../util";
import TypeSelection from "./TypeSelection";
import UnitSelection from "./UnitSelection";
import { beamtoast } from "../../../common/CustomToast";
import { formatNames } from "./Streams";

const AddColButton = ({ onClick, disabled }) => (
  <Icon
    style={{
      cursor: disabled === true ? "not-allowed" : "pointer",
    }}
    link={!disabled}
    name="add"
    onClick={onClick}
    disabled={disabled}
  />
);

type AddNewColumnProps = {
  readonly tableName: string;
  readonly addNewCol: (
    tableName: string,
    columnName: string,
    columnType: string,
    columnUnit: string | null
  ) => void;
  readonly columnNameSet: Set<string>;
  readonly dbcParserStreamName: Map<string, string[]>;
};

export default function AddNewColumn(props: AddNewColumnProps) {
  const { tableName, addNewCol, columnNameSet, dbcParserStreamName } = props;

  const [columnName, setColumnName] = useState<string>("");
  const [columnType, setColumnType] = useState<string>("");
  const [columnUnit, setColumnUnit] = useState<string | null>(null);

  const isValid = columnType !== "" && columnName !== "";
  const isDBCParserStream = dbcParserStreamName.has(tableName);
  const streamDbcParserNames = dbcParserStreamName.get(tableName) ?? [];

  useEffect(() => {
    if (!isNumericType(columnType)) {
      setColumnUnit(null);
    }
  }, [columnType]);

  return (
    <Table.Row key={tableName + ":add-col"}>
      <Table.Cell style={{ paddingRight: "36px" }}>
        <Input
          placeholder="Field Name"
          value={columnName}
          onChange={(_e, d) =>
            setColumnName(d.value?.replace(/\s+/g, "_")?.trim())
          }
          style={{ width: "100%" }}
        />
      </Table.Cell>

      <Table.Cell style={{ paddingRight: "36px" }}>
        <TypeSelection
          value={columnType}
          onChange={(_e, d) => setColumnType(d.value)}
          disabled={false}
          id={"add_column-type"}
          editMode={false}
        />
      </Table.Cell>

      <Table.Cell style={{ paddingRight: "36px" }}>
        <UnitSelection
          value={columnUnit ?? ""}
          onChange={(_e, d) => setColumnUnit(d.value as string)}
          disabled={!isNumericType(columnType)}
        />
      </Table.Cell>

      <Table.Cell>
        {isDBCParserStream ? (
          <Popup
            id="add-stream-column-dbc-parser-popup"
            content={`Cannot add column to stream used as input stream in DBC Parser: ${formatNames(streamDbcParserNames)}`}
            inverted
            position="top center"
            trigger={<Icon link name="add" disabled={isDBCParserStream} />}
          />
        ) : (
          <AddColButton
            onClick={() => {
              if (columnNameSet?.has(columnName)) {
                beamtoast.error(`${columnName} is already present`);
              } else if (hasSpecialCharacters(columnName)) {
                beamtoast.error(
                  "Field Name cannot contain special characters except underscore"
                );
              } else addNewCol(tableName, columnName, columnType, columnUnit);
            }}
            disabled={!isValid}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
}

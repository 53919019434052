import React from "react";
import { Button, Grid, Input } from "semantic-ui-react";

export interface Header {
  key: string;
  value: string;
}

export interface HeadersProps {
  headers: Header[];
  onChange: (headers: Header[]) => void;
}

export const HttpHeaders: React.FC<HeadersProps> = ({ headers, onChange }) => {
  const addHeader = () => {
    onChange([...headers, { key: "", value: "" }]);
  };

  const removeHeader = (index: number) => {
    const newHeaders = [...headers];
    newHeaders.splice(index, 1);
    onChange(newHeaders);
  };

  const updateHeader = (index: number, key: string, value: string) => {
    const newHeaders = [...headers];
    newHeaders[index] = { key, value };
    onChange(newHeaders);
  };

  return (
    <Grid>
      <Grid.Row style={{ paddingBottom: "8px" }}>
        <Grid.Column>Add Headers</Grid.Column>
      </Grid.Row>
      {headers.map(({ key, value }, index) => (
        <Grid.Row key={index} style={{ paddingTop: 0 }}>
          <Grid.Column width={6}>
            <Input
              placeholder="Header Key"
              value={key}
              onChange={(e) => updateHeader(index, e.target.value, value)}
            />
          </Grid.Column>
          <Grid.Column width={6}>
            <Input
              placeholder="Header Value"
              value={value}
              onChange={(e) => updateHeader(index, key, e.target.value)}
            />
          </Grid.Column>
          <Grid.Column
            width={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            {index > 0 || headers.length !== 1 ? (
              <Button
                id={`removeHeaderButton-${index}`}
                secondary
                icon="minus"
                onClick={() => removeHeader(index)}
              />
            ) : (
              <></>
            )}
            {index === headers.length - 1 ? (
              <Button
                id={`addHeaderButton-${index}`}
                disabled={key === "" || value === ""}
                primary
                icon="plus"
                onClick={addHeader}
              />
            ) : (
              <></>
            )}
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
};

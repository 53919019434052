import uuid from "uuid";
import { PanelDef } from "../PanelDef";
import { ViewBarChart } from "./ViewBarChart";
import { EditBarChartMeta } from "./EditBarChartMeta";

export class BarChartMetaData {
  type: "bar_chart" = "bar_chart";
  table: string = "";
  group: string = "";
  subGroup: string = "";
  aggregateColumn: string = "";
  aggregator: string = "";

  id: string = uuid.v4();
  title: string = "";
  description: string = "";

  minRange: number | undefined = undefined;
  maxRange: number | undefined = undefined;
}

export type BarChartValue = {
  group: string;
  subGroup: string;
  value: number;
};

export type BarChartData = BarChartValue[];

export const BarChartPanelDef: PanelDef<BarChartMetaData, BarChartData> = {
  ViewComponent: ViewBarChart,
  EditComponent: EditBarChartMeta,
  formatData: (data) => data,
  metaConstructor: BarChartMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

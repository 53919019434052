import React from "react";
import Favicon from "react-favicon";
import { Image } from "semantic-ui-react";
import { User } from "../../util";
import {
  defaultDarkLogo,
  defaultLightLogo,
} from "../Screens/Settings/tenantsSettings/logoAndFavicon/LogoSettings";
import {
  defaultDarkFavicon,
  defaultLightFavicon,
} from "../Screens/Settings/tenantsSettings/logoAndFavicon/FaviconSettings";

type BytebeamLogoProps = {
  height: string;
  user: User;
  currentTenant?: string;
  // Allowing all other props
  [x: string]: any;
};

function ByteBeamLogo(props: Readonly<BytebeamLogoProps>) {
  const { height, user, currentTenant } = props;

  const theme =
    user?.settings?.theme || window.localStorage.getItem("defaultTheme");

  let darkLogo, lightLogo, darkFavicon, lightFavicon;

  if (user && currentTenant) {
    // If the current tenant is the same as the user's tenant, then we can use the user's logo.
    if (currentTenant === user?.tenant_id) {
      darkLogo = user["tenant-settings"]?.logo?.dark;
      lightLogo = user["tenant-settings"]?.logo?.light;
      darkFavicon = user["tenant-settings"]?.favicon?.dark;
      lightFavicon = user["tenant-settings"]?.favicon?.light;

      window.localStorage.setItem(
        `${currentTenant}LightLogo`,
        lightLogo ? lightLogo : defaultLightLogo
      );

      window.localStorage.setItem(
        `${currentTenant}DarkLogo`,
        darkLogo ? darkLogo : defaultDarkLogo
      );

      window.localStorage.setItem(
        `${currentTenant}LightFavicon`,
        lightFavicon ? lightFavicon : defaultLightFavicon
      );

      window.localStorage.setItem(
        `${currentTenant}DarkFavicon`,
        darkFavicon ? darkFavicon : defaultDarkFavicon
      );
    }
  }

  const localLightLogo = window.localStorage.getItem(
    `${currentTenant}LightLogo`
  );
  const localDarkLogo = window.localStorage.getItem(`${currentTenant}DarkLogo`);
  const localLightFavicon = window.localStorage.getItem(
    `${currentTenant}LightFavicon`
  );
  const localDarkFavicon = window.localStorage.getItem(
    `${currentTenant}DarkFavicon`
  );

  let logo = defaultLightLogo;
  let favicon = defaultLightFavicon;

  if (theme === "dark") {
    logo = darkLogo || localDarkLogo || defaultDarkLogo;
    favicon = darkFavicon || localDarkFavicon || defaultDarkFavicon;
  } else {
    logo = lightLogo || localLightLogo || defaultLightLogo;
    favicon = lightFavicon || localLightFavicon || defaultLightFavicon;
  }

  return (
    <>
      <Image src={logo} height={height} />
      <Favicon url={favicon} />
    </>
  );
}

export default ByteBeamLogo;

import * as React from "react";
import { SVGProps } from "react";

const BarChartsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="17.5 21.5 115.04 106"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.5 21.5h4v102h18v-26h17v26h8v-36h17v36h8v-56h17v56c8.34-.166 16.673 0 25 .5.837 1.011 1.17 2.178 1 3.5h-115v-106Z"
      style={{
        opacity: 0.892,
      }}
    />
    <path
      fill="currentColor"
      d="M103.5 22.5c1.323 3.438 1.99 7.271 2 11.5l-1 11.5c-2.527-1.97-5.193-2.637-8-2-7.339 14.502-17.672 26.335-31 35.5-8.406 5.024-17.406 8.024-27 9-1.333-1-1.333-2 0-3C55.56 81.224 69.727 72.724 81 59.5A593.052 593.052 0 0 0 93.5 40l-7-4.5a233.234 233.234 0 0 0 17-13Z"
      style={{
        opacity: 0.758,
      }}
    />
  </svg>
);

export default BarChartsIcon;

import React, { useState, useEffect, useCallback } from "react";
import { Table, Icon, Button, Grid } from "semantic-ui-react";
import { ButtonIcon, DisplayIf } from "../../util";
import { ErrorMessage } from "../../../common/ErrorMessage";
import {
  fetchAllDeviceConfiguration,
  DeviceConfigurationType,
} from "../../../../BytebeamClient";
import { useHistory, useLocation } from "react-router-dom";
import DeactivateConfigModal from "./DeactivateConfigModal";
import { User } from "../../../../util";
import LoadingAnimation from "../../../common/Loader";
import Toggle from "../../../common/Toggle";

type DeviceConfigurationsProps = {
  user: User;
};

function DeviceConfigurations(props: DeviceConfigurationsProps) {
  const permissions = props.user?.role?.permissions || {};

  const [loading, setLoading] = useState<boolean>(false);
  const [configs, setConfigs] = useState<DeviceConfigurationType[]>([]);
  const [deviceConfigurations, setDeviceConfigurations] = useState<
    DeviceConfigurationType[]
  >([]);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [version, setVersion] = useState<string>("");
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [showDeactivated, setShowDeactivated] = useState<boolean>(false);
  const history = useHistory();
  const { pathname } = useLocation();

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const fillConfigsTable = useCallback(
    async (showDeactivatedDeviceConfigs?: boolean) => {
      setLoading(true);
      try {
        const configs = await fetchAllDeviceConfiguration();
        setConfigs(configs);
        const deviceConfigurations: DeviceConfigurationType[] = configs.filter(
          (config) =>
            (typeof showDeactivatedDeviceConfigs === "boolean"
              ? showDeactivatedDeviceConfigs
              : showDeactivated) || !config.is_deactivated
        );

        setLoading(false);
        setDeviceConfigurations(deviceConfigurations);
      } catch (e) {
        setErrorOccurred(true);
      }
    },
    [showDeactivated]
  );

  async function handleShowDeactivatedToggle() {
    setShowDeactivated(!showDeactivated);
    await fillConfigsTable(!showDeactivated);
  }

  useEffect(() => {
    document.title = "JSON configs | Bytebeam";
    fillConfigsTable();
  }, [fillConfigsTable]);

  if (errorOccurred) {
    return <ErrorMessage marginTop="30px" errorMessage />;
  }
  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading Device Configurations"
      />
    );
  }
  return (
    <Grid>
      <DeactivateConfigModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        version={version}
        fillConfigsTable={fillConfigsTable}
      />
      <Grid.Row>
        <Grid.Column width="3">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "12px",
              marginTop: "24px",
            }}
          >
            <div>Show deactivated</div>
            <div>
              <Toggle
                id="showDeactivatedConfig"
                checked={showDeactivated}
                onClick={async () => await handleShowDeactivatedToggle()}
                style={{
                  top: "2px",
                }}
              />
            </div>
          </div>
        </Grid.Column>
        <Grid.Column floated="right" width="3">
          <DisplayIf cond={permissions?.editDeviceConfigs}>
            <Button
              primary
              floated="right"
              labelPosition="left"
              icon
              onClick={() => {
                history.push(`${pathname}/add`, configs);
              }}
              style={{ whiteSpace: "nowrap" }}
            >
              <Icon name="add" />
              New Device Config
            </Button>
          </DisplayIf>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Version Name</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <DisplayIf cond={permissions?.editDeviceConfigs}>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </DisplayIf>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {deviceConfigurations.length !== 0 ? (
                deviceConfigurations.map((config) => (
                  <Table.Row key={config.version_name}>
                    <Table.Cell>{config.version_name}</Table.Cell>
                    <Table.Cell>
                      {config.is_deactivated ? "Deactivated" : "Active"}
                    </Table.Cell>
                    <DisplayIf cond={permissions?.editDeviceConfigs}>
                      <Table.Cell>
                        <div
                          style={{
                            width: "30%",
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <ButtonIcon
                            name="eye"
                            title="View Config"
                            onClick={() => {
                              history.push(
                                encodeURI(`${pathname}/${config.version_name}`)
                              );
                            }}
                          />
                          <ButtonIcon
                            name="edit"
                            title="Edit Config"
                            disabled={config.is_deactivated}
                            style={{
                              cursor: config.is_deactivated
                                ? "default"
                                : "pointer",
                            }}
                            onClick={() =>
                              history.push(
                                encodeURI(
                                  `${pathname}/${config.version_name}/edit`
                                )
                              )
                            }
                          />
                          <ButtonIcon
                            name="ban"
                            title={
                              config.is_deactivated ? "" : "Deactivate Config"
                            }
                            style={{
                              cursor: config.is_deactivated
                                ? "default"
                                : "pointer",
                            }}
                            color={config.is_deactivated ? "grey" : "orange"}
                            disabled={config.is_deactivated}
                            onClick={() => {
                              openModal();
                              setVersion(config.version_name);
                            }}
                          />
                        </div>
                      </Table.Cell>
                    </DisplayIf>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={"3"}>
                    <ErrorMessage message={"No Configurations Found!"} />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default DeviceConfigurations;

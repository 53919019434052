import AggregateValueIcon from "./PanelIcons/IconsComponent/AggregateValueIcon";
import AlertIcon from "./PanelIcons/IconsComponent/AlertIcon";
import BarChartIcon from "./PanelIcons/IconsComponent/BarChartIcon";
import BarChartsIcon from "./PanelIcons/IconsComponent/BarChartsIcon";
import DevicePulseIcon from "./PanelIcons/IconsComponent/DevicePulseIcon";
import GaugeChartIcon from "./PanelIcons/IconsComponent/GaugeChartIcon";
import HeatMapIcon from "./PanelIcons/IconsComponent/HeatMapIcon";
import HistogramIcon from "./PanelIcons/IconsComponent/HistogramIcon";
import LastValueIcon from "./PanelIcons/IconsComponent/LastValueIcon";
import LedPanelIcon from "./PanelIcons/IconsComponent/LedPanelIcon";
import StreamStatusIcon from "./PanelIcons/IconsComponent/StreamStatusIcon";
import LineChartIcon from "./PanelIcons/IconsComponent/LineChartIcon";
import LocateDevicesIcon from "./PanelIcons/IconsComponent/LocateDevicesIcon";
import LogsIcon from "./PanelIcons/IconsComponent/LogsIcon";
import PieChartIcon from "./PanelIcons/IconsComponent/PieChartIcon";
import PivotTableIcon from "./PanelIcons/IconsComponent/PivotTableIcon";
import SessionsIcon from "./PanelIcons/IconsComponent/SessionsIcon";
import StateTimelineIcon from "./PanelIcons/IconsComponent/StateTimelineIcon";
import StaticTextIcon from "./PanelIcons/IconsComponent/StaticTextIcon";
import IframePanelIcon from "./PanelIcons/IconsComponent/IFrameIcon";
import TimeseriesTableIcon from "./PanelIcons/IconsComponent/TimeseriesTableIcon";
import TrackDevicesIcon from "./PanelIcons/IconsComponent/TrackDevicesIcon";
import StatusTableIcon from "./PanelIcons/IconsComponent/StatusTableIcon";

export const panelImages = {
  aggregate_value: AggregateValueIcon,
  bar_chart: BarChartIcon,
  heatmap: HeatMapIcon,
  alerts: AlertIcon,
  gauge_chart: GaugeChartIcon,
  device_pulse: DevicePulseIcon,
  led_panel: LedPanelIcon,
  line_chart: LineChartIcon,
  locate_devices: LocateDevicesIcon,
  pie_chart: PieChartIcon,
  pivot_table: PivotTableIcon,
  sessions: SessionsIcon,
  logs: LogsIcon,
  state_timeline: StateTimelineIcon,
  static_text: StaticTextIcon,
  timeseries_table: TimeseriesTableIcon,
  histogram: HistogramIcon,
  track_devices: TrackDevicesIcon,
  barchart: BarChartsIcon,
  last_value: LastValueIcon,
  device_status: LedPanelIcon, // led-panel
  stream_status: StreamStatusIcon,
  iframe_panel: IframePanelIcon,
  // Micelio Panel icons
  micelio_locate_devices: LocateDevicesIcon,
  micelio_track_devices: TrackDevicesIcon,
  micelio_status_table: StatusTableIcon,
  micelio_status: LedPanelIcon,
  micelio_stats_panel: LineChartIcon,
  micelio_alerts_panel: AlertIcon,
};

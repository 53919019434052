import { PivotTableData, PivotTableMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";

export class ViewPivotTable extends PanelViewComponent<
  PivotTableMetaData,
  PivotTableData
> {
  render() {
    return <div>Not Implemented!</div>;
  }
}

import React, { useState, useEffect } from "react";
import LoadingAnimation from "../../../common/Loader";
import { useUser } from "../../../../context/User.context";
import useAsyncEffect from "../../common/useAsyncEffect";
import ModulePermissions from "./ModulePermissions";
import LogoSettings from "./logoAndFavicon/LogoSettings";
import FaviconSettings from "./logoAndFavicon/FaviconSettings";
import TenantLevelSettings from "./TenantLevelSettings";

type TenantSettingsPageProps = {
  currentTenant: string;
};

const TenantSettingsPage = (props: TenantSettingsPageProps) => {
  const { currentTenant } = props;
  const { user, getCurrentUser } = useUser();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    document.title = "General Settings | Bytebeam";
  }, []);

  const fetchUserTenantSettings = async () => {
    if (!user) await getCurrentUser();
    setLoading(false);
  };

  useAsyncEffect(fetchUserTenantSettings, []);

  if (loading) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading Settings"
      />
    );
  }

  return (
    <div style={{ marginTop: "30px" }}>
      <TenantLevelSettings user={user} getCurrentUser={getCurrentUser} />
      <LogoSettings
        user={user}
        getCurrentUser={getCurrentUser}
        currentTenant={currentTenant}
      />
      <FaviconSettings
        user={user}
        getCurrentUser={getCurrentUser}
        currentTenant={currentTenant}
      />
      <ModulePermissions user={user} getCurrentUser={getCurrentUser} />
    </div>
  );
};

export default TenantSettingsPage;

import * as React from "react";
import { SVGProps } from "react";

const PivotTableIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="24.5 24.5 100 100"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    {...props}
  >
    <path
      fill="currentColor"
      d="M24.5 24.5h23v23h-23v-23Z"
      style={{
        opacity: 0.978,
      }}
    />
    <path
      fill="currentColor"
      d="M52.5 24.5h72v23h-72v-23Z"
      style={{
        opacity: 0.985,
      }}
    />
    <path
      fill="currentColor"
      d="M24.5 51.5h23v73h-23v-73Z"
      style={{
        opacity: 0.972,
      }}
    />
    <path
      fill="currentColor"
      d="M112.5 63.5c.996-.086 1.829.248 2.5 1l5.5 11c-1.545.952-3.212 1.285-5 1 2.3 27.7-10.366 40.366-38 38a18.437 18.437 0 0 1-.5 6 264.772 264.772 0 0 1-13.5-7.5l13-6.5c.952 1.545 1.285 3.212 1 5 25.978 2.689 37.645-8.978 35-35a18.437 18.437 0 0 1-6-.5 71.741 71.741 0 0 0 6-12.5Z"
      style={{
        opacity: 0.696,
      }}
    />
  </svg>
);

export default PivotTableIcon;

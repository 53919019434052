import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { User } from "../../../util";
import { ListDashboards } from "./ListDashboards";

interface DashboardsProps extends RouteComponentProps {
  user: User;
  currentTenant: string;
}

class Dashboards extends React.Component<DashboardsProps> {
  componentDidMount(): void {
    document.title = "Dashboards | Bytebeam";
  }

  render() {
    return (
      <ListDashboards
        user={this.props.user}
        currentTenant={this.props.currentTenant}
      />
    );
  }
}

export default withRouter(Dashboards);

import styled from "styled-components";
import React, { FC, ComponentType } from "react";

type PointingType = "top" | "bottom";

const StyledDropdown = styled.div<{ pointing: PointingType }>`
  && {
    &.ui.dropdown .menu,
    &.ui.dropdown .visible.menu,
    &.ui.pointing.upward.dropdown .menu,
    &.ui.top.pointing.upward.dropdown .menu {
      position: absolute !important;
      top: ${({ pointing }) =>
        pointing === "bottom" ? "100%" : "auto"} !important;
      bottom: ${({ pointing }) =>
        pointing === "top" ? "100%" : "auto"} !important;
      margin: 0px !important;
    }

    .visible.menu::after {
      display: none !important;
    }
  }
`;

interface ForcedDirDropdownProps {
  dropdownComponent: ComponentType<any>;
  pointing: PointingType;
  [key: string]: any;
}

// ForcedDirDropdownStyled is a styled component that wraps another component with dropdown styling.
const ForcedDirDropdownStyled: FC<ForcedDirDropdownProps> = ({
  dropdownComponent: Component,
  pointing,
  ...rest
}) => {
  return <StyledDropdown as={Component} pointing={pointing} {...rest} />;
};

export default ForcedDirDropdownStyled;

import React from "react";
import { Dropdown } from "semantic-ui-react";

const typeOptions = [
  {
    value: "Bool",
    text: "Bool",
  },
  {
    value: "Nullable(Bool)",
    text: "Nullable(Bool)",
  },
  {
    value: "Float32",
    text: "Float32",
  },
  {
    value: "Nullable(Float32)",
    text: "Nullable(Float32)",
  },
  {
    value: "Float64",
    text: "Float64",
  },
  {
    value: "Nullable(Float64)",
    text: "Nullable(Float64)",
  },
  {
    value: "Int8",
    text: "Int8",
  },
  {
    value: "Nullable(Int8)",
    text: "Nullable(Int8)",
  },
  {
    value: "Int16",
    text: "Int16",
  },
  {
    value: "Nullable(Int16)",
    text: "Nullable(Int16)",
  },
  {
    value: "Int32",
    text: "Int32",
  },
  {
    value: "Nullable(Int32)",
    text: "Nullable(Int32)",
  },
  {
    value: "Int64",
    text: "Int64",
  },
  {
    value: "Nullable(Int64)",
    text: "Nullable(Int64)",
  },
  {
    value: "UInt8",
    text: "UInt8",
  },
  {
    value: "Nullable(UInt8)",
    text: "Nullable(UInt8)",
  },
  {
    value: "UInt16",
    text: "UInt16",
  },
  {
    value: "Nullable(UInt16)",
    text: "Nullable(UInt16)",
  },
  {
    value: "UInt32",
    text: "UInt32",
  },
  {
    value: "Nullable(UInt32)",
    text: "Nullable(UInt32)",
  },
  {
    value: "UInt64",
    text: "UInt64",
  },
  {
    value: "Nullable(UInt64)",
    text: "Nullable(UInt64)",
  },
  {
    value: "String",
    text: "String",
  },
  {
    value: "Nullable(String)",
    text: "Nullable(String)",
  },
  {
    value: "Date",
    text: "Date",
  },
  {
    value: "Nullable(Date)",
    text: "Nullable(Date)",
  },
  {
    value: "DateTime",
    text: "Timestamp",
  },
  {
    value: "Nullable(DateTime)",
    text: "Nullable Timestamp",
  },
  {
    value: "DateTime64(3)",
    text: "Timestamp milliseconds",
  },
  {
    value: "Nullable(DateTime64(3))",
    text: "Nullable Timestamp milliseconds",
  },
  {
    value: "DateTime64(6)",
    text: "Timestamp microseconds",
  },
  {
    value: "Nullable(DateTime64(6))",
    text: "Nullable Timestamp microseconds",
  },
];

const fullOptions = typeOptions.map((x) => ({
  key: x.value,
  text: x.text,
  value: x.value,
}));

function allowedConversions(value, editMode = true) {
  const nullable = value.startsWith("Nullable");
  const valueWithoutNullable = value.replace("Nullable(", "").replace(")", "");

  // Extracting numeric precision from type names
  const getTypePrecision = (typeName) => {
    const match = typeName.match(/\d+/);
    return match ? parseInt(match[0], 10) : undefined;
  };

  const isValidConversionTarget = (
    optionValue,
    baseType,
    basePrecision,
    isNullable
  ) => {
    const optionType = optionValue.replace("Nullable(", "").replace(")", "");
    const optionPrecision = getTypePrecision(optionType);
    // Checking if the option's type matches the base type and has equal or greater precision
    const isTypeMatch =
      optionType.includes(baseType) && optionPrecision >= basePrecision;

    const isNullableMatch = isNullable
      ? optionValue.startsWith("Nullable")
      : true;
    return isTypeMatch && isNullableMatch;
  };

  const basePrecision = getTypePrecision(valueWithoutNullable);

  let returnOptions = [];
  if (editMode) {
    returnOptions = typeOptions.filter((option) => {
      if (value.includes("Bool")) {
        return (
          option.value.includes("Bool") ||
          option.value.includes("Int") ||
          option.value.includes("UInt") ||
          option.value.includes("String")
        );
      } else if (value.includes("Int")) {
        // Allow conversion to String and Float types directly
        if (option.value.includes("String") || option.value.includes("Float"))
          return true;
        // For Int/UInt types, check for valid conversion targets based on type and precision
        return isValidConversionTarget(
          option.value,
          "Int",
          basePrecision,
          nullable
        );
      } else if (value.includes("Float")) {
        // Allow conversion to String directly
        if (option.value.includes("String")) return true;
        // For Float types, check for valid conversion targets based on precision
        return isValidConversionTarget(
          option.value,
          "Float",
          basePrecision,
          nullable
        );
      } else if (value.includes("Date") || value.includes("DateTime")) {
        // For Date and DateTime types, allow conversion within Date/DateTime types and to String
        return option.value.includes("Date") || option.value.includes("String");
      } else if (value.includes("String")) {
        // For String types, only allow conversion to Nullable(String) if not already nullable
        return option.value.includes("String");
      }
      return true;
    });
    if (nullable) {
      // If the original value is nullable, filter to only include nullable types
      returnOptions = returnOptions.filter((option) =>
        option.value.startsWith("Nullable")
      );
    }
  } else {
    returnOptions = typeOptions;
  }

  // Map to the expected format for the Dropdown component
  return returnOptions.map((option) => ({
    key: option.value,
    text: option.text,
    value: option.value,
  }));
}

export default function TypeSelection({
  value,
  onChange,
  disabled,
  id,
  editMode,
}) {
  let typeValue = "";
  for (const opt of fullOptions) {
    if (opt.value === value) {
      typeValue = opt.value;
    }
  }
  // Filter fullOptions based on allowed rules of conversions
  // if its Nullable, show only Nullable options.
  // If its integer type, then allow to convert to float or higher int types
  // If it is boolean, then allow to convert to nullable boolean or integer types
  // If it is float, then allow to convert to nullable float or higher float types
  let allowedOptions = allowedConversions(value, editMode);

  return (
    <Dropdown
      id={id}
      fluid
      search
      selection
      placeholder="Select Type"
      value={typeValue}
      onChange={onChange}
      options={allowedOptions}
      style={{ width: "100%" }}
      disabled={disabled}
    />
  );
}

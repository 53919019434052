import React, { useEffect, useState } from "react";
import {
  IconContainer,
  SettingRowContainer,
  SettingRowEditableSection,
  SettingRowLabel,
  TenantLevelSettingsProps,
} from "./TenantLevelSettings";
import TimeoutDropdown from "../../../common/TimeoutDropdown";
import { updateTenantSettings } from "../../../../BytebeamClient";
import { beamtoast } from "../../../common/CustomToast";
import { TenantSettings } from "../../../../util";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { neverTimestamp } from "../../Actions/NewAction/NewAction";

// Convert milliseconds to a human-readable format using moment

export const formatTimeoutDuration = (
  duration: number | Date | null | undefined
) => {
  if (duration === neverTimestamp) {
    return "Never";
  } else if (typeof duration === "number" && duration > 0) {
    const durationObj = moment.duration(duration);

    // Calculate total days
    const days = Math.floor(durationObj.asDays());
    const hours = Math.floor(durationObj.asHours() % 24);
    const minutes = Math.floor(durationObj.asMinutes() % 60);
    const seconds = Math.floor(durationObj.asSeconds() % 60);

    const parts: string[] = [];
    if (days > 0) parts.push(`${days} day${days > 1 ? "s" : ""}`);
    if (hours > 0) parts.push(`${hours} hr${hours > 1 ? "s" : ""}`);
    if (minutes > 0) parts.push(`${minutes} min${minutes > 1 ? "s" : ""}`);
    if (seconds > 0) parts.push(`${seconds} sec${seconds > 1 ? "s" : ""}`);

    return parts.length > 0 ? parts.join(", ") : "0 seconds";
  } else {
    return "No Timeout Set";
  }
};

const DefaultGlobalTimeout = ({
  user,
  getCurrentUser,
}: TenantLevelSettingsProps) => {
  const userDefaultTimeout = user?.["tenant-settings"]?.default_action_timeout;
  const aMonthInMs = 30 * 24 * 60 * 60 * 1000; // milliseconds in a month

  const [timeoutDuration, setTimeoutDuration] = useState<number | Date>(
    userDefaultTimeout || aMonthInMs
  );
  const [dropdownDisabled, setDropdownDisabled] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [localTimeout, setLocalTimeout] = useState<number | Date>(
    timeoutDuration || aMonthInMs
  );

  const updateDefaultTimeout = async (timeoutDuration: number | Date) => {
    const tenantSettings = user?.["tenant-settings"] ?? ({} as TenantSettings);
    try {
      setDropdownDisabled(true);
      const res = await updateTenantSettings({
        settings: {
          ...tenantSettings,
          default_action_timeout: timeoutDuration,
        },
      });
      if (res !== undefined && res !== null) {
        beamtoast.success(`Default Action Timeout updated successfully`);
        setTimeoutDuration(timeoutDuration); // Update the state to reflect the new timeout
      }
    } catch (error) {
      console.log("Error while updating tenant settings", error);
    } finally {
      await getCurrentUser();
      setDropdownDisabled(false);
      setEditMode(false); // Exit edit mode after successful update
    }
  };

  const handleSave = () => {
    updateDefaultTimeout(localTimeout);
  };

  const handleCancel = () => {
    setLocalTimeout(timeoutDuration); // Revert to the original timeout value
    setEditMode(false);
  };

  // Set the default timeout value from the user settings
  useEffect(() => {
    if (userDefaultTimeout) {
      setTimeoutDuration(userDefaultTimeout);
      setLocalTimeout(userDefaultTimeout);
    }
  }, [userDefaultTimeout]);

  return (
    <SettingRowContainer>
      <SettingRowLabel>Default Action Timeout:</SettingRowLabel>
      {!editMode ? (
        <SettingRowEditableSection>
          <span style={{ minWidth: "200px" }}>
            {formatTimeoutDuration(timeoutDuration)}
          </span>
          <Icon
            link
            name="edit"
            onClick={() => setEditMode(true)}
            style={{ cursor: "pointer", marginLeft: "10px" }}
          />
        </SettingRowEditableSection>
      ) : (
        <SettingRowEditableSection>
          <TimeoutDropdown
            maxwidthdropdown="400px"
            minwidthdropdown="200px"
            widthdropdown="100%"
            allowNever={false}
            saveLoading={dropdownDisabled}
            timeoutDuration={localTimeout}
            setTimeoutDuration={setLocalTimeout}
          />
          <IconContainer>
            <Icon
              link
              name="save"
              disabled={
                localTimeout === undefined ||
                localTimeout === timeoutDuration ||
                dropdownDisabled
              }
              onClick={handleSave}
            />
            <Icon link name="cancel" onClick={handleCancel} />
          </IconContainer>
        </SettingRowEditableSection>
      )}
    </SettingRowContainer>
  );
};

export default DefaultGlobalTimeout;

import React, { useState, useEffect } from "react";
import { Breadcrumb, Icon, Button, Grid } from "semantic-ui-react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/theme-chaos";
import {
  DeviceConfigurationType,
  fetchDeviceConfigurationDetails,
} from "../../../../BytebeamClient";
import { useHistory } from "react-router-dom";
import ThemeSchema from "../../../../theme/schema";
import LoadingAnimation from "../../../common/Loader";

type ViewDeviceConfigurationsProps = {
  name: string;
  theme: string;
};

function ViewDeviceConfigurations(props: ViewDeviceConfigurationsProps) {
  const { name, theme } = props;

  const [deviceConfig, setDeviceConfig] = useState<DeviceConfigurationType>();
  const [loadingConfig, setLoadingConfig] = useState<boolean>(true);
  const [configJSON, setConfigJSON] = useState<string>("");

  const history = useHistory();

  async function fetchConfigDetails() {
    try {
      const res = await fetchDeviceConfigurationDetails(name);
      setDeviceConfig(res);
      setConfigJSON(JSON.stringify(res.config_json, null, 2));
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingConfig(false);
    }
  }

  useEffect(() => {
    fetchConfigDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loadingConfig) {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText={`Loading ${name} Configuration`}
      />
    );
  }

  return (
    <Grid verticalAlign="middle">
      <Grid.Row>
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section
              link
              onClick={() => {
                history.push("/device-management/configurations");
              }}
            >
              Device Configs
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
            <Breadcrumb.Section active>{"Config " + name}</Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Button
            size="tiny"
            labelPosition="left"
            icon
            secondary
            style={{ marginRight: "8px" }}
            onClick={() => {
              history.push(encodeURI(`/device-management/configurations`));
            }}
          >
            <Icon name="arrow left" />
            Back
          </Button>

          {deviceConfig?.is_deactivated === true ? null : (
            <Button
              size="tiny"
              labelPosition="left"
              icon
              primary
              onClick={() => {
                history.push(
                  encodeURI(`/device-management/configurations/${name}/edit`)
                );
              }}
            >
              <Icon name="edit" />
              Edit
            </Button>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <h4>Config JSON</h4>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="12">
          <AceEditor
            height="450px"
            width="800px"
            mode="json"
            theme={
              ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-theme"]
            }
            name="config-json-view"
            readOnly={true}
            highlightActiveLine={false}
            placeholder={loadingConfig ? "Loading..." : "Failed to load JSON"}
            value={configJSON}
            fontSize={14}
            showPrintMargin={false}
            showGutter={true}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showLineNumbers: false,
              tabSize: 2,
            }}
            style={{
              marginBottom: "16px",
              borderRadius: "4px",
              border: `${
                ThemeSchema.data[theme ?? "dark"]?.colors["ace-editor-border"]
              }`,
              boxShadow: `${
                ThemeSchema.data[theme ?? "dark"]?.colors[
                  "ace-editor-box-shadow"
                ]
              }`,
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default ViewDeviceConfigurations;

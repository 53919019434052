import React from "react";
import { copyContentToClipboard } from "../../util";
import styled from "styled-components";
import { Popup, StrictPopupProps } from "semantic-ui-react";

const ToolTipPopUp = styled(Popup)`
  background-color: ${({ theme }) =>
    theme.colors["tooltip-background"]} !important;
  color: ${({ theme }) => theme.colors["tooltip_primary-text"]} !important;
  border: ${({ theme }) =>
    theme.colors["live-actions-more-info-popup-border"]} !important;
  word-break: break-word;
  white-space: pre-wrap;

  &::before {
    background-color: ${({ theme }) =>
      theme.colors["tooltip-background"]} !important;
    border-bottom: ${({ theme }) =>
      theme.colors["live-actions-more-info-popup-border"]} !important;
    border-right: ${({ theme }) =>
      theme.colors["live-actions-more-info-popup-border"]} !important;
  }
`;

const TextLabel = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 18px;
  width: 100% !important;
  color: ${({ theme }) => theme.colors["tooltip_primary-text"]} !important;
`;

const ListTextLabel = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  line-height: 18px;
  height: 18px !important;
  width: 100% !important;
  color: ${({ theme }) => theme.colors["tooltip_list-text-color"]};
`;

const ToolTip = styled.span<{ notBold?: boolean }>`
  max-width: 82%;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  font-weight: ${({ notBold }) => (notBold ? "normal" : "bolder")} !important;
  .slicedText {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

function toSliceText(
  text: string | number,
  length?: number
): [boolean, string | number] {
  let sliced = false,
    slicedText = text;

  if (typeof text === "string" || typeof text === "number") {
    slicedText = String(text);
    if (length && slicedText?.length > length) {
      slicedText = slicedText.substring(0, length);
      sliced = true;
    }
  }
  return [sliced, slicedText];
}

type TextWithToolTipType = {
  readonly text: string | number;
  readonly tooltipposition?: StrictPopupProps["position"];
  readonly length?: number;
  readonly textList?: string[];
  readonly notBold?: boolean;
};

function TextWithToolTip(props: TextWithToolTipType) {
  let { text, length, textList, notBold } = props;

  if (textList !== undefined && textList !== null) {
    textList = textList
      ?.filter((text) => text !== undefined && text !== null && text !== "")
      ?.map((text) => String(text).trim());
  }

  let [sliced, slicedText] = toSliceText(text, length);

  if (
    slicedText === undefined ||
    slicedText === null ||
    String(slicedText).trim() === ""
  ) {
    slicedText = "--";
  }

  if (!text) {
    return (
      <ToolTip className="tooltip" style={{ cursor: "pointer" }}>
        --
      </ToolTip>
    );
  }

  return (
    <ToolTipPopUp
      wide
      content={
        <div>
          {<TextLabel>{text}</TextLabel>}
          {textList !== undefined && textList?.length > 0 && (
            <div style={{ marginTop: "8px" }}>
              {textList?.map((item) => (
                <ListTextLabel key={item}>{item}</ListTextLabel>
              ))}
            </div>
          )}
        </div>
      }
      inverted
      position={props.tooltipposition ?? "top center"}
      trigger={
        <ToolTip
          className="tooltip"
          notBold={notBold}
          onClick={async () => await copyContentToClipboard(text)}
          style={{ cursor: "pointer" }}
        >
          <div className="slicedText">
            {sliced ? `${slicedText}...` : slicedText}
          </div>
        </ToolTip>
      }
    />
  );
}

export default TextWithToolTip;

import React, {
  useState,
  useEffect,
  createRef,
  useCallback,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import {
  Accordion,
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Label,
  Message,
  Modal,
  Popup,
} from "semantic-ui-react";
import styled from "styled-components";
import {
  StreamFields,
  createDBCParser,
  fetchAllStreamsWithDetails,
  stopDBCParser,
  updateDBCSettings,
  uploadFile,
} from "../../../../BytebeamClient";
import {
  DBCAdditionalSettings,
  DBCData,
  DBCResponse,
  DBCTypes,
  dropDownOptionsFromArray,
} from "../../../../util";
import _ from "lodash";
import { beamtoast } from "../../../common/CustomToast";
import { StyledFileUploadButton } from "../../DeviceManagement/Devices/ActionModals/SendFileModal";
import { StyledInput as StyledFileInput } from "../../Actions/action-modals/UploadFirmwareComponent";
import AnimatedEllipsis from "../../common/AnimatedEllipsis";
import { Mixpanel } from "../../common/MixPanel";
import LoadingAnimation from "../../../common/Loader";
import { DBCOperationType } from "./DBC";
import SimpleConfirmationModal from "../../common/SimpleConfirmationModal";
import { pgnsWithLessThan8bytes } from "./constant";
import { ThinDivider } from "../../Dashboards/Panel/util";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledLabel = styled(Label)`
  margin: 0px !important;
  min-width: 80px;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 14px;
  width: 100%;
`;

type Stream = {
  [key: string]: StreamFields;
};

interface UploadDBCFileSectionProps {
  readonly onClose: () => void;
  readonly DBCData: DBCResponse[];
  readonly newDBC: DBCData;
  readonly operationType: string;
  setIsEditOrCreateDBCGoingOn: (arg0: boolean) => void;
  setNewDBCOutputStream: (stream: string) => void;
  handleUpdate: () => void;
}

interface HandleUploadDBCType {
  handleUpload: (additional_settings: DBCAdditionalSettings) => Promise<void>;
}

const UploadDBCFileSection = forwardRef<
  HandleUploadDBCType,
  UploadDBCFileSectionProps
>((props, ref) => {
  const DBCFileInput = createRef<any>();

  const [DBCFileName, setDBCFileName] = useState<string>("");
  const [DBCFile, setDBCFile] = useState<File>(new File([""], "filename"));
  const [showDBCUploadProgress, setShowDBCUploadProgress] =
    useState<boolean>(false);
  const [DBCFileLoaded, setDBCFileLoaded] = useState<number>(0);
  const [DBCFileTotal, setDBCFileTotal] = useState<number>(0);
  const [uploadedDBCFileResponse, setUploadedDBCFileResponse] = useState<{
    status: number;
    data: { id: string };
  }>({
    status: 0,
    data: { id: "" },
  });

  const onSelect = useCallback((e) => {
    setShowDBCUploadProgress(false);
    setDBCFileLoaded(0);
    setDBCFileTotal(0);

    if (e.target.files.length !== 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        const fileName = selectedFile.name;
        const fileExtension = fileName.split(".").pop(); // Get the file extension
        // Check if the file extension is ".dbc"
        if (fileExtension !== "dbc") {
          beamtoast.error(`Only ".dbc" file types are allowed!`);
          console.log(
            'Only files with the ".dbc" extension are allowed to be uploaded.'
          );

          setShowDBCUploadProgress(false);
          setDBCFileLoaded(0);
          setDBCFileTotal(0);
          setDBCFile(new File([""], "filename"));
          setDBCFileName("");
          return;
        }

        // Check if the file is empty (size === 0)
        if (selectedFile.size === 0) {
          beamtoast.error("Selected DBC file is empty!");
          return;
        }

        // If both checks pass, set the DBC file and file name
        setDBCFile(selectedFile);
        setDBCFileName(fileName);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadDBC = async (
    additional_settings: string,
    setShowDBCUploadProgress: (arg0: boolean) => void
  ) => {
    // DBC Upload API Endpoint
    const url = `/api/v1/dbcs`;

    let formData = new FormData();

    // need to keep this check here itself due to ts strict typechecking
    if (
      props.newDBC.name &&
      props.newDBC.version &&
      props.newDBC.input_table &&
      props.newDBC.output_table &&
      props.newDBC.period &&
      additional_settings
    ) {
      formData.append("dbc_type", "custom");
      formData.append("file", DBCFile);
      formData.append("name", props.newDBC.name.trim());
      formData.append("version", props.newDBC.version.trim());
      formData.append("input_table", props.newDBC.input_table);
      formData.append("output_table", props.newDBC.output_table.trim());
      formData.append("period", props.newDBC.period.toString().trim());
      formData.append("additional_settings", additional_settings);
    } else {
      beamtoast.error("All fields are required.");
      return;
    }

    try {
      props.setIsEditOrCreateDBCGoingOn(true);
      const res = await uploadFile(url, formData, (p) => {
        // for loading progress
        setDBCFileLoaded(p.loaded);
        setDBCFileTotal(p.total);
      });

      setUploadedDBCFileResponse(res);

      if (res.status === 201) {
        beamtoast.success(`Created DBC "${props.newDBC.name}"`);
        Mixpanel.track("Uploaded DBC", {
          DBC: res.data["version"],
        });
        props.setNewDBCOutputStream(
          props.newDBC.output_table ? props.newDBC.output_table : ""
        );
        props.onClose();
        props.handleUpdate();
      }
    } catch (error: any) {
      console.log(error);
      setShowDBCUploadProgress(false);
      Mixpanel.track("Failure", {
        type: "Upload DBC",
      });
      props.setNewDBCOutputStream("");

      // Handling Error for File Size exceeding limit from NGINX
      if (String(error).includes("413")) {
        beamtoast.error("Upload failed due to size limit!");
      }

      // Handling other error types
      const errorMessage = error.response?.data?.error;

      if (errorMessage.includes("invalid continuation byte")) {
        beamtoast.error("Uploaded file is not supported!");
      } else if (errorMessage.includes("fixprotobuff failed")) {
        beamtoast.error("DBC created but fixprotobuff failed");
      } else if (errorMessage?.includes("No signals in DBC")) {
        beamtoast.error("Uploaded DBC file does not contain any signals!");
      } else {
        beamtoast.error(errorMessage, { duration: 6000 });
      }
    } finally {
      props.setIsEditOrCreateDBCGoingOn(false);
    }
  };

  // function for all data upload/edit validation
  const handleUpload = useCallback(
    async (additional_settings: DBCAdditionalSettings) => {
      // no file upload is happening while edit mode is on
      // file validation: cannot be empty + .dbc required
      if (
        props.operationType === DBCOperationType.Create &&
        !(
          DBCFileInput?.current?.files &&
          DBCFileName !== "" &&
          DBCFileInput.current.files.length > 0
        )
      ) {
        beamtoast.error("A DBC file must be selected!");
        return;
      }

      try {
        setShowDBCUploadProgress(true);

        uploadDBC(
          JSON.stringify(additional_settings),
          setShowDBCUploadProgress
        );
      } catch (error: any) {
        console.log(error);
        beamtoast.error("Something went wrong! Please try again.");
        setShowDBCUploadProgress(false);
      }
    },
    [DBCFileName, props.DBCData, DBCFileInput] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useImperativeHandle(ref, () => ({
    handleUpload,
  }));

  return (
    <Form>
      <Form.Field>
        <label htmlFor="upload_file">
          <p
            style={{
              fontSize: "1.1rem",
            }}
          >
            Upload file from the system
          </p>
        </label>
        <div style={{ position: "relative" }}>
          <StyledFileUploadButton
            fluid
            content="Select File"
            labelPosition="left"
            icon="file"
          />
          <StyledFileInput
            type="file"
            id="upload_file"
            ref={DBCFileInput}
            onChange={onSelect}
          />
        </div>
        <label htmlFor="file_name" style={{ marginTop: "12px" }}>
          File Chosen:{" "}
        </label>
        <span id="file_name">
          {DBCFileName !== "" ? DBCFileName : "No File Chosen"}
        </span>
      </Form.Field>
      {showDBCUploadProgress && (
        <Form.Field>
          <label htmlFor="file-progress">
            {uploadedDBCFileResponse.status === 0 ? (
              <span>
                File Uploading
                <AnimatedEllipsis spacing={3} dotSize={"8px"} />
              </span>
            ) : (
              <span>File Uploaded</span>
            )}
          </label>
          <progress
            id="file-progress"
            max={DBCFileTotal}
            value={DBCFileLoaded}
          />
        </Form.Field>
      )}
    </Form>
  );
});

interface CreateDBCModalProps {
  readonly open: boolean;
  readonly onOpen: () => void;
  readonly onClose: () => void;
  readonly selectedDBC?: DBCResponse;
  readonly DBCData: DBCResponse[];
  readonly operationType: string;
  readonly setNewDBCOutputStream: (stream: string) => void;
  readonly handleUpdate: () => void;
}

export default function CreateOrEditDBCModal(props: CreateDBCModalProps) {
  const uploadDBCRef = useRef<HandleUploadDBCType>(null);
  const allStreams = useRef<string[]>([]);
  const validStreams = useRef<Stream>({});

  const [loading, setLoading] = useState<boolean>(false);
  const [isEditOrCreateDBCGoingOn, setIsEditOrCreateDBCGoingOn] =
    useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [newDBC, setNewDBC] = useState<DBCData>({
    dbc_type: "custom",
    name: "",
    version: "",
    input_table: "",
    output_table: "",
    period: 1000,
    obd_can_id: 2024,
    j1939_pgns: [],
    additional_settings: {
      dont_decode_choices: false,
      add_suffix_canid: false,
      lower_threshold: undefined,
      upper_threshold: undefined,
      dbc_ver: undefined,
    },
  });
  const [inputTableOptions, setInputTableOptions] = useState<
    {
      key: string;
      value: string;
      text: string;
    }[]
  >([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  // destructure for better code readability
  const add_suffix_canid = newDBC.additional_settings?.add_suffix_canid;
  const dont_decode_choices = newDBC.additional_settings?.dont_decode_choices;
  const upper_threshold = newDBC.additional_settings?.upper_threshold;
  const lower_threshold = newDBC.additional_settings?.lower_threshold;
  const dbc_ver = newDBC.additional_settings?.dbc_ver;

  const dbcTypes: DBCTypes[] = ["custom", "obd", "j1939"];

  const disableEnumParsingAndSuffixCanId =
    props.operationType === DBCOperationType.Edit ||
    newDBC.dbc_type !== "custom";

  const resetNewDBCState = () => {
    setNewDBC({
      dbc_type: "custom",
      name: "",
      version: "",
      input_table: "",
      output_table: "",
      period: 1000,
      obd_can_id: 2024,
      j1939_pgns: [],
      additional_settings: {
        dont_decode_choices: false,
        add_suffix_canid: false,
        lower_threshold: undefined,
        upper_threshold: undefined,
        dbc_ver: undefined,
      },
    });
    setActiveIndex(-1);
  };

  const setDBCType = (type: DBCTypes) => {
    setNewDBC({
      ...newDBC,
      dbc_type: type,
      ...(type === "obd" ? { j1939_pgns: [] } : {}),
      ...(type === "j1939" ? { obd_can_id: 2024 } : {}),
      ...(type !== "custom"
        ? {
            additional_settings: {
              ...newDBC.additional_settings,
              dont_decode_choices: false,
              add_suffix_canid: false,
            },
          }
        : {}),
    });
  };

  const setObdCanId = (canId: number) => {
    setNewDBC({
      ...newDBC,
      obd_can_id: canId,
    });
  };

  const setSelectedJ1939PGNs = (pgns: number[]) => {
    setNewDBC({
      ...newDBC,
      j1939_pgns: pgns,
    });
  };

  const setDBCName = (DBCName: string) => {
    setNewDBC({
      ...newDBC,
      name: DBCName,
    });
  };

  const setDBCVersion = (DBCVersion: string) => {
    setNewDBC({
      ...newDBC,
      version: DBCVersion,
    });
  };

  const setDBCInputTable = (DBCInputTableValue: string) => {
    setNewDBC({
      ...newDBC,
      input_table: DBCInputTableValue,
    });
  };

  const setDBCOutputTable = (DBCOutputTableValue: string) => {
    setNewDBC({
      ...newDBC,
      output_table: DBCOutputTableValue,
    });
  };

  const setDBCPeriod = (DBCPeriod: number) => {
    setNewDBC({
      ...newDBC,
      period: DBCPeriod,
    });
  };

  const updateSuffixCanidState = (state: boolean) => {
    setNewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        add_suffix_canid: state,
      },
    });
  };

  const updateDisableEnumParsingState = (state: boolean) => {
    setNewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        dont_decode_choices: state,
      },
    });
  };

  const setDBCLowerThreshold = (DBCLowerThreshold: number | undefined) => {
    setNewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        lower_threshold: DBCLowerThreshold,
      },
    });
  };

  const setDBCUpperThreshold = (DBCUpperThreshold: number | undefined) => {
    setNewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        upper_threshold: DBCUpperThreshold,
      },
    });
  };

  const setDBCVersionFilter = (DBCVersionFilter: number | undefined) => {
    setNewDBC({
      ...newDBC,
      additional_settings: {
        ...newDBC.additional_settings,
        dbc_ver: DBCVersionFilter,
      },
    });
  };

  const handleAccordionClick = () => {
    setActiveIndex((index) => (index === 0 ? 1 : 0));
  };

  // stream validity
  // only allowed to contain underscore as special character without spaces
  const isStreamValid = (output_stream: string) => {
    const regex = /^(?=.*[a-zA-Z])\w+$/;
    return regex.test(output_stream);
  };

  const isStreamUnique = (output_stream: string) => {
    if (props.operationType === DBCOperationType.Edit) return true;
    return !allStreams.current.find((stream) => stream === output_stream);
  };

  function isNumeric(input: string): boolean {
    // Regular expression to match numeric values
    const numericRegex = /^-?(?!-)\d*\.?\d+$/;
    return numericRegex.test(input);
  }

  const isFieldPresentInStream = (field: string): boolean => {
    const input_table = newDBC.input_table;
    const validDBCStreams = validStreams.current;

    return Object.keys(validDBCStreams).some(
      (stream: string) =>
        stream === input_table && // get the selected stream &&
        // check if "dbc_ver" field is present in the stream
        Object.keys(validDBCStreams[stream]).includes(field)
    );
  };

  const handleAdditionalSettingsValidation = (): {
    isAdditionalSettingsValid: boolean;
    additional_settings: DBCAdditionalSettings;
  } => {
    let additional_settings = newDBC.additional_settings;
    let isAdditionalSettingsValid = true;

    // Check if additional_settings is defined
    if (additional_settings === undefined || additional_settings === null) {
      additional_settings = {};
    }
    const { lower_threshold, upper_threshold, dbc_ver } = additional_settings;

    // ------------------------ Handle thresholds validation ---------------------------
    if (lower_threshold === undefined || lower_threshold === null) {
      delete additional_settings.lower_threshold;
    } else if (!isNumeric(lower_threshold.toString())) {
      isAdditionalSettingsValid = false;
      beamtoast.error("Please enter a valid lower threshold value!");
    } else if (lower_threshold <= 0) {
      isAdditionalSettingsValid = false;
      beamtoast.error("Lower Threshold cannot be negative or zero!");
    }

    if (upper_threshold === undefined || upper_threshold === null) {
      delete additional_settings.upper_threshold;
    } else if (!isNumeric(upper_threshold.toString())) {
      isAdditionalSettingsValid = false;
      beamtoast.error("Please enter a valid upper threshold value!");
    } else if (upper_threshold <= 0) {
      isAdditionalSettingsValid = false;
      beamtoast.error("Upper Threshold cannot be negative or zero!");
    }

    if (
      lower_threshold &&
      upper_threshold &&
      isNumeric(lower_threshold.toString()) &&
      isNumeric(upper_threshold.toString()) &&
      lower_threshold >= upper_threshold
    ) {
      isAdditionalSettingsValid = false;
      beamtoast.error(
        "Lower Threshold cannot be equal or greater than upper threshold!"
      );
    }
    // -----------------------------------------------------------------------------------

    // ------------------------ Handle dbc version validation ----------------------------
    /*
     * validation for dbc_ver:
     ** cannot be < 0
     ** "dbc_ver" field should be present in the selected stream
     */
    if (dbc_ver === undefined || dbc_ver === null) {
      delete additional_settings.dbc_ver;
    } else if (dbc_ver < 0) {
      isAdditionalSettingsValid = false;
      beamtoast.error("Version Filter cannot be negative!");
    } else if (!isFieldPresentInStream("dbc_ver")) {
      isAdditionalSettingsValid = false;
      beamtoast.error(
        "Cannot pass dbc_ver as its column is not present in the selected stream!"
      );
    }

    return { isAdditionalSettingsValid, additional_settings };
  };

  const createJ1939_OBDParser = async (
    dbcType: string,
    additional_settings: DBCAdditionalSettings
  ) => {
    let formData = new FormData();
    formData.append("dbc_type", dbcType);

    if (dbcType === "obd") {
      formData.append("can_id", (newDBC.obd_can_id as number).toString());
    } else if (dbcType === "j1939") {
      formData.append("pgns", JSON.stringify(newDBC.j1939_pgns as number[]));
    }

    if (
      newDBC.name &&
      newDBC.version &&
      newDBC.input_table &&
      newDBC.output_table &&
      newDBC.period &&
      additional_settings
    ) {
      formData.append("name", newDBC.name.trim());
      formData.append("version", newDBC.version.trim());
      formData.append("input_table", newDBC.input_table);
      formData.append("output_table", newDBC.output_table.trim());
      formData.append("period", newDBC.period.toString().trim());
      formData.append(
        "additional_settings",
        JSON.stringify(additional_settings)
      );
    } else {
      beamtoast.error("All fields are required.");
      return;
    }

    if (
      dbcType === "obd" &&
      ((newDBC.obd_can_id as number) < 0 ||
        (newDBC.obd_can_id as number) > 536870911)
    ) {
      beamtoast.error(
        "Invalid CAN ID! Please enter a CAN ID between 0 - 536870911"
      );
      return;
    }

    if (dbcType === "j1939" && newDBC.j1939_pgns?.length === 0) {
      beamtoast.error("Please select at least one PGN");
      return;
    }

    try {
      setIsEditOrCreateDBCGoingOn(true);

      const res = await createDBCParser(formData);
      if (res.status === 201) {
        beamtoast.success(`Created DBC "${newDBC.name}"`);
        Mixpanel.track("Created DBC", {
          DBC: newDBC.version,
        });
        props.setNewDBCOutputStream(
          newDBC.output_table ? newDBC.output_table : ""
        );
        props.onClose();
        props.handleUpdate();
      }
    } catch (error: any) {
      console.log(error);
      beamtoast.error("Something went wrong! Please try again.");
    } finally {
      setIsEditOrCreateDBCGoingOn(false);
    }
  };

  const createParser = async (additional_settings: DBCAdditionalSettings) => {
    if (newDBC.dbc_type === "custom" && uploadDBCRef.current) {
      await uploadDBCRef.current?.handleUpload(additional_settings);
    } else if (newDBC.dbc_type === "obd") {
      await createJ1939_OBDParser("obd", additional_settings);
    } else if (newDBC.dbc_type === "j1939") {
      await createJ1939_OBDParser("j1939", additional_settings);
    }
  };

  const editDBC = useCallback(
    async (additional_settings: DBCAdditionalSettings) => {
      // need to keep this check here itself due to ts strict typechecking
      if (
        newDBC.name &&
        newDBC.version &&
        newDBC.input_table &&
        newDBC.output_table &&
        newDBC.period &&
        additional_settings
      ) {
        if (props.selectedDBC) {
          try {
            setLoading(true);
            setIsEditOrCreateDBCGoingOn(true);
            await updateDBCSettings(props.selectedDBC.id, {
              name: newDBC.name.trim(),
              period: newDBC.period.toString().trim(),
              version: newDBC.version.trim(),
              additional_settings: {
                dbc_ver: additional_settings.dbc_ver ?? null,
                lower_threshold: additional_settings.lower_threshold ?? null,
                upper_threshold: additional_settings.upper_threshold ?? null,
              },
            });

            beamtoast.success(`Edited DBC "${newDBC.name}"`);
            Mixpanel.track("Edited DBC", {
              Firmware: newDBC.version,
            });
            props.setNewDBCOutputStream(
              newDBC.output_table ? newDBC.output_table : ""
            );
            setLoading(false);
            onModalClose();
            props.handleUpdate();
          } catch (error: any) {
            setLoading(false);
            console.log(error);

            Mixpanel.track("Failure", {
              type: "Upload DBC",
            });
            props.setNewDBCOutputStream("");

            // Handling other error types
            const errorMessage = error.response?.data?.error;
            console.log(errorMessage); // print error message received from api error response
          } finally {
            setIsEditOrCreateDBCGoingOn(false);
          }
        }
      } else {
        beamtoast.error("All fields are required.");
      }
    },
    [props.selectedDBC, newDBC] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const stopAndUpdateDBCParser = async () => {
    setIsConfirmModalOpen(false);
    if (props.selectedDBC) {
      try {
        setLoading(true);
        await stopDBCParser(props.selectedDBC?.id);
        beamtoast.success(`Stopped DBC Parser "${props.selectedDBC.name}"`);
        editDBC(newDBC.additional_settings ?? {});
      } catch (e) {
        beamtoast.error(
          `Failed to stop DBC Parser "${props.selectedDBC.name}"`
        );
        setLoading(false);
        console.log(e);
      }
    }
  };

  const editParser = async (additional_settings: DBCAdditionalSettings) => {
    // check if parser is already running and restart
    // only if cycle time or any additional settings have been changed
    const { selectedDBC } = props;
    const isRunning = selectedDBC?.status === "started";
    const isPeriodDifferent = selectedDBC?.period !== newDBC?.period;

    const prevAdditionalSettings = selectedDBC?.additional_settings ?? {};
    const updatedAdditionalSettings = newDBC?.additional_settings ?? {};

    // Check for extra keys in updated data with non-undefined and non-false values
    const hasExtraKeys = Object.keys(updatedAdditionalSettings).some(
      (key) =>
        !prevAdditionalSettings.hasOwnProperty(key) &&
        updatedAdditionalSettings[key] !== null &&
        updatedAdditionalSettings[key] !== undefined &&
        updatedAdditionalSettings[key] !== false
    );

    let areAdditionalSettingsEqual = true;

    if (!hasExtraKeys) {
      // Check if all common keys in both objects have equal values
      areAdditionalSettingsEqual = Object.keys(prevAdditionalSettings).every(
        (key) =>
          updatedAdditionalSettings.hasOwnProperty(key) &&
          prevAdditionalSettings[key] === updatedAdditionalSettings[key]
      );
    } else {
      areAdditionalSettingsEqual = false;
    }

    // check for any overall updates else do not trigger the update-api without any need
    const {
      additional_settings: as1,
      id,
      tenant_id,
      status,
      offsets,
      ...prevRest
    } = props.selectedDBC ?? {};
    const { additional_settings: as2, ...updatedRest } = newDBC;

    if (areAdditionalSettingsEqual && _.isEqual(prevRest, updatedRest)) {
      beamtoast.error("No values have been updated!");
      return;
    }

    if (isRunning && (isPeriodDifferent || !areAdditionalSettingsEqual))
      setIsConfirmModalOpen(true);
    else editDBC(additional_settings);
  };

  const handleSubmit = async () => {
    // output_stream value validation acc to regex
    if (!(newDBC.output_table && isStreamValid(newDBC.output_table))) {
      beamtoast.error(
        "Please enter a valid output stream name! Space and special characters are not allowed except underscore"
      );
      return;
    }

    if (!(newDBC.output_table && isStreamUnique(newDBC.output_table))) {
      beamtoast.error("Output stream name already exists!");
      return;
    }

    // period validation (cannot be <= 0)
    if (newDBC.period <= 0) {
      beamtoast.error("Cycle time cannot be negative or zero!");
      return;
    }

    // additional settings validation
    const { additional_settings, isAdditionalSettingsValid } =
      handleAdditionalSettingsValidation();
    if (!isAdditionalSettingsValid) return;

    try {
      if (props.operationType === DBCOperationType.Create) {
        await createParser(additional_settings);
      } else if (props.operationType === DBCOperationType.Edit) {
        await editParser(additional_settings);
      }
    } catch (error: any) {
      console.log(error);
      beamtoast.error("Something went wrong! Please try again.");
    }
  };

  const onModalClose = () => {
    // Reset the state of the modal
    setDBCType("custom");
    setLoading(false);
    setIsEditOrCreateDBCGoingOn(false);
    resetNewDBCState();
    setInputTableOptions([]);
    setButtonDisabled(true);
    setObdCanId(2024);
    setSelectedJ1939PGNs([]);
    setIsConfirmModalOpen(false);

    // Close the modal
    props.onClose();
  };

  useEffect(() => {
    const fn = async () => {
      let streamNames: string[] = [];
      const requiredFields = [
        "can_id",
        "byte1",
        "byte2",
        "byte3",
        "byte4",
        "byte5",
        "byte6",
        "byte7",
        "byte8",
        "sequence",
        "timestamp",
      ];

      const streams = await fetchAllStreamsWithDetails();

      for (const stream in streams) {
        const fields = streams[stream].fields;
        allStreams.current.push(stream);

        // check all mentioned fields above are present in the stream
        const allRequiredFieldsPresent = requiredFields.every((requiredField) =>
          Object.keys(fields).includes(requiredField)
        );

        if (allRequiredFieldsPresent) {
          /*
           * update valid streams list:
           ** validStreams contains list of streams with their data
           ** streamNames only contains a list of names of the valid streams
           */
          validStreams.current[stream] = streams[stream].fields;
          streamNames.push(stream);
        }
      }

      const tableOptions = dropDownOptionsFromArray(streamNames);
      setInputTableOptions(tableOptions);
    };

    fn();
  }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

  // disable submit button until all compulsory data is filled
  useEffect(() => {
    function areAllKeysNotEmpty(dbc: DBCData): boolean {
      return Object.entries(dbc).every(([key, value]) => {
        if (key === "additional_settings") {
          return true; // Skip checking this key as it is not mandatory
        } else {
          return value || value === null || value === 0;
        }
      });
    }

    if (areAllKeysNotEmpty(newDBC)) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [newDBC, props.open]);

  useEffect(() => {
    const {
      selectedDBC: {
        dbc_type = "custom",
        name = "",
        version = "",
        input_table = "",
        output_table = "",
        period = 1000,
        additional_settings = {},
        obd_can_id = 2024,
        j1939_pgns = [],
      } = {},
    } = props;

    const {
      dont_decode_choices = false,
      add_suffix_canid = false,
      lower_threshold = undefined,
      upper_threshold = undefined,
      dbc_ver = undefined,
    } = additional_settings || {};

    setNewDBC({
      dbc_type,
      name,
      version,
      input_table,
      output_table,
      period,
      obd_can_id,
      j1939_pgns,
      additional_settings: {
        dont_decode_choices,
        add_suffix_canid,
        lower_threshold,
        upper_threshold,
        dbc_ver,
      },
    });
  }, [props.selectedDBC, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <SimpleConfirmationModal
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={stopAndUpdateDBCParser}
        header="Restart Parser"
        content={
          "Changing the cycle time or additional settings would require the parser to be restarted. Are you sure?"
        }
      />
      <Modal
        className="dark"
        onClose={onModalClose}
        onOpen={props.onOpen}
        open={props.open}
      >
        <Modal.Header>
          {props.operationType === DBCOperationType.Create ? "Create" : "Edit"}{" "}
          DBC Parser
        </Modal.Header>
        <Modal.Content>
          {loading ? (
            <LoadingAnimation
              loaderContainerHeight="350px"
              fontSize="1.25rem"
              loadingText="Loading..."
              loaderSize="42px"
              loaderBorderSize="4px"
            />
          ) : (
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <StyledInput labelPosition="left">
                    <StyledLabel>DBC Type</StyledLabel>
                    <Dropdown
                      selection
                      fluid
                      search
                      placeholder="DBC Type"
                      options={dbcTypes.map((type) => ({
                        key: type,
                        value: type,
                        text: type,
                      }))}
                      value={newDBC.dbc_type}
                      disabled={props.operationType === DBCOperationType.Edit}
                      onChange={(_e, d) => {
                        if (d.value) setDBCType(d.value.toString() as DBCTypes);
                      }}
                      style={{
                        border: "none",
                      }}
                    />
                  </StyledInput>
                </Grid.Column>
              </Grid.Row>

              {/* Custom DBC Type  */}
              {newDBC.dbc_type === "custom" &&
                props.operationType === DBCOperationType.Create && (
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <UploadDBCFileSection
                        ref={uploadDBCRef}
                        onClose={onModalClose}
                        DBCData={props.DBCData}
                        newDBC={newDBC}
                        setIsEditOrCreateDBCGoingOn={
                          setIsEditOrCreateDBCGoingOn
                        }
                        setNewDBCOutputStream={(name: string) =>
                          props.setNewDBCOutputStream(name)
                        }
                        operationType={props.operationType}
                        handleUpdate={() => props.handleUpdate()}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}

              {/* OBD DBC Type */}
              {newDBC.dbc_type === "obd" && (
                <Grid.Row>
                  <Grid.Column width={8}>
                    <StyledInput labelPosition="left">
                      <StyledLabel>CAN ID</StyledLabel>
                      <input
                        type="number"
                        placeholder="CAN ID of the message"
                        value={newDBC.obd_can_id as number}
                        onChange={(e) =>
                          setObdCanId(parseInt(e.target.value || "0"))
                        }
                        disabled={props.operationType === DBCOperationType.Edit}
                      />
                    </StyledInput>
                  </Grid.Column>
                </Grid.Row>
              )}

              {/* J1939 DBC Type */}
              {newDBC.dbc_type === "j1939" && (
                <Grid.Row>
                  <Grid.Column width={8}>
                    <StyledInput labelPosition="left">
                      <StyledLabel>Select PGNs</StyledLabel>
                      <Dropdown
                        selection
                        fluid
                        multiple
                        search
                        placeholder="Select PGNs"
                        options={pgnsWithLessThan8bytes.map((pgn) => ({
                          key: pgn,
                          value: pgn,
                          text: pgn,
                        }))}
                        value={newDBC.j1939_pgns as number[]}
                        disabled={props.operationType === DBCOperationType.Edit}
                        onChange={(_e, d) => {
                          let values = d.value as number[];
                          if (values?.length > 20) {
                            beamtoast.error("Cannot select more than 20 PGNs");
                          } else if (values) setSelectedJ1939PGNs(values);
                        }}
                        style={{
                          border: "none",
                        }}
                      />
                    </StyledInput>
                  </Grid.Column>
                </Grid.Row>
              )}

              <ThinDivider />

              <Grid.Row>
                <Grid.Column width={8}>
                  <StyledInput labelPosition="left">
                    <StyledLabel>DBC Name</StyledLabel>
                    <input
                      placeholder="DBC Name"
                      value={newDBC.name}
                      onChange={(e) => setDBCName(e.target.value)}
                    />
                  </StyledInput>
                </Grid.Column>
                <Grid.Column width={8}>
                  <StyledInput labelPosition="left">
                    <StyledLabel>DBC Version</StyledLabel>
                    <input
                      placeholder="DBC Version"
                      value={newDBC.version}
                      onChange={(e) => setDBCVersion(e.target.value)}
                    />
                  </StyledInput>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <StyledInput labelPosition="left">
                    <StyledLabel>
                      Input Stream
                      <Popup
                        content={"This Stream Can No Longer Be Edited"}
                        position="top center"
                        inverted
                        trigger={
                          <Icon
                            style={{
                              marginLeft: "8px",
                              justifyContent: "center",
                            }}
                            name="info circle"
                          />
                        }
                      />
                    </StyledLabel>
                    <Dropdown
                      selection
                      fluid
                      search
                      placeholder="Stream name"
                      options={inputTableOptions}
                      value={newDBC.input_table}
                      disabled={props.operationType === DBCOperationType.Edit}
                      onChange={(_e, d) => {
                        if (d.value) setDBCInputTable(d.value.toString());
                      }}
                      style={{
                        border: "none",
                      }}
                    />
                  </StyledInput>
                </Grid.Column>
                <Grid.Column width={8}>
                  <StyledInput labelPosition="left">
                    <StyledLabel>Output Stream</StyledLabel>
                    <input
                      placeholder="Output Stream"
                      value={newDBC.output_table}
                      disabled={props.operationType === DBCOperationType.Edit}
                      onChange={(e) => setDBCOutputTable(e.target.value)}
                    />
                  </StyledInput>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <StyledInput labelPosition="left">
                    <StyledLabel>Cycle Time (ms)</StyledLabel>
                    <input
                      placeholder="Cycle Time (ms)"
                      type="number"
                      value={newDBC.period}
                      onChange={(e) => setDBCPeriod(Number(e.target.value))}
                    />
                  </StyledInput>
                </Grid.Column>
              </Grid.Row>

              {newDBC.input_table &&
                props.operationType === DBCOperationType.Create && (
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Message color="yellow">
                        {`Warning: Selected input stream(${newDBC.input_table}) for DBC parsing will no
                      longer be editable once the DBC Parser is created.`}
                      </Message>
                    </Grid.Column>
                  </Grid.Row>
                )}

              <ThinDivider />

              <Grid.Row>
                <Accordion inverted>
                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={handleAccordionClick}
                  >
                    <Icon name="dropdown" />
                    <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                      Additional Settings
                    </span>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 0}
                    style={{ paddingLeft: "20px" }}
                  >
                    <Grid style={{ width: "100%" }}>
                      <Grid.Row style={{ marginTop: "1rem" }}>
                        <Grid.Column width={16}>
                          <Checkbox
                            checked={add_suffix_canid}
                            style={{ marginRight: "1rem" }}
                            disabled={disableEnumParsingAndSuffixCanId}
                            onChange={(e, { checked }) => {
                              updateSuffixCanidState(checked ?? false);
                            }}
                          />
                          <span
                            style={{
                              fontSize: "1.1rem",
                              cursor: disableEnumParsingAndSuffixCanId
                                ? "not-allowed"
                                : "text",
                              opacity: disableEnumParsingAndSuffixCanId
                                ? 0.5
                                : 1,
                            }}
                          >
                            Add CAN ID as suffix to column names
                          </span>
                          {newDBC.dbc_type !== "custom" && (
                            <Popup
                              content={`${newDBC.dbc_type === "obd" ? "OBD" : "J1939"} DBC parser is standard and all signals are unique.`}
                              position="top center"
                              inverted
                              trigger={
                                <Icon
                                  style={{
                                    marginLeft: "8px",
                                    justifyContent: "center",
                                  }}
                                  name="info circle"
                                />
                              }
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Checkbox
                            checked={dont_decode_choices}
                            style={{ marginRight: "1rem" }}
                            disabled={disableEnumParsingAndSuffixCanId}
                            onChange={(e, { checked }) => {
                              updateDisableEnumParsingState(checked ?? false);
                            }}
                          />
                          <span
                            style={{
                              fontSize: "1.1rem",
                              cursor: disableEnumParsingAndSuffixCanId
                                ? "not-allowed"
                                : "text",
                              opacity: disableEnumParsingAndSuffixCanId
                                ? 0.5
                                : 1,
                            }}
                          >
                            Disable Enum Parsing
                          </span>
                          {newDBC.dbc_type !== "custom" && (
                            <Popup
                              content={`${newDBC.dbc_type === "obd" ? "OBD" : "J1939"} DBC parser is standard and all signals are unique.`}
                              position="top center"
                              inverted
                              trigger={
                                <Icon
                                  style={{
                                    marginLeft: "8px",
                                    justifyContent: "center",
                                  }}
                                  name="info circle"
                                />
                              }
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={12}>
                          <StyledInput labelPosition="left">
                            <StyledLabel>
                              Lower Threshold for flushing clusters (ms)
                            </StyledLabel>
                            <input
                              placeholder="value in ms"
                              type="number"
                              value={
                                lower_threshold === 0 ||
                                lower_threshold === null
                                  ? undefined
                                  : lower_threshold
                              }
                              onChange={(e) =>
                                setDBCLowerThreshold(
                                  e.target.value
                                    ? Number(e.target.value)
                                    : undefined
                                )
                              }
                            />
                          </StyledInput>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={12}>
                          <StyledInput labelPosition="left">
                            <StyledLabel>
                              Upper Threshold for flushing clusters (ms)
                            </StyledLabel>
                            <input
                              placeholder="value in ms"
                              type="number"
                              value={
                                upper_threshold === 0 ||
                                upper_threshold === null
                                  ? undefined
                                  : upper_threshold
                              }
                              onChange={(e) =>
                                setDBCUpperThreshold(
                                  e.target.value
                                    ? Number(e.target.value)
                                    : undefined
                                )
                              }
                            />
                          </StyledInput>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={12}>
                          <StyledInput labelPosition="left">
                            <StyledLabel>
                              DBC Version filter in CAN table on column dbc_ver
                            </StyledLabel>
                            <input
                              placeholder="value"
                              type="number"
                              value={
                                dbc_ver === 0 || dbc_ver === null
                                  ? undefined
                                  : dbc_ver
                              }
                              onChange={(e) =>
                                setDBCVersionFilter(
                                  e.target.value
                                    ? Number(e.target.value)
                                    : undefined
                                )
                              }
                            />
                          </StyledInput>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Accordion.Content>
                </Accordion>
              </Grid.Row>
            </Grid>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              onModalClose();
            }}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            primary
            disabled={buttonDisabled || isEditOrCreateDBCGoingOn}
            loading={isEditOrCreateDBCGoingOn}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

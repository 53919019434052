import React, { useEffect, useState, FC } from "react";
import { Icon, Menu, MenuItem, MenuItemProps } from "semantic-ui-react";
import {
  Route,
  Switch,
  Link,
  useRouteMatch,
  useLocation,
  Redirect,
} from "react-router-dom";
import FirmwareVersions from "./FirmwareVersions/FirmwareVersions";
import DeviceConfigurations from "./DeviceConfigurations/DeviceConfigurations";
import { DisplayIf } from "../../util";
import styled from "styled-components";
import { CardContainer } from "../../../common/ActionsUtils";

export const StyledNavLinks = styled(MenuItem)<MenuItemProps>`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 16px;
    margin-right: 18px;
    background-color: ${(props) =>
      props.active
        ? props.theme.colors["inventory-navbar-background-active-color"]
        : props.theme.colors["inventory-navbar-background-color"]};
    font-weight: bold;
    color: ${(props) =>
      props.active
        ? props.theme.colors["inventory-navbar-text-active-color"]
        : props.theme.colors["inventory-navbar-text-color"]};
    border: 1px solid
      ${(props) =>
        props.active
          ? props.theme.colors["inventory-navbar-border-active-color"]
          : props.theme.colors["inventory-navbar-border-color"]};
    border-radius: 24px;
    white-space: nowrap;
    &:hover {
      opacity: 0.7;
    }
  }
`;

const InventoryManagement: FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const resolver: Record<string, string> = {
    [`${path}/firmware-files`]: "Firmware Files",
    [`${path}/json-configurations`]: "JSON Configurations",
    [`${path}/geofence-configurations`]: "Geofence Configurations",
  };

  const [activeItem, setActiveItem] = useState<string>(
    resolver[location.pathname] || "Firmware Files"
  );

  const handleItemClick = (name: string) => {
    setActiveItem(name);
  };

  const menuTab = (
    name: string,
    to: string,
    shouldDisplay: boolean,
    icon: string
  ) => {
    return (
      <DisplayIf cond={shouldDisplay}>
        <StyledNavLinks
          as={Link}
          to={to}
          name={name}
          active={activeItem === name}
          onClick={() => handleItemClick(name)}
        >
          <Icon
            className={icon}
            style={{
              float: "none",
              fontSize: "18px",
            }}
          />
          {name}
        </StyledNavLinks>
      </DisplayIf>
    );
  };

  useEffect(() => {
    document.title = "Inventory | Bytebeam";

    switch (location.pathname) {
      case `${path}/firmware-files`:
        setActiveItem("Firmware Files");
        break;

      case `${path}/json-configurations`:
        setActiveItem("JSON Configurations");
        break;

      case `${path}/geofence-configurations`:
        setActiveItem("Geofence Configurations");
        break;

      default:
        break;
    }
  }, [location.pathname, activeItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CardContainer>
      <h1
        style={{
          fontSize: "32px",
          fontWeight: 700,
          textAlign: "left",
          marginBottom: "32px",
        }}
      >
        Inventory
      </h1>
      <Menu
        pointing
        secondary
        style={{ marginTop: 20, border: "none" }}
        className="desktop-view"
      >
        {menuTab(
          "Firmware Files",
          `${path}/firmware-files`,
          true,
          "file alternate outline"
        )}
        {menuTab(
          "JSON Configurations",
          `${path}/json-configurations`,
          true,
          "code"
        )}
        {menuTab(
          "Geofence Configurations",
          `${path}/geofence-configurations`,
          true,
          "street view"
        )}
      </Menu>

      <Switch>
        <Route exact path={`${path}/firmware-files`}>
          <DisplayIf cond={true}>
            <FirmwareVersions />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/json-configurations`}>
          <DisplayIf cond={true}>
            <DeviceConfigurations type="json" />
          </DisplayIf>
        </Route>

        <Route exact path={`${path}/geofence-configurations`}>
          <DisplayIf cond={true}>
            <DeviceConfigurations type="geofence" />
          </DisplayIf>
        </Route>

        <Redirect from={path} to={`${path}/firmware-files`} />
      </Switch>
    </CardContainer>
  );
};

export default InventoryManagement;

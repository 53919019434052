import React, { useEffect, useState } from "react";
import {
  NotificationRule,
  SmsNotificationChannel,
} from "../CreateNotificationRule";
import { StyledInput, StyledLabel } from "../CreateAlertRuleModal";
import { ErrorMessage } from "../../roles/CreateOrEditRoleModal";
import { validatePhoneNumber } from "../../../../../util";

type CreateSmsNotificationChannelProps = {
  readonly channel: SmsNotificationChannel;
  readonly setChannel: (channel: SmsNotificationChannel) => void;
  readonly setIsValid: (isValid: boolean) => void;
  readonly notificationRule: NotificationRule;
};

export default function CreateSmsNotificationChannel(
  props: CreateSmsNotificationChannelProps
) {
  const { setIsValid, channel, setChannel, notificationRule } = props;
  const [validationError, setValidationError] = useState<string | null>(null);
  const [blurred, setBlurred] = useState(false);

  useEffect(() => {
    const channelPhoneNumbers = channel.phoneNumbers.filter(
      (e) => e.length > 0
    );
    const isValid =
      channelPhoneNumbers.length > 0 &&
      notificationRule.alertRuleId !== "" &&
      !validationError;

    setIsValid(isValid);
  }, [channel, setIsValid, notificationRule.alertRuleId, validationError]);

  const setPhoneNumbers = (phoneString: string) => {
    const phoneNumbers = phoneString.split(",").map((e) => e.trim());
    const arePhoneNumbersValid = phoneNumbers.every(
      (e) => e === "" || validatePhoneNumber(e)
    );

    if (phoneString.length > 0 && !arePhoneNumbersValid) {
      // Set local validation error state if blurred and invalid
      setValidationError("Invalid phone number format");
    } else {
      // Reset the error if phone numbers are valid or not blurred
      setBlurred(false);
      setValidationError(null);
    }

    setChannel({
      ...channel,
      phoneNumbers,
    });
  };

  const handleBlur = () => {
    setBlurred(true);
  };

  const handleFocus = () => {
    if (!validationError) {
      setBlurred(false);
    }
  };

  return (
    <div style={{ marginBottom: "14px" }}>
      <StyledInput style={{ marginBottom: "0px" }} labelPosition="left">
        <StyledLabel>Phone Numbers</StyledLabel>
        <input
          value={channel.phoneNumbers.join(",")}
          type="tel"
          multiple
          onChange={(e) => setPhoneNumbers(e.target.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder="+91xxxxx, Comma-separated list of phone numbers"
        />
      </StyledInput>
      {validationError && blurred && (
        <ErrorMessage
          id="validation_message"
          style={{ fontWeight: 700, top: "0px" }}
        >
          {validationError}
        </ErrorMessage>
      )}
    </div>
  );
}

import React, { useState } from "react";
import { Button, Input, Segment, Label, Dropdown } from "semantic-ui-react";

import { Auth } from "../../../../util";
import { getEndUserAuth, putEndUserAuth } from "../../../../BytebeamClient";
import useAsyncEffect from "../../common/useAsyncEffect";
import LoadingAnimation from "../../../common/Loader";

type EditStatus = "loading" | "ready" | "editing" | "saving";
// type AuthCallback = (auth: Auth) => void;

export default function EndUserAuth() {
  const [auth, setAuth] = useState<Auth>({
    url: "",
    type: "webhook",
  });
  // loading -> fetch and setAuth
  // ready
  // editing
  // saving -> fetch
  // back to ready
  const [editStatus, setEditStatus] = useState<EditStatus>("loading");

  useAsyncEffect(async () => {
    // Error cases are handled internally, where a beamtoastr
    // message will be shown!
    // TODO handle error!
    const newAuth = await getEndUserAuth();
    setAuth(newAuth);
    setEditStatus("ready");
  }, []);

  const onSave = async () => {
    setEditStatus("saving");
    try {
      await putEndUserAuth(auth);
      setEditStatus("ready");
    } catch (e) {
      console.log(e);
      setEditStatus("loading");
    }
  };

  if (editStatus === "loading") {
    return (
      <LoadingAnimation
        loaderContainerHeight="65vh"
        fontSize="1.5rem"
        loadingText="Loading auth"
      />
    );
  }
  if (editStatus === "saving") {
    return <LoadingAnimation loadingText={`Saving auth...`} fontSize="20px" />;
  }

  const typeOptions = [
    {
      key: "jwks",
      value: "jwks",
      text: "jwks",
    },
    {
      key: "webhook",
      value: "webhook",
      text: "webhook",
    },
  ];

  const validAuth =
    auth &&
    (auth?.type === "jwks" || auth?.type === "webhook") &&
    (auth?.url?.startsWith("https://") || auth?.url?.startsWith("http://"));

  return (
    <Segment>
      <Input labelPosition="left" fluid>
        <Label>Type</Label>
        <Dropdown
          disabled={editStatus !== "editing"}
          options={typeOptions}
          style={{
            paddingLeft: "10px",
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingRight: "10px",
          }}
          value={auth.type}
          onChange={(e, d) => {
            setAuth({ ...auth, type: d.value as string });
          }}
        ></Dropdown>
      </Input>
      <br />
      <Input labelPosition="left" fluid>
        <Label>Url</Label>
        <input
          type="url"
          value={auth.url}
          disabled={editStatus !== "editing"}
          onChange={(e) => {
            setAuth({ ...auth, url: e.target.value });
          }}
        ></input>
      </Input>
      <br />
      <Button
        onClick={() => {
          setEditStatus("editing");
        }}
        disabled={editStatus === "editing"}
      >
        Edit
      </Button>
      <Button
        primary
        disabled={!(validAuth && editStatus === "editing")}
        onClick={onSave}
      >
        Save
      </Button>
    </Segment>
  );
}

import { IframeData, IframeMetaData } from "./PanelDef";
import React from "react";
import { PanelViewComponent } from "../PanelDef";
import { isURLValid } from "../../../../../util";

export class ViewIframe extends PanelViewComponent<IframeMetaData, IframeData> {
  getURL() {
    return this.props.panelMeta.url;
  }

  render() {
    const url = this.getURL();
    if (url.length !== 0 && isURLValid(url)) {
      return (
        <iframe
          title="iframe_panel"
          allowFullScreen
          allow="encrypted-media; picture-in-picture"
          style={{ width: "100%", height: "100%" }}
          src={url}
        />
      );
    } else {
      return <div className="panel-no-data">No Data</div>;
    }
  }
}

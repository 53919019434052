import React, { useContext, useEffect, useState } from "react";
import { Menu, Dropdown, Icon, Label } from "semantic-ui-react";
import Toggle from "../Toggle";
import ByteBeamLogo from "../ByteBeamLogo";
import { Mixpanel } from "../../Screens/common/MixPanel";
import styled, { keyframes, ThemeContext } from "styled-components";
import {
  currentReleaseId,
  currentReleaseVersion,
  UserSettings,
  User,
  showGetStartedForHost,
  urlToDocId,
} from "../../../util";
import { getPathFromURL, updateUserSettings } from "../../../BytebeamClient";
import { DisplayIf, capitalizeFirstLetter } from "../../Screens/util";
import { Link, useHistory } from "react-router-dom";
import UserSummaryWithProjectPicker from "./UserSummaryWithProjectPicker";

const breatheAnimation = keyframes`
  0%, 67% { 
  opacity: 0;
  }
  34%, 100% {
    opacity: 1;
  }
`;

const NotificationHighlight = styled.span<{ isvisible: boolean }>`
  position: absolute;
  display: ${(props) => (props.isvisible ? "none" : "inline")};
  bottom: 0px;
  right: 0px;
  width: 0px;
  height: 0px;
  border-left: 7px solid transparent;
  border-top: 7px solid transparent;
  border-right: 7px solid #609d5a;
  border-bottom: 7px solid #609d5a;
  animation-name: ${breatheAnimation};
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
`;

type DesktopNavBarProps = {
  user: User;
  getCurrentUser: () => Promise<void>;
  activeItem: string;
  setActiveItem: (item: string) => void;
  currentTenant: string;
  setCurrentTenant: (tenant: string) => void;
  isMultiTenant: boolean;
  showSettingsTab: () => boolean;
  setShowNewProjectModal: (show: boolean) => void;
  handleThemeToggle: () => Promise<void>;
};

function DesktopNavBar(props: DesktopNavBarProps) {
  const {
    user,
    getCurrentUser,
    activeItem,
    setActiveItem,
    currentTenant,
    isMultiTenant,
    setCurrentTenant,
    showSettingsTab,
    setShowNewProjectModal,
    handleThemeToggle,
  } = props;

  const permissions = user?.role?.permissions || {};
  const userSettings = user?.settings as UserSettings;
  const userSettingsGetStartedSkip = user?.settings?.get_started?.skip ?? false;

  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    setIsDarkTheme(themeContext?.name?.toLowerCase() === "dark");
  }, [themeContext]);

  useEffect(() => {
    // Listen for location changes
    const unlisten = history.listen((location, action) => {
      if (location.pathname.includes("get-started"))
        setActiveItem("get-started");
      else if (location.pathname.includes("device-management"))
        setActiveItem("device-management");
      else if (location.pathname.includes("dashboards"))
        setActiveItem("dashboards");
      else if (location.pathname.includes("actions")) setActiveItem("actions");
      else if (location.pathname.includes("alerts")) setActiveItem("alerts");
    });

    // Cleanup the listener on component unmount
    return () => {
      unlisten();
    };
  }, [history, setActiveItem]);

  function openArchBeeWidget(type: string) {
    let docToRedirect = "https://docs.bytebeam.io/docs/";
    if (type === "release") {
      docToRedirect = docToRedirect + currentReleaseId;
      window.open(docToRedirect, "_blank");
    } else {
      let path = getPathFromURL();

      let subPath = window.location.pathname.split("/").pop();
      if (path === "alerts") {
        if (subPath === "alert-notification") {
          path = "alert-notification";
        } else {
          path = "alert-system";
        }
      } else if (path === "actions") {
        if (subPath === "new-action") {
          path = "new-action";
        } else {
          path = "actions";
        }
      }
      let link = urlToDocId[path] ?? "";
      docToRedirect = docToRedirect + link;
      window.open(docToRedirect, "_blank");
    }
  }

  // Function for handling click on Release Note Dropdown option
  async function handleReleaseNoteClick() {
    const userSettings = user?.settings ?? ({} as UserSettings);
    openArchBeeWidget("release");
    if (currentReleaseId !== userSettings?.current_release_id) {
      try {
        const res = await updateUserSettings({
          settings: {
            ...userSettings,
            current_release_id: currentReleaseId,
          },
        });

        if (res !== undefined && res !== null) {
          await getCurrentUser();
        }
      } catch (error) {
        console.log("Error while updating user settings", error);
      }
    }
  }

  // Navbar Item
  function menuTab(
    name: string,
    title: string,
    to: string,
    shouldDisplay: boolean,
    isTabVisible?: boolean
  ) {
    const isVisible = isTabVisible !== false;
    return (
      <DisplayIf
        cond={
          shouldDisplay && isVisible && window.location.pathname !== "/welcome"
        }
      >
        <Menu.Item
          as={Link}
          to={to}
          name={name}
          active={activeItem === name}
          onClick={() => setActiveItem(name)}
        >
          <h4 style={{ fontSize: "14px", fontWeight: 600 }}>{title}</h4>
        </Menu.Item>
      </DisplayIf>
    );
  }

  return (
    <Menu fixed="top" className="main-navbar">
      {/* Navbar Left Elements */}
      <Menu.Item
        style={{
          padding: "12px 24px",
        }}
        className="logo"
        as={Link}
        to={
          window.location.pathname === "/welcome"
            ? window.location.pathname
            : permissions.showDeviceManagementTab
              ? `/projects/${currentTenant}/device-management/devices`
              : `/projects/${currentTenant}/dashboards`
        }
        onClick={() => {
          if (window.location.pathname !== "/welcome")
            permissions.showDeviceManagementTab
              ? setActiveItem("device-management")
              : setActiveItem("dashboards");
        }}
      >
        <ByteBeamLogo height="30px" user={user} currentTenant={currentTenant} />
      </Menu.Item>

      {/* Navbar Left Elements */}
      {menuTab(
        "get-started",
        "Get Started",
        `/projects/${currentTenant}/get-started`,
        !userSettingsGetStartedSkip && showGetStartedForHost
      )}
      {menuTab(
        "device-management",
        "Device Management",
        `/projects/${currentTenant}/device-management/devices`,
        permissions.showDeviceManagementTab,
        user?.["tenant-settings"]?.show_tabs?.device_management?.show
      )}
      {menuTab(
        "dashboards",
        "Dashboards",
        `/projects/${currentTenant}/dashboards`,
        permissions.showDashboardsTab,
        user?.["tenant-settings"]?.show_tabs?.dashboards?.show
      )}

      {menuTab(
        "actions",
        "Actions",
        `/projects/${currentTenant}/actions`,
        permissions.showActionsTab,
        user?.["tenant-settings"]?.show_tabs?.actions?.show
      )}

      {menuTab(
        "alerts",
        "Alerts",
        `/projects/${currentTenant}/alerts`,
        permissions.viewAlerts || permissions.viewNotifications,
        user?.["tenant-settings"]?.show_tabs?.alerts?.show
      )}

      {/* Navbar Right Elements */}
      {user && (
        <Menu.Menu position="right">
          {window.location.pathname !== "/welcome" && (
            <Menu.Item name="help" onClick={() => openArchBeeWidget("help")}>
              <Icon name="help circle" size="small" className="help-icon" />
            </Menu.Item>
          )}

          <Menu.Item
            style={{
              padding: "0px",
              position: "relative",
            }}
            name="user"
            active={activeItem === "user"}
          >
            <NotificationHighlight
              isvisible={userSettings?.current_release_id === currentReleaseId}
            />
            <Dropdown
              className="link item customDropDown"
              style={{
                height: "100%",
                minWidth: "160px",
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
              }}
              labeled
              button
              scrolling
              text={capitalizeFirstLetter(user?.name)}
            >
              <Dropdown.Menu style={{ maxHeight: "24rem" }}>
                <UserSummaryWithProjectPicker
                  currentTenant={currentTenant}
                  setCurrentTenant={setCurrentTenant}
                  isMultiTenant={isMultiTenant}
                  setShowNewProjectModal={setShowNewProjectModal}
                />
                {/* Below Dropdown.Item contains code contains Release Notes */}
                <Dropdown.Item
                  style={{ position: "relative" }}
                  onClick={() => {
                    setActiveItem("user");
                    handleReleaseNoteClick();
                  }}
                >
                  <NotificationHighlight
                    isvisible={
                      userSettings?.current_release_id === currentReleaseId
                    }
                  />
                  <Label color="blue">{currentReleaseVersion}</Label>
                  What's New !
                </Dropdown.Item>

                {(userSettingsGetStartedSkip || !showGetStartedForHost) && (
                  <Dropdown.Item
                    icon="book"
                    text="Get Started"
                    onClick={() => {
                      history.push(`/projects/${currentTenant}/get-started`);
                      setActiveItem("user");
                    }}
                  />
                )}

                {showSettingsTab() && (
                  <Dropdown.Item
                    icon="setting"
                    text="Settings"
                    onClick={() => {
                      history.push(`/projects/${currentTenant}/settings`);
                      setActiveItem("user");
                    }}
                  />
                )}
                {/* Below Dropdown.Item contains code contains theme toggle */}
                <Dropdown.Item style={{ position: "relative" }}>
                  <Icon name="adjust" /> Dark Mode{" "}
                  <Toggle
                    id="theme-toggle"
                    size="medium"
                    style={{
                      marginLeft: "12px",
                    }}
                    checked={isDarkTheme}
                    onClick={() => handleThemeToggle()}
                  />
                </Dropdown.Item>

                {/* Below Dropdown.Item contains code contains Logout  */}
                <Dropdown.Item
                  icon="power off"
                  text="Logout"
                  onClick={() => {
                    Mixpanel.track("Logout");
                    window.location.href = "/logout";
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Menu.Menu>
      )}
    </Menu>
  );
}

export default DesktopNavBar;

import React, { useEffect, useState } from "react";
import CarouselImg01 from "../common/images/carousel-img01.png";
import CarouselImg02 from "../common/images/carousel-img02.png";
import CarouselImg03 from "../common/images/carousel-img03.png";
import CarouselImg04 from "../common/images/carousel-img04.png";
import CarouselItem from "./CarouselItem";
import styled from "styled-components";
import { breakpoints } from "../../common/breakpoints";

export type CarouselItemObject = {
  index: number;
  image: string;
  caption: string;
};

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 624px;
`;

const CarouselInnerContainer = styled.div`
  white-space: nowrap;
  transition: transform 0.5s;
  width: 500px;
`;

const CarouselIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;

  @media (max-width: ${breakpoints.md}px) {
    margin-top: 30px;
    margin-bottom: 40px;
  }
`;

const CarouselIndicator = styled.div<{
  activeindex: number;
  currentindex: number;
}>`
  height: 11px;
  width: 11px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.currentindex === props.activeindex
      ? "#407BFF"
      : props.theme.colors["carousel-indicator"]};
`;

const items: CarouselItemObject[] = [
  {
    index: 1,
    image: CarouselImg01,
    caption: "Easier Over the Air update with Bytebeam",
  },
  {
    index: 2,
    image: CarouselImg02,
    caption: "Reliable Remote Device Monitoring",
  },
  {
    index: 3,
    image: CarouselImg03,
    caption: "Faster Troubleshooting",
  },
  {
    index: 4,
    image: CarouselImg04,
    caption: "Accurate Actionable Insights",
  },
];

export default function WelcomeCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const myInterval = setInterval(() => {
      setActiveIndex((index) => (index === items.length - 1 ? 0 : index + 1));
    }, 3000);

    return () => clearInterval(myInterval);
  }, []);

  return (
    <CarouselContainer>
      <CarouselInnerContainer
        style={{ transform: `translate(-${activeIndex * 100}%)` }}
      >
        {items.map((item) => (
          <CarouselItem key={item.index} item={item} />
        ))}
      </CarouselInnerContainer>
      <CarouselIndicatorContainer>
        {items.map((item, index) => (
          <CarouselIndicator
            key={item.index}
            activeindex={activeIndex}
            currentindex={index}
            style={{
              marginRight: `${index === items.length - 1 ? "0px" : "20px"}`,
            }}
            onClick={() => {
              updateIndex(index);
            }}
          />
        ))}
      </CarouselIndicatorContainer>
    </CarouselContainer>
  );
}

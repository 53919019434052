import React, { useEffect } from "react";
import styled from "styled-components";
import { Mixpanel } from "../common/MixPanel";
import LoginForm from "./LogInForm";
import { ErrorMessage } from "../../common/ErrorMessage";
import { Settings } from "../../../util";

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

type WelcomeScreenProps = {
  settings: Settings;
  setCurrentTenant: (tenant: string) => void;
};

const WelcomeScreen = (props: WelcomeScreenProps) => {
  const { settings, setCurrentTenant } = props;

  const tenancy = settings?.tenancy ?? "multi";

  const tryDemo = () => {
    Mixpanel.track("Trying Demo");
    window.location.href = `projects/demo/`;
  };

  useEffect(() => {
    document.title = "Welcome | Bytebeam";
  }, []);

  return (
    <div className="dark">
      {tenancy === "multi" ? (
        <LoginForm
          type="welcome"
          setCurrentTenant={(tenant) => setCurrentTenant(tenant)}
          tryDemo={() => tryDemo()}
        />
      ) : (
        <CenteredWrapper>
          <ErrorMessage messageLess errorMessage marginBottom="0px" />
          <h2>You do not have access</h2>
          <h3 style={{ marginTop: "10px" }}>
            Please ask your administrator to add you to the system
          </h3>
        </CenteredWrapper>
      )}
    </div>
  );
};

export default WelcomeScreen;

import React from "react";
import StyledSVGIcon from "../../components/common/StyledSVGIcon";

const svgContent = `<svg
width="20"
height="18"
viewBox="0 0 20 18"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M4 18V16L5 15H2C1.45 15 0.979167 14.8042 0.5875 14.4125C0.195833 14.0208 0 13.55 0 13V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10V2H2V13H18V10H20V13C20 13.55 19.8042 14.0208 19.4125 14.4125C19.0208 14.8042 18.55 15 18 15H15L16 16V18H4ZM13 12L8 7L9.4 5.6L12 8.175V0H14V8.175L16.6 5.6L18 7L13 12Z"
  fill="white"
/>
</svg>`;

type BrowserUpdatedIconProps = {
  height: string;
  // Allowing all other props
  [x: string]: any;
};

function BrowserUpdatedIcon(props: Readonly<BrowserUpdatedIconProps>) {
  const { height, ...rest } = props;

  return <StyledSVGIcon height={height} {...rest} svgContent={svgContent} />;
}

export default BrowserUpdatedIcon;

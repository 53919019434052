import { HeatmapMetaData } from "./PanelDef";

import { PartialMetaData } from "../PanelDef";
import { EditPieChartMeta } from "../PieChart/EditPieChartMeta";

export class EditHeatmapMeta extends EditPieChartMeta {
  getPanelMeta(): PartialMetaData<HeatmapMetaData> {
    let { meta, complete } = super.getPanelMeta();

    return {
      meta: { ...meta, type: "heatmap" },
      complete: complete,
    };
  }
}

import { GaugeChartData, GaugeChartMetaData } from "./PanelDef";
import React, { Fragment } from "react";
import { PanelViewComponent } from "../PanelDef";
import { ReplayState } from "../../DashboardHeader";
import moment from "moment";
import GaugeChart from "react-gauge-chart";

// Shows single Gauge Panel
class GaugeChartComponent extends PanelViewComponent<
  GaugeChartMetaData,
  GaugeChartData
> {
  ref = React.createRef<HTMLDivElement>();
  state = {
    fontSize: 10,
    gaugeSize: 50,
  };

  canvas = document.createElement("canvas");

  getGaugeWidth(text, font) {
    const context = this.canvas.getContext("2d");
    if (context) {
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    }

    return 0;
  }

  calculateFontSize(fontFamily, maxWidth, maxHeight) {
    const text = this.getText(this.getDataPoint());
    let numIterations = 0;
    let start = 10;
    let end = Math.max(10, Math.min(200, maxHeight));

    while (true) {
      const fontSize = (start + end) / 2;
      const font = `${fontSize}px ${fontFamily}`;

      numIterations += 1;

      if (numIterations > 20) {
        return fontSize;
      }

      const textWidth = this.getGaugeWidth(text, font);
      if (Math.abs(textWidth - maxWidth) < 2 || Math.abs(end - start) < 2) {
        return fontSize;
      }

      if (textWidth < maxWidth) {
        start = fontSize;
      } else {
        end = fontSize;
      }
    }
  }

  getDataPoint() {
    const data = this.props.data.data;

    if (
      this.props.replayState === ReplayState.ReplayRunning ||
      this.props.replayState === ReplayState.ReplayPaused
    ) {
      if (this.props.replayStep < data.length) {
        return data[this.props.replayStep];
      } else {
        return data[data.length - 1];
      }
    } else if (data.length > 0) {
      return data[0];
    }

    return {
      timestamp: 0,
    };
  }

  getText(data) {
    let formatValue = (value: any) => {
      if (typeof value == "number") {
        return (Math.round(value * 100) / 100).toString();
      }
      return value;
    };

    const value = (data || {})[this.props.panelMeta.columns[0].name];
    let text = "-";
    if (value !== undefined && value !== null) {
      text = formatValue(value);
    }

    return `${text}`;
  }

  componentDidMount() {
    if (this.ref.current) {
      const rect = this.ref.current.getBoundingClientRect();
      const fontFamily = window.getComputedStyle(this.ref.current).fontFamily;

      const fontSize = this.calculateFontSize(
        fontFamily,
        rect.width * 0.1,
        rect.height * 0.1
      );
      const gaugeSize = this.calculateFontSize(
        fontFamily,
        rect.width * 0.9,
        rect.height * 0.5
      );

      this.setState({
        fontSize: 0.8 * fontSize,
        gaugeSize: gaugeSize,
      });
    }
  }

  render() {
    const columns = this.props.panelMeta.columns;
    const data = this.getDataPoint();

    if (data?.value !== undefined && data?.value !== null) {
      return (
        <div className="big-number-root" ref={this.ref}>
          <div className="big-number-value">
            {
              // Will create multiple Gauge if multiple columns
              columns.map(({ name }) => {
                let value = data["value"] ? data["value"] : 0;
                let gaugeId = "gauge_" + name;
                if (typeof value === "number") {
                  let maxThreshold = this.props.panelMeta.maxThreshold
                    ? this.props.panelMeta.maxThreshold
                    : value;
                  let minThreshold = this.props.panelMeta.minThreshold
                    ? this.props.panelMeta.minThreshold
                    : 0;
                  let percentValue =
                    ((value - minThreshold) / (maxThreshold - minThreshold)) *
                    100;
                  let unit = this.props.panelMeta.valUnit || "";
                  return (
                    <Fragment key={gaugeId}>
                      <div>
                        <GaugeChart
                          id={gaugeId}
                          animate={false}
                          nrOfLevels={
                            this.props.panelMeta.divisionCount
                              ? this.props.panelMeta.divisionCount
                              : 5
                          }
                          arcPadding={0.05}
                          cornerRadius={5}
                          percent={percentValue / 100}
                          hideText={true}
                          needleColor="#DEDEDE"
                          needleBaseColor="#DFDFDF"
                          colors={[
                            this.props.panelMeta.startColor,
                            this.props.panelMeta.endColor,
                          ]}
                        />
                      </div>
                      <div>
                        {this.props.panelMeta.showPercentage
                          ? percentValue.toFixed(2) + " %"
                          : value.toFixed(2) + " " + unit}
                      </div>
                    </Fragment>
                  );
                } else {
                  return (
                    <div key={gaugeId}>
                      <p>The value is not a number.</p>
                    </div>
                  );
                }
              })
            }
          </div>
          {Boolean(data.timestamp) && (
            <div className="big-number-timestamp">
              At: {moment(data.timestamp).format("DD-MM-YY HH:mm:ss")}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="bytebeam-panel-content panel-no-data">No Data</div>
      );
    }
  }
}

export class ViewGaugeChart extends PanelViewComponent<
  GaugeChartMetaData,
  GaugeChartData
> {
  render() {
    return <GaugeChartComponent {...this.props} />;
  }
}

import * as React from "react";
import { SVGProps } from "react";

const LogsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clip-path="url(#clip0_269_66)">
      <path
        d="M4.75 68.875V7.12503C4.75 5.81284 5.81281 4.75003 7.125 4.75003H42.75V14.25C42.75 16.8729 44.8771 19 47.5 19H57V26.125H61.75V16.625C61.753 15.9942 61.5066 15.3871 61.0612 14.9388L46.8112 0.688776C46.363 0.244948 45.7559 -0.00294239 45.125 2.63612e-05H7.125C3.18992 2.63612e-05 0 3.18995 0 7.12503V68.875C0 72.8101 3.18992 76 7.125 76H23.75V71.25H7.125C5.81281 71.25 4.75 70.1872 4.75 68.875Z"
        fill="currentColor"
      />
      <path
        d="M71.25 47.5H66.5V52.25H71.25V57H61.75V42.75H76C76 40.1271 73.8729 38 71.25 38H61.75C59.1271 38 57 40.1271 57 42.75V57C57 59.6229 59.1271 61.75 61.75 61.75H71.25C73.8729 61.75 76 59.6229 76 57V52.25C76 49.6271 73.8729 47.5 71.25 47.5Z"
        fill="currentColor"
      />
      <path
        d="M45.125 38C39.8777 38 35.625 42.2527 35.625 47.5V52.25C35.625 57.4973 39.8777 61.75 45.125 61.75C50.3723 61.75 54.625 57.4973 54.625 52.25V47.5C54.625 42.2527 50.3723 38 45.125 38ZM49.875 52.25C49.875 54.8729 47.7479 57 45.125 57C42.5021 57 40.375 54.8729 40.375 52.25V47.5C40.375 44.8771 42.5021 42.75 45.125 42.75C47.7479 42.75 49.875 44.8771 49.875 47.5V52.25Z"
        fill="currentColor"
      />
      <path
        d="M23.75 38H19V59.375C19 60.6872 20.0628 61.75 21.375 61.75H33.25V57H23.75V38Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_269_66">
        <rect width="76" height="76" fill="transparent" />
      </clipPath>
    </defs>
  </svg>
);

export default LogsIcon;

import React from "react";
import { createGlobalStyle } from "styled-components";
import { Popup } from "semantic-ui-react";

// Global style to apply the z-index override for the popup parent to render it below the modals and dropdowns
const GlobalStyle = createGlobalStyle`
  .popup-with-low-z-index {
    z-index: 10 !important;
  }
`;

interface CustomPopupProps {
  trigger: React.ReactNode;
  content: string;
  open?: boolean;
}

const PopupWithLowZIndex: React.FC<CustomPopupProps> = ({
  trigger,
  content,
  open,
}) => {
  return (
    <>
      <GlobalStyle />
      <Popup
        inverted
        position="top center"
        className="popup-with-low-z-index"
        trigger={trigger}
        content={content}
        on="hover"
        open={open}
      />
    </>
  );
};

export default PopupWithLowZIndex;

import React, { useState, useEffect } from "react";
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Input,
  Label,
  Modal,
  Popup,
} from "semantic-ui-react";
import styled from "styled-components";
import {
  fetchAllStreamsWithDetails,
  FetchStreamsAPIResponse,
  StreamFieldDetails,
} from "../../../../BytebeamClient";
import {
  AlertGroups,
  AlertRule,
  AlertRuleCriticality,
  CompositeCondition,
  Condition,
  dropDownOptionsFromArray,
  SimpleCondition,
  specialCharacterValidation,
} from "../../../../util";
import SqlWhereClauseBuilder from "../../common/SqlWhereClauseBuilder";
import { beamtoast } from "../../../common/CustomToast";
import { AlertRuleOperationType } from "./AlertRules";
import { ErrorMessage } from "../roles/CreateOrEditRoleModal";

type AlertRuleType = "signal_based_alert" | "no_signal_alert";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledLabel = styled(Label)`
  margin: 0px !important;
  min-width: 80px;
`;

export const StyledInput = styled(Input)`
  margin-bottom: 14px;
  height: 36px;
  width: 100%;
`;

const StyledAlertGroupRow = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-bottom: 14px;
`;

type alertErrorsType = {
  error: boolean;
  message: string;
  field: "alert_group" | "alert_name";
};

type ChooseAlertTypeProps = {
  readonly onSubmit: (alertType: AlertRuleType) => void;
};

const isAlertRuleNameValid = (
  alertRule: AlertRule,
  setAlertErrors: (error: alertErrorsType) => void
) => {
  if (alertRule.name.length > 64) {
    setAlertErrors({
      error: true,
      message: "Alert Name should not exceed 64 characters.",
      field: "alert_name",
    });
    return false;
  }
  return true;
};

const isAlertGroupNameValid = (
  alertGroupName: string,
  setAlertErrors: (error: alertErrorsType) => void
) => {
  if (alertGroupName.length > 0) {
    if (alertGroupName.length > 64) {
      setAlertErrors({
        error: true,
        message: "Alert Group Name should not exceed 64 characters.",
        field: "alert_group",
      });
      return false;
    } else if (!specialCharacterValidation(alertGroupName)) {
      setAlertErrors({
        error: true,
        message:
          "Alert Group Name should begin with an alphanumeric character and only contain alphanumeric, space, and underscores.",
        field: "alert_group",
      });
      return false;
    }
  }

  setAlertErrors({
    error: false,
    message: "",
    field: "alert_group",
  });
  return true;
};

function ChooseAlertType(props: ChooseAlertTypeProps) {
  const { onSubmit } = props;
  return (
    <Row style={{ width: "100%", justifyContent: "space-between" }}>
      <Popup
        wide
        trigger={
          <Button primary onClick={() => onSubmit("no_signal_alert")}>
            No Signal Alert
          </Button>
        }
        content={
          "No Signal Based Alerts, also known as heartbeat or keep-alive alerts, are triggered by the absence of expected signals or data within a certain time frame."
        }
        inverted
        position="top center"
      />

      <Popup
        wide
        trigger={
          <Button primary onClick={() => onSubmit("signal_based_alert")}>
            Signal Based Alert
          </Button>
        }
        content={
          "Signal Based Alerts are triggered when a device sends a specific signal or data that meets predefined criteria."
        }
        inverted
        position="top center"
      />
    </Row>
  );
}

function AlertGroupInput({
  alertGroupsOptions,
  alertRule,
  setAlertGroup,
  alertErrors,
}: {
  alertGroupsOptions: { key: string; text: string; value: string }[];
  alertRule: AlertRule;
  setAlertGroup: (group: string) => void;
  alertErrors: alertErrorsType;
}) {
  const [isAlertGroupNameInputShown, setIsAlertGroupNameInputShown] =
    useState(false);
  const [addAlertGroupInputValue, setAddAlertGroupInputValue] = useState("");
  const [alertGroupDropdownValue, setAlertGroupDropdownValue] = useState(
    alertRule.group
  );

  return (
    <>
      <StyledAlertGroupRow>
        <StyledInput labelPosition="left" style={{ marginBottom: "0px" }}>
          <StyledLabel>Alert Group</StyledLabel>
          <Dropdown
            fluid
            selection
            search
            disabled={isAlertGroupNameInputShown}
            placeholder="Select Alert Group Name"
            options={alertGroupsOptions}
            value={
              isAlertGroupNameInputShown ? "" : alertRule.group || "default"
            }
            onChange={(_e, d) => {
              setAlertGroup(d.value as string);
            }}
            style={{
              border: "none",
            }}
          />
        </StyledInput>
        {!isAlertGroupNameInputShown && (
          <>
            <b>OR</b>
            <Button
              title="Add New Alert Group"
              id="addAlertGroupButton"
              icon
              secondary
              labelPosition="left"
              onClick={() => {
                setAlertGroup("");
                setAlertGroupDropdownValue(alertRule.group);
                setIsAlertGroupNameInputShown(true);
              }}
              style={{ border: "1px solid #a7a9b7" }}
            >
              <Icon name="plus" />
              <span style={{ whiteSpace: "nowrap" }}>Alert Group</span>
            </Button>
          </>
        )}
      </StyledAlertGroupRow>
      {isAlertGroupNameInputShown && (
        <>
          <StyledAlertGroupRow>
            <StyledInput labelPosition="left" style={{ marginBottom: "0px" }}>
              <StyledLabel>Add Alert Group</StyledLabel>
              <input
                placeholder="Enter new alert group name..."
                type="text"
                autoFocus
                defaultValue={""}
                value={addAlertGroupInputValue}
                onChange={(e) => {
                  setAlertGroup(e.target.value);
                  setAddAlertGroupInputValue(e.target.value);
                }}
              />
            </StyledInput>
            <Button
              title="Cancel, Select from existing alert groups"
              id="removeAlertGroupButton"
              icon="minus"
              secondary
              onClick={() => {
                setAlertGroup(alertGroupDropdownValue);
                setIsAlertGroupNameInputShown(false);
              }}
            />
          </StyledAlertGroupRow>
          {alertErrors.error && alertErrors.field === "alert_group" ? (
            <ErrorMessage id={"Alert Group Name error span"}>
              {alertErrors.message}
            </ErrorMessage>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}

type CreateSignalBasedAlertRuleProps = {
  readonly alertRule: AlertRule;
  readonly setAlertRule: (alertRule: AlertRule) => void;
  readonly setIsValid: (isValid: boolean) => void;
  readonly streams: FetchStreamsAPIResponse;
  readonly alertGroupsOptions: { key: string; text: string; value: string }[];
  readonly operationType: string;
};

function CreateSignalBasedAlertRule(props: CreateSignalBasedAlertRuleProps) {
  const { streams, setIsValid, alertRule, setAlertRule, alertGroupsOptions } =
    props;

  let fieldsInit = {};

  if (alertRule.stream && streams[alertRule.stream]) {
    fieldsInit = streams[alertRule.stream].fields;
  }

  const [fields, setFields] = useState<{ [key: string]: StreamFieldDetails }>(
    fieldsInit
  );
  const [alertErrors, setAlertErrors] = useState<alertErrorsType>({
    error: false,
    message: "",
    field: "alert_group",
  });

  const setInputStream = (inputStream: string) => {
    let updatedAlertRule = {
      ...alertRule,
      stream: inputStream,
      condition: {
        conditions: [{ field: "", operator: "=", value: "" }],
        operator: "and",
      } as CompositeCondition,
    };
    setAlertRule(updatedAlertRule);

    const fields = streams[inputStream].fields;
    setFields(fields);
  };

  const setAlertName = (alertName: string) => {
    setAlertRule({
      ...alertRule,
      name: alertName,
    });
  };

  const setAlertCondition = (condition: Condition) => {
    setAlertRule({
      ...alertRule,
      condition: condition,
    });
  };

  const setAlertGroup = (group: string) => {
    setAlertRule({
      ...alertRule,
      group: group.trim(),
    });
  };

  const setActivationThreshold = (threshold: string) => {
    setAlertRule({
      ...alertRule,
      activation_threshold_seconds: parseFloat(threshold),
    });
  };

  const setDeactivationThreshold = (threshold: string) => {
    setAlertRule({
      ...alertRule,
      deactivation_threshold_seconds: parseFloat(threshold),
    });
  };

  const streamOptions = dropDownOptionsFromArray(Object.keys(streams));

  useEffect(() => {
    const isValidCondition = (condition: Condition) => {
      const compositeOperators = ["and", "or"];

      if (compositeOperators.includes(condition.operator)) {
        const conditions = (condition as CompositeCondition).conditions;
        return conditions.every(isValidCondition);
      } else {
        const simpleCondition = condition as SimpleCondition;
        return (
          simpleCondition.field.length > 0 &&
          simpleCondition.value !== null &&
          simpleCondition.value !== undefined &&
          simpleCondition.value !== ""
        );
      }
    };

    const isValidSignalBasedAlert = (alertRule: AlertRule) => {
      return (
        alertRule.name.length > 0 &&
        alertRule.activation_threshold_seconds > 0 && 
        alertRule.deactivation_threshold_seconds >= 0 &&
        alertRule.stream.length > 0 &&
        alertRule.group.length > 0 &&
        isValidCondition(alertRule.condition)
      );
    };

    setIsValid(
      isAlertRuleNameValid(alertRule, setAlertErrors) &&
        isAlertGroupNameValid(alertRule.group, setAlertErrors) &&
        isValidSignalBasedAlert(alertRule)
    );
  }, [alertRule, setIsValid]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <StyledInput labelPosition="left">
            <StyledLabel>Alert Name</StyledLabel>
            <input
              placeholder="Alert Name"
              value={alertRule.name}
              onChange={(e) => setAlertName(e.target.value)}
            />
          </StyledInput>
          {alertErrors.error && alertErrors.field === "alert_name" ? (
            <ErrorMessage id={"Alert Name error span"}>
              {alertErrors.message}
            </ErrorMessage>
          ) : (
            ""
          )}
        </Grid.Column>
        <Grid.Column width={8}>
          <StyledInput labelPosition="left">
            <StyledLabel>Stream</StyledLabel>
            <Dropdown
              fluid
              selection
              search
              disabled={props.operationType === AlertRuleOperationType.Update}
              placeholder="Stream"
              options={streamOptions}
              value={alertRule.stream}
              onChange={(_e, d) => {
                setInputStream(d.value as string);
              }}
              style={{
                border: "none",
              }}
            />
          </StyledInput>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column width={16}>
          <SqlWhereClauseBuilder
            fields={fields}
            condition={alertRule.condition}
            onChange={setAlertCondition}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ paddingBottom: 0 }}>
        <Grid.Column width={16}>
          <AlertGroupInput
            alertGroupsOptions={alertGroupsOptions}
            alertRule={alertRule}
            setAlertGroup={setAlertGroup}
            alertErrors={alertErrors}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ paddingTop: 0 }}>
        <Grid.Column width={16}>
          <StyledInput labelPosition="left">
            <StyledLabel>Activation Threshold (seconds)</StyledLabel>
            <input
              placeholder="Activation Threshold (seconds)"
              type="number"
              value={alertRule.activation_threshold_seconds}
              onChange={(e) => setActivationThreshold(e.target.value)}
            />
          </StyledInput>
        </Grid.Column>

        <Grid.Column width={16}>
          <StyledInput labelPosition="left">
            <StyledLabel>Deactivation Threshold (seconds)</StyledLabel>
            <input
              placeholder="Deactivation Threshold (seconds)"
              type="number"
              value={alertRule.deactivation_threshold_seconds}
              onChange={(e) => setDeactivationThreshold(e.target.value)}
            />
          </StyledInput>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

type CreateNoSignalAlertRuleProps = {
  readonly alertRule: AlertRule;
  readonly setAlertRule: (alertRule: AlertRule) => void;
  readonly setIsValid: (isValid: boolean) => void;
  readonly streams: FetchStreamsAPIResponse;
  readonly alertGroupsOptions: { key: string; text: string; value: string }[];
  readonly operationType: string;
};

function CreateNoSignalAlertRule(props: CreateNoSignalAlertRuleProps) {
  const { streams, setIsValid, alertRule, setAlertRule, alertGroupsOptions } =
    props;
  const [alertErrors, setAlertErrors] = useState<alertErrorsType>({
    error: false,
    message: "",
    field: "alert_group",
  });

  useEffect(() => {
    const isValidNoSignalAlert = (alertRule: AlertRule) => {
      return (
        alertRule.name.length > 0 &&
        alertRule.activation_threshold_seconds > 0 &&
        alertRule.stream.length > 0 &&
        alertRule.group.length > 0
      );
    };

    setIsValid(
      isAlertRuleNameValid(alertRule, setAlertErrors) &&
        isAlertGroupNameValid(alertRule.group, setAlertErrors) &&
        isValidNoSignalAlert(alertRule)
    );
  }, [alertRule, setIsValid]);

  const setInputStream = (inputStream: string) => {
    setAlertRule({
      ...alertRule,
      stream: inputStream,
    });
  };

  const setAlertName = (alertName: string) => {
    setAlertRule({
      ...alertRule,
      name: alertName,
    });
  };

  const setAlertThreshold = (threshold: string) => {
    setAlertRule({
      ...alertRule,
      activation_threshold_seconds: parseFloat(threshold) * 60,
    });
  };

  const setAlertGroup = (group: string) => {
    setAlertRule({
      ...alertRule,
      group: group.trim(),
    });
  };

  const streamOptions = dropDownOptionsFromArray(Object.keys(streams));
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <StyledInput labelPosition="left">
            <StyledLabel>Alert Name</StyledLabel>
            <input
              placeholder="Alert Name"
              value={alertRule.name}
              onChange={(e) => setAlertName(e.target.value)}
            />
          </StyledInput>
          {alertErrors.error && alertErrors.field === "alert_name" ? (
            <ErrorMessage id={"Alert Name error span"}>
              {alertErrors.message}
            </ErrorMessage>
          ) : (
            ""
          )}
        </Grid.Column>
        <Grid.Column width={8}>
          <StyledInput labelPosition="left">
            <StyledLabel>Stream</StyledLabel>
            <Dropdown
              fluid
              selection
              search
              disabled={props.operationType === AlertRuleOperationType.Update}
              placeholder="Stream"
              options={streamOptions}
              value={alertRule.stream}
              onChange={(_e, d) => {
                setInputStream(d.value as string);
              }}
              style={{
                border: "none",
              }}
            />
          </StyledInput>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column width={16}>
          <AlertGroupInput
            alertGroupsOptions={alertGroupsOptions}
            alertRule={alertRule}
            setAlertGroup={setAlertGroup}
            alertErrors={alertErrors}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ paddingTop: 0 }}>
        <Grid.Column width={16}>
          <StyledInput labelPosition="left">
            <StyledLabel>Alert Threshold (minutes)</StyledLabel>
            <input
              placeholder="Alert Threshold (minutes)"
              type="number"
              value={parseFloat(
                (alertRule.activation_threshold_seconds / 60).toFixed(3)
              )}
              onChange={(e) => setAlertThreshold(e.target.value)}
            />
          </StyledInput>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

interface CreateAlertRuleModalProps {
  readonly open: boolean;
  readonly onOpen: () => void;
  readonly onClose: () => void;
  readonly alertRule?: AlertRule;
  readonly alertRules: AlertRule[];
  readonly alertGroups: AlertGroups;
  readonly title: string;
  readonly onSubmit: (alertRule: AlertRule) => void;
  readonly alertModalStep: number;
  readonly operationType: string;
}

export default function CreateAlertRuleModal(props: CreateAlertRuleModalProps) {
  const {
    open,
    onOpen,
    onClose,
    alertRules,
    title,
    onSubmit,
    operationType,
    alertGroups,
  } = props;

  const [streams, setStreams] = useState<FetchStreamsAPIResponse>({});
  const [alertRule, setAlertRule] = useState<AlertRule | undefined>(
    props.alertRule
  );
  const [allAlertNames, setAllAlertNames] = useState<Set<string>>(new Set());
  const [buttonDisabled, setButtonDisabled] = useState(true);

  // Create a new array including "default" if it's not already in alertGroups
  const extendedAlertGroups = alertGroups.includes("default")
    ? alertGroups
    : [...alertGroups, "default"];

  // Generate dropdown options from the new array
  const alertGroupsOptions = dropDownOptionsFromArray(extendedAlertGroups);

  useEffect(() => {
    const fn = async () => {
      const streams = await fetchAllStreamsWithDetails();
      setStreams(streams);
    };

    fn();
  }, []);

  const setIsValid = (isValid: boolean) => {
    setButtonDisabled(!isValid);
  };

  const setAlertType = (alertType: AlertRuleType) => {
    if (alertType === "no_signal_alert") {
      const alertRule: AlertRule = {
        id: "",
        name: "",
        stream: "",
        criticality: "critical",
        group: "default",
        metadata_filters: {},
        activation_threshold_seconds: 10 * 60,
        deactivation_threshold_seconds: 0,
        condition: {
          operator: "no_data",
        },
      };

      setAlertRule(alertRule);
    } else if (alertType === "signal_based_alert") {
      const alertRule: AlertRule = {
        id: "",
        name: "",
        stream: "",
        condition: {
          operator: "and",
          conditions: [{ operator: "=", field: "", value: "" }],
        },
        group: "default",
        activation_threshold_seconds: 30,
        deactivation_threshold_seconds: 0,
        criticality: "critical",
        metadata_filters: {},
      };

      setAlertRule(alertRule);
    }
  };

  const isNoDataAlert = (alertRule: AlertRule) => {
    return alertRule.condition.operator === "no_data";
  };

  const renderContent = () => {
    if (!alertRule || Object.keys(alertRule).length === 0) {
      return <ChooseAlertType onSubmit={setAlertType} />;
    } else if (isNoDataAlert(alertRule)) {
      return (
        <CreateNoSignalAlertRule
          alertRule={alertRule}
          setAlertRule={setAlertRule}
          setIsValid={setIsValid}
          streams={streams}
          alertGroupsOptions={alertGroupsOptions}
          operationType={operationType}
        />
      );
    } else {
      return (
        <CreateSignalBasedAlertRule
          alertRule={alertRule}
          setAlertRule={setAlertRule}
          setIsValid={setIsValid}
          streams={streams}
          alertGroupsOptions={alertGroupsOptions}
          operationType={operationType}
        />
      );
    }
  };

  const alertCriticalityOptions = dropDownOptionsFromArray([
    "critical",
    "warning",
    "info",
  ]);

  const setAlertCriticality = (criticality: AlertRuleCriticality) => {
    if (alertRule && Object.keys(alertRule).length > 0) {
      setAlertRule({
        ...alertRule,
        criticality: criticality,
      });
    }
  };

  const renderAlertCriticality = () => {
    return (
      <StyledInput labelPosition="left">
        <StyledLabel>Alert Criticality</StyledLabel>

        <Dropdown
          selection
          fluid
          search
          placeholder="Alert Criticality"
          options={alertCriticalityOptions}
          value={alertRule?.criticality}
          onChange={(_e, d) => {
            setAlertCriticality(d.value as AlertRuleCriticality);
          }}
          style={{
            border: "none",
          }}
        />
      </StyledInput>
    );
  };

  const getModalSize = () => {
    if (alertRule && Object.keys(alertRule).length > 0) {
      // if (isNoDataAlert(alertRule)) {
      //   return "tiny";
      // } else {
      return "large";
      // }
    } else {
      return "mini";
    }
  };

  useEffect(() => {
    setAlertRule(props.alertRule);
  }, [props.alertRule]);

  useEffect(() => {
    const currentAlertName = (props.alertRule?.name ?? "").toLowerCase();

    const alertNamesSet = new Set(
      alertRules.flatMap((alertRule) =>
        alertRule.name.toLowerCase() !== currentAlertName
          ? [alertRule.name.toLowerCase()]
          : []
      )
    );

    setAllAlertNames(alertNamesSet);
  }, [alertRules, props.alertRule]);

  return (
    <Modal
      className="dark"
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      size={getModalSize()}
    >
      <Modal.Header>
        {alertRule && Object.keys(alertRule).length > 0
          ? title
          : "Choose Alert Type"}
      </Modal.Header>
      <Modal.Content>
        {renderContent()}

        {alertRule && Object.keys(alertRule).length > 0 ? (
          renderAlertCriticality()
        ) : (
          <></>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            setAlertRule(undefined);
            onClose();
          }}
        >
          Cancel
        </Button>

        {alertRule && Object.keys(alertRule).length > 0 ? (
          <Button
            type="submit"
            primary
            disabled={buttonDisabled}
            onClick={() => {
              if (allAlertNames.has(alertRule.name.toLowerCase())) {
                beamtoast.error("Alert name already exists");
              } else {
                onClose();
                onSubmit(alertRule);
              }
            }}
          >
            Submit
          </Button>
        ) : (
          <></>
        )}
      </Modal.Actions>
    </Modal>
  );
}

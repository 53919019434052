import React, { useState } from "react";
import { Button, Modal, Icon, Form } from "semantic-ui-react";
import { fetchDevicesCount, uploadFile } from "../../../../../BytebeamClient";
import styled from "styled-components";
import { StyledFileInput, StyledFileUploadButton } from "./SendFileModal";
import { ThinDivider } from "../../../Dashboards/Panel/util";
import { getAllDevicesMetadata } from "./DownloadMetadataModal";
import { saveAs } from "file-saver";
import { getFileExtension } from "../../../util";
import AnimatedEllipsis from "../../../common/AnimatedEllipsis";
import { ErrorMessage } from "../../../../common/ErrorMessage";
import LoadingAnimation from "../../../../common/Loader";
import { beamtoast } from "../../../../common/CustomToast";
import { useUser } from "../../../../../context/User.context";

export const TemplateDownloaderButton = styled.span`
  font-weight: 700;
  color: #1158e1;
  cursor: pointer;
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 64px;
  p {
    font-size: 15px;
  }
`;

type RenderUpdatingMetadataFailedProps = {
  readonly close: (...args: any[]) => any;
};

function RenderUpdatingMetadataFailed(
  props: RenderUpdatingMetadataFailedProps
) {
  const { close } = props;
  return (
    <>
      <Modal.Content>
        <Modal.Description>
          <MessageContainer>
            <ErrorMessage
              errorMessage
              marginTop="15px"
              marginBottom="10px"
              iconSize="big"
              messageLess
            />
            <p style={{ marginTop: "12px" }}>
              Updating device metadata failed. Please try again. If the issue
              persists, please contact{" "}
              <a href="mailto:support@bytebeam.io"> support@bytebeam.io </a>{" "}
              from your registered email id.
            </p>
          </MessageContainer>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => close()}>
          <Icon name="angle left" /> Back
        </Button>
        <Button primary disabled={true}>
          <Icon name="checkmark" /> Update Metadata
        </Button>
      </Modal.Actions>
    </>
  );
}

type RenderMetadataSuccessfullyUpdatedProps = {
  readonly close: (...args: any[]) => any;
};

function RenderMetadataSuccessfullyUpdated(
  props: RenderMetadataSuccessfullyUpdatedProps
) {
  const { close } = props;

  return (
    <>
      <Modal.Content>
        <Modal.Description>
          <MessageContainer>
            <Icon name="check circle outline" size="huge" />
            <p style={{ marginTop: "12px" }}>
              Congratulations! Your device metadata has been successfully
              updated for the required devices.
            </p>
          </MessageContainer>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => close()}>
          <Icon name="angle left" /> Back
        </Button>
        <Button primary disabled={true}>
          <Icon name="checkmark" /> Update Metadata
        </Button>
      </Modal.Actions>
    </>
  );
}

enum UpdateMetadataStatus {
  TriggerMetadataUpdate,
  UpdatingMetadataFailed,
  MetadataSuccessfullyUpdated,
}

type RenderTriggerMetadataUpdateProps = {
  readonly close: (...args: any[]) => any;
  readonly setStatus: (...args: any[]) => any;
  readonly setLoading: (...args: any[]) => void;
};

function RenderTriggerMetadataUpdate(props: RenderTriggerMetadataUpdateProps) {
  const { close, setStatus, setLoading } = props;

  const { user } = useUser();
  const serial_metadataKey = user?.["tenant-settings"]?.["serial-key"];
  const permissions = user?.role?.permissions;

  const [file, setFile] = useState<File>(new File([""], ""));
  const [fileName, setFileName] = useState<string>("");
  const [csvFileArray, setCSVFileArray] = useState<string[]>([]);
  const [showUploadProgress, setShowUploadProgress] = useState<boolean>(false);
  const [fileTotal, setFileTotal] = useState<number>(0);
  const [fileLoaded, setFileLoaded] = useState<number>(0);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [downloadingTemplate, setDownloadingTemplate] =
    useState<boolean>(false);

  async function downloadTemplate() {
    setDownloadingTemplate(true);

    try {
      // Have to fetch total devices count again, because the props will have totalDevices count of the filtered selection
      let totalDevices = await fetchDevicesCount("active");
      let csvData: BlobPart = await getAllDevicesMetadata(
        totalDevices,
        permissions,
        true
      );
      csvData = csvData.replace("Device Id", "id");
      const file = new File([csvData], "update metadata template.csv", {
        type: "text/csv",
      });
      saveAs(file);
    } catch (error) {
      console.log("Error While Downloading Metadata Template: ", error);
    } finally {
      setDownloadingTemplate(false);
    }
  }

  const processCSV = (str, delimiter = ",") => {
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    const newArray = rows.map((row) => {
      const values = row.split(delimiter);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });
    while (newArray[newArray.length - 1].id === "") {
      newArray.pop();
    }
    setCSVFileArray(newArray);
  };

  function onSelect(e) {
    const csvFile = e.target.files[0];
    setFile(csvFile);
    setFileName(csvFile.name);

    const reader = new FileReader();
    reader.onload = function (param) {
      const text = param.target?.result;
      processCSV(text);
    };
    reader.readAsText(csvFile);
  }

  async function fileUpload(file: File) {
    setButtonDisabled(true);
    const formData = new FormData();
    formData.append("file", file);

    const url = `/api/v1/devices/metadata-bulk`;
    try {
      const res = await uploadFile(url, formData, (p) => {
        setFileTotal(p.total);
        setFileLoaded(p.loaded);
      });

      if (res.status === 200) {
        setStatus(UpdateMetadataStatus.MetadataSuccessfullyUpdated);
        setLoading();
      } else {
        setStatus(UpdateMetadataStatus.UpdatingMetadataFailed);
      }
    } catch (error: any) {
      // Error handling for different scenarios
      if (error?.response?.data?.error) {
        const errorMessage: string = error.response.data.error.message;

        // Handling the error message related to the serial-key metadata
        if (errorMessage.toLowerCase().includes("must be unique")) {
          beamtoast.error(
            `'${serial_metadataKey}' metadata values must be unique`
          );
        } else if (
          errorMessage
            .toLowerCase()
            .includes("serial key value cannot be empty")
        ) {
          beamtoast.error(
            `'${serial_metadataKey}' metadata values cannot be null or empty`
          );
        } else {
          // Handling other error messages
          beamtoast.error(errorMessage);
        }
      }
      setStatus(UpdateMetadataStatus.UpdatingMetadataFailed);
      console.log("Error While Updating Metadata - ", error.response);
    } finally {
      setButtonDisabled(false);
      setShowUploadProgress(false);
    }
  }

  function validateFile(file: File): { state: boolean; message: string } {
    const errorObj = { state: false, message: "" };
    const extension = getFileExtension(file.name);
    if (extension !== "csv") {
      errorObj.state = true;
      errorObj.message = "Invalid file type";
    }
    if (csvFileArray.length > 10000) {
      errorObj.state = true;
      errorObj.message = "Update limit (10000 rows data) exceeded";
    }
    return errorObj;
  }

  async function updateMetadata() {
    let validationResult = validateFile(file);
    if (!validationResult.state) {
      setShowUploadProgress(true);
      await fileUpload(file);
    } else {
      beamtoast.error(validationResult.message);
    }
  }

  return (
    <>
      <Modal.Content>
        <Modal.Description>
          {downloadingTemplate ? (
            <div
              style={{
                height: "64px",
              }}
            >
              <LoadingAnimation
                loadingText="Downloading Metadata Bulk Update Template..."
                loaderBorderSize="3px"
                loaderSize="30px"
                fontSize="12px"
              />
            </div>
          ) : (
            <div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                  paddingBottom: "20px",
                  height: "64px",
                }}
              >
                Download template file to fill all devices metadata which needs
                to be updated.
              </div>
              <TemplateDownloaderButton
                id="downloadTemplateButton"
                onClick={async () => {
                  await downloadTemplate();
                }}
              >
                Click here
              </TemplateDownloaderButton>{" "}
              to download the template file.
            </div>
          )}

          <ThinDivider
            style={{
              marginTop: "15px",
            }}
          />

          <Form>
            <Form.Field>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Upload File from the system:{" "}
              </label>
              <div style={{ position: "relative", marginTop: "12px" }}>
                <StyledFileUploadButton
                  fluid
                  content="Select File"
                  labelPosition="left"
                  icon="file"
                />
                <StyledFileInput
                  type="file"
                  id="file"
                  accept=".csv"
                  onChange={onSelect}
                />
              </div>
              <label
                htmlFor="warning"
                style={{ marginLeft: "10px", marginTop: "8px", color: "grey" }}
              >
                *File upload size is limited to 1GiB and 10000 rows of data.
              </label>
              <label htmlFor="file_name" style={{ marginTop: "12px" }}>
                File Chosen:{" "}
              </label>
              <div id="file_name">
                {fileName !== "" ? fileName : "No File Chosen"}
              </div>
            </Form.Field>

            {showUploadProgress && (
              <Form.Field>
                <label htmlFor="file-progress">
                  Updating Metadata in Bulk
                  <AnimatedEllipsis spacing={3} dotSize={"8px"} />
                </label>
                <progress
                  id="file-progress"
                  max={fileTotal}
                  value={fileLoaded}
                />
              </Form.Field>
            )}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          secondary
          onClick={() => {
            close();
          }}
        >
          <Icon name="angle left" /> Back
        </Button>
        <Button
          primary
          disabled={buttonDisabled || file?.size === 0}
          onClick={async () => {
            await updateMetadata();
          }}
        >
          <Icon name="checkmark" /> Update Metadata
        </Button>
      </Modal.Actions>
    </>
  );
}

type BulkMetadataUpdateModalProps = {
  readonly close: (...args: any[]) => any;
  readonly setLoading: (...args: any[]) => void;
};

function BulkMetadataUpdateModal(props: BulkMetadataUpdateModalProps) {
  const { close, setLoading } = props;

  const [status, setStatus] = useState<number>(
    UpdateMetadataStatus.TriggerMetadataUpdate
  );

  switch (status) {
    case UpdateMetadataStatus.TriggerMetadataUpdate:
      return (
        <RenderTriggerMetadataUpdate
          close={close}
          setStatus={setStatus}
          setLoading={setLoading as (...args: any[]) => void}
        />
      );

    case UpdateMetadataStatus.UpdatingMetadataFailed:
      return <RenderUpdatingMetadataFailed close={close} />;

    case UpdateMetadataStatus.MetadataSuccessfullyUpdated:
      return <RenderMetadataSuccessfullyUpdated close={close} />;

    default:
      return null;
  }
}

export default BulkMetadataUpdateModal;

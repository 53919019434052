import React, { useEffect, useState, useMemo } from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import styled from "styled-components";
import {
  fetchAllMetadataKeys,
  markMetadataSerialKey,
  unmarkMetadataSerialKey,
} from "../../../../BytebeamClient";
import { beamtoast } from "../../../common/CustomToast";
import {
  IconContainer,
  SettingRowContainer,
  SettingRowEditableSection,
  SettingRowLabel,
  TenantLevelSettingsProps,
} from "./TenantLevelSettings";

type MetadataItem = { key: string; value: string; text: string };

const StyledDropdown = styled(Dropdown)`
  &&& {
    min-width: 200px;
    max-width: 400px;
  }
`;

const SerialMetadata = ({ user, getCurrentUser }: TenantLevelSettingsProps) => {
  const userSerialKey = user?.["tenant-settings"]?.["serial-key"];

  const [metaDataItems, setMetaDataItems] = useState<MetadataItem[]>([]);
  const [dropdownLoading, setDropdownLoading] = useState<boolean>(false);
  const [dropdownDisabled, setDropdownDisabled] = useState(false);
  const [newSerialKey, setNewSerialKey] = useState(userSerialKey);
  const [editMode, setEditMode] = useState(false);

  const fetchAllMetadataKeysfunct = async () => {
    try {
      setDropdownLoading(true);
      const res = await fetchAllMetadataKeys();
      const dropdownItems = res.map((item: any) => ({
        key: item.key,
        value: item.key,
        text: item.key,
      }));
      setMetaDataItems(dropdownItems);
    } catch (error) {
      console.error("Failed to fetch metadata keys:", error);
    } finally {
      setDropdownLoading(false);
    }
  };

  const updateSerialKey = async (metadataKey: string) => {
    try {
      setDropdownDisabled(true);
      if (userSerialKey) {
        await unmarkMetadataSerialKey(userSerialKey);
        if (metadataKey === "")
          beamtoast.success(`Removed '${userSerialKey}' as Serial Key`);
      }
      if (metadataKey) {
        await markMetadataSerialKey(metadataKey);
        beamtoast.success(`Updated Serial Key: '${metadataKey}'`);
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error;

      // Handling different error messages
      if (errorMessage?.includes("null values not allowed")) {
        beamtoast.error("Metadata contains null values.");
      } else if (errorMessage?.includes("values should be unique")) {
        beamtoast.error("Metadata values should be unique.");
      } else {
        beamtoast.error("Failed to update serial key.");
      }
    } finally {
      await getCurrentUser();
      setDropdownDisabled(false);
      setEditMode(false); // Exiting edit mode after successful update
    }
  };

  useEffect(() => {
    fetchAllMetadataKeysfunct();
  }, []);

  const handleSave = () => {
    updateSerialKey(newSerialKey || "");
  };

  const handleCancel = () => {
    setNewSerialKey(userSerialKey);
    setEditMode(false);
  };

  const memoizedOptions = useMemo(() => {
    return metaDataItems.map((item) => ({
      key: item.key,
      value: item.value,
      text: item.text,
    }));
  }, [metaDataItems]);

  return (
    <SettingRowContainer>
      <SettingRowLabel>Serial Key:</SettingRowLabel>
      {!editMode ? (
        <SettingRowEditableSection>
          <span style={{ minWidth: "200px" }}>
            {userSerialKey || "No Serial Key Selected"}
          </span>
          <Icon
            link
            name="edit"
            onClick={() => {
              setEditMode(true);
              fetchAllMetadataKeysfunct();
            }}
            style={{ cursor: "pointer", marginLeft: "10px" }}
          />
        </SettingRowEditableSection>
      ) : (
        <SettingRowEditableSection>
          <StyledDropdown
            placeholder="Select Metadata"
            fluid
            search
            selection
            clearable
            loading={dropdownLoading}
            value={newSerialKey}
            disabled={dropdownDisabled}
            options={memoizedOptions}
            onChange={(e, { value }) => setNewSerialKey(value as string)}
          />
          <IconContainer>
            <Icon
              link
              name="save"
              disabled={newSerialKey === userSerialKey}
              onClick={handleSave}
            />
            <Icon link name="cancel" onClick={handleCancel} />
          </IconContainer>
        </SettingRowEditableSection>
      )}
    </SettingRowContainer>
  );
};

export default SerialMetadata;

import React from "react";
import {
  toast as hotToast,
  Toaster as HotToaster,
  ToastOptions,
} from "react-hot-toast";
import { Icon, SemanticICONS } from "semantic-ui-react";
import ThemeSchema from "../../theme/schema";

// Define the default duration for toasts
export const DEFAULT_DURATION = 4000;

// Initialize global variables to keep track of visible toasts
export let toastLimit = 1;
export let visibleToasts: any[] = [];

// Define the interface for the extended toast functions
interface CustomToastFunctions {
  // Function to create a basic toast
  (message: any, options?: ToastOptions): string;
  // Functions for creating toasts with specific types
  success: (message: any, options?: ToastOptions) => string;
  error: (message: any, options?: ToastOptions) => string;
  loading: (message: any, options?: ToastOptions) => string;
  custom: (message: any, options?: ToastOptions) => string;
  info: (message: any, options?: ToastOptions) => string;
  warning: (message: any, options?: ToastOptions) => string;
  // Functions to dismiss and remove toasts
  dismiss(toastId?: string): void;
  remove(toastId?: string): void;
  // Function to create a promise toast
  promise<T>(
    promise: Promise<T>,
    msgs: { loading: any; success: any; error: any },
    opts?: any
  ): Promise<T>;
  // Function to set the global toast limit
  setLimit(newLimit: number): void;
}

// Function to create and display a toast with optional options
const showToast = (message: any, options: ToastOptions = {}): string => {
  // If the number of visible toasts is at the limit, dismiss the oldest toast
  if (visibleToasts.length >= toastLimit) {
    const oldestToast = visibleToasts.pop();
    if (oldestToast) {
      hotToast.dismiss(oldestToast);
    }
  }

  // Create and show the toast, and store its ID in visibleToasts
  const toastId = hotToast(message, options);
  visibleToasts.unshift(toastId);
  return toastId;
};

// Function to create and display a toast with an icon
const showToastWithIcon = (
  message: any,
  iconName: string,
  backgroundColor: string,
  color: string,
  options?: ToastOptions
) => {
  return showToast(message, {
    ...options,
    // Use Semantic UI React Icon component with the specified icon name and style
    icon: <Icon name={iconName as SemanticICONS} />,
    style: {
      ...options?.style,
      backgroundColor,
      color,
    },
  });
};

// Custom toast methods with styling for info and warning
const customMethods = {
  info: (message: any, theme: string, options?: ToastOptions) =>
    showToastWithIcon(
      message,
      "info circle",
      ThemeSchema.data[theme ?? "dark"]?.colors["toast-info-background"],
      ThemeSchema.data[theme ?? "dark"]?.colors["toast-info-text-color"],
      options
    ),
  warning: (message: any, theme: string, options?: ToastOptions) =>
    showToastWithIcon(
      message,
      "warning sign",
      ThemeSchema.data[theme ?? "dark"]?.colors["toast-warning-background"],
      ThemeSchema.data[theme ?? "dark"]?.colors["toast-warning-text-color"],
      options
    ),
};

// Function to dismiss excess toasts beyond the global limit
const dismissExcessToasts = () => {
  const excessToasts = visibleToasts.slice(toastLimit);
  excessToasts.forEach((toast) => hotToast.dismiss(toast.id));
  visibleToasts = visibleToasts.slice(0, toastLimit);
};

// Function to intercept the hotToast function and apply global toast limit
const interceptHotToast = (
  type: "success" | "error" | "loading" | "custom",
  message: any,
  originalOptions?: ToastOptions
) => {
  const optionsWithStyling = {
    ...originalOptions,
    style: {
      ...originalOptions?.style,
    },
  };

  // If the number of visible toasts is at the limit, dismiss the oldest toast
  if (visibleToasts.length >= toastLimit) {
    const oldestToastId = visibleToasts.pop();
    if (oldestToastId) {
      hotToast.dismiss(oldestToastId);
    }
  }

  // Create and show the toast, and store its ID in visibleToasts
  const toastId = hotToast[type](message, optionsWithStyling);
  visibleToasts.unshift(toastId);
  return toastId;
};

// Function to create the toaster with a global toast limit
const ToasterWithLimit = () => {
  const theme = window.localStorage.getItem("defaultTheme") ?? "dark";
  // Create an object with extended toast functions
  const beamtoast: CustomToastFunctions = Object.assign(
    // Custom function for creating a toast with custom styling
    (message: any, options?: ToastOptions) =>
      interceptHotToast("custom", message, options),
    {
      // Functions for creating toasts with specific types
      success: (message: any, options?: ToastOptions) =>
        interceptHotToast("success", message, {
          ...options,
          style: {
            backgroundColor:
              ThemeSchema.data[theme ?? "dark"]?.colors[
                "toast-success-background"
              ],
            color:
              ThemeSchema.data[theme ?? "dark"]?.colors[
                "toast-success-text-color"
              ],
            ...options?.style,
          },
        }),
      error: (message: any, options?: ToastOptions) =>
        interceptHotToast("error", message, {
          ...options,
          style: {
            backgroundColor:
              ThemeSchema.data[theme ?? "dark"]?.colors[
                "toast-error-background"
              ],
            color:
              ThemeSchema.data[theme ?? "dark"]?.colors[
                "toast-error-text-color"
              ],
            ...options?.style,
          },
        }),
      loading: (message: any, options?: ToastOptions) =>
        interceptHotToast("loading", message, options),
      custom: (message: any, options?: ToastOptions) =>
        interceptHotToast("custom", message, options),

      // Custom methods for info and warning toasts
      info: (message: any, options?: ToastOptions) =>
        customMethods.info(message, theme, options),
      warning: (message: any, options?: ToastOptions) =>
        customMethods.warning(message, theme, options),

      // Functions to dismiss and remove toasts
      dismiss: (toastId?: string) => {
        hotToast.dismiss(toastId);
        visibleToasts = visibleToasts.filter((toast) => toast.id !== toastId);
      },
      remove: (toastId?: string) => {
        hotToast.remove(toastId);
        visibleToasts = visibleToasts.filter((toast) => toast.id !== toastId);
      },
      // Function to set the global toast limit
      setLimit: (newLimit: number) => {
        if (newLimit !== toastLimit) {
          toastLimit = newLimit;
          dismissExcessToasts();
        }
      },
      // Function to create a promise toast
      promise: (promise: Promise<any>, msgs: any, opts?: any) =>
        hotToast.promise(promise, msgs, opts),
    }
  );

  // Component for rendering the toast container
  const ToastContainer: React.FC = () => (
    <HotToaster
      toastOptions={{
        duration: DEFAULT_DURATION,
        style: {
          marginTop: "55px",
          whiteSpace: "wrap",
          wordBreak: "break-word",
        },
      }}
      position="top-right"
    />
  );

  // Return the custom toast functions and the toast container component
  return { beamtoast, ToastContainer };
};

/**  This function is used to show a dismissible toast based on a condition
 * @param condition - The condition to determine whether to show the toast
 * @param toastId - The ID of the toast to show or dismiss
 * @param setToastId - The function to set the toast ID
 * @param message - The message to display in the toast
 * @param options - The options to customize the toast
 */
const handleConditionalDismissibleToast = (
  condition: boolean,
  toastId: string | null,
  setToastId: (id: string | null) => void,
  message: string,
  options: ToastOptions
): void => {
  if (condition) {
    if (!toastId) {
      // Create a new toast and store its ID
      const newToastId = beamtoast.error(message, options);
      setToastId(newToastId);
    }
  } else if (toastId) {
    // Dismiss the toast if the condition is not met
    beamtoast.dismiss(toastId);
    setToastId(null); // Reset the toast ID
  }
};

// Export the custom toast functions and the toast container
const { beamtoast, ToastContainer } = ToasterWithLimit();

// Optional: Export the functions individually if needed
export { beamtoast, ToastContainer, handleConditionalDismissibleToast };

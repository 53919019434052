import React from "react";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

type SelectedDashboardFilterProps = {
  readonly id?: number;
  readonly name: string;
  readonly disabled: boolean;
  readonly marginLeft: string;

  onRemoveClick: () => void;
};

const SelectedFilterWrapper = styled.button<{
  marginLeft: string;
  disabled: boolean;
}>`
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
  background-color: ${(props) =>
    props.theme.colors["active-state-led-panel-label-bg"]};
  border: ${(props) =>
    props.theme.colors["active-state-led-panel-label-border"]};
  border-radius: 5px;
  color: ${(props) => props.theme.colors["text"]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => props.marginLeft};
  height: 35px;
  padding: 0 1rem;
  margin-bottom: 10px;
  width: max-content;
`;

const FilterCrossButton = styled.div<{ disabled: boolean }>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
  background-color: transparent;
  height: 100%;
  color: #1c1b1f;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div<{ disabled: boolean }>`
  transition: ${(props) => (props.disabled ? "" : "0.15s ease all")};
  .iconColor {
    margin: 0px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    color: ${(props) => props.theme.colors["reorder-icon-panel-color"]};

    &:hover {
      transform: ${(props) => (props.disabled ? "" : `scale(1.2)`)};
      color: ${(props) =>
        props.disabled
          ? ""
          : props.theme.colors["reorder-icon-panel-hover-color"]};
    }
  }
`;

export default function SelectedDashboardFilter(
  props: SelectedDashboardFilterProps
) {
  return (
    <SelectedFilterWrapper
      disabled={props.disabled}
      marginLeft={props.marginLeft}
    >
      <p style={{ margin: "0 0.5rem 0 0" }}>{props.name}</p>
      <FilterCrossButton disabled={props.disabled}>
        <IconContainer
          id="icon"
          disabled={props.disabled}
          onClick={() => props.onRemoveClick()}
        >
          <Icon name="close" className="iconColor" />
        </IconContainer>
      </FilterCrossButton>
    </SelectedFilterWrapper>
  );
}

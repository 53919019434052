import uuid from "uuid";
import { TimeRange } from "../../Datetime/TimeRange";
import { ReplayState } from "../../DashboardHeader";
import { PanelDef } from "../PanelDef";
import { ViewLedPanel } from "./ViewLedPanel";
import { EditLedPanelMeta } from "./EditLedPanelMeta";

export type LedPanelColumn = {
  name: string;
  prefix: string;
  suffix: string;
};

// Define default values for on and off states
export const onStateDefaultValues = ["on", "true"];
export const offStateDefaultValues = ["off", "false", ""];

export class LedPanelMetaData {
  type = "led_panel";
  table: string = "";
  columns: Array<LedPanelColumn> = [];
  id: string = uuid.v4();
  title: string = "";
  description: string = "";
  onStateColor: string = "#39fa39";
  onStateTextList: string[] = onStateDefaultValues;
  offStateTextList: string[] = offStateDefaultValues;
  offStateColor: string = "#fa1c1c";
  ledSize: number = 30;
  fleetTableView?: boolean = false;
}

export type TimestampedValue = {
  timestamp: number;
  [key: string]: number;
};

export type LedPanelData = {
  data: TimestampedValue[];
};

function formatData(
  panelData: LedPanelData,
  timeRange: TimeRange,
  replayState: ReplayState,
  replayStep: number
): LedPanelData {
  if (replayState === ReplayState.ReplayStopped) {
    return panelData;
  }

  const data = panelData.data;
  const newData: TimestampedValue[] = [];
  let index = 0;
  const startTimestamp = timeRange.getStartTime().toDate().valueOf();
  const endTimestamp = timeRange.getEndTime().toDate().valueOf();

  for (let i = startTimestamp; i <= endTimestamp; i += replayStep) {
    while (index < data.length) {
      if (data[index].timestamp > i) {
        break;
      }

      index++;
    }

    index = index === 0 ? 0 : index - 1;

    newData.push(data[index]);
  }

  return {
    data: newData,
  };
}

export const LedPanelPanelDef: PanelDef<LedPanelMetaData, LedPanelData> = {
  ViewComponent: ViewLedPanel,
  EditComponent: EditLedPanelMeta,
  formatData: formatData,
  metaConstructor: LedPanelMetaData,
  previewAspectRatio: { width: 500, height: 300 },
};

import React, { useEffect, useState } from "react";
import RemoteShell from "../../DeviceManagement/Devices/ActionModals/RemoteShell/RemoteShell";
import { markActionAsCompleted } from "../../../../BytebeamClient";

type TerminalProps = {
  readonly deviceId: number;
};

function Terminal(props: TerminalProps) {
  const { deviceId } = props;

  const [actionId, setActionId] = useState();

  useEffect(() => {
    // This function is called only when the component is unmounted, marking the action as completed when we switch to another screen.
    return () => {
      if (actionId) {
        try {
          markActionAsCompleted(actionId, { all: true });
        } catch (e) {
          console.log("Failed to mark action as completed", e);
        }
      }
    };
  }, [actionId, deviceId]);

  return (
    <div>
      <RemoteShell deviceId={deviceId} setActionId={setActionId} />
    </div>
  );
}

export default Terminal;

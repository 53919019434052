import React, { memo, useEffect, useState } from "react";
import {
  Dropdown,
  Grid,
  Icon,
  Popup,
  Progress,
  Table,
} from "semantic-ui-react";
import moment from "moment";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { capitalizeFirstLetter } from "../../util";
import styled from "styled-components";
import {
  ActionStatusType,
  approveAction,
  cancelActions,
  markActionAsCompleted,
  retryActions,
} from "../../../../BytebeamClient";
import { useHistory } from "react-router-dom";
import ReleaseNotesModal from "./ReleaseNotesModal";
import ViewPayloadModal from "./ViewPayloadModal";
import ConfirmationModalMessageNonDelete from "../../common/ConfirmationModalMessageNonDelete";
import ConfirmationModal from "../../common/ConfirmationModal";
import { useUser } from "../../../../context/User.context";
import { beamtoast } from "../../../common/CustomToast";
import { isActionCompletedOrFailed, isActionFailed } from "../util";
import LoadingAnimation from "../../../common/Loader";

export const ActionTable = styled(Table)`
  & tbody tr {
    td {
      padding: 2rem 1rem !important;
    }
  }
`;

export const ActionTableRow = styled(Table.Row)`
  cursor: pointer;

  &.selected {
    background-color: ${({ theme }) =>
      theme.colors["action-selected-table-background"]};
    color: ${({ theme }) => theme.colors["action-selected-table-text-color"]};
    border-left: 4px solid
      ${({ theme }) => theme.colors["action-selected-table-border-color"]};
  }

  & .checkbox-hidden {
    opacity: 0;
  }

  &:hover .checkbox-hidden {
    opacity: 1;
  }
`;

function sumObjectValues(obj: { [key: string]: number }): number {
  return Object.values(obj).reduce((sum, current) => sum + current, 0);
}

type LiveActionsListProp = {
  readonly action: ActionStatusType[];
  readonly users: { name: string; email: string }[];
  readonly selectedAction: ActionStatusType;
  readonly setSelectedAction: (action: ActionStatusType) => void;
  readonly setSelectedPhase: (phase: string) => void;
  readonly liveActionsListLoading: boolean;
};

const LiveActionsList = (props: LiveActionsListProp) => {
  const history = useHistory();
  const { user } = useUser();
  const currentUserRole = user.role.name;

  const {
    action,
    users,
    setSelectedAction,
    selectedAction,
    setSelectedPhase,
    liveActionsListLoading,
  } = props;
  const [email, setEmail] = useState<{ [key: string]: string }>({});
  const [showReleaseNotes, setShowReleaseNotes] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState("");
  const [showPayload, setShowPayload] = useState(false);

  useEffect(() => {
    const emailMap: { [key: string]: string } = {};

    action.forEach((actionItem) => {
      const user = users.find((user) => user.name === actionItem.user_name);
      if (selectedAction.action_id === actionItem.action_id) {
        setSelectedAction(actionItem);
      }
      if (user) {
        emailMap[actionItem.user_name] = user.email;
      }
    });

    setEmail(emailMap);
  }, [action, users, selectedAction.action_id, setSelectedAction]);

  function getActionProgressStatus(action: ActionStatusType) {
    const numTotal: number = Object.values(action.statuses).reduce(
      (a: number, b: number) => a + b,
      0
    );
    const {
      PendingApproval,
      Scheduled,
      Queued,
      Initiated,
      Failed,
      Completed,
      ...remaining
    } = action.statuses;

    let InProgress = 0;
    Object.keys(remaining).forEach((status) => {
      InProgress += remaining[status];
    });

    let progressPercentage =
      (((Completed ?? 0) + (Failed ?? 0)) / numTotal) * 100;
    if (Failed) {
      return {
        status: "Failed",
        progress: progressPercentage.toFixed(1),
        className: "failed",
      };
    } else if (Completed) {
      // calculate progress
      return {
        status: "Completed",
        progress: progressPercentage.toFixed(1),
        className: "completed",
      };
    } else if (Initiated === numTotal) {
      return {
        status: "Initiated",
        progress: 100,
        className: "initiated",
      };
    } else if (Queued === numTotal) {
      return {
        status: "Queued",
        progress: 100,
        className: "queued",
      };
    } else if (Scheduled === numTotal) {
      return {
        status: "Scheduled",
        progress: 100,
        className: "scheduled",
      };
    } else if (PendingApproval === numTotal) {
      return {
        status: "Pending Approval",
        progress: 100,
        className: "pending_approval",
      };
    } else if (
      Initiated &&
      (Queued || Scheduled || PendingApproval) &&
      Initiated + (Queued ?? 0) + (Scheduled ?? 0) + (PendingApproval ?? 0) ===
        numTotal
    ) {
      // calculate progress
      return {
        status: "Initiated",
        progress: ((Initiated / numTotal) * 100).toFixed(1),
        className: "initiated",
      };
    } else {
      // calculate progress
      return {
        status: "InProgress",
        progress: ((InProgress / numTotal) * 100).toFixed(1),
        className: "in-progress",
      };
    }
  }

  async function approvePhasedAction(actionId: string) {
    try {
      await approveAction(actionId);
      beamtoast.success(`Action ${actionId} approved successfully`);
    } catch (error) {
      console.log(error);
      beamtoast.error(`Failed to approve action ${actionId}`);
    }
  }

  type handleCancelOrRetryActionType = {
    actionId: string;
    operation: "cancel" | "retry";
  };

  /**
   * Handles the cancellation or retry of an action.
   * @param actionId - The action ID to be cancelled or retried.
   * @param operation - The operation to be performed on the action.
   */
  const handleCancelOrRetryAction = async ({
    actionId,
    operation,
  }: handleCancelOrRetryActionType) => {
    try {
      if (operation === "cancel") {
        await cancelActions(actionId, { all: true });
        beamtoast.success(`Action ${actionId} cancelled successfully`);
      } else if (operation === "retry") {
        await retryActions(actionId, { all: true });
        beamtoast.success(`Action ${actionId} retried successfully`);
      }
    } catch (error) {
      console.log(error);
      beamtoast.error(`Failed to ${operation} action ${actionId}`);
    }
  };

  const handleMarkAction = async (actionID: string) => {
    try {
      if (actionID) {
        await markActionAsCompleted(actionID, { all: true });
        beamtoast.success(`Marked action ${actionID} as completed`);
      }
    } catch (e) {
      console.log(e);
      beamtoast.error(`Failed to mark action ${actionID} as completed`);
    }
  };

  const handleConfirmMarkComplete = (actionId: string) => {
    if (user.role.permissions.allowMarkActionAsCompleted)
      handleMarkAction(actionId);
  };

  return (
    <Grid style={{ marginBottom: "20px" }}>
      <ReleaseNotesModal
        isOpen={showReleaseNotes}
        onClose={() => setShowReleaseNotes(false)}
        releaseNotes={releaseNotes}
      />
      <ViewPayloadModal
        isOpen={showPayload}
        onClose={() => setShowPayload(false)}
        actionId={selectedAction.action_id}
      />
      <Grid.Row>
        <Grid.Column>
          <ActionTable
            id="live_action-list"
            selectable
            style={{ borderCollapse: "collapse" }}
          >
            <Table.Header>
              <Table.Row textAlign={"center"}>
                <Table.HeaderCell>Action ID</Table.HeaderCell>
                <Table.HeaderCell>Action Type</Table.HeaderCell>
                <Table.HeaderCell>Triggered At</Table.HeaderCell>
                <Table.HeaderCell>Triggered By</Table.HeaderCell>
                <Table.HeaderCell>Triggered On</Table.HeaderCell>
                <Table.HeaderCell>
                  Device(s) Stats
                  <Popup
                    inverted
                    trigger={
                      <Icon
                        name="question circle"
                        style={{ marginBottom: "2px", marginLeft: "2px" }}
                      />
                    }
                    content={"% of devices in action state."}
                    position="top center"
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>Operations</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!liveActionsListLoading && action?.length !== 0
                ? action.map((action) => {
                    let actionProgress = getActionProgressStatus(action);
                    let noOfDevices: number | string =
                      sumObjectValues(action.statuses) ?? "--";

                    return (
                      <ActionTableRow
                        textAlign={"center"}
                        key={action.action_id}
                        className={
                          selectedAction.action_id === action.action_id
                            ? "selected"
                            : ""
                        }
                        onClick={() => {
                          setSelectedAction(action);
                          setSelectedPhase("all");
                        }}
                        onDoubleClick={(e: Event) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedAction(action);
                          history.push(`?action_id=${action.action_id}`);
                        }}
                      >
                        <Table.Cell>{action.action_id}</Table.Cell>
                        <Table.Cell>{action.type}</Table.Cell>
                        <Table.Cell>
                          <Popup
                            content={moment(action.created_at).format(
                              "ddd, MMM Do YYYY, HH:mm:ss"
                            )}
                            position="top center"
                            inverted
                            trigger={
                              <div>
                                {action.created_at
                                  ? capitalizeFirstLetter(
                                      moment(action.created_at).fromNow()
                                    )
                                  : "--"}
                              </div>
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {email[action.user_name] ? (
                            <Popup
                              content={email[action.user_name]}
                              position="top center"
                              inverted
                              trigger={<div>{action.user_name}</div>}
                            />
                          ) : (
                            <div>{action.user_name}</div>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {typeof noOfDevices === "number"
                            ? `${noOfDevices} ${noOfDevices > 1 ? "devices" : "device"}`
                            : noOfDevices}
                        </Table.Cell>
                        <Table.Cell width={4}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Popup
                              content={actionProgress.status}
                              position="top center"
                              inverted
                              trigger={
                                <Progress
                                  progress
                                  indicating={
                                    !(
                                      actionProgress.status === "Completed" ||
                                      actionProgress.status === "Failed"
                                    )
                                  }
                                  percent={actionProgress.progress}
                                  className={actionProgress.className}
                                  style={{
                                    width: "150px",
                                    marginBottom: "0px",
                                  }}
                                />
                              }
                            />
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Dropdown
                              style={{ border: "none" }}
                              direction="left"
                              trigger={<Icon name="ellipsis vertical" />}
                              icon={null}
                            >
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    setSelectedAction(action);
                                    history.push(
                                      `?action_id=${action.action_id}`
                                    );
                                  }}
                                >
                                  <Icon name="eye" />
                                  View Details
                                </Dropdown.Item>

                                {currentUserRole === "admin" && (
                                  <Dropdown.Item
                                    disabled={
                                      action?.schedule?.status !==
                                      "pending_approval"
                                    }
                                    onClick={() => {
                                      setSelectedAction(action);
                                      approvePhasedAction(
                                        String(action.action_id)
                                      );
                                    }}
                                  >
                                    <Icon name="check" />
                                    Approve Action
                                  </Dropdown.Item>
                                )}

                                {
                                  // Only allow cancel action if the user has the permission for the action.
                                  user.role.permissions.allowedActions.includes(
                                    action.type
                                  ) && (
                                    <ConfirmationModal
                                      prefixContent="Cancel Action"
                                      expectedText={String(action.action_id)}
                                      onConfirm={() => {
                                        setSelectedAction(action);
                                        handleCancelOrRetryAction({
                                          actionId: String(action.action_id),
                                          operation: "cancel",
                                        });
                                      }}
                                      trigger={
                                        <Dropdown.Item
                                          disabled={
                                            action.type === "cancel_action" ||
                                            action.type === "launch_shell" ||
                                            isActionCompletedOrFailed(action)
                                          }
                                        >
                                          <Icon name="cancel" />
                                          Cancel Action
                                        </Dropdown.Item>
                                      }
                                      message={
                                        <ConfirmationModalMessageNonDelete
                                          name={"Cancel Action"}
                                          expectedText={String(
                                            action.action_id
                                          )}
                                          type={""}
                                          specialMessage={`Action-ID: ${action.action_id}, Type: ${action.type}`}
                                        />
                                      }
                                    />
                                  )
                                }

                                {
                                  // Show the retry action option only if the user has the permission for the action.
                                  user.role.permissions.allowedActions.includes(
                                    action.type
                                  ) && (
                                    <ConfirmationModal
                                      prefixContent="Retry Action"
                                      expectedText={String(action.action_id)}
                                      onConfirm={() => {
                                        setSelectedAction(action);
                                        handleCancelOrRetryAction({
                                          actionId: String(action.action_id),
                                          operation: "retry",
                                        });
                                      }}
                                      trigger={
                                        <Dropdown.Item
                                          disabled={
                                            !isActionFailed(action) ||
                                            action.type === "cancel_action" ||
                                            action?.type === "launch_shell"
                                          }
                                        >
                                          <Icon name="redo" />
                                          Retry Action
                                        </Dropdown.Item>
                                      }
                                      message={
                                        <ConfirmationModalMessageNonDelete
                                          name={"Retry Action"}
                                          expectedText={String(
                                            action.action_id
                                          )}
                                          type={""}
                                          specialMessage={`Action-ID: ${action.action_id}, Type: ${action.type}`}
                                        />
                                      }
                                    />
                                  )
                                }

                                {user.role.permissions
                                  .allowMarkActionAsCompleted && (
                                  <ConfirmationModal
                                    prefixContent="Mark Action as Complete"
                                    expectedText={String(action.action_id)}
                                    onConfirm={() => {
                                      setSelectedAction(action);
                                      handleConfirmMarkComplete(
                                        String(action.action_id)
                                      );
                                    }}
                                    trigger={
                                      <Dropdown.Item
                                        disabled={
                                          action.type === "cancel_action" ||
                                          isActionCompletedOrFailed(action)
                                        }
                                      >
                                        <Icon name="check square" />
                                        Mark Action as completed
                                      </Dropdown.Item>
                                    }
                                    message={
                                      <ConfirmationModalMessageNonDelete
                                        name={"Mark Action as Complete"}
                                        expectedText={String(action.action_id)}
                                        type={""}
                                        specialMessage={`This will mark the Action(id: ${action.action_id}, type: ${action.type}) triggered on all Devices which are not in completed/failed states as completed.`}
                                      />
                                    }
                                  />
                                )}

                                <Dropdown.Item
                                  disabled={
                                    action.payload_type === "none" &&
                                    action.type !== "send_file" &&
                                    action.type !== "send_script"
                                  }
                                  onClick={() => {
                                    setSelectedAction(action);
                                    setShowPayload(true);
                                  }}
                                >
                                  <Icon name="file code outline" />
                                  View Payload
                                </Dropdown.Item>

                                <Dropdown.Item
                                  disabled={
                                    typeof action?.schedule?.release_notes !==
                                    "string"
                                  }
                                  onClick={() => {
                                    setSelectedAction(action);
                                    setShowReleaseNotes(true);
                                    setReleaseNotes(
                                      action?.schedule?.release_notes
                                    );
                                  }}
                                >
                                  <Icon name="sticky note outline" />
                                  Release Notes
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Table.Cell>
                      </ActionTableRow>
                    );
                  })
                : !liveActionsListLoading && (
                    <Table.Row>
                      <Table.Cell colSpan={"7"}>
                        <div
                          style={{
                            height: "60vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <ErrorMessage
                            marginTop="30px"
                            message={
                              "No actions found! Please trigger an action to view its progress here."
                            }
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  )}
              {liveActionsListLoading && (
                <Table.Row>
                  <Table.Cell colSpan={"7"}>
                    <LoadingAnimation
                      loadingText="Loading Actions List"
                      loaderSize="50px"
                      fontSize="18px"
                      marginTopText="10px"
                      loaderContainerMargin="60px 0px 60px 0px"
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </ActionTable>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default memo(LiveActionsList);
